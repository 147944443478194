import {
  SET_BUDGET_REPORT_PROVIDER,
  SET_CAMPAIGN_PROVIDER,
} from '../ActionNames';

const campaignProvider = (
  state: any = { campaignProvider: null },
  action: any,
) => {
  switch (action.type) {
    case SET_CAMPAIGN_PROVIDER:
      return { ...state, campaignProvider: action.payload };
    default:
      return state;
  }
};

const budgetReportProvider = (
  state: any = { provider: 'facebook' },
  action: any,
) => {
  switch (action.type) {
    case SET_BUDGET_REPORT_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
    default:
      return state;
  }
};

export { campaignProvider, budgetReportProvider };
