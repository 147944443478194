import { Avatar, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ChatUser } from '../../../types';
import styles from '../../../assets/styles/components/Forms/DesktopChatDialog.module.scss';
import BadgeIcon from '@mui/icons-material/Badge';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FlagIcon from '@mui/icons-material/Flag';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import moment from 'moment';
import { humanizeString } from '../../../utils/stringModifier';
import { chatResult } from '../../../utils/chatDialogHelper';

interface DesktopChatDialogUserDetailsProps {
  chat: ChatUser;
}

const DesktopChatDialogUserDetails: React.FC<
  DesktopChatDialogUserDetailsProps
> = ({ chat }) => {
  return (
    <Grid item xs={3} sx={{ borderLeft: '1px solid #e0e0e0' }}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <Avatar
            alt="test"
            src={chat?.profilePic}
            sx={{
              width: 80,
              height: 80,
              justifyContent: 'center',
              display: 'flex',
              margin: 'auto',
            }}
          />

          <div className={styles.name}>
            <Typography variant="h6" color="#484848" fontWeight="bold">
              {chat?.name}
            </Typography>
          </div>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <BadgeIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.chatuserId || '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <AccessTimeIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat
              ? moment
                  .utc(chat?.lastInteraction)
                  .local()
                  .format('MMM DD, YYYY hh:mm a')
              : '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <EmailIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.candidateemail ? chat?.candidateemail : '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <LocalPhoneIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.candidatephone ? chat?.candidatephone : '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <LocationOnIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat ? humanizeString(chat?.territory) : '--'}
          </Typography>
        </div>

        <Divider />

        <div className={styles.title}>
          <Typography
            variant="h6"
            color="#484848"
            fontSize="15px"
            fontWeight="bold"
          >
            Other Info
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <BusinessIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.brand?.agency.agency || '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <LocalOfferOutlinedIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.brand?.brand || '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <AccountCircleIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat?.salesperson?.name || '--'}
          </Typography>
        </div>

        <div className={styles.details}>
          <Typography variant="subtitle1" color="#484848" fontSize="15px">
            <FlagIcon sx={{ position: 'relative', top: 5 }} />{' '}
            {chat
              ? chatResult(chat?.result, chat?.investment, chat?.brand)
              : '--'}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default DesktopChatDialogUserDetails;
