import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context';
import { fetchFBPages } from '../Auth/FetchFacebookPages';
import { PrimaryButton } from '../Buttons';
import ModalHeader from './ModalHeader';
import styles from '../../assets/styles/components/Modal/PageListModal.module.scss';
import { ChangeEventType, PageData, PageFormFields } from '../../types';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface PageListModalProps {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  handleConnectPage: (
    obj: PageFormFields,
    objectId: string,
    model: string,
  ) => void;
  objectId: string;
  model: string;
  defaultAccessToken?: string;
}

const PageListModal: React.FC<PageListModalProps> = ({
  open,
  handleClose,
  loading,
  handleConnectPage,
  objectId,
  model,
  defaultAccessToken,
}) => {
  const dispatch = useDispatch();
  const [authorizedPages, setAuthorizedPages] = useState<PageData[]>(null);
  const [filteredResults, setFilteredResults] = useState<PageData[]>(null);
  const [search, setSearch] = useState<string>('');
  const { state } = useContext(AuthContext);
  const accessToken = defaultAccessToken
    ? defaultAccessToken
    : state.authUser?.accessToken;

  useEffect(() => {
    if (open) {
    }
  }, [open]);

  useEffect(() => {
    if (accessToken !== undefined) {
      fetchFBPages(accessToken, 'get')
        .then((result: any) => {
          setAuthorizedPages(result?.data || []);
          setFilteredResults(result?.data || []);
        })
        .catch((error: any) => {
          // TODO: Create a Facebook API Call Error Message Parser
          dispatch(
            toggleAlert({
              toggle: true,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (search) {
      const data = authorizedPages.filter((page: PageData) =>
        page.name.toLowerCase().includes(search.toLowerCase()),
      );

      setFilteredResults(data);
    } else {
      setFilteredResults(authorizedPages);
    }
  }, [search]);

  useEffect(() => {
    if (!open) setSearch(null);
  }, [open]);

  const handleOnSearch = (e: ChangeEventType) => {
    setSearch(e.target.value);
  };

  const renderCards = (authorizedPages: PageData[]) => {
    return authorizedPages?.map((page: PageData) => {
      const formObj = {
        fbPageId: page.id,
        accessToken: page.access_token,
        name: page.name,
        imageUrl: page.picture.data.url,
      };
      return (
        <Grid
          key={page.id}
          item
          sm={12}
          lg={authorizedPages?.length === 1 ? 12 : 6}
          sx={{
            width: '100%',
          }}
        >
          <Card>
            <CardHeader
              sx={{
                display: 'flex',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',

                paddingBottom: '0',
              }}
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={page?.picture.data.url}
                ></Avatar>
              }
              titleTypographyProps={{ fontWeight: 'bold' }}
              title={page?.name}
              subheader={page?.id}
            />

            <CardActions sx={{ justifyContent: 'flex-end' }} disableSpacing>
              <PrimaryButton
                type="button"
                title="Connect"
                loading={loading}
                handleOnClick={() => {
                  handleConnectPage(formObj, objectId, model);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  const noAllowedPageTemplate = () => {
    return (
      <div className={`${styles['no-content']}`}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          No Allowed Facebook page.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Add allowed Facebook Pages via Facebook Login dialog settings.
        </Typography>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div
          className={`${styles.pages} ${
            filteredResults?.length === 1 && search === ''
              ? styles['-single']
              : ''
          }`}
        >
          <ModalHeader title="Page List" />

          <div className={styles.search}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              onChange={handleOnSearch}
              value={search}
              autoFocus
              size="small"
              sx={{
                '& .MuiInputBase-root': {
                  '&:has(> input:-webkit-autofill)': {
                    backgroundColor: 'rgb(232, 240, 254)',
                  },
                },
              }}
              InputProps={{ sx: { borderRadius: '49px' } }}
            />
          </div>

          {filteredResults?.length === 0 ? noAllowedPageTemplate() : null}

          {filteredResults?.length > 0 ? (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                paddingTop: '24px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: '50vh',
                '&::-webkit-scrollbar': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#F1F0F0',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                },

                '&::-webkit-scrollbar-thumb': {
                  background: DEFAULT_COLOR_THEME,
                  borderRadius: '18px',

                  '&:hover': {
                    background: DEFAULT_COLOR_THEME,
                  },
                },
              }}
            >
              {renderCards(filteredResults)}
            </Grid>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default PageListModal;
