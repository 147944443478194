import {
  Box,
  Grid,
  Tab,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styles from '../../../assets/styles/pages/Reports.module.scss';
import CircularLoading from '../../CircularLoading';
import PageHeader from '../../PageHeader';
import { ChangeEventType, AIReportSettings as Settings } from '../../../types';
import { getSingleAiReportSettings } from '../../../services/report';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { camelCaseToHumanized } from '../../../utils/stringModifier';
import FormHeader from '../FormHeader';
import { DEFAULT_COLOR_THEME } from '../../../utils/Styling';

const AIReportSettings: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const [tabs, setTabs] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState<string>('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Settings>({
    platform: 'facebook',
    performanceScore: {
      primaryText: '',
      secondaryText: '',
      scoreText: [],
      ratings: [],
      summaryText: [],
    },
    cpcScore: {
      ratingText: [],
      ratings: [],
      scores: [],
    },
    cpcChangeScore: {
      ratings: [],
      scores: [],
    },
    ctrScore: {
      ratingText: [],
      ratings: [],
      scores: [],
    },
    ctrChangeScore: {
      ratings: [],
      scores: [],
    },
    interestScore: {
      mainText: '',
      ratings: [],
      ratingText: [],
    },
    cplScore: {
      ratings: [],
      scores: [],
    },
    conversionScore: {
      ratings: [],
      scores: [],
    },
    conversionRateScore: {
      mainText: '',
      ratings: [],
    },
    cpqlScore: {
      ratings: [],
      scores: [],
    },
    qcvrScore: {
      ratings: [],
      scores: [],
    },
    qualifiedLeadScore: {
      mainText: '',
      ratingText: [],
    },
    additionalInsightsText: {
      text: '',
    },
    frequencyScore: {
      ratingText: [],
      ratings: [],
      scores: [],
    },
    clickToLeadScore: {
      ratings: [],
      ratingText: [],
    },
    backgroundQualityScore: {
      text: '',
    },
    additionalTips: {
      text: '',
    },
    acquisitionScore: {
      ratings: [],
    },
  });

  useEffect(() => {
    getAiReportSettings();
  }, []);

  useEffect(() => {
    buildTabs();
  }, [formValues]);

  const buildFormHeader = (tab: string) => {
    let header: string = '';

    switch (tab) {
      default:
        header = camelCaseToHumanized(tab);
        break;
    }

    return header;
  };

  const getAiReportSettings = async () => {
    try {
      const response = await getSingleAiReportSettings('facebook');

      setFormValues(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const buildTabs = () => {
    let temp: any[] = [];

    Object.keys(formValues).forEach((key: any) => {
      if (['platform', '_id', '__v'].includes(key)) return;

      const params = {
        label: camelCaseToHumanized(key),
        key,
      };

      temp = [...temp, params];
    });

    setTabs(temp);
  };

  const handleOnChange = (e: ChangeEventType, tab: string) => {
    const name = e.target.name;
    let temp: Settings = { ...formValues };

    switch (tab) {
      case 'performanceScore':
        temp = {
          ...temp,
          performanceScore: {
            ...temp.performanceScore,
            [name]: e.target.value,
          },
        };
        break;
      default:
        break;
    }

    setFormValues(temp);
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'performanceScore') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormHeader title={buildFormHeader(tab)} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id={`${tab}-primaryText`}
              name="primaryText"
              label="Primary Text"
              onChange={(e: ChangeEventType) => handleOnChange(e, tab)}
              value={formValues?.performanceScore?.primaryText}
              InputLabelProps={{ shrink: true }}
              size="small"
              //error={errors?.agency ? true : false}
              //helperText={errors?.agency ? errors.agency : ''}
              autoFocus
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id={`${tab}-secondaryText`}
              name="secondaryText"
              label="Secondary Text"
              onChange={(e: ChangeEventType) => handleOnChange(e, tab)}
              value={formValues?.performanceScore?.secondaryText}
              InputLabelProps={{ shrink: true }}
              size="small"
              //error={errors?.agency ? true : false}
              //helperText={errors?.agency ? errors.agency : ''}
              multiline
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box className={styles.report}>
      <CircularLoading loading={loading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="AI Analyz Settings" />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <TabList
                onChange={handleChangeTabValue}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
                textColor="secondary"
                TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                TabScrollButtonProps={{
                  style: {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                  },
                }}
                sx={{
                  backgroundColor: DEFAULT_COLOR_THEME,
                  '.MuiTab-root': {
                    top: 5,
                    color: 'white',
                  },
                  '.Mui-selected': {
                    color: `${DEFAULT_COLOR_THEME} !important`,
                    backgroundColor: 'white',
                    top: 5,
                    borderRadius: 2,
                  },
                }}
              >
                {tabs?.map((tab: any, index: number) => (
                  <Tab label={tab.label} value={`${index + 1}`} />
                ))}
              </TabList>

              {tabs?.map((tab: any, index: number) => {
                return (
                  <TabPanel
                    value={`${index + 1}`}
                    sx={{
                      backgroundColor: '#d9d9d933',
                      minHeight: 'calc(100vh - 310px)',
                    }}
                    key={`billing-tab-${index}`}
                  >
                    {renderTabContent(tab.key)}
                  </TabPanel>
                );
              })}
            </TabContext>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default AIReportSettings;
