import React, { FormEvent, useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthForm from '../../components/Forms/AuthForm';
import { AuthContext } from '../../context';
import { ChangeEventType, IUser } from '../../types';
import { createUser } from '../../services/user';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../../services/axios-client';
import { AUTH_TOKEN, IS_DIY_ADZ, LOGGED_IN_USER } from '../../utils';
import styles from '../../assets/styles/components/Forms/AuthForm.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';

interface DecodeToken {
  email: string;
  invitationId: string;
  roleBasedId: string;
}

const Join: React.FC = () => {
  const reduxDispatch = useDispatch();
  const { token } = useParams();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [roleBasedId, setRoleBasedId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    password: '',
    email: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const decodeToken = async () => {
      try {
        const decoded: DecodeToken = await jwtDecode(token ? token : '');
        setEmail(decoded.email);
        setRoleBasedId(decoded.roleBasedId);
      } catch (error: any) {
        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: 'Invalid Token',
            type: 'error',
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    };

    decodeToken();
  }, [token]);

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      const obj: IUser = {
        email,
        password,
        token,
        roleBasedId,
        confirmPassword,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      };

      const {
        token: userToken,
        user,
        page,
        brand,
        redirectUrl,
        name,
        message,
      } = await createUser(obj);

      if (token) {
        const decode: any = await jwtDecode(userToken);

        await dispatch({
          type: LOGGED_IN_USER,
          // TODO: need set the user info on payload obj
          payload: {
            token: userToken,
            role: user.role,
            roleBasedId: user.roleBasedId,
            capabilities: decode.capabilities,
            withBrandAccess: decode.withBrandAccess,
            withAgencyAccess: decode.withAgencyAccess,
            user: {
              id: user._id,
              email: user.email,
              page,
              brand,
              name,
              redirectUrl,
            },
          },
        });

        localStorage.setItem(AUTH_TOKEN, userToken);
        setAuthToken(userToken);
        reduxDispatch(toggleAlert({ toggle: true, message }));

        navigate(IS_DIY_ADZ ? '/scorecardz' : redirectUrl, {
          replace: true,
        });
      }
    } catch (error: any) {
      console.log(error);
      const err = error.response?.data;

      setErrors({ ...errors, email: err?.email, password: err?.password });

      if (!err?.email && !err?.password) {
        const erroMsg = errorMessageParser(error);

        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: erroMsg || 'Invalid Credentials',
            type: 'error',
            horizontal: 'center',
            vertical: 'top',
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    }
  };

  return (
    <div className={`${styles.join}`}>
      <AuthForm
        handleOnSubmit={handleOnSubmit}
        handleOnChange={handleOnChange}
        title="Join Us"
        email={email}
        password={password}
        isLogin={false}
        isJoin={true}
        errors={errors}
        loading={loading}
        role={null}
        confirmPassword={confirmPassword}
      />
    </div>
  );
};

export default Join;
