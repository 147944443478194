import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { Brand } from '../../../../types';
import GGTemplateCreation from './GGTemplateCreation';
import PageHeader from '../../../PageHeader';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import {
  fetchGoogleTemplates,
  setGoogleTemplates,
} from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import GoogleTemplatesPreview from './GGLTemplatesPreview';
import { NoContent } from '../../../../pages/NoContent';
import GoogleTemplateSummary from './GGLTemplateSummary';

interface GGTemplatezIndexProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
}

const styles = {
  header: {
    marginBottom: '20px',
  },
};

const GGTemplatezIndex: React.FC<GGTemplatezIndexProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
}) => {
  const [templateCollection, setTemplateCollection] = useState([]);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectionURL, setSelectionURL] = React.useState(true);
  const [selectionKeywords, setSelectionKeywords] = React.useState(true);
  const [selectionPhoneNumber, setSelectionPhoneNumber] = React.useState(true);
  const [selectionHeadlines, setSelectionHeadlines] = React.useState(true);
  const [selectionDescriptions, setSelectionDescriptions] =
    React.useState(true);
  const [selectionCallouts, setSelectionCallouts] = React.useState(true);
  const [selectionSitelinks, setSelectionSitelinks] = React.useState(true);
  const [selectionImages, setSelectionImages] = React.useState(true);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editPages, setEditPages] = React.useState([]);
  const [isExisting, setIsExisting] = React.useState(false);

  const dispatch = useDispatch();

  const handleTemplateEdit = () => {
    setEditModalOpen(true);
  };

  const handleCloseTemplateEdit = () => {
    setEditModalOpen(false);
    setSelectionURL(false);
    setSelectionKeywords(false);
    setSelectionPhoneNumber(false);
    setSelectionHeadlines(false);
    setSelectionDescriptions(false);
    setSelectionCallouts(false);
    setSelectionSitelinks(false);
    setSelectionImages(false);
  };

  const handleEditTemplateContinue = () => {
    const selectedFields: {
      [key: string]: boolean;
    } = {
      URL: selectionURL,
      Keywords: selectionKeywords,
      'Keywords Matching Type': selectionKeywords,
      'Phone Number': selectionPhoneNumber,
      Headlines: selectionHeadlines,
      Descriptions: selectionDescriptions,
      Callouts: selectionCallouts,
      Sitelinks: selectionSitelinks,
      Images: selectionImages,
      Preview: true,
    };
    const editPages = Object.keys(selectedFields).filter(
      (field) => selectedFields[field],
    );
    console.log('EDIT TEMPLATE', editPages);
    setEditModalOpen(false);
    setEditPages(editPages);
    setIsExisting(true);
    setIsCreatingTemplate(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
  };

  const getTemplates = async () => {
    setIsLoading(true);
    const ts = await fetchGoogleTemplates(brand?._id);

    console.log('ts', ts);
    if (ts.data) {
      setTemplateCollection(ts.data);
    }

    setIsLoading(false);
  };

  const checkDuplicateProductAndService = (productAndService: string) => {
    if (
      isExisting &&
      selectedTemplate.product_and_service === productAndService
    ) {
      return true;
    }
    return templateCollection
      .map((t) => t.product_and_service)
      .includes(productAndService);
  };

  const saveTempate = async (template: any) => {
    console.log('template', template);
    setIsLoading(true);
    const templateNames = templateCollection.map((t) => t.name);

    if (templateNames.includes(template.name) && !isExisting) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Template with the name ${template.name} already exists`,
          type: 'error',
        }),
      );
      setIsLoading(false);
      return { error: 'Template Name already exists' };
    }

    // if (templateProductAndServices.includes(template.product_and_service)) {
    //   dispatch(
    //     toggleAlert({
    //       toggle: true,
    //       message: `Template with the product and service ${template.product_and_service} already exists`,
    //       type: 'error',
    //     }),
    //   );
    //   setIsLoading(false);
    //   return { error: 'Template Product and Service already exists' };
    // }

    const saveTp = await setGoogleTemplates({
      brandId: brand?._id,
      ...template,
      category: 'search',
    });

    if (saveTp) {
      setTemplateCollection([
        ...templateCollection,
        { ...template, category: 'search' },
      ]);
    }

    setIsLoading(false);
    setIsCreatingTemplate(false);
    dispatch(
      toggleAlert({
        toggle: true,
        message: `Template Saved Successfully`,
      }),
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const PreviewDrawer = (
    <Box
      sx={{ width: 420, ...scrollBarGreen }}
      role="presentation"
      onClick={() => {
        setOpen(false);
      }}
    >
      <Box
        bgcolor={`#eeeeee`}
        sx={{
          position: `sticky`,
          top: 0,
        }}
      >
        <Typography variant="h6" sx={{ padding: 2 }}>
          Template Details
        </Typography>
      </Box>
      <Divider />
      <Box paddingTop={2}>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>Name:</Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.name}
        </Typography>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Product and Service:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.product_and_service}
        </Typography>

        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Category:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.category}
        </Typography>

        <Divider />

        <GoogleTemplatesPreview
          headlines={selectedTemplate.headlines}
          descriptions={selectedTemplate.descriptions}
          sitelinks={selectedTemplate.sitelinks}
          url={selectedTemplate.final_url}
          showToolbar={false}
          images={selectedTemplate.images?.map((i: any) => i.url)}
          business_logo={selectedTemplate.business_logo?.url}
        />
      </Box>

      <Divider />

      {Object.keys(selectedTemplate).length > 0 ? (
        <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <GoogleTemplateSummary template={selectedTemplate} />
        </Box>
      ) : null}

      <Divider />
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: `#eeeeee`,
          display: `flex`,
          justifyContent: `space-between`,
          paddingX: 1,
          paddingY: 2,
          alignItems: `center`,
          width: `100%`,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30 }}
          onClick={handleEditTemplateContinue}
        >
          Edit
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30 }}
          onClick={() => {
            console.log('Edit');
          }}
        >
          Duplicate
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30 }}
          onClick={() => {
            console.log('Edit');
          }}
        >
          Archive
        </Button>
      </Box>
    </Box>
  );

  const EditModal = (
    <Modal
      open={editModalOpen}
      // onClose={handleCloseConfirmOverwrite}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color={DEFAULT_COLOR_THEME}
          sx={{
            display: `flex`,
            justifyContent: `start`,
            gap: 1,
            alignItems: `center`,
            fontWeight: 600,
          }}
        >
          Template Edit
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          You are about to edit the template:{' '}
          <Typography
            sx={{
              display: `inline`,
              color: DEFAULT_COLOR_THEME,
              fontWeight: 600,
            }}
          >
            {selectedTemplate.name}
          </Typography>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Select the fields you want to edit for this template.
        </Typography>
        <Box sx={{ marginBottom: 2, paddingLeft: `20px` }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionURL}
                  onChange={() => {
                    setSelectionURL(!selectionURL);
                  }}
                />
              }
              label="URL"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionKeywords}
                  onChange={() => {
                    setSelectionKeywords(!selectionKeywords);
                  }}
                />
              }
              label="Keywords & Keyword Match Types"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionPhoneNumber}
                  onChange={() => {
                    setSelectionPhoneNumber(!selectionPhoneNumber);
                  }}
                />
              }
              label="Phone Number"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionHeadlines}
                  onChange={() => {
                    setSelectionHeadlines(!selectionHeadlines);
                  }}
                />
              }
              label="Headlines"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionDescriptions}
                  onChange={() => {
                    setSelectionDescriptions(!selectionDescriptions);
                  }}
                />
              }
              label="Descriptions"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionCallouts}
                  onChange={() => {
                    setSelectionCallouts(!selectionCallouts);
                  }}
                />
              }
              label="Callouts"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionSitelinks}
                  onChange={() => {
                    setSelectionSitelinks(!selectionSitelinks);
                  }}
                />
              }
              label="Sitelinks"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionImages}
                  onChange={() => {
                    setSelectionImages(!selectionImages);
                  }}
                />
              }
              label="Business Logo / Images"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            gap: 2,
          }}
        >
          <Button onClick={handleCloseTemplateEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleEditTemplateContinue}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Box sx={{ paddingTop: 4, paddingX: 4 }}>
      <PageHeader title="Google Ads Templatez" />
      <CircularLoading loading={isLoading} />
      {!isCreatingTemplate ? (
        <>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => {
                setIsCreatingTemplate(true);
              }}
            >
              Create New Template
            </Button>
          </Box>
          <Box>
            {templateCollection.length > 0 ? (
              <Box
                sx={{
                  '& .green-header': {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                    fontWeight: 'bold',
                  },
                }}
              >
                <DataGridPremium
                  rows={templateCollection.map((template, index) => ({
                    id: index,
                    ...template,
                  }))}
                  onRowClick={(row) => {
                    console.log('row', row.row);
                    setSelectedTemplate(row.row);
                    setOpen(true);
                  }}
                  columns={[
                    {
                      field: 'name',
                      headerName: 'Template Name',
                      headerClassName: 'green-header',
                      width: 300,
                      flex: 1,
                    },
                    {
                      field: 'product_and_service',
                      headerName: 'Product and Service',
                      headerClassName: 'green-header',
                      width: 300,
                    },
                    {
                      field: 'category',
                      headerName: 'Category',
                      width: 300,
                      headerClassName: 'green-header',
                    },
                  ]}
                  hideFooter
                />
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  {PreviewDrawer}
                </Drawer>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  minHeight: '200px',
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ maxWidth: `500px`, textAlign: `center` }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: DEFAULT_COLOR_THEME,
                      fontWeight: 'bold',
                    }}
                  >
                    No Templates Found
                  </Typography>
                  Please Create a New Template by Clicking the Button on the
                  upper right corner
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : null}
      {isCreatingTemplate ? (
        <>
          <Box
            sx={{
              textAlign: `center`,
              position: `relative`,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {selectedTemplate?.name}
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                paddingY: 0,
                paddingX: 2,
                height: 30,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setIsCreatingTemplate(false);
              }}
            >
              Cancel
            </Button>
          </Box>

          <GGTemplateCreation
            checkPS={checkDuplicateProductAndService}
            creationComplete={saveTempate}
            brandId={brand?._id}
            defaultRefreshToken={defaultRefreshToken}
            isExisting={isExisting}
            template={selectedTemplate}
            editPages={editPages}
          />
        </>
      ) : null}

      {EditModal}
    </Box>
  );
};

export default GGTemplatezIndex;
