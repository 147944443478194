import { Box } from '@mui/material';
import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { leadFormColumns } from './LeadFormListTableColumn';
import { LeadFormData, LeadNotification } from '../../../types';
import momentTz from 'moment-timezone';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';

interface LeadFormListTableProps {
  leadForms: LeadFormData[];
  loading: boolean;
  onOpenPreview: (leadForm: LeadFormData) => void;
  isMobile: boolean;
  defaultNotification: LeadNotification;
}

const LeadFormListTable: React.FC<LeadFormListTableProps> = ({
  leadForms,
  loading,
  onOpenPreview,
  isMobile,
  defaultNotification,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );

  const rows = leadForms.map((form: LeadFormData) => {
    return {
      id: form?.id,
      name: form?.name || '',
      formId: form?.id,
      createdAt: momentTz(form?.createdAt)
        .tz(timezone)
        .format('MM-DD-YYYY hh:mm A'),
      description: form?.description,
      leadForm: form,
      enableLeadSend: form.enableSendLead,
      enableZapier: form.enableZapierWebhook,
      default: form._id === defaultNotification?.leadForm,
      onOpenPreview,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        columns={leadFormColumns(isMobile)}
      />
    </Box>
  );
};

export default LeadFormListTable;
