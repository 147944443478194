import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { snakeCaseToHumanized } from '../../../utils/stringModifier';
import { toCurrency } from '../../../utils/numberFormatter';

export const accountPayoutColumns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    headerClassName: styles.header,
    width: 150,
    flex: 1,
    valueGetter: (_, row) => row.createdAt,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { formattedCreatedAt } = params.row;

      return (
        <div className={`${styles.name} ${styles['-break']}`}>
          {formattedCreatedAt}
        </div>
      );
    },
  },
  {
    field: 'arrivalDate',
    headerClassName: styles.header,
    headerName: 'Arrived At',
    width: 150,
    flex: 1,
    valueGetter: (_, row) => row.arrivalDate,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { formattedArrivalDate } = params.row;

      return (
        <div className={`${styles.name} ${styles['-break']}`}>
          {formattedArrivalDate}
        </div>
      );
    },
  },
  {
    field: 'type',
    headerClassName: styles.header,
    headerName: 'Type',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { type } = params.row;

      return (
        <div className={`${styles.name} ${styles['-break']}`}>
          {snakeCaseToHumanized(type)}
        </div>
      );
    },
  },
  {
    field: 'amount',
    headerClassName: styles.header,
    headerName: 'Amount',
    width: 150,
    flex: 1,
    valueGetter: (_, row) => row.amount,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { amount } = params.row;

      return (
        <div className={`${styles.name} ${styles['-break']}`}>
          {toCurrency('USD', amount)}
        </div>
      );
    },
  },
  {
    field: 'status',
    headerClassName: styles.header,
    headerName: 'Status',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { status } = params.row;

      return (
        <div className={`${styles.name} ${styles['-break']}`}>
          {snakeCaseToHumanized(status)}
        </div>
      );
    },
  },
];
