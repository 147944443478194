import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import GoogleDirectCampaignTable from './GoogleDirectCampaignTable';
interface GoogleDirectCampaignMetricsProps {
  refreshToken: string;
  directData: any;
  brand: string;
  fromDate: string;
  toDate: string;
  allowCheckboxes: boolean;
  reload?: any;
  brandId: string;
}
const GoogleDirectCampaignMetrics: React.FC<
  GoogleDirectCampaignMetricsProps
> = ({
  refreshToken,
  directData,
  brand,
  fromDate,
  toDate,
  allowCheckboxes = false,
  reload,
  brandId,
}) => {
  const [termsList, setTermsList] = useState<
    {
      id: number;
      text: string;
      matchtype: string;
      resource_name: string;
      ad_group_resource_name: string;
    }[]
  >([]);

  return (
    <Box
      position={`relative`}
      marginTop={`20px`}
      sx={{
        '& .green': {
          backgroundColor: 'green',
          fontWeight: 'bold',
        },
        '& .yellow': {
          backgroundColor: 'yellow',
          fontWeight: 'bold',
        },
        '& .red': {
          backgroundColor: 'red',
          fontWeight: 'bold',
        },
        '& .primary': {
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
        },
      }}
    >
      <Typography variant="h5" fontWeight={800} textAlign={`center`}>
        {brand} Campaigns
      </Typography>

      <Typography fontWeight={400} textAlign={`center`}>
        {fromDate} - {toDate}
      </Typography>

      {directData ? (
        <GoogleDirectCampaignTable
          brandId={brandId}
          refreshToken={refreshToken}
          reload={reload}
          allowCheckboxes={allowCheckboxes}
          campaignList={directData[`campaignList`]}
          ad_groups={directData[`combinedAdGroups`]}
          keywords={directData[`keyword`]}
          search_terms={directData[`search_term`]}
          locations={directData[`location`]}
          termsList={termsList}
          setTermsList={setTermsList}
          loading={directData ? false : true}
        />
      ) : null}
    </Box>
  );
};

export default GoogleDirectCampaignMetrics;
