import {
  SET_BUDGET_REPORT_PROVIDER,
  SET_CAMPAIGN_PROVIDER,
} from '../ActionNames';

export const setCampaignProvider = (value: string) => {
  return {
    type: SET_CAMPAIGN_PROVIDER,
    payload: value,
  };
};

export const setBudgetReportProvider = (value: string) => {
  return {
    type: SET_BUDGET_REPORT_PROVIDER,
    payload: value,
  };
};
