import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import GoogleDirectDrawer from './GoogleDirectDrawer';
import { googleReportsColumnDefs } from './GoogleCampaignColumn';

import {
  DataGridPremium,
  DataGridPremiumProps,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

import {
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdOutlinePauseCircleOutline,
} from 'react-icons/md';

import { RxDash } from 'react-icons/rx';
import { IoIosCloseCircleOutline, IoMdAddCircleOutline } from 'react-icons/io';

import Button, { ButtonProps } from '@mui/material/Button';
import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { CustomLoadingOverlay } from '../Loading';
import { googleEnums, googleHelpers } from '../../utils/constants/google';
import { toggleAlert } from '../../redux/actions';

interface termsListType {
  id: number;
  text: string;
  matchtype: string;
  resource_name: string;
  ad_group_resource_name: string;
}

interface GoogleDirectCampaignTableProps {
  refreshToken: string;
  ad_groups: any[];
  keywords: any[];
  search_terms: any[];
  locations: any[];
  loading: boolean;
  campaignList: string[];
  allowCheckboxes: boolean;
  reload?: any;
  brandId: string;
  termsList: termsListType[];
  setTermsList: Dispatch<SetStateAction<termsListType[]>>;
}

const GoogleDirectCampaignTable: React.FC<GoogleDirectCampaignTableProps> = ({
  refreshToken,
  ad_groups,
  keywords,
  search_terms,
  locations,
  loading,
  campaignList,
  allowCheckboxes = false,
  reload,
  brandId,
  termsList,
  setTermsList,
}) => {
  const rows: any = [];
  const [termType, setTermType] = useState<string>('keyword');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const pinnedRows: any = {
    top: [],
    bottom: [],
  };
  const [termLock, setTermLock] = useState<string | null>(null);
  const [adGroupLock, setAdGroupLock] = useState<string | null>(null);
  const [adGroupName, setAdGroupName] = useState<string | null>(null);
  const [campaignLock, setCampaignLock] = useState<string | null>(null);
  const [prevTermsListLength, setPrevTermsListLength] = useState<number>(0);
  const endoftable = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const round = (num: number, places: number) => {
    return +(Math.round(Number(num + 'e' + places)) + 'e-' + places);
  };

  useEffect(() => {
    setPrevTermsListLength(termsList.length);

    if (termsList.length === 1 && prevTermsListLength === 0) {
      endoftable.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [termsList]);

  let tempTermList: any[] = [...termsList];

  const removeFromTermList = (row: any) => {
    let tempremove = [...termsList];
    tempremove = tempremove.filter((t) => t.id !== row.id);
    if (tempremove.length === 0) {
      setAdGroupLock(null);
      setTermLock(null);
      setCampaignLock(null);
      setAdGroupName(null);
    }
    setTermsList(tempremove);
  };

  const removeAllFromTermList = () => {
    tempTermList = [];
    setAdGroupLock(null);
    setTermLock(null);
    setCampaignLock(null);
    setAdGroupName(null);
    setTermsList(tempTermList);
  };

  const addToTermList = async (row: any) => {
    let tempadd = [...termsList];
    tempadd = [...tempadd, row];
    tempTermList = tempadd;
    setTermsList([...termsList.filter((t) => t.id !== row.id), row]);
  };

  const filtered_ad_groups = ad_groups.filter((c: any) => {
    return campaignList.includes(c.campaign.id.toString());
  });
  const topRow = {
    id: `c-all`,
    path: [`c-all-path`],
    text: '',
    campaign: 'All Campaigns',
    status: '',
    actype: '',
    Clicks: filtered_ad_groups.reduce((acc: number, adgroup: any) => {
      return acc + adgroup.campaign.metrics.clicks;
    }, 0),
    Leads: round(
      filtered_ad_groups.reduce((acc: number, adgroup: any) => {
        return acc + adgroup.campaign.metrics.all_conversions;
      }, 0),
      1,
    ),
    Costs: googleHelpers.micros(
      filtered_ad_groups.reduce((acc: number, adgroup: any) => {
        return acc + adgroup.campaign.metrics.cost_micros;
      }, 0),
    ),
    Impressions: filtered_ad_groups.reduce((acc: number, adgroup: any) => {
      return acc + adgroup.campaign.metrics.impressions;
    }, 0),
    CPC: googleHelpers.micros(
      filtered_ad_groups.reduce((acc: number, adgroup: any) => {
        return acc + adgroup.campaign.metrics.clicks;
      }, 0)
        ? filtered_ad_groups.reduce((acc: number, adgroup: any) => {
            return acc + adgroup.campaign.metrics.cost_micros;
          }, 0) /
            filtered_ad_groups.reduce((acc: number, adgroup: any) => {
              return acc + adgroup.campaign.metrics.clicks;
            }, 0)
        : 0,
    ),
    CPM: googleHelpers.micros(
      filtered_ad_groups.reduce((acc: number, adgroup: any) => {
        return acc + adgroup.campaign.metrics.impressions;
      }, 0)
        ? filtered_ad_groups.reduce((acc: number, adgroup: any) => {
            return acc + adgroup.campaign.metrics.cost_micros;
          }, 0) /
            filtered_ad_groups.reduce((acc: number, adgroup: any) => {
              return acc + adgroup.campaign.metrics.impressions;
            }, 0)
        : 0,
    ),
    CPL: googleHelpers.micros(
      filtered_ad_groups.reduce((acc: number, adgroup: any) => {
        return acc + adgroup.campaign.metrics.all_conversions;
      }, 0)
        ? filtered_ad_groups.reduce((acc: number, adgroup: any) => {
            return acc + adgroup.campaign.metrics.cost_micros;
          }, 0) /
            filtered_ad_groups.reduce((acc: number, adgroup: any) => {
              return acc + adgroup.campaign.metrics.all_conversions;
            }, 0)
        : 0,
    ),
  };
  ad_groups
    .filter((c: any) => campaignList.includes(c.campaign.id.toString()))
    .sort((a, b) => a.campaign.status - b.campaign.status)
    .forEach((adgroup: any, ci: number) => {
      if (
        adgroup.campaign.status === 4 ||
        (adgroup.campaign.status === 3 &&
          adgroup.campaign.metrics.cost_micros === 0)
      ) {
        return;
      }
      rows.push({
        id: `c${ci}`,
        path: [`campaign-[${ci}]`],
        text: '',
        rowType: 'campaign',
        defex: true,
        campaign: adgroup.campaign.name,
        status: googleEnums.campaignStatus[adgroup.campaign.status],
        actype: adgroup.campaign.advertising_channel_type,
        Clicks: adgroup.campaign.metrics ? adgroup.campaign.metrics.clicks : 0,
        Leads: adgroup.campaign.metrics
          ? round(adgroup.campaign.metrics.all_conversions, 1)
          : 0,
        Costs: adgroup.campaign.metrics
          ? googleHelpers.micros(adgroup.campaign.metrics.cost_micros)
          : 0,
        Impressions: adgroup.campaign.metrics
          ? adgroup.campaign.metrics.impressions
          : 0,
        CPC:
          adgroup.campaign.metrics.clicks > 0
            ? googleHelpers.micros(
                adgroup.campaign.metrics.cost_micros /
                  adgroup.campaign.metrics.clicks,
              )
            : 0,
        CPM:
          adgroup.campaign.metrics.impressions > 0
            ? googleHelpers.micros(adgroup.campaign.metrics.cost_micros) /
              adgroup.campaign.metrics.impressions
            : 0,
        CPL:
          adgroup.campaign.metrics.all_conversions > 0
            ? googleHelpers.micros(
                adgroup.campaign.metrics.cost_micros /
                  adgroup.campaign.metrics.all_conversions,
              )
            : 0,
      });
      adgroup.ad_groups
        .filter(
          (ag: any) =>
            ag.status !== 4 && (ag.status !== 3 || ag.metrics.cost_micros > 0),
        )
        .forEach((adg: any, ai: number) => {
          rows.push({
            id: `c${ci}-ag${ai}`,
            path: [`campaign-[${ci}]`, `ad_group-[${ai}]`],
            rowType: 'ad_group',
            defex: true,
            campaign: adgroup.campaign.name,
            status: googleEnums.campaignStatus[adg.status],
            actype: adgroup.campaign.advertising_channel_type,
            ad_group: adg.name,
            ad_group_resource_name: adg.resource_name,
            adGroupId: adg.id,
            text: '',
            Clicks: adg.metrics ? adg.metrics.clicks : 0,
            Leads: adg.metrics ? round(adg.metrics.all_conversions, 1) : 0,
            Costs: adg.metrics
              ? googleHelpers.micros(adg.metrics.cost_micros)
              : 0,
            Impressions: adg.metrics ? adg.metrics.impressions : 0,
            CPC:
              adg.metrics.clicks > 0
                ? googleHelpers.micros(
                    adg.metrics.cost_micros / adg.metrics.clicks,
                  )
                : 0,
            CPM:
              adg.metrics.impressions > 0
                ? googleHelpers.micros(
                    adg.metrics.cost_micros / adg.metrics.impressions,
                  )
                : 0,
            CPL:
              adg.metrics.all_conversions > 0
                ? googleHelpers.micros(
                    adg.metrics.cost_micros / adg.metrics.all_conversions,
                  )
                : 0,
          });

          if (adgroup.campaign.advertising_channel_type !== `DISPLAY`) {
            const kws = keywords.filter(
              (kw) =>
                kw.ad_group.id === adg.id && !kw.ad_group_criterion.negative,
            );
            const sts = search_terms.filter((st) => st.ad_group.id === adg.id);
            rows.push({
              id: `c${ci}-ag${ai}-kwx`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`, `Keywords`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adg.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term: kws.length > 0 ? `Keywords [${kws.length}]` : `No Keywords`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            kws.forEach((kw: any, ki: number) => {
              rows.push({
                id: `c${ci}-ag${ai}-kw${ki}`,
                path: [
                  `campaign-[${ci}]`,
                  `ad_group-[${ai}]`,
                  `Keywords`,
                  `keyword_view-[${ki}]`,
                ],
                rowType: 'keyword',
                campaign: adgroup.campaign.name,
                status: kw.status,
                actype: adgroup.campaign.advertising_channel_type,
                ad_group: adg.name,
                ad_group_resource_name: adg.resource_name,
                adGroupId: adg.id,
                text: kw.ad_group_criterion.keyword.text,
                isNegative: kw.ad_group_criterion.negative,
                matchtype:
                  googleEnums.keywords[
                    kw.ad_group_criterion.keyword.match_type
                  ],
                resource_name: kw.ad_group_criterion.resource_name,
                term: `Keywords`,
                Clicks: kw.metrics ? kw.metrics.clicks : 0,
                Leads: kw.metrics ? round(kw.metrics.all_conversions, 1) : 0,
                Costs: kw.metrics.cost_micros
                  ? googleHelpers.micros(kw.metrics.cost_micros)
                  : 0,
                Impressions: kw.metrics ? kw.metrics.impressions : 0,
                CPC:
                  kw.metrics.clicks > 0
                    ? googleHelpers.micros(
                        kw.metrics.cost_micros / kw.metrics.clicks,
                      )
                    : 0,
                CPM:
                  kw.metrics.impressions > 0
                    ? googleHelpers.micros(
                        kw.metrics.cost_micros / kw.metrics.impressions,
                      )
                    : 0,
                CPL:
                  kw.metrics.all_conversions > 0
                    ? googleHelpers.micros(
                        kw.metrics.cost_micros / kw.metrics.all_conversions,
                      )
                    : 0,
              });
            });
            rows.push({
              id: `c${ci}-ag${ai}-stx}`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`, `Search Term`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adg.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term:
                sts.length > 0
                  ? `Search Terms [${sts.length}]`
                  : `No Search Terms`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            sts
              .filter((st) => st.status !== 4 && st.metrics.impressions > 0)
              .forEach((st: any, si: number) => {
                rows.push({
                  id: `c${ci}-ag${ai}-st${si}`,
                  path: [
                    `campaign-[${ci}]`,
                    `ad_group-[${ai}]`,
                    `Search Term`,
                    `search_term_view-[${si}]`,
                  ],
                  rowType: 'search_term',
                  campaign: adgroup.campaign.name,
                  status: st.status,
                  actype: adgroup.campaign.advertising_channel_type,
                  ad_group: adg.name,
                  ad_group_resource_name: adg.resource_name,
                  adGroupId: adg.id,
                  text: st.search_term_view.search_term,
                  term: 'Search Term',
                  matchtype:
                    googleEnums.keywords[st.segments.keyword.info.match_type],
                  matchingKeyword: st.segments.keyword.info.text,
                  Clicks: st.metrics ? st.metrics.clicks : 0,
                  Leads: st.metrics ? round(st.metrics.all_conversions, 1) : 0,
                  Impressions: st.metrics ? st.metrics.impressions : 0,
                  Costs: st.metrics.cost_micros
                    ? googleHelpers.micros(st.metrics.cost_micros)
                    : 0,
                  CPC:
                    st.metrics.clicks > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.clicks,
                        )
                      : 0,
                  CPM:
                    st.metrics.impressions > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.impressions,
                        )
                      : 0,
                  CPL:
                    st.metrics.all_conversions > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.all_conversions,
                        )
                      : 0,
                });
              });
          }
        });
    });

  let lc = {
    id: `locations-all`,
    path: [`locations`],
    text: '',
    rowType: 'location',
    campaign: 'Locations',
    defex: true,
    status: '',
    actype: '',
    Clicks: '',
    Leads: '',
    Costs: '',
    Impressions: '',
    CPC: '',
    CPM: '',
    CPL: '',
  };

  if (rows.length > 0) {
    pinnedRows.top.push(topRow as any);
    rows.push({ ...lc });
    locations.forEach((location: any, li: number) => {
      let lc = {
        id: `locations-${li}`,
        path: [`locations`, `locations-[${li}]`],
        campaign: location.location_name,
        location: location.location_name,
        rowType: 'location-child',
        Clicks: location.metrics ? location.metrics.clicks : 0,
        Leads: location.metrics
          ? round(location.metrics.all_conversions, 1)
          : 0,
        Costs: location.metrics
          ? googleHelpers.micros(location.metrics.cost_micros)
          : 0,
        Impressions: location.metrics ? location.metrics.impressions : 0,
        CPC:
          location.metrics.clicks > 0
            ? googleHelpers.micros(
                location.metrics.cost_micros / location.metrics.clicks,
              )
            : 0,
        CPM:
          location.metrics.impressions > 0
            ? googleHelpers.micros(
                location.metrics.cost_micros / location.metrics.impressions,
              )
            : 0,
        CPL:
          location.metrics.all_conversions > 0
            ? googleHelpers.micros(
                location.metrics.cost_micros / location.metrics.all_conversions,
              )
            : 0,
      };
      rows.push({ ...lc });
    });
  }

  const CustomGridTreeDataGroupingCell = (props: any) => {
    const { id, row, rowNode } = props;
    const dgApiRef = useGridApiContext();
    const iconsProps = {
      size: `24px`,
    };

    const handleClick: ButtonProps['onClick'] = (event) => {
      if (rowNode.type !== 'group') {
        return;
      }

      rowNode.childrenExpanded = !rowNode.childrenExpanded;
      dgApiRef.current.setRowChildrenExpansion(id, rowNode.childrenExpanded);
      if (rowNode.childrenExpanded) {
        let expandedKs = [...expandedKeys, rowNode.id];
        setExpandedKeys(expandedKs);
      } else {
        let expandedKs = expandedKeys.filter((k) => k !== rowNode.id);
        setExpandedKeys(expandedKs);
      }
    };

    const handleCheckBoxClick: ButtonProps['onClick'] = () => {
      if (!allowCheckboxes) {
        return;
      }
      const { adGroupId, term, campaign, ad_group } = row;

      if (
        (adGroupLock && adGroupLock !== row.adGroupId) ||
        (termLock && termLock !== row.term)
      ) {
        return dispatch(
          toggleAlert({
            toggle: true,
            type: 'error',
            message: `Please select ${termLock} from the same ad group`,
          }),
        );
      }

      if (termsList.map((t) => t.id).includes(row.id)) {
        return removeFromTermList(row);
      }

      setAdGroupLock(adGroupId);
      setAdGroupName(ad_group);
      setTermLock(term);
      setCampaignLock(campaign);

      setTermType(term);
      addToTermList(row);
    };

    row.tooltip = '';
    row.title =
      row.text ||
      row.ad_group ||
      `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`;
    if (rowNode.type === `group` || rowNode.depth < 3) {
      row.tooltip = `${row.campaign}${row.actype ? ` - ` + row.actype : ``}`;

      if (rowNode.depth === 0) {
        row.title = `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`;
      }
      if (rowNode.depth === 1) {
        row.title = row.ad_group || row.location;
      }
      if (rowNode.depth === 2) {
        row.title = row.term;
      }
    }

    if (rowNode.type === `leaf`) {
      row.tooltip = `${row.title} - ${row.status} ${
        row.matchingKeyword ? ` (${row.matchingKeyword})` : ''
      }`;
    }

    return (
      <Box
        sx={{
          ml: rowNode.depth !== 3 ? rowNode.depth * 2 : 4,
        }}
      >
        {rowNode.type === `group` || rowNode.depth < 3 ? (
          <Tooltip
            title={`${row.tooltip}${
              row.status === 'PAUSED' ? ' - PAUSED' : ''
            }`}
            disableInteractive
          >
            <Button
              onClick={handleClick}
              tabIndex={-1}
              size="small"
              sx={{
                display: `flex`,
                gap: `1em`,
                height: `2.7em`,
                width: `100%`,
                justifyContent: `flex-start`,
                alignItems: `center`,
                color: `#000000`,
              }}
            >
              {rowNode.depth === 0 ? (
                <Box
                  display={`flex`}
                  justifyContent={`start`}
                  alignItems={`center`}
                  gap={1}
                >
                  {row.actype === `PERFORMANCE MAX` || row.id === 'c-all' ? (
                    <RxDash {...iconsProps} />
                  ) : //@ts-ignore
                  rowNode.childrenExpanded ? (
                    <MdOutlineArrowDropUp size={24} />
                  ) : (
                    <MdOutlineArrowDropDown size={24} />
                  )}
                  {row.title}
                  {row.status === 'PAUSED' ? (
                    <MdOutlinePauseCircleOutline {...iconsProps} />
                  ) : (
                    <></>
                  )}
                  <Typography
                    sx={{
                      fontSize: `0.6rem`,
                      display:
                        row.id === `c-all` || row.rowType === 'location'
                          ? `none`
                          : `block`,
                      backgroundColor: `#f0f0f0`,
                      padding: `0.2em 0.4em`,
                      borderRadius: `2em`,
                    }}
                  >
                    {row.actype}
                  </Typography>
                </Box>
              ) : rowNode.depth === 1 ? (
                <>
                  {row.actype !== `DISPLAY` &&
                  !row.rowType.includes(`location`) ? (
                    //@ts-ignore
                    rowNode.childrenExpanded ? (
                      <MdOutlineArrowDropUp {...iconsProps} />
                    ) : (
                      <MdOutlineArrowDropDown {...iconsProps} />
                    )
                  ) : (
                    <RxDash {...iconsProps} />
                  )}

                  {row.title}

                  {row.status === 'PAUSED' ? (
                    <MdOutlinePauseCircleOutline {...iconsProps} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {
                    //@ts-ignore
                    rowNode.children ? (
                      //@ts-ignore
                      rowNode.childrenExpanded ? (
                        <MdOutlineArrowDropUp {...iconsProps} />
                      ) : (
                        <MdOutlineArrowDropDown {...iconsProps} />
                      )
                    ) : (
                      <RxDash {...iconsProps} />
                    )
                  }
                  {row.title}

                  {row.status === 'PAUSED' ? (
                    <MdOutlinePauseCircleOutline {...iconsProps} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              row.rowType === 'search_term' ? (
                <Box>
                  <Typography fontSize={`0.8rem`}>
                    Search Term: {row.title}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Status: {row.status}
                  </Typography>

                  <hr />
                  <Typography fontSize={`0.8rem`}>
                    Matching Keyword: {row.matchingKeyword}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Keyword Match Type: {row.matchtype}
                  </Typography>
                </Box>
              ) : row.rowType === 'keyword' ? (
                <Box>
                  <Typography fontSize={`0.8rem`}>
                    Keyword: {row.title}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Status: {row.status}
                  </Typography>
                </Box>
              ) : (
                row.tooltip
              )
            }
            disableInteractive
          >
            <Button
              sx={{
                width: `100%`,
                display: `flex`,
                justifyContent: `start`,
                alignItems: `center`,
              }}
              type="button"
              onClick={handleCheckBoxClick}
            >
              <Checkbox
                id={row.id}
                checked={termsList.find((t) => t.id === row.id) ? true : false}
                readOnly
                sx={{
                  padding: `0`,
                  margin: `0 10px 0 0`,
                  display: allowCheckboxes ? `block` : `none`,
                }}
              />
              <Typography
                sx={{
                  fontSize: `0.8rem`,
                  pointerEvents: `none`,
                  color: `#000000`,
                  textTransform: `none`,
                  display: `flex`,
                  justifyContent: `start`,
                  alignItems: `center`,
                  gap: `0.2em`,
                }}
              >
                {row.title}{' '}
                {row.status === 'PAUSED' ? (
                  <MdOutlinePauseCircleOutline {...iconsProps} />
                ) : null}
                {row.status === 'REMOVED' || row.status === 'EXCLUDED' ? (
                  <IoIosCloseCircleOutline {...iconsProps} />
                ) : null}
                {row.status === 'ADDED' ? (
                  <IoMdAddCircleOutline {...iconsProps} />
                ) : null}
              </Typography>
            </Button>
          </Tooltip>
        )}
      </Box>
    );
  };

  const customSortComparator: any = (
    v1: any,
    v2: any,
    cellParams1: any,
    cellParams2: any,
  ) => {
    if (
      (cellParams1.rowNode.type === 'group' &&
        cellParams1.id.toString().includes('location')) ||
      (cellParams2.rowNode.type === 'group' &&
        cellParams2.id.toString().includes('location'))
    ) {
      return 0;
    }

    if (v1 < v2) {
      return -1;
    } else if (v1 > v2) {
      return 1;
    } else {
      return 0;
    }
  };

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Campaigns',
    sortable: true,
    minWidth: 450,
    flex: 1,
    headerClassName: 'bg-green',
    valueGetter: (_, row) => {
      return (
        row.text ||
        row.ad_group ||
        `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`
      );
    },
    sortComparator: customSortComparator,
    renderCell: (params: any) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          minHeight: '200px',
          maxHeight: '500px',
          width: '90%',
          marginX: 'auto',
          '& .bg-green': {
            backgroundColor: '#096f4d',
            color: '#ffffff',
          },

          '& .campaign': {
            backgroundColor: '#B6C61A',
            '&:hover': {
              backgroundColor: 'rgba(182, 198, 26, 0.9) !important',
            },
          },

          '& .ad_group': {
            backgroundColor: '#B9D870',
            '&:hover': {
              backgroundColor: 'rgba(185, 216, 112, 0.9) !important',
            },
          },

          paddingBottom: '200px',
        }}
      >
        <Box
          sx={{
            height: 550,
            margin: `0 -65px`,
          }}
        >
          <DataGridPremium
            pinnedRows={pinnedRows}
            treeData
            disableRowSelectionOnClick
            getRowId={(row) => row.id}
            getTreeDataPath={(row) => row.path}
            rows={[...rows]}
            columns={googleReportsColumnDefs}
            density="compact"
            hideFooterSelectedRowCount
            slots={{ loadingOverlay: CustomLoadingOverlay }}
            groupingColDef={groupingColDef}
            hideFooter
            initialState={{
              sorting: { sortModel: [{ field: 'Costs', sort: 'desc' }] },
            }}
            getRowClassName={(params) => {
              return params.row.rowType === 'campaign' ||
                params.row.rowType === 'location'
                ? 'campaign'
                : params.row.rowType === 'ad_group'
                ? 'ad_group'
                : '';
            }}
            defaultGroupingExpansionDepth={2}
            isGroupExpandedByDefault={(row: any) => {
              return expandedKeys.includes(row.id) || row.depth < 2;
            }}
            sx={{
              'MuiDataGrid-virtualScrollerContent': {
                '&::-webkit-scrollbar': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  // background: "#F1F0F0",
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: `#096f4d`,
                  borderRadius: '18px',

                  '&:hover': {
                    background: `#096f4d`,
                  },
                },
              },
            }}
          />
        </Box>
        <Box
          ref={endoftable}
          sx={{
            height: `30vh`,
            maxHeight: `250px`,
            display: termsList
              ? termsList.length === 0
                ? `none`
                : `block`
              : `none`,
          }}
        />
      </Box>

      <GoogleDirectDrawer
        brandId={brandId}
        termType={termType}
        termsList={termsList}
        campaignLock={campaignLock}
        adGroupLock={adGroupLock}
        adGroupName={adGroupName}
        uncheckAllTerms={removeAllFromTermList}
        removeFromTermList={removeFromTermList}
        updateRows={() => {
          removeAllFromTermList();
          //reload();
        }}
      />
    </>
  );
};

export default GoogleDirectCampaignTable;
