import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import React, {
  Dispatch,
  FormEvent,
  FormEventHandler,
  SetStateAction,
} from 'react';
import { PrimaryButton } from '../../Buttons';
import {
  ChangeEventType,
  ChatUser,
  FileInput,
  FileInputError,
} from '../../../types';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import Spinner from '../../Spinner';
import styles from '../../../assets/styles/components/Forms/DesktopChatDialog.module.scss';

interface DesktopChatDialogSendMessageFormProps {
  chat: ChatUser;
  onOpenCannedResponses: (value: boolean) => void;
  onSendMessage: (e: FormEvent) => void;
  onChange: (e: ChangeEventType) => void;
  message: string;
  onFileUpload: (e: any) => void;
  showEmojis: boolean;
  setShowEmojis: Dispatch<SetStateAction<boolean>>;
  onAddEmoji: (event: any, obj: any) => void;
  loading: boolean;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  fileError: FileInputError;
  fileDetails: FileInput;
  onRemoveAttachment: () => void;
}

const DesktopChatDialogSendMessageForm: React.FC<
  DesktopChatDialogSendMessageFormProps
> = ({
  chat,
  onOpenCannedResponses,
  onSendMessage,
  onChange,
  message,
  onFileUpload,
  showEmojis,
  setShowEmojis,
  onAddEmoji,
  loading,
  onSubmit,
  fileError,
  fileDetails,
  onRemoveAttachment,
}) => {
  return (
    <>
      <Divider />

      <Grid
        container
        spacing={2}
        sx={{
          margin: '20px 0 0 20px',
          justifyContent: 'space-between',
        }}
      >
        <PrimaryButton
          type="button"
          disabled={chat ? false : true}
          title="Canned Responses"
          handleOnClick={() => {
            onOpenCannedResponses(true);
          }}
        />
      </Grid>

      <Grid container spacing={2} sx={{ padding: '30px' }}>
        <Grid item xs={12} position="relative">
          <TextField
            fullWidth
            multiline
            maxRows={2}
            autoComplete="off"
            type="text"
            id="message"
            name="message"
            label="Reply here:"
            InputProps={{
              sx: {
                paddingRight: 5.5,
                '.MuiInputBase-input': {
                  width: 'calc(100% - 50px)',
                },
              },
            }}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                onSendMessage(e);
                e.preventDefault();
              }
            }}
            onChange={onChange}
            value={message}
            InputLabelProps={{ shrink: true }}
            size="medium"
          />

          <IconButton
            size="large"
            edge="end"
            aria-label="upload file"
            color="inherit"
            sx={{
              width: 0,
              height: 0,
              position: 'absolute',
              top: `calc(100% - 30px)`,
              right: '85px',
              padding: 0,
            }}
            component="label"
          >
            <input
              id="file-upload"
              hidden
              accept="image/*,video/*,.pdf,.csv"
              type="file"
              onChange={(e: any) => {
                onFileUpload(e);
              }}
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />

            <AttachFileOutlinedIcon
              sx={{
                color: '#096F4D',
                fontSize: '25px',
                padding: '0px',
              }}
            />
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="chat-settings"
            aria-haspopup="true"
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              setShowEmojis(!showEmojis);
            }}
            sx={{
              width: 0,
              height: 0,
              position: 'absolute',
              top: `calc(100% - 30px)`,
              right: '60px',
              padding: 0,
            }}
          >
            <SentimentSatisfiedOutlinedIcon
              sx={{
                color: '#096F4D',
                fontSize: '25px',
              }}
            />
          </IconButton>

          {showEmojis ? (
            <ClickAwayListener
              onClickAway={() => {
                setShowEmojis(false);
              }}
            >
              <Box sx={{ position: 'absolute', top: -300, right: 0 }}>
                <Picker
                  onEmojiClick={onAddEmoji}
                  disableAutoFocus={true}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                  groupNames={{ smileys_people: 'PEOPLE' }}
                  native
                />
              </Box>
            </ClickAwayListener>
          ) : null}

          <IconButton
            disabled={!chat}
            type="submit"
            size="large"
            edge="end"
            aria-label="send message"
            aria-controls="send-message"
            aria-haspopup="true"
            color="inherit"
            sx={{
              width: loading ? '50px' : 0,
              height: loading ? '50px' : 0,
              padding: loading ? '' : 0,
              position: 'absolute',
              top: loading ? 'calc(100% - 54px)' : 'calc(100% - 30px)',
              right: loading ? '9px' : '30px',
            }}
            onClick={(e: any) => onSubmit(e)}
          >
            {loading ? (
              <Spinner />
            ) : (
              <SendOutlinedIcon
                sx={{
                  color: '#096F4D',
                  fontSize: '25px',
                }}
              />
            )}
          </IconButton>
        </Grid>

        <Grid
          item
          xs={12}
          position="relative"
          sx={{ pt: 0 }}
          className={styles.form}
        >
          <p
            className={`${styles['file-details']} ${
              fileError?.error ? styles['-error'] : ''
            }`}
          >
            {fileDetails
              ? `Attachment: ${fileDetails.name}`
              : !fileError?.error
              ? ''
              : fileError?.error}

            {fileDetails ? (
              <span
                className={`${styles['remove']}`}
                onClick={onRemoveAttachment}
              >
                Remove
              </span>
            ) : null}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopChatDialogSendMessageForm;
