import {
  IS_LEAD_ADS,
  IS_MESSENGER_ADS,
  IS_SALES_ADS,
  IS_TRAFFIC_ADS,
} from '../constants';

export const Objectives = {
  APP_INSTALLS: 'APP_INSTALLS',
  BRAND_AWARENESS: 'BRAND_AWARENESS',
  CONVERSIONS: 'CONVERSIONS',
  EVENT_RESPONSES: 'EVENT_RESPONSES',
  LEAD_GENERATION: 'LEAD_GENERATION',
  LINK_CLICKS: 'LINK_CLICKS',
  LOCAL_AWARENESS: 'LOCAL_AWARENESS',
  MESSAGES: 'MESSAGES',
  OFFER_CLAIMS: 'OFFER_CLAIMS',
  PAGE_LIKES: 'PAGE_LIKES',
  POST_ENGAGEMENT: 'POST_ENGAGEMENT',
  PRODUCT_CATALOG_SALES: 'PRODUCT_CATALOG_SALES',
  REACH: 'REACH',
  STORE_VISITS: 'STORE_VISITS',
  VIDEO_VIEWS: 'VIDEO_VIEWS',
  /**
   * New Facebook Ads Objectives
   * @description These are the list of the new Facebook Ads Objectives.
   *              Old objectives listed above is about to change to these
   *              new list of objectives.
   * @reference https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#objective-validation
   */
  OUTCOME_APP_PROMOTION: 'OUTCOME_APP_PROMOTION',
  OUTCOME_AWARENESS: 'OUTCOME_AWARENESS',
  OUTCOME_ENGAGEMENT: 'OUTCOME_ENGAGEMENT',
  OUTCOME_LEADS: 'OUTCOME_LEADS',
  OUTCOME_SALES: 'OUTCOME_SALES',
  OUTCOME_TRAFFIC: 'OUTCOME_TRAFFIC',
};

export const isMessengerAds = (objective: string) => {
  return IS_MESSENGER_ADS.includes(objective);
};

export const isLeadAds = (objective: string) => {
  return IS_LEAD_ADS.includes(objective);
};

export const isTrafficAds = (objective: string) => {
  return IS_TRAFFIC_ADS.includes(objective);
};

export const isSalesAds = (objective: string) => {
  return IS_SALES_ADS.includes(objective);
};

export const adsProvider = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const CreativeTemplate = {
  DESKTOP: 'DESKTOP_FEED_STANDARD',
  MOBILE: 'MOBILE_FEED_STANDARD',
};

export const CallToActionType = {
  OPEN_LINK: 'OPEN_LINK',
  LIKE_PAGE: 'LIKE_PAGE',
  SHOP_NOW: 'SHOP_NOW',
  PLAY_GAME: 'PLAY_GAME',
  INSTALL_APP: 'INSTALL_APP',
  USE_APP: 'USE_APP',
  CALL: 'CALL',
  CALL_ME: 'CALL_ME',
  VIDEO_CALL: 'VIDEO_CALL',
  INSTALL_MOBILE_APP: 'INSTALL_MOBILE_APP',
  USE_MOBILE_APP: 'USE_MOBILE_APP',
  MOBILE_DOWNLOAD: 'MOBILE_DOWNLOAD',
  BOOK_TRAVEL: 'BOOK_TRAVEL',
  LISTEN_MUSIC: 'LISTEN_MUSIC',
  WATCH_VIDEO: 'WATCH_VIDEO',
  LEARN_MORE: 'LEARN_MORE',
  SIGN_UP: 'SIGN_UP',
  DOWNLOAD: 'DOWNLOAD',
  WATCH_MORE: 'WATCH_MORE',
  NO_BUTTON: 'NO_BUTTON',
  VISIT_PAGES_FEED: 'VISIT_PAGES_FEED',
  CALL_NOW: 'CALL_NOW',
  APPLY_NOW: 'APPLY_NOW',
  CONTACT: 'CONTACT',
  BUY_NOW: 'BUY_NOW',
  GET_OFFER: 'GET_OFFER',
  GET_OFFER_VIEW: 'GET_OFFER_VIEW',
  BUY_TICKETS: 'BUY_TICKETS',
  UPDATE_APP: 'UPDATE_APP',
  GET_DIRECTIONS: 'GET_DIRECTIONS',
  BUY: 'BUY',
  SEND_UPDATES: 'SEND_UPDATES',
  MESSAGE_PAGE: 'MESSAGE_PAGE',
  DONATE: 'DONATE',
  SUBSCRIBE: 'SUBSCRIBE',
  SAY_THANKS: 'SAY_THANKS',
  SELL_NOW: 'SELL_NOW',
  SHARE: 'SHARE',
  DONATE_NOW: 'DONATE_NOW',
  GET_QUOTE: 'GET_QUOTE',
  CONTACT_US: 'CONTACT_US',
  ORDER_NOW: 'ORDER_NOW',
  START_ORDER: 'START_ORDER',
  ADD_TO_CART: 'ADD_TO_CART',
  VIDEO_ANNOTATION: 'VIDEO_ANNOTATION',
  MOMENTS: 'MOMENTS',
  RECORD_NOW: 'RECORD_NOW',
  INQUIRE_NOW: 'INQUIRE_NOW',
  REFER_FRIENDS: 'REFER_FRIENDS',
  REQUEST_TIME: 'REQUEST_TIME',
  GET_SHOWTIMES: 'GET_SHOWTIMES',
  LISTEN_NOW: 'LISTEN_NOW',
  WOODHENGE_SUPPORT: 'WOODHENGE_SUPPORT',
  SOTTO_SUBSCRIBE: 'SOTTO_SUBSCRIBE',
  FOLLOW_USER: 'FOLLOW_USER',
  RAISE_MONEY: 'RAISE_MONEY',
  EVENT_RSVP: 'EVENT_RSVP',
  WHATSAPP_MESSAGE: 'WHATSAPP_MESSAGE',
  FOLLOW_NEWS_STORYLINE: 'FOLLOW_NEWS_STORYLINE',
  SEE_MORE: 'SEE_MORE',
  FIND_A_GROUP: 'FIND_A_GROUP',
  FIND_YOUR_GROUPS: 'FIND_YOUR_GROUPS',
  PAY_TO_ACCESS: 'PAY_TO_ACCESS',
  PURCHASE_GIFT_CARDS: 'PURCHASE_GIFT_CARDS',
  FOLLOW_PAGE: 'FOLLOW_PAGE',
  SEND_A_GIFT: 'SEND_A_GIFT',
  SWIPE_UP_SHOP: 'SWIPE_UP_SHOP',
  SWIPE_UP_PRODUCT: 'SWIPE_UP_PRODUCT',
  SEND_GIFT_MONEY: 'SEND_GIFT_MONEY',
  PLAY_GAME_ON_FACEBOOK: 'PLAY_GAME_ON_FACEBOOK',
  GET_STARTED: 'GET_STARTED',
  OPEN_INSTANT_APP: 'OPEN_INSTANT_APP',
  AUDIO_CALL: 'AUDIO_CALL',
  GET_PROMOTIONS: 'GET_PROMOTIONS',
  SEND_MESSAGE: 'SEND_MESSAGE',
  BOOK_NOW: 'BOOK_NOW',
};

export const leadDescription = (objective: string) => {
  if (isTrafficAds(objective)) {
    return 'link_click';
  }

  if (isMessengerAds(objective)) {
    return 'onsite_conversion.messaging_conversation_started_7d';
  }

  if (isSalesAds(objective)) {
    return 'offsite_conversion.fb_pixel_purchase';
  }

  return 'lead';
};
