import axios from 'axios';
import { apiBaseUrl } from './api';

export const fetchLeadSource = async (accessToken: string, webKey: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/client-tether/lead-sources`, {
    params: { accessToken, webKey },
  });

  return data?.data;
};

export const fetchActionPlans = async (accessToken: string, webKey: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/client-tether/action-plans`, {
    params: { accessToken, webKey },
  });

  return data?.data;
};

export const fetchSalesCycleReport = async (
  brandId: string,
  provider: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/crm/client-tether/sales-cycles/report/stage`,
    {
      params: { brandId, provider },
    },
  );

  return data?.data;
};

export const fetchSalesCycleReportByAds = async (
  brandId: string,
  provider: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/crm/client-tether/sales-cycles/report/ad`,
    {
      params: { brandId, provider },
    },
  );

  return data?.data;
};

export const fetchClientHistory = async (brandId: string, clientId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/crm/client-tether/client-history-notes`,
    {
      params: { brandId, clientId },
    },
  );

  return data?.data;
};

export const syncClientTetherClients = async (
  brandId: string,
  accessToken: string,
  webKey: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/crm/client-tether/clients/sync`,
    { brandId, accessToken, webKey },
  );

  return data?.data;
};

export const fetchSalesCycles = async (
  brandId: string,
  accessToken: string,
  webKey: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/crm/client-tether/sales-cycles`, {
    params: { brandId, accessToken, webKey },
  });

  return data?.data;
};
