import { Grid, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import ModalHeader from '../../Modal/ModalHeader';
import { DiySettings } from '../../../types/IDiy';
import {
  Brand,
  ChangeEventType,
  Country,
  FBLeadGenForm,
  LeadFormData,
  LeadNotification,
} from '../../../types';
import {
  securedUrlPattern,
  validateEmailList,
} from '../../../utils/validation';
import { ThankYouPageButtonType } from '../../../utils/helpers/LeadHelpers';
import CountrySelectMenu from '../../Select/CountrySelectMenu';
import { PrimaryButton } from '../../Buttons';

interface DuplicateLeadFormProps {
  brand: Brand;
  diySettings: DiySettings;
  notification: LeadNotification;
  setNotification: Dispatch<SetStateAction<LeadNotification>>;
  leadForm: LeadFormData;
  leadFormValues: FBLeadGenForm;
  setLeadFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  country: Country;
  onSelectCountry: (e: any, value: any) => void;
  onSubmit: () => void;
  loading: boolean;
  register: any;
  errors: any;
  clearErrors: any;
}

const DuplicateLeadForm: React.FC<DuplicateLeadFormProps> = ({
  brand,
  diySettings,
  notification,
  setNotification,
  leadForm,
  leadFormValues,
  setLeadFormValues,
  country,
  onSelectCountry,
  onSubmit,
  loading,
  register,
  errors,
  clearErrors,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader title="Create Lead Form" />
      </Grid>

      {diySettings?.defaultEmailRecipients?.length === 0 ? (
        <Grid item xs={12}>
          <TextField
            {...register('recipients', {
              required: true,
              validate: validateEmailList,
            })}
            fullWidth
            variant="standard"
            type="text"
            name="recipients"
            label="Email Recipients"
            onChange={(e: ChangeEventType) => {
              clearErrors('recipients');
              setNotification({
                ...notification,
                [e.target.name]: e.target.value.trim().split(','),
              });
            }}
            value={notification?.defaultEmailRecipients?.join(',')}
            InputLabelProps={{ shrink: true }}
            size="small"
            multiline
            helperText={
              errors?.recipients?.message ||
              (errors?.recipients &&
                'Please enter at least 1 email recipient') ||
              'Enter list of email addresses separated by comma'
            }
            error={!!errors.recipients}
          />
        </Grid>
      ) : null}

      {leadForm?.cta === ThankYouPageButtonType.CALL_BUSINESS &&
      !brand?.phoneNumber ? (
        <>
          <Grid item xs={12} sm={6}>
            <CountrySelectMenu
              value={country}
              onChange={onSelectCountry}
              withCode
              label="Country Code"
              shrink
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('business_phone_number', {
                required: true,
              })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="business_phone_number"
              label="Business Phone Number"
              onChange={(e: ChangeEventType) => {
                clearErrors('business_phone_number');
                setLeadFormValues({
                  ...leadFormValues,
                  thank_you_page: {
                    ...leadFormValues?.thank_you_page,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              value={leadFormValues?.thank_you_page?.business_phone_number}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors?.business_phone_number}
              helperText={
                errors.business_phone_number &&
                'Business phone number is required'
              }
            />
          </Grid>
        </>
      ) : null}

      {(leadForm?.cta === ThankYouPageButtonType.VIEW_WEBSITE &&
        !diySettings?.defaultUrl) ||
      (leadForm?.cta === ThankYouPageButtonType.SCHEDULE_CALL &&
        !diySettings?.defaultCalendarUrl) ? (
        <Grid item xs={12}>
          <TextField
            {...register('website_url', {
              required: true,
              pattern: securedUrlPattern,
            })}
            fullWidth
            required
            variant="standard"
            type="text"
            name="website_url"
            label={
              leadFormValues?.cta === 'VIEW_WEBSITE'
                ? 'Website URL'
                : 'Calendar URL'
            }
            onChange={(e: ChangeEventType) => {
              clearErrors('website_url');
              setLeadFormValues({
                ...leadFormValues,
                follow_up_action_url: e.target.value,
                thank_you_page: {
                  ...leadFormValues?.thank_you_page,
                  [e.target.name]: e.target.value,
                },
              });
            }}
            value={leadFormValues?.thank_you_page?.website_url}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={!!errors.website_url}
            helperText={
              errors?.website_url?.message ||
              (errors?.website_url && 'Website URL is required')
            }
          />
        </Grid>
      ) : null}

      <Grid
        item
        xs={12}
        sx={{ justifyContent: 'center', display: 'flex' }}
        mt={2}
      >
        <PrimaryButton
          title="Submit"
          type="button"
          handleOnClick={onSubmit}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default DuplicateLeadForm;
