import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useState,
} from 'react';
import ModalHeader from '../../../Modal/ModalHeader';
import { subscriptionCalcelationReason } from '../../../Cards/Billing/PaymentMethodHelpers';
import {
  ChangeEventType,
  StripeCanceledSubscriptionForm,
} from '../../../../types';
import { PrimaryButton } from '../../../Buttons';

interface SubscriptionCancellationFormProps {
  formValues: StripeCanceledSubscriptionForm;
  setFormValues: Dispatch<SetStateAction<StripeCanceledSubscriptionForm>>;
  onChange: (e: ChangeEventType) => void;
  loading: boolean;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
}

const SubscriptionCancellationForm: React.FC<
  SubscriptionCancellationFormProps
> = ({ formValues, setFormValues, onChange, loading, onSubmit }) => {
  const [value, setValue] = useState<any>(null);
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      sx={{ maxWidth: '440px' }}
    >
      <ModalHeader title="Cancel Subscription" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="reason"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option.description}
                </li>
              );
            }}
            options={subscriptionCalcelationReason}
            value={value}
            getOptionLabel={(option) => option?.description}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Feedback"
                placeholder="Select feedback"
                multiline
                InputLabelProps={{ shrink: true }}
                required
              />
            )}
            disableClearable
            onChange={async (e: any, value: any) => {
              setValue(value);
              setFormValues({
                ...formValues,
                cancellation_details: {
                  ...formValues.cancellation_details,
                  feedback: value.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            required
            variant="standard"
            type="text"
            id="comment"
            name="comment"
            label="Comment"
            onChange={onChange}
            value={formValues.cancellation_details.comment}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton title="Confirm" loading={loading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionCancellationForm;
