import { Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  Dispatch,
  FormEvent,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ChangeEventType, Fee, StripeCustomerForm } from '../../../../types';
import StripeCustomerAddressForm from '../StripeCustomerAddressForm';
import StripeCustomerDetailsForm from './StripeCustomerDetailsForm';
import StripePaymentUIForm from '../StripePaymentUIForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../../../../services/api';
import StripeSubscriptionFees from './StripeSubscriptionFees';
import StripeSubscriptionSetupForm from './StripeSubscriptionSetupForm';
import { Moment } from 'moment';
import { FeeType } from '../../../../utils/helpers/stripeHelper';

interface StripePaymentStepsProps {
  formValues: StripeCustomerForm;
  onChange: (e: ChangeEventType) => void;
  clientSecret: string;
  onPay: (stripe: any, elements: any) => void;
  loading: boolean;
  subscriptionStartDate: Moment | null;
  setSubscriptionStartDate: Dispatch<SetStateAction<Moment | null>>;
  brandFees: Fee[];
  handleOnChangeFees: (
    e: ChangeEventType,
    index: number,
    entity: string,
  ) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  onUpdateBudget: (e: FormEvent, isCreate: boolean) => void;
  onChangeNumber: (newValue: string) => void;
  onOpenTerms: React.FormEventHandler<HTMLFormElement> | undefined;
  transactionType: string;
  feeTotal: number;
  onClose: () => void;
}

const stripePromise = loadStripe(stripePublicKey);

const StripePaymentSteps: React.FC<StripePaymentStepsProps> = ({
  formValues,
  onChange,
  clientSecret,
  onPay,
  loading,
  subscriptionStartDate,
  setSubscriptionStartDate,
  brandFees,
  handleOnChangeFees,
  activeStep,
  setActiveStep,
  onUpdateBudget,
  onChangeNumber,
  onOpenTerms,
  transactionType,
  feeTotal,
  onClose,
}) => {
  const [allowBudget, setAllowBudget] = useState<boolean>(false);
  const steps = [
    'Set Up Subscription',
    'Charges',
    'Personal Details',
    'Billing Address',
    'Add Credit Card',
  ];

  useEffect(() => {
    const fee = brandFees.find((fee: Fee) => {
      return fee.type === FeeType.AdBudget;
    });

    setAllowBudget(fee?.enabled);
  }, []);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderForm = () => {
    if (activeStep === 0) {
      return (
        <StripeSubscriptionSetupForm
          subscriptionStartDate={subscriptionStartDate}
          setSubscriptionStartDate={setSubscriptionStartDate}
          brandFees={brandFees}
          handleOnChangeFees={handleOnChangeFees}
          entity="brand"
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onUpdateBudget={onUpdateBudget}
          allowBudget={allowBudget}
          onClose={onClose}
        />
      );
    }

    if (activeStep === 1) {
      return (
        <StripeSubscriptionFees
          brandFees={brandFees}
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          feeTotal={feeTotal}
          onClose={onClose}
        />
      );
    }

    if (activeStep === 2) {
      return (
        <StripeCustomerDetailsForm
          formValues={formValues}
          onChange={onChange}
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onChangeNumber={onChangeNumber}
          onClose={onClose}
        />
      );
    }

    if (activeStep === 3) {
      return (
        <StripeCustomerAddressForm
          formValues={formValues}
          onChange={onChange}
          onOpenTerms={onOpenTerms}
          onBack={handleBack}
          activeStep={activeStep}
          steps={steps}
          onClose={onClose}
        />
      );
    }

    if (activeStep === 4) {
      const options = {
        clientSecret,
        appearance: {
          theme: 'stripe',
        },
      };

      return clientSecret ? (
        <Elements options={options as any} stripe={stripePromise}>
          <StripePaymentUIForm
            clientSecret={clientSecret}
            onPay={onPay}
            loading={loading}
            transactionType={transactionType}
          />
        </Elements>
      ) : null;
    }
  };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} sx={{ marginBottom: '20px' }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};

            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            {renderForm()}
          </Box>
        </Fragment>
      </Box>
    </div>
  );
};

export default StripePaymentSteps;
