import { Typography } from '@mui/material';
import React from 'react';
import styles from '../../assets/styles/pages/Privacy.module.scss';

const Privacy: React.FC = () => {
  return (
    <div className={styles.privacy}>
      <div className={styles.base}>
        <Typography
          variant="h4"
          sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}
        >
          Privacy Policy
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Google API Services User Data Policy
        </Typography>

        <div className={styles.google}>
          <p>
            Chatz.app's use of information received from Google APIs will adhere
            to the{' '}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
              rel="noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the{' '}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noreferrer"
            >
              Limited Use requirements
            </a>
            .
          </p>
        </div>

        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          ARTICLE I - PRINCIPLES
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 1.01 — Principles:
        </Typography>

        <ol type="I" className={styles.section}>
          <li className={styles.list}>
            Notice: We may gather and use information as follows:
            <ol type="a" className={styles.items}>
              <li className={styles.title}>
                Volunteered Information:
                <span className={styles.content}>
                  We will receive and store any information you enter on the
                  Website or give us in any other way that personally identifies
                  you to improve your experience on the Website, to get a better
                  general understanding of the type of individuals visiting the
                  Website, and to enable us to contact you when needed.
                  Typically you will provide information on the Website for the
                  purchase of products or services or when you submit comments
                  or questions for review by us.
                </span>
              </li>

              <li className={styles.title}>
                Automatic Information:
                <span className={styles.content}>
                  To enable us to provide content that users need and desire, we
                  collect aggregated site-visitation statistics using cookies. A
                  cookie is simply a small data file we place on your computer's
                  hard drive when you first visit the Website (“Cookie”). This
                  file contains a unique number that helps us identify you when
                  you return to the Website. Cookies are employed by thousands
                  of websites to enhance users' web viewing experience. Cookies
                  can neither damage user files nor can they read information
                  from users' hard drives. We combine all usage information
                  provided by such Cookies and we do not monitor your use of the
                  Website. Allowing us to create a Cookie on your hard drive
                  will not give us or any other site access to the rest of your
                  computer's hard drive, and only we will be able to read the
                  Cookie that the Website creates.
                </span>
              </li>

              <li className={styles.title}>
                Information Use:
                <span className={styles.content}>
                  <p className={styles.paragraph}>
                    We use information and e-mail address for personal
                    identification; to get a better general understanding of the
                    type of individuals visiting the Website; to respond to your
                    requests for donations, comments, or questions; to improve
                    your experience on the Website; to collect aggregated
                    site-visitation statistics; and to alert you to product
                    enhancements, special offers, updated information, and other
                    new services from us.
                  </p>

                  <p>
                    We may release any user information if required to comply
                    with law; to enforce or to apply the Website Terms and
                    Conditions; or under the good faith belief that disclosure
                    is otherwise necessary or advisable including, without
                    limitation, to protect the rights, properties, or safety of
                    us or our users. Other than as described above, we will not
                    use your personal information nor release it to any other
                    party without your permission, unless we believe it is
                    necessary to share information in order to investigate,
                    prevent, or take action regarding illegal activities,
                    violations of our terms of use, or as otherwise required by
                    law. In such cases, we will use or share only as much of
                    your personal information as is required for this purpose.
                  </p>
                </span>
              </li>
            </ol>
          </li>

          <li className={styles.list}>
            Choice: We give you a choice as to the type and amount of private
            information we have, as follows:
            <ol type="a" className={styles.items}>
              <li className={styles.title}>
                Volunteered Information:
                <span className={styles.content}>
                  You can always choose not to provide certain information to
                  us. If you choose not to provide personal information, you can
                  still use many parts of the Website. However, you will not be
                  able to make an online donation. If you have previously
                  provided certain information to us and you desire to remove
                  such information from our databases, or if you do not wish for
                  your information to be disclosed to third parties, you may
                  email us to remove such information.
                </span>
              </li>

              <li className={styles.title}>
                Automatic Information:
                <span className={styles.content}>
                  Most web browsers automatically accept Cookies, but if you
                  prefer, you can edit your browser options to block Cookies in
                  the future. The “Help” portion of the toolbar on most browsers
                  will explain how you can prevent your browser from accepting
                  new Cookies, how to set the browser to inform you when you
                  receive a new Cookie, or how to reject Cookies altogether.
                </span>
              </li>

              <li className={styles.title}>
                Access:
                <span className={styles.content}>
                  We provide you with the ability to ensure that your personal
                  information is correct and current. You may review and update
                  your personal information by emailing us. For security
                  purposes, we require user verification via user's user name
                  and password to receive personal information.
                </span>
              </li>

              <li className={styles.title}>
                Security:
                <span className={styles.content}>
                  We protect the security of your personal information. We take
                  steps to protect your data from loss, misuse, alteration,
                  destruction, or unauthorized access. We use sophisticated
                  security technologies to secure users' ordering information,
                  user name, and password. We encrypt users' ordering
                  information, user name, and password (including users' credit
                  card account number) using Secure Socket Layer (“SSL”)
                  technology. SSL is a proven coding system that lets your
                  browser automatically encrypt, or scramble, data before you
                  send it to us. To support this technology, users must have an
                  SSL-capable browser. SSL is one of the safest encryption
                  technologies available. While we use such sophisticated
                  security technology to secure users' ordering information,
                  user name, and password, we cannot (nor can any service
                  provider) guarantee that any electronic commerce is totally
                  secure.
                </span>
              </li>
            </ol>
          </li>
        </ol>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 2.01 — User Safeguards:
        </Typography>

        <p className={`${styles['unlist-content']}`}>
          You are responsible for maintaining the secrecy of your user name,
          password, and any account information. It is important for you to be
          responsible and protect your user name, password, and computer against
          unauthorized users. It is important to sign off when you have
          completed using a computer accessible by others.
        </p>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 3.01 — Children:
        </Typography>

        <p className={`${styles['unlist-content']}`}>
          We do not sell products for children. Use of the Website by
          individuals under the age of 18 is not allowed except under the
          supervision of a parent, legal guardian, or other responsible adult.
        </p>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 4.01 — Third-Party Websites:
        </Typography>

        <p className={`${styles['unlist-content']}`}>
          Please be aware that the Website may have links to third-party
          websites that may collect personally identifiable information about
          you. When you click on one of these third-party links, you are
          entering another website for which we have no responsibility. This
          Notice does not cover the information practices or policies of such
          third-party websites. We encourage you to read the privacy statements
          of all such websites since their privacy policies may be materially
          different from our Notice.
        </p>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 5.01 — Agreement and Modification:
        </Typography>

        <p className={`${styles['unlist-content']}`}>
          By using and accessing the Website you indicate that you have read and
          understand this Notice and you consent to the collection and use of
          information by us in the manner explained in this Notice. This Notice
          and the Website Terms and Conditions, including without limitation,
          provisions covering limitations on damages, arbitration of disputes,
          and the application of Florida law govern any dispute over privacy. By
          using and accessing the Website you agree to indemnify us for any and
          all third-party claims resulting from your breach of this Notice or
          the Website Terms and Conditions. If you have any questions or
          concerns about your privacy on the Website, please email us. If you do
          not accept this Notice, do not access and use the Website. We may
          revise this Notice at any time without notice by updating this Notice.
          Any modifications in the way we use personal information will be
          provided in future updates of this Notice so you are always aware of
          what information we collect, how we use it, and under what
          circumstances we disclose such information. Modifications will be
          effective immediately and will be available on the Website. You should
          visit this web page periodically to review the Notice. User accepts
          any such modifications to this Notice by continued use of the Website
          after such modifications are made.
        </p>

        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Section 6.01 — Disclaimer of Warranties and Limitation of Liability:
        </Typography>

        <p className={`${styles['unlist-content']}`}>
          THIS SITE IS PROVIDED BY SALES CHATZ, LLC ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. SALES CHATZ, LLC MAKES NO REPRESENTATIONS OR
          WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WHETHER BASED UPON THE
          NATURE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          OTHERWISE, AS TO THE OPERATION OF THIS SITE, OR THE ACCURACY,
          TIMELINESS, OR COMPLETENESS OF INFORMATION, CONTENT, MATERIALS, OR
          SERVICES INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF
          THIS SITE AND ANY INFORMATION CONTAINED HEREIN IS AT YOUR SOLE RISK.
          SALES CHATZ, LLC WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
          ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO,
          DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
