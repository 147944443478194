import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { CampaignFormFields } from '../../../types';
import {
  humanizeString,
  snakeCaseToHumanized,
} from '../../../utils/stringModifier';
import styles from '../../../assets/styles/components/Modal/CampaignModal.module.scss';
import FormHeader from '../FormHeader';
import { IS_DIY_ADZ } from '../../../utils';

interface CampaignFormProps {
  providerCampaigns: CampaignFormFields[];
  brandCampaigns: CampaignFormFields[];
  onSelectCampaign: (campaign: CampaignFormFields) => void;
  onRemoveCampaign: (campaign: CampaignFormFields) => void;
  provider: string;
}

const CampaignForm: React.FC<CampaignFormProps> = ({
  providerCampaigns,
  brandCampaigns,
  onSelectCampaign,
  onRemoveCampaign,
  provider,
}) => {
  const selectedOption = (o: any) => {
    return !!brandCampaigns.find((e: any) => e.id === o.id && !e.deleted);
  };

  return (
    <div className={styles.campaigns}>
      <FormHeader title={`Select ${humanizeString(provider)} Campaigns`} />

      <Autocomplete
        id="campaigns"
        multiple
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        options={providerCampaigns.sort(
          (a, b) => -b.name?.localeCompare(a.name),
        )}
        getOptionDisabled={(option) => selectedOption(option)}
        value={brandCampaigns}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Campaigns"
            placeholder="Select your campaigns"
          />
        )}
        disableClearable
        onChange={async (e: any, value: any[], reason, detail) => {
          if (reason === 'selectOption') {
            onSelectCampaign(detail.option as CampaignFormFields);
          } else if (reason === 'removeOption') {
            onRemoveCampaign(detail.option as CampaignFormFields);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={!option.manual && IS_DIY_ADZ}
            />
          ))
        }
      />

      <Grid container spacing={1} mt={1}>
        {brandCampaigns?.map((campaign: CampaignFormFields) => {
          return campaign.deleted ? null : (
            <Grid item xs={12} sm={6} lg={3} key={campaign.id}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  subheader={campaign.name}
                  subheaderTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                      color: 'black',
                      textAlign: 'center',
                    },
                  }}
                />

                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography variant="body2" fontWeight="bold" component="div">
                    ID:
                    <Typography variant="body2" display="inline" ml={1}>
                      {campaign.id}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Status:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(campaign.status)}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Objective:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(snakeCaseToHumanized(campaign.objective))}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CampaignForm;
