import { Box, Grid, TextField } from '@mui/material';
import React, { FormEventHandler } from 'react';
import {
  ChangeEventType,
  StripeCustomerForm as CustomerForm,
  StripeCustomerFormErrors,
} from '../../../types';
import { PrimaryButton } from '../../Buttons';
import { MuiTelInput } from 'mui-tel-input';
import CountrySelectMenu from '../../Select/CountrySelectMenu';

interface StripeCustomerFormProps {
  formValues: CustomerForm;
  onChange: (e: ChangeEventType) => void;
  field: string;
  onChangeNumber: (newValue: string) => void;
  onCancel: () => void;
  loading: boolean;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  errors: StripeCustomerFormErrors;
}

const StripeCustomerForm: React.FC<StripeCustomerFormProps> = ({
  formValues,
  onChange,
  onChangeNumber,
  field,
  onCancel,
  loading,
  onSubmit,
  errors,
}) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ width: '100%' }}
      onSubmit={onSubmit}
    >
      <Grid container spacing={1}>
        {field === 'name' ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              id="name"
              name="name"
              label="Full Name"
              onChange={onChange}
              value={formValues?.name}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.name ? true : false}
              helperText={errors?.name ? errors.name : ''}
              autoFocus
            />
          </Grid>
        ) : null}

        {field === 'email' ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="email"
              id="email"
              name="email"
              label="Email"
              onChange={onChange}
              value={formValues?.email}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.email ? true : false}
              helperText={errors?.email ? errors.email : ''}
              autoFocus
            />
          </Grid>
        ) : null}

        {field === 'phone' ? (
          <Grid item xs={12}>
            <MuiTelInput
              fullWidth
              required
              id="phone"
              name="phone"
              label="Phone Number"
              onChange={(newValue: string) => onChangeNumber(newValue)}
              value={formValues?.phone}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
              error={errors?.phone ? true : false}
              helperText={errors?.phone ? errors.phone : ''}
            />
          </Grid>
        ) : null}

        {field === 'address' ? (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="line1"
                  name="line1"
                  label="Address Line 1 (Company Name, Street, PO Box)"
                  onChange={onChange}
                  value={formValues?.address?.line1}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  id="line2"
                  name="line2"
                  label="Address Line 2 (Apartment, Suite, Unit, Building)"
                  onChange={onChange}
                  value={formValues?.address?.line2}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="city"
                  name="city"
                  label="City"
                  onChange={onChange}
                  value={formValues?.address?.city}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  error={errors?.address.city ? true : false}
                  helperText={errors?.address.city ? errors.address.city : ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="state"
                  name="state"
                  label="State"
                  onChange={onChange}
                  value={formValues?.address?.state}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  error={errors?.address.state ? true : false}
                  helperText={errors?.address.state ? errors.address.state : ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CountrySelectMenu
                  value={{
                    code: 'US',
                    label: 'United States',
                    phone: '1',
                    suggested: true,
                  }}
                  disabled
                  shrink
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="postalCode"
                  name="postal_code"
                  label="Postal Code"
                  onChange={onChange}
                  value={formValues?.address?.postal_code}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  error={errors?.address.postal_code ? true : false}
                  helperText={
                    errors?.address.postal_code
                      ? errors.address.postal_code
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <PrimaryButton title="Save" marginRight5 loading={loading} />

          <PrimaryButton
            title="Cancel"
            type="button"
            theme="red"
            variant="text"
            handleOnClick={onCancel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StripeCustomerForm;
