import React, { useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
  LoadScript,
} from '@react-google-maps/api';
import { TextField } from '@mui/material';
import { googleMapsApiKey, googleMapId } from '../../services/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 41.850033,
  lng: -87.6500523,
};

interface GoogleMapComponentProps {
  zoom: number;
  places: any[];
}
const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({
  zoom,
  places,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
    libraries: ['places', 'maps'],
    mapIds: [googleMapId],
  });

  const [map, setMap] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [markers, setMarkers] = React.useState([]);

  useEffect(() => {
    if (isLoaded && map) {
      console.log(`places`, places);

      const bounds = new window.google.maps.LatLngBounds(center);
      const placesService = new window.google.maps.places.PlacesService(map);
      let placeid = '';
      markers.forEach((m) => {
        m.setMap(null);
      });
      setMarkers([]);
      places.forEach((pl) => {
        placesService.findPlaceFromQuery(
          {
            query: pl.address ? pl.address : pl.name,
            fields: ['all'],
          },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              console.log('results: ', results);
              for (let i = 0; i < results.length; i++) {
                const place = results[i];
                placeid = place.place_id;
                const m = new window.google.maps.Marker({
                  position: place.geometry.location,
                  map: map,
                });

                setMarkers((current) => [...current, m]);
                bounds.extend(place.geometry.location);
              }
            }
          },
        );
      });

      // places.textSearch(
      //   {
      //     query: 'gym locations',
      //   },
      //   (results, status) => {
      //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      //       console.log('results2: ', results);
      //       for (let i = 0; i < results.length; i++) {
      //         const place = results[i];
      //         new window.google.maps.Marker({
      //           position: place.geometry.location,
      //           map,
      //         });
      //         bounds.extend(place.geometry.location);
      //       }
      //     }
      //   },
      // );
      // console.log(places);
      // map.fitBounds(bounds);
    }
  }, [isLoaded, map, loaded, places]);

  const onLoad = React.useCallback(function callback(mp) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    setMap(mp);

    setLoaded(true);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleLoad = (searchBox: any) => {
    console.log('searchBox: ', searchBox);
  };

  const hanldePlacesChanged = () => {
    console.log('places: ', places);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapComponent);
