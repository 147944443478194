/**
 * Get Ad Score Visible Value
 * @description Reformat values of the actual ad score value
 *              The original ad score value was comes from our custom GPT,
 *              and we are formatting it into more shorter version.
 *              Effective (Original) -> Good (Custom)
 *              Needs Effective (Original) -> Poor (Custom)
 *              The default is TBD.
 * @author Angelo David <angelod@codev.com>
 * @params adScore <String> Original ad score from the custom GPT response
 * @return <String> Custom ad score value
 */
export const getAdScoreValue = (adScore: string) => {
  if (adScore === 'Effective') {
    return 'Good';
  }

  if (adScore === 'Needs Improvement') {
    return 'Poor';
  }

  return adScore;
};
