import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import React, { Fragment } from 'react';
import { PrimaryButton } from '../../Buttons';
import { AdsTemplate, DiySettings } from '../../../types/IDiy';
import { AdsPreviewSquare, AdsPreviewVertical } from './AdsPreview';
import { Brand } from '../../../types';

const ASPECT_RATIOS = ['square', 'vertical'];

interface MediaPlacementFormProps {
  onBack: (replace: boolean, ratio?: string) => void;
  mediaType: string;
  template: AdsTemplate;
  brand: Brand;
  diySettings: DiySettings;
  objective: string;
}

const MediaPlacementForm: React.FC<MediaPlacementFormProps> = ({
  onBack,
  mediaType,
  template,
  brand,
  diySettings,
  objective,
}) => {
  const buildMediaDescription = (ratio: string) => {
    let description: string = '';

    switch (ratio) {
      case 'vertical':
        description = 'Stories and Reels, Apps and sites';
        break;
      case 'horizontal':
        description = 'Right column';
        break;
      default:
        description = 'Feeds, In-stream ads, Search results';
        break;
    }

    return description;
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#096F4D' }}
            >
              Select media crop placement
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: '#096F4D' }} mb={2}>
              We evaluated your image and cropped it to fill the placement when
              we think it may perform better. You can edit these crops or upload
              new media for each placement by hovering over the image.
            </Typography>
          </Grid>

          {ASPECT_RATIOS.map((ratio: string) => {
            return (
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    avatar={
                      <Fragment>
                        <PrimaryButton
                          title="Replace"
                          type="button"
                          handleOnClick={() => onBack(true, ratio)}
                        />
                      </Fragment>
                    }
                    sx={{ paddingBottom: 0 }}
                  />

                  <CardContent
                    sx={{
                      paddingBottom: 0,
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <div>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ textAlign: 'center', fontWeight: 'bold' }}
                        mb={2}
                      >
                        {buildMediaDescription(ratio)}
                      </Typography>
                    </div>

                    <Box
                      component="div"
                      sx={{
                        justifyContent: 'center',
                        width: '100%',
                        display: 'flex',
                      }}
                    >
                      <div>
                        {ratio === 'square' ? (
                          <AdsPreviewSquare
                            page={diySettings?.facebookPage}
                            body="Sample"
                            isImage={mediaType === 'image'}
                            imageUrl={template?.images?.square}
                            websiteUrl={diySettings?.defaultUrl}
                            headline="Headline"
                            description="Click learn more"
                            cta="LEARN_MORE"
                            videoUrl={template?.videos?.square}
                            objective={objective}
                            brand={brand}
                          />
                        ) : (
                          <AdsPreviewVertical
                            page={diySettings?.facebookPage}
                            body="Sample"
                            isImage={mediaType === 'image'}
                            imageUrl={template?.images?.vertical}
                            cta="LEARN_MORE"
                            videoUrl={template?.videos?.vertical}
                            brand={brand}
                          />
                        )}
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default MediaPlacementForm;
