import axios from 'axios';
import { Fee } from '../types';
import { apiBaseUrl } from './api';

export const fetchAllFees = async () => {
  const data = await axios.get(`${apiBaseUrl}/fee`);

  return data?.data;
};

export const fetchAllFeesByBrand = async (brandId: string, entity?: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/fee?brandId=${brandId}${entity ? `&entity=${entity}` : ''}`,
  );

  return data?.data;
};

export const createOrUpdateFee = async (params: Fee[]) => {
  const data = await axios.post(`${apiBaseUrl}/fee`, { fees: params });

  return data?.data;
};

export const setBudgetInput = async (brandId: string, allowed: boolean) => {
  const data = await axios.post(`${apiBaseUrl}/fee/allow-budget`, {
    brandId,
    allowed,
  });

  return data?.data;
};

export const updateFees = async (brandId: string, params: Fee[]) => {
  const data = await axios.put(`${apiBaseUrl}/fee/${brandId}`, {
    adBudgets: params,
  });

  return data?.data;
};
