import { Box } from '@mui/material';
import React, { FormEvent, useContext, useState, useEffect } from 'react';
import CannedResponsesForm from '../../components/Forms/CannedResponsesForm';
import ProfileForm from '../../components/Forms/ProfileForm';
import Spinner from '../../components/Spinner';
import { AuthContext } from '../../context';
import { editAgencyProfile, getSingleAgency } from '../../services/agency';
import {
  editSalespersonProfile,
  getSingleSalesperson,
} from '../../services/salesperson';
import { deleteLogo, uploadLogo } from '../../services/upload';
import {
  AgencyErrors,
  ChangeEventType,
  ProfileFormValues,
  CannedResponse,
} from '../../types';
import {
  AGENCY,
  resizeFile,
  ResizerReturnType,
  SALESPERSON,
} from '../../utils';
import ChangePassword from '../../components/Forms/ChangePassword';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import ConfirmNavigationModal, {
  usePrompt,
} from '../../components/Navigation/RoutePrompt';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const role = state.role;
  const isAgency = role === AGENCY;
  const isSalesperson = role === SALESPERSON;
  const userId = state.roleBasedId;
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [cannedResponses] = useState<CannedResponse[]>([
    {
      message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
      isDeleted: false,
    },
    {
      message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
      isDeleted: false,
    },
    {
      message:
        'I am glad I was able to help. Please tell me if you have any more queries 🙂',
      isDeleted: false,
    },
  ]);
  const [formValues, setFormValues] = useState<ProfileFormValues>({
    agency: '',
    name: '',
    email: '',
    cell: '',
    url: '',
    logo: {
      url: '',
      public_id: '',
    },
    cannedResponses: [],
    firstname: '',
    lastname: '',
  });
  const [errors, setErrors] = useState<AgencyErrors>({
    agency: '',
    cell: '',
    email: '',
    name: '',
    url: '',
    logo: '',
  });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isAgency) {
        const res = await getSingleAgency(userId);
        setFormValues({
          ...formValues,
          agency: res.agency,
          name: res.name,
          email: res.email,
          cell: res.cell,
          url: res.url,
          logo: res.logo,
          id: res._id,
          cannedResponses:
            res.cannedResponses.length === 0
              ? cannedResponses
              : res.cannedResponses,
        });
        setLoading(false);
      } else {
        const res = await getSingleSalesperson(userId);
        setFormValues({
          ...formValues,
          name: res.name,
          email: res.email,
          cell: res.cell,
          id: res._id,
          receiveNotification: res.receiveNotification,
          firstname: res.firstname,
          lastname: res.lastname,
        });
        setLoading(false);
      }
    })();
  }, []);

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    try {
      setUploadLoading(true);
      const image: ResizerReturnType = await resizeFile(file);
      const res = await uploadLogo(image);
      if (res) {
        setFormValues({ ...formValues, logo: res.logo });
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
        setUploadLoading(false);
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const handleRemoveLogo = async (public_id: string) => {
    try {
      setUploadLoading(true);
      const res = await deleteLogo(public_id);
      if (res) {
        setFormValues({ ...formValues, logo: { url: '', public_id: '' } });
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
        setUploadLoading(false);
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const buildCannedResponses = () => {
    let newFormObj = { ...formValues };
    let newCannedResponses: CannedResponse[] = [];
    newFormObj.cannedResponses.forEach((response: CannedResponse) => {
      if (!response.isDeleted) {
        newCannedResponses = [...newCannedResponses, response];
      } else {
        return;
      }
    });

    return newCannedResponses;
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setEditLoading(true);
    try {
      if (isAgency) {
        const { message } = await editAgencyProfile(formValues.id, {
          ...formValues,
          cannedResponses: buildCannedResponses(),
        });
        dispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );
        setEditLoading(false);
      } else {
        const { message } = await editSalespersonProfile(
          { ...formValues, cannedResponses: buildCannedResponses() },
          formValues.id,
        );

        setEditLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );
      }

      setIsDirty(false);
    } catch (error: any) {
      setIsDirty(true);
      const err = error.response.data;
      setErrors({
        ...errors,
        agency: err.agency,
        cell: err.cell,
        email: err.email,
        name: err.name,
        url: err.url,
        logo: err.logo,
      });

      dispatch(
        toggleAlert({
          toggle: true,
          message: err.message,
          type: 'error',
        }),
      );
      setEditLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setIsDirty(true);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleAddResponses = () => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses = [
      ...formValues.cannedResponses,
      { message: '', isDeleted: false },
    ];

    setFormValues(newFormObj);
  };

  const handleDeleteReponses = (index: number) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].isDeleted = true;

    setFormValues(newFormObj);
  };

  const handleEditResponses = (index: number, value: string) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].message = value;

    setFormValues(newFormObj);
  };

  const handleSetCustomFields = (
    selectedForm: number,
    customFields: string,
  ) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    const message = newFormObj.cannedResponses[selectedForm].message;
    newFormObj.cannedResponses[
      selectedForm
    ].message = `${message} ${customFields}`;

    setFormValues(newFormObj);
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  if (loading) {
    return (
      <Box
        component="div"
        sx={{
          py: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <div>
      <ProfileForm
        handleOnSubmit={handleOnSubmit}
        handleOnChange={handleOnChange}
        formValues={formValues}
        isAgency={isAgency}
        handleUploadImage={handleUploadImage}
        handleRemoveLogo={handleRemoveLogo}
        errors={errors}
        isSalesperson={isSalesperson}
        setFormValues={setFormValues}
        editLoading={editLoading}
        uploadLoading={uploadLoading}
      />

      <ChangePassword />

      {isAgency ? (
        <CannedResponsesForm
          formValues={formValues}
          handleAddResponses={handleAddResponses}
          handleDeleteReponses={handleDeleteReponses}
          handleOnSubmit={handleOnSubmit}
          handleEditResponses={handleEditResponses}
          handleSetCustomFields={handleSetCustomFields}
          errors={errors}
          loading={editLoading}
        />
      ) : null}

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />
    </div>
  );
};

export default Profile;
