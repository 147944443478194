import { Card, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { toCurrency } from '../../utils/numberFormatter';
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
  PointElement,
  LineElement,
} from 'chart.js';
import { ScoreboardScatterBreakdown } from '../../types';
import PopupModal from '../Modal';
import ScatterChartBreakdownTable from '../Tables/ReportList/ScatterChartBreakdownTable';

ChartJs.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
);

interface ScatterChartProps {
  scatterChartData: any;
  showLegend?: boolean;
  title?: string | null;
  titleFontSize?: number;
  xIsCurrency?: boolean;
  yIsCurrency?: boolean;
  subtitle?: string | null;
  xLabel?: string | null;
  yLabel?: string | null;
  isLead?: boolean;
}

const ScatterChart: React.FC<ScatterChartProps> = ({
  scatterChartData,
  showLegend = true,
  title = null,
  titleFontSize = 14,
  xIsCurrency = false,
  yIsCurrency = false,
  xLabel: subtitle = null,
  xLabel = null,
  yLabel = null,
  isLead = false,
}) => {
  const chartRef = useRef();
  const theme = useTheme();
  const xsOnly = theme.breakpoints.only('xs');

  const [breakdown, setBreakdown] = useState<ScoreboardScatterBreakdown[]>([]);
  const [openBreakdownModal, setOpenBreakdownModel] = useState<boolean>(false);

  const chartHeight = () => {
    if (xsOnly) {
      return 200;
    }

    return 30;
  };

  const options = () => {
    let data: any = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: showLegend,
        },
        title: {
          display: true,
          text: title || '',
          font: {
            size: titleFontSize,
          },
        },
        subtitle: {
          display: true,
          text: subtitle || '',
        },
        tooltip: {
          callbacks: {
            label: (tooltipItems: any, data: any) => {
              return ` ${tooltipItems.dataset.label} (${
                tooltipItems.dataset.data[0].x
              }, ${toCurrency('USD', tooltipItems.dataset.data[0].y, 0)})`;
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: xLabel || '',
          },
          ticks: {
            callback: (value: any, index: number, ticks: any) => {
              if (xIsCurrency) return toCurrency('USD', value, 0);

              return value;
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: yLabel || '',
          },
          ticks: {
            callback: (value: any, index: number, ticks: any) => {
              if (yIsCurrency) return toCurrency('USD', value, 0);

              return value;
            },
          },
        },
      },
      onClick: (e: any, element: any) => {
        if (chartRef.current) {
          const index = element[0].datasetIndex;
          const data = scatterChartData.datasets[index];
          setBreakdown(data.data[0].breakdown);
          handleOpenBreakdownModal();
        }
      },
      onHover: (e: any, element: any) => {
        e.native.target.style.cursor = element[0] ? 'pointer' : 'default';
      },
    };

    return data;
  };

  const handleOpenBreakdownModal = () => {
    setOpenBreakdownModel((prev) => !prev);
  };

  return (
    <>
      <Card sx={{ padding: '20px' }}>
        {scatterChartData ? (
          <Scatter
            ref={chartRef}
            options={options()}
            data={scatterChartData}
            height={chartHeight()}
            width="100%"
          />
        ) : null}
      </Card>

      <PopupModal
        open={openBreakdownModal}
        handleClose={handleOpenBreakdownModal}
      >
        <ScatterChartBreakdownTable breakdown={breakdown} isLead={isLead} />
      </PopupModal>
    </>
  );
};

export default ScatterChart;
