import { Brand } from '../../types';
import {
  SET_BRAND,
  SET_BRANDS,
  SET_BRAND_FILTER,
  SET_DEMO_MODE,
  SET_LOCATION,
  SET_LOCATIONS,
  SET_SHOW_ALL_BRANDS,
} from '../ActionNames';

export const setBrands = (value: Brand[]) => {
  return {
    type: SET_BRANDS,
    payload: value,
  };
};

export const setBrand = (value: Brand) => {
  return {
    type: SET_BRAND,
    payload: value,
  };
};

export const setBrandFilter = (value: any) => {
  return {
    type: SET_BRAND_FILTER,
    payload: value,
  };
};

export const setLocations = (value: Brand[]) => {
  return {
    type: SET_LOCATIONS,
    payload: value,
  };
};

export const setLocation = (value: Brand) => {
  return {
    type: SET_LOCATION,
    payload: value,
  };
};

export const setShowAllBrand = (value: boolean) => {
  return {
    type: SET_SHOW_ALL_BRANDS,
    payload: value,
  };
};

export const setDemoMode = (value: boolean) => {
  return {
    type: SET_DEMO_MODE,
    payload: value,
  };
};
