import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { toCurrency } from '../../../utils/numberFormatter';

export const breakdownColumns = (isLead: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'template',
      headerClassName: styles.header,
      headerName: 'Template Name',
      width: 200,
      renderCell: (params: any) => {
        const { template } = params.row;

        return (
          <div className={styles.name}>
            <Tooltip
              title={<Typography variant="body2">{template}</Typography>}
              disableInteractive
            >
              {template}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'spend',
      headerName: 'Spend',
      flex: 1,
      headerClassName: styles.header,
      valueGetter: (_, row) => row.spend,
      renderCell: (params: any) => {
        const { spend } = params.row;

        return <div className={styles.name}>{toCurrency('USD', spend)}</div>;
      },
    },
    {
      field: isLead ? 'leads' : 'visitors',
      headerClassName: styles.header,
      headerName: isLead ? 'Leads' : 'Visitors',
      flex: 1,
      renderCell: (params: any) => {
        const { leads, visitors } = params.row;

        return <div className={styles.name}>{isLead ? leads : visitors}</div>;
      },
    },
    {
      field: isLead ? 'cpl' : 'cpv',
      headerClassName: styles.header,
      headerName: isLead ? 'CPL' : 'CPV',
      flex: 1,
      valueGetter: (_, row) => (isLead ? row.cpl : row.cpv),
      renderCell: (params: any) => {
        const { cpl, cpv } = params.row;

        return (
          <div className={styles.name}>
            {toCurrency('USD', isLead ? cpl : cpv)}
          </div>
        );
      },
    },
  ];

  return columns;
};
