import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface GoogleTemplatesCalloutsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GoogleTemplatesCallouts: React.FC<GoogleTemplatesCalloutsProps> = ({
  submitForm,
  templateForm,
  setIsDirty,
}) => {
  const [callouts, setCallouts] = useState([]);
  const maxItems = googleResourceLimits.callouts.items;
  const maxLength = googleResourceLimits.callouts.length;

  useEffect(() => {
    if (templateForm && templateForm.length > 0) {
      setCallouts(templateForm);
    }
  }, [templateForm]);

  useEffect(() => {
    submitForm(callouts);
  }, [callouts]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `space-between`,
        alignItems: `start`,
      }}
    >
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
        }}
      >
        <Box sx={{}}>
          <TextField
            multiline
            rows={17}
            sx={{
              backgroundColor: 'white',
              border: `2px solid ${DEFAULT_COLOR_THEME}`,
              borderRadius: 2,
            }}
            value={callouts.join('\n')}
            onChange={(e) => {
              setIsDirty(true);

              const temp = e.target.value.split('\n');
              if (temp.length > maxItems) {
                temp.splice(maxItems, temp.length - maxItems);
              }
              setCallouts(temp);
            }}
            placeholder="Enter or paste callouts separated by new lines"
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          maxHeight: 460,
          overflowY: 'auto',
          width: `100%`,
          borderRadius: 2,
          display: callouts.length > 0 ? 'block' : 'none',
          ...scrollBarGreen,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Callouts [{callouts.length} of {maxItems}]
        </Typography>
        {callouts.map((callout, index) => (
          <Box
            sx={{
              textAlign: 'right',
              marginBottom: '1rem',
              maxWidth: 800,
              position: 'relative',
            }}
          >
            <TextField
              error={callout.length > maxLength || callout === ''}
              sx={{
                backgroundColor: 'white',
                '& input': {
                  paddingRight: `50px`,
                },
              }}
              id={`Callouts-${index}`}
              label={`Callout ${index + 1}`}
              variant="outlined"
              name={`Callout-${index}`}
              fullWidth
              value={callout}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="delete button"
                      onClick={() => {
                        const temp = [...callouts];
                        temp.splice(index, 1);
                        setCallouts(temp);
                        setIsDirty(true);
                      }}
                    >
                      <MdDeleteOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setIsDirty(true);
                const updatedItem = [...callouts];
                updatedItem[index] = e.target.value;
                setCallouts(updatedItem);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    callout.length > maxLength || callout === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {`${callout.length}/${maxLength}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    callout.length > maxLength || callout === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {callout.length > maxLength ? 'Max length reached' : ''}
                {callout === '' ? 'Empty callout' : ''}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesCallouts;
