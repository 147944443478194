import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider,
  Drawer,
  Modal,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  formatNumber,
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { Brand } from '../../../../types';
import PageHeader from '../../../PageHeader';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import GGAdzCreation from './GGAdzCreation';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import {
  fetchGoogleAds,
  saveModifiedAdCallouts,
  saveModifiedAdDescriptions,
  saveModifiedAdFinalUrls,
  saveModifiedAdHeadlines,
} from '../../../../services/googleAds';
import { MdOutlinePauseCircleOutline } from 'react-icons/md';
import CircularLoading from '../../../CircularLoading';
import GoogleTemplatesPreview from '../../Templatez/Google/GGLTemplatesPreview';
import GoogleTemplateSummary from '../../Templatez/Google/GGLTemplateSummary';
import GGTemplateCreation from '../../Templatez/Google/GGTemplateCreation';

interface GGAdzIndexProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
}

const styles = {
  header: {
    marginBottom: '20px',
  },
};

const GGAdzIndex: React.FC<GGAdzIndexProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
}) => {
  const [isCreatingAds, setIsCreatingAds] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [adsCollection, setAdsCollection] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [selectedAd, setSelectedAd] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editPages, setEditPages] = React.useState([]);
  const [selectionBudget, setSelectionBudget] = React.useState(false);
  const [selectionLocations, setSelectionLocations] = React.useState(false);
  const [selectionURL, setSelectionURL] = React.useState(false);
  const [selectionKeywords, setSelectionKeywords] = React.useState(false);
  const [selectionPhoneNumber, setSelectionPhoneNumber] = React.useState(false);
  const [selectionHeadlines, setSelectionHeadlines] = React.useState(false);
  const [selectionDescriptions, setSelectionDescriptions] =
    React.useState(false);
  const [selectionCallouts, setSelectionCallouts] = React.useState(false);
  const [selectionSitelinks, setSelectionSitelinks] = React.useState(false);
  const [selectionImages, setSelectionImages] = React.useState(false);
  const [isExisting, setIsExisting] = React.useState(false);
  const [isEditingAd, setIsEditingAd] = React.useState(false);
  const dispatch = useDispatch();
  const syncGoogleAds = async () => {
    fetchGoogleAds(brand._id)
      .then((data) => {
        console.log('fetchGoogleAds data', data);
        setAdsCollection(data);
      })
      .finally(() => setIsLoading(false));
  };

  const doneCreatingHandler = () => {
    setIsCreatingAds(false);
    setIsEditingAd(false);
    setIsExisting(false);
    setSelectionBudget(false);
    setSelectionLocations(false);
    setSelectionURL(false);
    setSelectionKeywords(false);
    setSelectionPhoneNumber(false);
    setSelectionHeadlines(false);
    setSelectionDescriptions(false);
    setSelectionCallouts(false);
    setSelectionSitelinks(false);
    setSelectionImages(false);

    syncGoogleAds();
    dispatch(
      toggleAlert({
        toggle: true,
        message: `Changes have been saved successfully`,
      }),
    );
  };

  useEffect(() => {
    syncGoogleAds();
  }, []);

  const handleAdEdit = () => {
    setEditModalOpen(true);
  };

  const handleCloseAdEdit = () => {
    setEditModalOpen(false);
    setSelectionBudget(false);
    setSelectionLocations(false);
    setSelectionURL(false);
    setSelectionKeywords(false);
    setSelectionPhoneNumber(false);
    setSelectionHeadlines(false);
    setSelectionDescriptions(false);
    setSelectionCallouts(false);
    setSelectionSitelinks(false);
    setSelectionImages(false);
  };

  const handleEditAdContinue = () => {
    const selectedFields: {
      [key: string]: boolean;
    } = {
      Budget: selectionBudget,
      Locations: selectionLocations,
      URL: selectionURL,
      Keywords: selectionKeywords,
      'Keywords Matching Type': selectionKeywords,
      'Phone Number': selectionPhoneNumber,
      Headlines: selectionHeadlines,
      Descriptions: selectionDescriptions,
      Callouts: selectionCallouts,
      Sitelinks: selectionSitelinks,
      Images: selectionImages,
      Preview: true,
    };
    const editPages = Object.keys(selectedFields).filter(
      (field) => selectedFields[field],
    );
    console.log('EDIT AD', editPages);
    setEditModalOpen(false);
    setEditPages(editPages);
    setIsExisting(true);
    setIsEditingAd(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
  };

  const EditModal = (
    <Modal
      open={editModalOpen}
      // onClose={handleCloseConfirmOverwrite}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color={DEFAULT_COLOR_THEME}
          sx={{
            display: `flex`,
            justifyContent: `start`,
            gap: 1,
            alignItems: `center`,
            fontWeight: 600,
          }}
        >
          Ad Edit
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          You are about to edit the Ad:{' '}
          <Typography
            sx={{
              display: `inline`,
              color: DEFAULT_COLOR_THEME,
              fontWeight: 600,
            }}
          >
            {selectedTemplate.name}
          </Typography>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Select the fields you want to edit for this Ad.
        </Typography>

        <Box sx={{ marginBottom: 2, paddingLeft: `20px` }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionBudget}
                  onChange={() => {
                    setSelectionBudget(!selectionBudget);
                  }}
                />
              }
              label="Budget"
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionLocations}
                  onChange={() => {
                    setSelectionLocations(!selectionLocations);
                  }}
                />
              }
              label="Locations"
            />
          </Box>
          <Divider />
          <Typography sx={{ fontSize: 12, fontStyle: 'italic' }}>
            *Note: Changing the following will also affect the linked template
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionURL}
                  onChange={() => {
                    setSelectionURL(!selectionURL);
                  }}
                />
              }
              label="URL"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionKeywords}
                  onChange={() => {
                    setSelectionKeywords(!selectionKeywords);
                  }}
                />
              }
              label="Keywords & Keyword Match Types"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionPhoneNumber}
                  onChange={() => {
                    setSelectionPhoneNumber(!selectionPhoneNumber);
                  }}
                />
              }
              label="Phone Number"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionHeadlines}
                  onChange={() => {
                    setSelectionHeadlines(!selectionHeadlines);
                  }}
                />
              }
              label="Headlines"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionDescriptions}
                  onChange={() => {
                    setSelectionDescriptions(!selectionDescriptions);
                  }}
                />
              }
              label="Descriptions"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionCallouts}
                  onChange={() => {
                    setSelectionCallouts(!selectionCallouts);
                  }}
                />
              }
              label="Callouts"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionSitelinks}
                  onChange={() => {
                    setSelectionSitelinks(!selectionSitelinks);
                  }}
                />
              }
              label="Sitelinks"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionImages}
                  onChange={() => {
                    setSelectionImages(!selectionImages);
                  }}
                />
              }
              label="Business Logo / Images"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            gap: 2,
          }}
        >
          <Button onClick={handleCloseAdEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleEditAdContinue}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const PreviewDrawer = (
    <Box
      sx={{ width: 420, ...scrollBarGreen }}
      role="presentation"
      onClick={() => {
        setOpen(false);
      }}
    >
      <Box
        bgcolor={`#eeeeee`}
        sx={{
          position: `sticky`,
          top: 0,
        }}
      >
        <Typography variant="h6" sx={{ padding: 2 }}>
          Ad Details
        </Typography>
      </Box>
      <Divider />
      <Box paddingTop={2}>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>Name:</Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.name}
        </Typography>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Product and Service:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.product_and_service}
        </Typography>

        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Category:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.category}
        </Typography>

        <Divider />

        <GoogleTemplatesPreview
          headlines={selectedTemplate.headlines}
          descriptions={selectedTemplate.descriptions}
          sitelinks={selectedTemplate.sitelinks}
          url={selectedTemplate.final_url}
          showToolbar={false}
          images={
            selectedTemplate?.images
              ? selectedTemplate?.images.map((img: any) => img.url)
              : []
          }
          business_logo={selectedTemplate?.business_logo?.url}
        />
      </Box>

      <Divider />

      {Object.keys(selectedTemplate).length > 0 ? (
        <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <GoogleTemplateSummary template={selectedTemplate} ad={selectedAd} />
        </Box>
      ) : null}

      <Divider />
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: `#eeeeee`,
          display: `flex`,
          justifyContent: `space-between`,
          paddingX: 1,
          paddingY: 2,
          alignItems: `center`,
          width: `100%`,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30 }}
          onClick={() => {
            setEditModalOpen(true);
          }}
        >
          Edit
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30, display: `hidden` }}
          onClick={() => {
            saveModifiedAdCallouts({
              brandId: brand._id,
              ad: selectedAd,
              callouts: [
                `Outdoor Training`,
                `Certified Trainers`,
                `Personalized Plans`,
                `Transform Your Body`,
              ],
              //finalurl: `https://sales.chatz.app/login`,
              // descriptions: [
              //   `Conquer Your Goals. Achieve More Than You Ever Thought Possible.`,
              //   `Ignite Your Potential. Unleash Your Inner Strength.`,
              //   `A New You Awaits. Start Your Transformation Today.`,
              //   `Rise Above the Ordinary. Elevate Your Fitness Game.`,
              // ],
            });
          }}
        >
          TEMP??
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30, display: 'none' }}
          onClick={() => {
            console.log('Edit');
          }}
        >
          Duplicate
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30, display: 'none' }}
          onClick={() => {
            console.log('Edit');
          }}
        >
          Archive
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ paddingTop: 4, paddingX: 4 }}>
      <CircularLoading loading={isLoading} />
      <PageHeader title="Google Ads" />
      {!isCreatingAds && !isEditingAd ? (
        <>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => {
                setIsCreatingAds(true);
              }}
            >
              Create New Ad
            </Button>
          </Box>
          <Box>
            {adsCollection.length > 0 ? (
              <Box
                sx={{
                  '& .green-header': {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                    fontWeight: 'bold',
                  },
                }}
              >
                <DataGridPremium
                  rows={adsCollection.map((template, index) => ({
                    id: index,
                    ...template,
                  }))}
                  onRowClick={(row) => {
                    console.log('row', row.row);
                    setSelectedTemplate(row.row.template);
                    setSelectedAd(row.row);
                    setOpen(true);
                  }}
                  columns={[
                    // name: 'ads 1',
                    // adgroup: 'adgroup 1',
                    // campaign: 'campaign 1',
                    // budget: 'budget 1',
                    // template: 'template 1',
                    {
                      field: 'name',
                      headerName: 'Name',
                      headerClassName: 'green-header',
                      width: 400,
                      flex: 1,
                    },
                    {
                      field: 'status',
                      headerName: 'Status',
                      headerClassName: 'green-header',
                      width: 100,
                      renderCell: (params) => (
                        <Box
                          sx={{
                            display: `flex`,
                            alignItems: `center`,
                            justifyContent: `start`,
                          }}
                        >
                          <MdOutlinePauseCircleOutline
                            size={24}
                            color={`#777777`}
                          />
                          PAUSED
                        </Box>
                      ),
                    },
                    {
                      field: 'budget',
                      headerName: 'Budget',
                      headerClassName: 'green-header',
                      width: 100,
                      renderCell: (params) => (
                        <>${formatNumber(params.row.budget)}</>
                      ),
                    },
                    {
                      field: 'dateStart',
                      headerName: 'Start Date/Time',
                      headerClassName: 'green-header',
                      width: 300,
                    },
                    {
                      field: 'category',
                      headerName: 'Category',
                      headerClassName: 'green-header',
                      width: 300,
                      renderCell: (params) => <>RESPONSIVE_SEARCH_AD</>,
                    },
                  ]}
                  hideFooter
                />
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  {PreviewDrawer}
                </Drawer>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  minHeight: '200px',
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ maxWidth: `500px`, textAlign: `center` }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: DEFAULT_COLOR_THEME,
                      fontWeight: 'bold',
                    }}
                  >
                    No Ads Found
                  </Typography>
                  Please create a New Ad by Clicking the Button on the upper
                  right corner
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : null}

      {isCreatingAds ? (
        <>
          <GGAdzCreation
            brand={brand}
            doneCreating={doneCreatingHandler}
            adsCollection={adsCollection}
          />
        </>
      ) : null}

      {isEditingAd ? (
        <>
          <Box
            sx={{
              textAlign: `center`,
              position: `relative`,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {selectedTemplate?.name}
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                paddingY: 0,
                paddingX: 2,
                height: 30,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setIsEditingAd(false);
              }}
            >
              Cancel
            </Button>
          </Box>
          <GGTemplateCreation
            creationComplete={doneCreatingHandler}
            brandId={brand?._id}
            defaultRefreshToken={defaultRefreshToken}
            isExisting={isExisting}
            template={selectedTemplate}
            editPages={editPages}
            ad={selectedAd}
          />
        </>
      ) : null}

      {EditModal}
    </Box>
  );
};

export default GGAdzIndex;
