import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface AssetGoogleDescriptionProps {
  submitForm: any;
  handleInputChange: any;
  submenuForm: any;
  selectedProductAndService: string;
  setIsDirty: any;
}

const AssetGoogleDescription: React.FC<AssetGoogleDescriptionProps> = ({
  submitForm,
  handleInputChange,
  submenuForm,
  selectedProductAndService,
  setIsDirty,
}) => {
  const tempH = () => {
    const Descriptions = [];
    for (let i = 0; i < 5; i++) {
      Descriptions.push('');
    }
    return Descriptions;
  };
  const [descriptions, setDescriptions] = useState([...tempH()]);
  const maxItems = googleResourceLimits.descriptions.items;
  const maxLength = googleResourceLimits.descriptions.length;

  useEffect(() => {
    if (submenuForm.descriptions) {
      setDescriptions(submenuForm.descriptions);
    }
  }, [submenuForm]);

  return (
    <Box sx={GoogleAssetsContainer}>
      <FloatingButton
        title="Save"
        onClick={() => {
          submitForm({
            submenu: 'descriptions',
            submenuData: { descriptions },
          });
        }}
      />
      <Typography
        variant="h6"
        fontWeight={800}
        mb={2}
        color={DEFAULT_COLOR_THEME}
      >
        Descriptions [{descriptions.length} of {maxItems}]
      </Typography>
      {descriptions.map((description, index) => (
        <Box
          sx={{
            textAlign: 'right',
            marginBottom: '1rem',
            maxWidth: 780,
          }}
        >
          <FormControl variant="outlined" key={index} fullWidth>
            <InputLabel
              htmlFor={`Description-${index}`}
              sx={{
                backgroundColor: 'white',
              }}
            >
              Description {index + 1}
            </InputLabel>
            <OutlinedInput
              id={`Description-${index}`}
              type={'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      const newDescriptions = [...descriptions];
                      newDescriptions.splice(index, 1);
                      setDescriptions(newDescriptions);
                      setIsDirty(true);
                    }}
                    edge="end"
                  >
                    <MdDeleteOutline color="#aaaaaa" />
                  </IconButton>
                </InputAdornment>
              }
              name={`Description-${index}`}
              fullWidth
              value={description}
              inputProps={{ maxLength }}
              onChange={(e) => {
                const newDescriptions = [...descriptions];
                newDescriptions[index] = e.target.value;
                setDescriptions(newDescriptions);
                setIsDirty(true);
              }}
            />
          </FormControl>

          <Typography variant="body2" color="textSecondary">
            {`${description.length}/${maxLength}`}
          </Typography>
        </Box>
      ))}
      {descriptions.length < maxItems ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDescriptions([...descriptions, '']);
          }}
        >
          Add Description
        </Button>
      ) : null}
    </Box>
  );
};

export default AssetGoogleDescription;
