import { Card, CardHeader } from '@mui/material';
import React, { ReactNode } from 'react';
import CountUp from 'react-countup';

interface DashboardItemProps {
  endingValue: number;
  label: string;
  icon?: ReactNode | null;
  width?: string;
  duration?: number;
  separator?: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  decimals?: number;
  fontSize?: string;
  isMobile?: boolean;
}

const DashboardItem: React.FC<DashboardItemProps> = ({
  endingValue,
  label,
  icon = null,
  // non-mobile width
  width = '200px',
  duration = 3,
  separator = ',',
  isCurrency = false,
  isPercentage = false,
  decimals = 2,
  // non-mobile font size
  fontSize = '16px',
  isMobile = false,
}) => {
  return (
    <Card
      sx={{
        width: { xs: '100%', sm: width },
        minHeight: '100%',
      }}
    >
      <CardHeader
        avatar={icon}
        title={label}
        subheader={
          <CountUp
            end={endingValue}
            duration={duration}
            separator={separator}
            decimals={decimals}
            prefix={isCurrency ? '$' : ''}
            suffix={isPercentage ? '%' : ''}
          />
        }
        titleTypographyProps={{
          sx: {
            color: '#096F4D',
            fontSize: '12px',
            paddingLeft: '0',
          },
        }}
        subheaderTypographyProps={{
          sx: {
            color: '#096F4D',
            fontWeight: 'bold',
            fontSize: isMobile ? '14px' : fontSize,
          },
        }}
        sx={{ padding: '10px' }}
      />
    </Card>
  );
};

export default DashboardItem;
