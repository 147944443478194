import axios from 'axios';
import {
  StripeCanceledSubscriptionForm,
  StripeCreateInvoiceItemForm,
  StripeSubscriptionForm,
} from '../../types';
import { apiBaseUrl } from '../api';

export const createStripeSubscription = async (
  params: StripeSubscriptionForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/subscription`,
    params,
  );

  return data?.data;
};

export const createStripeInvoiceItem = async (
  params: StripeCreateInvoiceItemForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/invoice-item`,
    params,
  );

  return data?.data;
};

export const retriveStripeSubscription = async (
  brandId: string,
  campaignId?: string,
) => {
  let params: any = {};
  if (campaignId) {
    params = { ...params, campaignId };
  }
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/subscription/${brandId}`,
    { params },
  );

  return data?.data;
};

export const retriveStripeInvoiceItems = async (
  brandId: string,
  status: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/invoice-items/${brandId}?status=${status}`,
  );

  return data?.data;
};

export const cancelStripeSubscription = async (
  userId: string,
  subscriptionId: string,
  params: StripeCanceledSubscriptionForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/subscription/cancel`,
    params,
    {
      params: {
        userId,
        subscriptionId,
      },
    },
  );

  return data?.data;
};

export const renewStripeSubscription = async (
  userId: string,
  subscriptionId: string,
  params: StripeCanceledSubscriptionForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/subscription/renew`,
    params,
    {
      params: {
        userId,
        subscriptionId,
      },
    },
  );

  return data?.data;
};
