import { AdImage, Brand, Campaign } from '../../types';
import { Objectives } from '../constants/facebookAds';
import eventLogo from '../../assets/images/events.png';
import promotionLogo from '../../assets/images/promotions.png';
import employmentLogo from '../../assets/images/employment.png';
import cateringLogo from '../../assets/images/catering.png';
import membershipsLogo from '../../assets/images/memberships.png';
import appDownloadLogo from '../../assets/images/app-download.png';
import leadCampaignLogo from '../../assets/images/lead.png';
import engagementLogo from '../../assets/images/engagement.png';
import trafficLogo from '../../assets/images/traffic.png';
import { AdsTemplate, DiySettings } from '../../types/IDiy';
import { BrandIndustry, BusinessType } from './BrandHelpers';
import { constantStringToHumanized, humanizeString } from '../stringModifier';
import { topfireMediaId } from '../../services/api';
import { DEFAULT_TAG, TFM_TAG } from '../constants';

export const diySteps = (brand: Brand) => {
  let temp: string[] = [
    'Campaign Type',
    'Target Market',
    'Budget',
    'Ad Preview',
    'Campaign Details',
  ];

  if (brand?.allowSetupPayment) {
    temp = [...temp, 'Billing Summary'];
  }

  return temp;
};

export const editDiySteps = (
  brand: Brand,
  isChangeTemplate: boolean,
  isBudgetChanged: boolean,
  isAudienceChanged: boolean,
  isGeographicsChanged: boolean,
  isCreativeChanged: boolean,
) => {
  let temp: string[] = [];

  if (isChangeTemplate) {
    temp = [...temp, 'Templates'];
  }

  if (isAudienceChanged) {
    temp = [...temp, 'Audience Target'];
  }

  if (isGeographicsChanged) {
    temp = [...temp, 'Target Market'];
  }

  if (isBudgetChanged) {
    temp = [...temp, 'Budget'];
  }

  if (isCreativeChanged) {
    temp = [...temp, 'Ad Preview'];
  }

  temp = [...temp, 'Campaign Details'];

  if (brand?.allowSetupPayment && isBudgetChanged) {
    temp = [...temp, 'Billing Summary'];
  }

  return temp;
};

export const locationTypes = [
  'address',
  'zip',
  'city',
  'geo_market',
  'county',
  'state',
  'country',
];

export const buildDiySteps = (brand: Brand, category: string) => {
  let steps: string[] = [];
  if (
    buildDiyCampaignObjectives(brand).length > 1 &&
    !(brand?.businessType === BusinessType.FRANDEV)
  ) {
    steps = [...steps, 'The Objective'];
  }

  if (
    category !== 'Employment' &&
    !(brand?.businessType === BusinessType.FRANDEV)
  ) {
    steps = [...steps, 'The Category'];
  }

  steps = [...steps, 'The Audience', 'The Ad', 'The Template'];

  return steps;
};

export const getGeolocationFields = (locationType: string) => {
  if (locationType === 'city') {
    return 'cities';
  }

  if (locationType === 'county') {
    return 'cities';
  }

  if (locationType === 'zip') {
    return 'zips';
  }

  if (locationType === 'state') {
    return 'regions';
  }

  if (locationType === 'geo_market') {
    return 'geo_markets';
  }

  return 'countries';
};

export const buildDiyCampaignObjectives = (diySettings: DiySettings) => {
  let options: any[] = [];

  options = [
    ...options,
    {
      label: 'Get More Traffic',
      value: Objectives.OUTCOME_TRAFFIC,
      image: trafficLogo,
      description:
        'Learn how the traffic objective can help you reach new people and excite your audience.',
    },
  ];

  if (diySettings?.allowLeadAds) {
    options = [
      ...options,
      {
        label: 'Get More Leads',
        value: Objectives.OUTCOME_LEADS,
        image: leadCampaignLogo,
        description:
          'Use lead gen ads to gather information from new contacts across Facebook, Instagram and Messenger, and move them through the buying process.',
      },
    ];
  }

  if (diySettings?.allowMessengerAds) {
    options = [
      ...options,
      {
        label: 'Messenger (Sales Chatz)',
        value: Objectives?.OUTCOME_ENGAGEMENT,
        image: engagementLogo,
        description:
          'Engage with more people across Facebook, Instagram and Messenger. This ad type can help you get more messages, video views, post engagement or event responses.',
      },
    ];
  }

  if (diySettings?.allowAppAds) {
    options = [
      ...options,
      {
        label: 'Get More App Download',
        value: Objectives?.OUTCOME_TRAFFIC,
        image: appDownloadLogo,
        description:
          'Boosts app downloads by targeting specific audiences and driving users to install your mobile app.',
      },
    ];
  }

  options = [
    ...options,
    {
      label: 'Employment',
      value: Objectives?.OUTCOME_TRAFFIC,
      image: employmentLogo,
      description:
        'Boosts app downloads by targeting specific audiences and driving users to install your mobile app.',
    },
  ];

  return options;
};

export const buildDiyCampaignType = (industry: string) => {
  let campaignTypes: any[] = [
    {
      label: 'Events',
      description:
        "Tailor your ads to specific seasons and holidays. Create campaigns around Valentine's Day, Thanksgiving, or other relevant occasions to drive seasonal traffic.",
      image: eventLogo,
    },
    {
      label: 'Promotions',
      description:
        'Advertise special offers, discounts, and promotions to attract budget-conscious clients. Use enticing visuals and clear, concise messaging to communicate the value of your deals.',
      image: promotionLogo,
    },
    {
      label: 'Memberships/Subscribers',
      description:
        'Share positive reviews and testimonials from satisfied customers. ',
      image: membershipsLogo,
    },
  ];

  if (industry === BrandIndustry.RESTAURANT) {
    campaignTypes = [
      ...campaignTypes,
      {
        label: 'Orders/Catering',
        description:
          'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
        image: cateringLogo,
      },
    ];
  }

  return campaignTypes;

  /**
   * 
   * TODO: Will add if necessary
  return [
    {
      label: 'Awareness',
      description:
        "Use Facebook's geotargeting capabilities to reach people in your local area. Promote your business' proximity to their location and emphasize the convenience of doing business with you.",
      image: awarenessLogo,
    },
    {
      label: 'Testimonials/Reviews',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: feedbackLogo,
    },
    {
      label: 'Orders',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: ordersLogo,
    },
    {
      label: 'Catering',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: cateringLogo,
    },
    {
      label: 'Content/Education',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: educationLogo,
    },
    {
      label: 'Contests/Giveaways',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: giveawaysLogo,
    },
    {
      label: 'Menu Showcase',
      description:
        'Share positive reviews and testimonials from satisfied customers. This social proof can build trust and encourage new customers to give your restaurant a try.',
      image: menuLogo,
    },
    
  ];
  */
};

export const buildAddress = (value: any, search?: boolean) => {
  let address: string = '';

  const { name, region, country_name, type, primary_city } = value;

  switch (type) {
    case 'city':
    case 'medium_geo_area':
    case 'subcity':
    case 'neighborhood':
    case 'subneighborhood':
      address = `${name}, ${region}, ${country_name}`;

      break;
    case 'region':
      address = `${name}, ${country_name}`;

      break;
    case 'geo_market':
      address = `${
        search
          ? name.replace(/[^a-zA-Z ]/g, ' ').split(' ')[0]
          : name.split('(')[0]
      }, ${country_name}`;

      break;
    case 'zip':
      address = `${name}, ${primary_city}, ${region}, ${country_name}`;

      break;
    default:
      address = country_name;

      break;
  }

  return address;
};

/**
 * Build Facebook Crop Object
 * @description Provide aspect ratios for images in different ad placements.
 * @reference https://developers.facebook.com/docs/marketing-api/image-crops/
 *            https://developers.facebook.com/docs/marketing-api/reference/ads-image-crops
 * @author Angelo David <angelod@codev.com>
 * @param ratio
 * @param image
 * @returns <Object>
 */
export const buildImageCropObject = (ratio: string, image: AdImage) => {
  let crops: any = {};

  switch (ratio) {
    case 'square':
      if (!(image.height === image.width)) {
        let cropRatio = 100;
        let x1 = (image.width - cropRatio) / 2;
        let y1 = (image.height - cropRatio) / 2;
        let x2 = x1 + cropRatio;
        let y2 = y1 + cropRatio;
        crops = {
          '100x100': [
            [x1, y1],
            [x2, y2],
          ],
        };
      }
      break;
    case 'vertical':
      const verticalImageRatio = image.width / image.height;
      const verticalAspectRatio = 9 / 16;
      const negTolerance = verticalAspectRatio - verticalAspectRatio * 0.05;
      const posTolerance = verticalAspectRatio + verticalAspectRatio * 0.05;
      const isVertical =
        verticalImageRatio >= negTolerance &&
        verticalImageRatio <= posTolerance;

      if (!isVertical) {
        let widthCropRatio = 90;
        let heightCropRatio = 160;
        let x1 = (image.width - widthCropRatio) / 2;
        let y1 = (image.height - heightCropRatio) / 2;
        let x2 = x1 + widthCropRatio;
        let y2 = y1 + heightCropRatio;
        crops = {
          '90x160': [
            [x1, y1],
            [x2, y2],
          ],
        };
      }
      break;
    default:
      const imageRatio = image.width / image.height;
      const aspectRatio = 1.9;
      const tolerance = 0.01;
      const isHorizontal = imageRatio - aspectRatio >= aspectRatio * tolerance;

      if (!isHorizontal) {
        let widthCropRatio = 600;
        let heightCropRatio = 360;
        let x1 = (image.width - widthCropRatio) / 2;
        let y1 = (image.height - heightCropRatio) / 2;
        let x2 = x1 + widthCropRatio;
        let y2 = y1 + heightCropRatio;
        crops = {
          '600x360': [
            [x1, y1],
            [x2, y2],
          ],
        };
      }
      break;
  }

  return crops;
};

/**
 * Ad Naming Convention Builder
 * @description Build naming convention for Campaign, Adset and Ad that we pass
 *              on Facebook Graph API. Naming convention will change based
 *              on brand industry.
 * @author Angelo David <angelod@codev.com>
 * @param type
 * @param brand
 * @param template
 * @param campaigns
 * @returns <String> built ad name
 */
export const buildAdNamingConvention = (
  type: string,
  brand: Brand,
  template: AdsTemplate,
  campaigns: Campaign[],
  limited: boolean,
  campaignName?: string,
  adSetName?: string,
  campaignTag?: string,
  adSetTag?: string,
  adTag?: string,
) => {
  let names: string[] = [];
  let campaignCount: number = 0;
  let adSetCount: number = 0;
  let adCount: number = 0;
  const isTFM = brand?.agency?._id === topfireMediaId;
  const hasSpecialCategory =
    template?.campaignTemplate?.special_ad_categories?.length > 0;

  let temp: Campaign[] = [];
  if (hasSpecialCategory) {
    campaigns.forEach((campaign: Campaign) => {
      if (
        campaign.objective === template?.campaignTemplate?.objective &&
        campaign.special_ad_categories ===
          template?.campaignTemplate?.special_ad_categories
      ) {
        temp = [...temp, campaign];
      }
    });
  } else {
    campaigns.forEach((campaign: Campaign) => {
      if (campaign.objective === template?.campaignTemplate?.objective) {
        temp = [...temp, campaign];
      }
    });
  }

  if (temp.length > 0) {
    campaignCount = temp.length;
  }

  let campaign: Campaign;
  if (!hasSpecialCategory) {
    campaign = campaigns?.find((campaign: Campaign) => {
      return (
        campaign.objective === template?.campaignTemplate?.objective &&
        campaign.limited === limited
      );
    });
  } else {
    campaign = campaigns?.find((campaign: Campaign) => {
      return (
        campaign.objective === template?.campaignTemplate?.objective &&
        campaign.special_ad_categories ===
          template?.campaignTemplate?.special_ad_categories &&
        campaign.limited === limited
      );
    });
  }

  if (campaign) {
    adSetCount =
      campaign?.adSets?.length > 0 ? campaign?.adSets?.length + 1 : 0;
    adCount = campaign?.ads?.length > 0 ? campaign?.ads?.length + 1 : 0;
  }

  switch (type) {
    case 'adset':
      if (campaignName) {
        names = [...names, campaignName];
      }

      if (template?.audienceType === 'custom') {
        names = [...names, 'Lookalike'];
      } else {
        names = [...names, 'Interest'];
      }

      if (adSetCount > 0) {
        names = [...names, `${adSetCount}`];
      }

      if (adSetTag) {
        names = [...names, adSetTag];
      }
      break;
    case 'ad':
      if (adSetName) {
        names = [...names, adSetName];
      }

      names = [...names, humanizeString(template?.media)];

      if (adCount > 0) {
        names = [...names, `${adCount}`];
      }

      if (adTag) {
        names = [...names, adTag];
      }

      break;
    default:
      if (isTFM) {
        names = [...names, TFM_TAG];
      } else {
        names = [...names, DEFAULT_TAG];
      }

      if (!(brand?.businessType === BusinessType.FRANDEV)) {
        names = [...names, brand?.brand];
      }

      names = [
        ...names,
        constantStringToHumanized(template?.campaignTemplate?.objective).split(
          ' ',
        )[1],
      ];

      if (hasSpecialCategory) {
        names = [
          ...names,
          humanizeString(
            buildSpecialCategoriesTags(
              template?.campaignTemplate?.special_ad_categories,
            ),
          ),
        ];
      }

      if (campaignCount > 0) {
        names = [...names, `${campaignCount}`];
      }

      if (campaignTag) {
        names = [...names, `${campaignTag}`];
      }
      break;
  }

  return names.join('-');
};

/**
 * Special Category String Parser
 * @description Use special categories array to built it into string
 * @author Angelo David <angelod@codev.com>
 * @param categories
 * @returns <String> Parse special category string
 */
const buildSpecialCategoriesTags = (categories: string[]) => {
  let tag: string = '';
  categories.forEach((category: string) => {
    tag = `${tag}-${category}`;
  });

  return tag;
};

const removeBlankArrays = (obj: any) => {
  let newObj: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        newObj[key] = value;
      }
    } else if (typeof value === 'object' && value !== null) {
      const nestedObj = removeBlankArrays(value);
      if (Object.keys(nestedObj).length > 0) {
        newObj[key] = nestedObj;
      }
    } else {
      newObj[key] = value;
    }
  });
  return newObj;
};

const sortTargetKeys = (targets: any) => {
  const filteredTargets = removeBlankArrays(targets);
  let sortedObj: any = {};

  Object.keys(filteredTargets)
    .sort()
    .forEach((key) => {
      let newObject: any = filteredTargets[key];
      if (Array.isArray(newObject) && newObject.length === 0) {
        // Skip blank arrays
        return;
      }

      if (newObject === undefined) {
        // Skip undefined values
        return;
      }

      sortedObj[key] = newObject;
    });

  return sortedObj;
};

export const isTargetingEqual = (newTargets: any, currentTargets: any) => {
  // Sort keys alphabetically for both objects
  const sortedObj1 = sortTargetKeys(newTargets);
  const sortedObj2 = sortTargetKeys(currentTargets);
  // Convert objects to strings for comparison
  const stringObj1 = JSON.stringify(sortedObj1);
  const stringObj2 = JSON.stringify(sortedObj2);
  // Compare the string representations of the objects
  return stringObj1 === stringObj2;
};
