import axios from 'axios';
import {
  AdsAccountFormFields,
  Attachment,
  CampaignFormFields,
  FBLeadGenForm,
  FbTargetSearch,
  TargetSearchKey,
} from '../types';
import { apiBaseUrl } from './api';

export const fetchFbAdsAccount = async (
  fbBusinessIds: string[],
  brandId: string,
  userId?: string,
) => {
  let params: any = { fbBusinessIds: fbBusinessIds.join(','), brandId };

  if (userId) {
    params = { ...params, userId };
  }
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/accounts`, {
    params,
  });

  return data?.data;
};

export const fetchAdsAccountByPage = async (page: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/accounts?page=${page}`);

  return data?.data;
};

export const fetchAdsAccountByAgency = async (agency: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/accounts?agency=${agency}`);

  return data?.data;
};

export const fetchAdsAccountByBrand = async (
  brandId: string,
  provider?: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/accounts?brandId=${brandId}${
      provider ? `&provider=${provider}` : ''
    }`,
  );

  return data?.data;
};

export const createAdAccount = async (adAccount: AdsAccountFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/ads/accounts`, adAccount);

  return data?.data;
};

export const removeAdAccount = async (
  accountId: string,
  brandId: string,
  provider: string,
) => {
  const data = await axios.delete(`${apiBaseUrl}/ads/accounts/${accountId}`, {
    params: {
      brandId,
      provider,
    },
  });

  return data?.data;
};

export const fetchFbCampaigns = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/campaigns`, {
    params: {
      brandId,
    },
  });

  return data?.data;
};

export const fetchFbCampaignAds = async (campaignId: string, token: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/facebook/ads?campaignId=${campaignId}&token=${token}`,
  );

  return data?.data;
};

export const createCampaign = async (campaign: CampaignFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/ads/campaigns/create`, campaign);

  return data?.data;
};

export const createCampaignAds = async (campaignAds: any) => {
  const data = await axios.post(`${apiBaseUrl}/ads/create`, {
    campaignAds,
  });

  return data?.data;
};

export const fetchBrandCampaigns = async (
  brand: string,
  provider?: string,
  diy?: boolean,
  adId?: string,
) => {
  let params: any = {};

  if (provider) {
    params = { ...params, provider };
  }

  if (diy) {
    params = { ...params, diy };
  }

  if (adId) {
    params = { ...params, adId };
  }

  const data = await axios.get(`${apiBaseUrl}/ads/campaigns/${brand}`, {
    params,
  });

  return data?.data;
};

export const fetchBrandCampaignsNested = async (
  brandId: string,
  provider?: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/campaigns/nested/${brandId}`,
    {
      params: { provider },
    },
  );

  return data?.data;
};

export const syncFbBusinessAccount = async (
  fbBusinessids: string[],
  brandId: string,
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/facebook/business/sync`, {
    facebookBusinessIds: fbBusinessids,
    brandId,
  });

  return data?.data;
};

export const syncConnectedCampaigns = async (obj: any) => {
  const data = await axios.post(`${apiBaseUrl}/ads/campaigns/sync`, obj);

  return data?.data;
};

export const fetchSpecificFbAd = async (adId: string, brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/ad/${adId}`, {
    params: {
      brandId,
    },
  });

  return data?.data;
};

export const fetchSpecificFbAdset = async (
  adsetId: string,
  brandId: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/adset/${adsetId}`, {
    params: {
      brandId,
    },
  });

  return data?.data;
};

export const generateAdsPreview = async (
  creativeId: string,
  template: string,
  brandId: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/ad/generate-preview`,
    {
      creativeId,
      template,
      brandId,
    },
  );

  return data?.data;
};

export const fetchTargetSearch = async (
  type: string,
  countryCode: string,
  searchQuery?: string,
) => {
  let params: any = {
    type,
    countryCode,
  };
  if (searchQuery) params = { ...params, query: searchQuery };

  const data = await axios.get(`${apiBaseUrl}/ads/facebook/target-search`, {
    params,
  });

  return data?.data;
};

export const deleteCampaign = async (
  campaignId: string,
  brandId: string,
  provider: string,
) => {
  const data = await axios.delete(`${apiBaseUrl}/ads/campaigns/${campaignId}`, {
    params: { brandId, provider },
  });

  return data?.data;
};

export const getFbTargetingBrowse = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/target-search/browse`,
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const getFbTargetingSearch = async (brandId: string, query: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/target-search/search`,
    {
      params: {
        brandId,
        query,
      },
    },
  );

  return data?.data;
};

export const getFbTargetingSuggestions = async (
  brandId: string,
  accountId: string,
  params: TargetSearchKey[],
) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/targeting/suggestions`,
    { targetList: params },
    {
      params: {
        brandId,
        accountId,
      },
    },
  );

  return data?.data;
};

export const getFbTargetingReachEstimate = async (
  brandId: string,
  targets: FbTargetSearch,
  optimizationGoal: string,
  plain?: boolean,
  accountId?: string,
) => {
  let params: any = { brandId, optimizationGoal };

  /**
   * Send the plain version of the target specs
   * @context We are passing the Formatted version (with non-Facebook related fields)
   *          during DIY process which are formatted on the back-end into plain version.
   *          Set this to true will skip the formatting logic.
   */
  if (plain) {
    params = { ...params, plain };
  }

  if (accountId) {
    params = { ...params, accountId };
  }

  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/targeting/reach`,
    targets,
    {
      params,
    },
  );

  return data?.data;
};

export const fetchFbAdAccountImages = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/ad-images`, {
    params: { brandId },
  });

  return data?.data;
};

export const uploadFbAdAccountImages = async (
  brandId: string,
  file: Attachment,
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/facebook/ad-image`, {
    brandId,
    file,
  });

  return data?.data;
};

export const duplicateFbAdAccountImages = async (
  brandId: string,
  params: any,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/ad-image/duplicate`,
    {
      ...params,
    },
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const fetchFbAdAccountVideos = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/ad-videos`, {
    params: { brandId },
  });

  return data?.data;
};

export const fetchSingleFbAdAccountVideo = async (
  brandId: string,
  videoId: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/ad-videos/${videoId}`,
    {
      params: { brandId },
    },
  );

  return data?.data;
};

export const uploadFbAdAccountVideos = async (
  brandId: string,
  file: Attachment,
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/facebook/ad-video`, {
    brandId,
    file,
  });

  return data?.data;
};

export const fetchBusinessFbPages = async (
  businessIds: string[],
  token: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/business/pages`, {
    params: { businessIds: businessIds.join(','), token },
  });

  return data?.data;
};

export const getFbPageDefaultIgAccount = async (pageId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/pages/default-ig-account/${pageId}`,
  );

  return data?.data;
};

export const fetchFbPageIgAccounts = async (
  pageId: string,
  token: string,
  brandId: string | null,
) => {
  let params: any = { pageId };

  if (!brandId) {
    params = { ...params, token };
  } else {
    params = { ...params, brandId };
  }

  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/business/pages/ig-accounts`,
    { params },
  );

  return data?.data;
};

export const fetchBusinessInstagramAccounts = async (
  brandId: string,
  token: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/business/instagram`,
    {
      params: { brandId, token },
    },
  );

  return data?.data;
};

export const fetchFacebookGenForms = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/facebook/lead-gen-forms`, {
    params: { brandId },
  });

  return data?.data;
};

export const fetchSpecificFacebookGenForms = async (
  brandId: string,
  formId: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/lead-gen-forms/${formId}`,
    {
      params: { brandId },
    },
  );

  return data?.data;
};

export const fetchFbCoverPhoto = async (
  brandId: string,
  coverPhotoId: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/lead-gen-forms/cover-photo/${coverPhotoId}`,
    {
      params: { brandId },
    },
  );

  return data?.data;
};

export const createFacebookGenForms = async (
  brandId: string,
  params: FBLeadGenForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/facebook/lead-gen-form`,
    { leadGen: params },
    {
      params: { brandId },
    },
  );

  return data?.data;
};

export const generateAdCopyTemplates = async (
  brandId: string,
  emoji: string,
  purpose: string,
  characters: number,
  bulletPoints: string,
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/generate-text-copy`, {
    brandId,
    emoji,
    purpose,
    characters,
    bulletPoints,
  });

  return data?.data;
};

export const getSingleFbAdCreative = async (
  brandId: string,
  creativeId: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/facebook/creative/${creativeId}`,
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const syncFbAds = async (brandId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/ads/campaigns/ads/sync?brandId=${brandId}`,
  );

  return data?.data;
};

export const getSingleBrandAd = async (adId: string, brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/ads/single/${adId}`, {
    params: {
      brandId,
    },
  });

  return data?.data;
};

export const getAdsChangeLogs = async (params: {
  brandId: string;
  provider: string;
  campaignId?: string;
  adsetId?: string;
  adId?: string;
  userId?: string;
}) => {
  const { brandId, provider, campaignId, adsetId, adId, userId } = params;

  let query: any = { brandId, provider };

  if (campaignId) {
    query['campaignId'] = campaignId;
  }

  if (adsetId) {
    query['adsetId'] = adsetId;
  }

  if (adId) {
    query['adId'] = adId;
  }

  if (userId) {
    query['userId'] = userId;
  }

  const data = await axios.get(`${apiBaseUrl}/ads/campaigns/logs`, {
    params: query,
  });

  return data?.data;
};
