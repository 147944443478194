import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  googleResourceLimits,
  GoogleAssetsContainer,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface AssetGoogleSitelinksProps {
  submitForm: any;
  handleInputChange: any;
  submenuForm: any;
  selectedProductAndService: string;
  setIsDirty: any;
}

const AssetGoogleSitelinks: React.FC<AssetGoogleSitelinksProps> = ({
  submitForm,
  handleInputChange,
  submenuForm,
  selectedProductAndService,
  setIsDirty,
}) => {
  const tempH = () => {
    const x = [];
    for (let i = 0; i < 2; i++) {
      x.push({
        headline: '',
        description1: '',
        description2: '',
        finalUrl: '',
      });
    }
    return x;
  };
  const [sitelinks, setSitelinks] = React.useState([...tempH()]);
  const maxItems = googleResourceLimits.sitelinks.items;
  const maxLength = googleResourceLimits.sitelinks.length;

  useEffect(() => {
    if (submenuForm.sitelinks) {
      setSitelinks(submenuForm.sitelinks);
    }
  }, [submenuForm]);

  return (
    <Box sx={GoogleAssetsContainer}>
      <FloatingButton
        title="Save"
        onClick={() => {
          submitForm({
            submenu: 'sitelinks',
            submenuData: { sitelinks },
          });
        }}
      />
      <Typography
        variant="h6"
        fontWeight={800}
        mb={2}
        color={DEFAULT_COLOR_THEME}
      >
        Sitelinks [{sitelinks.length} of {maxItems}]
      </Typography>
      {sitelinks.map((sitelink, index) => (
        <Box
          sx={{
            textAlign: 'right',
            marginBottom: '1rem',
            maxWidth: 600,
            backgroundColor: `#ebebeb`,
            padding: '1rem',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0.25rem',
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              fontSize={16}
              color={DEFAULT_COLOR_THEME}
            >
              Sitelinks #{index + 1}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: '#ffcccc',
                },
              }}
              onClick={() => {
                const temp = [...sitelinks];
                temp.splice(index, 1);
                setSitelinks(temp);
                setIsDirty(true);
              }}
            >
              <MdDeleteOutline size={24} color="#ababab" />
            </Button>
          </Box>

          <TextField
            key={`headline-${index}`}
            id={`headline-${index}`}
            label={`Headline ${index + 1}`}
            variant="outlined"
            fullWidth
            value={sitelink.headline}
            onChange={(e) => {
              setIsDirty(true);
              const temp = [...sitelinks];
              temp[index].headline = e.target.value;
              setSitelinks(temp);
            }}
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="body2" color="textSecondary">
            {`${sitelink.headline.length} of ${maxLength.headline}`}
          </Typography>

          <TextField
            key={`description1-${index}`}
            id={`description1-${index}`}
            label={`Description 1`}
            variant="outlined"
            fullWidth
            value={sitelink.description1}
            onChange={(e) => {
              setIsDirty(true);
              const temp = [...sitelinks];
              temp[index].description1 = e.target.value;
              setSitelinks(temp);
            }}
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="body2" color="textSecondary">
            {`${sitelink.description1.length} of ${maxLength.description1}`}
          </Typography>

          <TextField
            key={`description2-${index}`}
            id={`description2-${index}`}
            label={`Description 2`}
            variant="outlined"
            fullWidth
            value={sitelink.description2}
            onChange={(e) => {
              setIsDirty(true);
              const temp = [...sitelinks];
              temp[index].description2 = e.target.value;
              setSitelinks(temp);
            }}
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="body2" color="textSecondary">
            {`${sitelink.description2.length} of ${maxLength.description2}`}
          </Typography>

          <TextField
            key={`finalUrl-${index}`}
            id={`finalUrl-${index}`}
            label={`Final URL`}
            variant="outlined"
            fullWidth
            value={sitelink.finalUrl}
            onChange={(e) => {
              setIsDirty(true);
              const temp = [...sitelinks];
              temp[index].finalUrl = e.target.value;
              setSitelinks(temp);
            }}
            sx={{ backgroundColor: 'white' }}
          />

          <Typography variant="body2" color="textSecondary">
            {`${sitelink.finalUrl.length} of ${maxLength.finalUrl}`}
          </Typography>
        </Box>
      ))}
      {sitelinks.length < maxItems ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSitelinks([
              ...sitelinks,
              {
                headline: '',
                description1: '',
                description2: '',
                finalUrl: '',
              },
            ]);
          }}
        >
          Add Sitelink
        </Button>
      ) : null}
    </Box>
  );
};

export default AssetGoogleSitelinks;
