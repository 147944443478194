import { Grid, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ChangeEventType, FBLeadGenForm } from '../../../../../types';

interface LeadFormDetailsFormProps {
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  register: any;
  errors: any;
  clearErrors: any;
}

const LeadFormDetailsForm: React.FC<LeadFormDetailsFormProps> = ({
  formValues,
  setFormValues,
  register,
  errors,
  clearErrors,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register('name', { required: true })}
              fullWidth
              required
              type="text"
              name="name"
              label="Form Name"
              onChange={(e: ChangeEventType) => {
                clearErrors('name');
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
              }}
              value={formValues?.name}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.name}
              helperText={errors.name && 'Form name is required'}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="description"
              label="Description"
              onChange={(e: ChangeEventType) =>
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                })
              }
              value={formValues?.description}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              minRows={4}
              maxRows={100}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadFormDetailsForm;
