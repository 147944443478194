import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { AdsAccount, AdsAccountFormFields, Brand } from '../../../types';
import styles from '../../../assets/styles/components/Modal/CampaignModal.module.scss';
import { humanizeString } from '../../../utils/stringModifier';
import FormHeader from '../FormHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { IS_DIY_ADZ } from '../../../utils';

interface FacebookAdsAccountFormProps {
  brand: Brand;
  handleSync: (value: any[], brand: Brand) => void;
  fbAdsAccounts: AdsAccount[];
  adAccounts: AdsAccountFormFields[];
  connectedAccountsIds: string[];
  onSelectAdsAccount: (adAccount: AdsAccountFormFields) => void;
  onRemoveAdAccount: (adAccount: AdsAccountFormFields) => void;
  provider: string;
}

const FacebookAdsAccountForm: React.FC<FacebookAdsAccountFormProps> = ({
  brand,
  handleSync,
  fbAdsAccounts,
  adAccounts,
  connectedAccountsIds,
  onSelectAdsAccount,
  onRemoveAdAccount,
  provider,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.accounts}>
      <FormHeader title={`Connect ${humanizeString(provider)} Ad Accounts`} />

      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          {brand ? (
            <Autocomplete
              id="facebookBusinessIds"
              multiple
              disableClearable
              freeSolo
              options={[]}
              value={brand?.facebookBusinessIds}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Facebook Business Ids"
                  placeholder="Enter Facebook Businesss ID"
                  name="facebookBusinessIds"
                />
              )}
              onChange={(e: any, value: any[]) => {
                handleSync(value, brand);
              }}
              disabled={IS_DIY_ADZ}
            />
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="adsAccount"
            multiple
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.accountId}>
                  {option.name}
                </li>
              );
            }}
            options={fbAdsAccounts.sort(
              (a, b) => -b.name.localeCompare(a.name),
            )}
            getOptionDisabled={(option) =>
              !!adAccounts.find(
                (element: any) =>
                  element.accountId === option.accountId && !element.deleted,
              )
            }
            value={adAccounts}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ad Accounts"
                placeholder="Select Your Ads Accounts"
              />
            )}
            disableClearable
            onChange={async (e: any, value: any[], reason, detail) => {
              if (
                reason === 'removeOption' &&
                connectedAccountsIds.includes(detail.option.accountId)
              ) {
                return dispatch(
                  toggleAlert({
                    toggle: true,
                    message: `Cannot remove ${detail.option.name} Ad Account. Kindly disconnect the connected ${detail.option.name}'s campaigns first.`,
                    type: 'error',
                  }),
                );
              }

              if (reason === 'selectOption') {
                onSelectAdsAccount(detail.option as AdsAccountFormFields);
              } else if (reason === 'removeOption') {
                onRemoveAdAccount(detail.option as AdsAccountFormFields);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  disabled={!option.manual && IS_DIY_ADZ}
                />
              ))
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            {adAccounts.map((adsAccount: AdsAccountFormFields) => {
              return adsAccount.deleted ? null : (
                <Grid item xs={12} sm={4} lg={3} key={adsAccount.accountId}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader
                      subheader={adsAccount.name}
                      subheaderTypographyProps={{
                        sx: {
                          fontWeight: 'bold',
                          color: 'black',
                          textAlign: 'center',
                        },
                      }}
                    />

                    <CardContent sx={{ paddingTop: 0 }}>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Account ID:
                        <Typography variant="body2" display="inline" ml={1}>
                          {adsAccount.accountId}
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Status:
                        <Typography variant="body2" display="inline" ml={1}>
                          {humanizeString(adsAccount.currency)}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FacebookAdsAccountForm;
