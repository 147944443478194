import { DEFAULT_COLOR_THEME } from '../Styling';

export const formatNumber = (n: string | number) => {
  // Add commas for thousands, millions, etc. example n===1000, return 1,000
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const googleEnums = {
  keywords: ['UNSPECIFIED', 'UNKNOWN', 'EXACT', 'PHRASE', 'BROAD'],
  advertisingChannel: [
    'UNSPECIFIED',
    'UNKNOWN',
    'SEARCH',
    'DISPLAY',
    'SHOPPING',
    'HOTEL',
    'VIDEO',
    'MULTI CHANNEL',
    'LOCAL',
    'SMART',
    'PERFORMANCE MAX',
    'LOCAL SERVICES',
    'DISCOVERY',
    'TRAVEL',
  ],
  campaignStatus: ['UNSPECIFIED', 'UNKNOWN', 'ENABLED', 'PAUSED', 'REMOVED'],
};

export const GoogleCampaignStatus = {
  UNSPECIFIED: 'UNSPECIFIED',
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  PAUSED: 'PAUSED',
  REMOVED: 'REMOVED',
};

export const googleHelpers = {
  micros: (value: number) => {
    if (!value || value === 0) {
      return 0;
    }

    return value / 1000000;
  },
};

export const scrollBarGreen = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: DEFAULT_COLOR_THEME,
    borderRadius: '18px',

    '&:hover': {
      background: DEFAULT_COLOR_THEME,
    },
  },
};

export const GoogleAssetsContainer = {
  height: '100vh',
  maxHeight: '670px',
  padding: '0.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  ...scrollBarGreen,
};

export const GoogleAssestsSidebarVisibilityOnTabs: {
  [key: string]: string[];
} = {
  Home: ['keyword set'],
};

export const GoogleAssetsMainMenu = [
  // oldones
  // 'Account',
  // 'Business',
  // 'Search',
  // //'Display',
  // //'Performance Max',
  'Home',
];

export const GoogleAssetsSubMenu: {
  [key: string]: string[];
} = {
  Home: ['account', 'business', `location set`],
  Account: ['account', 'images'],
  Business: ['business', 'competitors', 'products and services'],
  Search: [
    'keywords',
    //'images',
    //'videos',
    //'logo',
    'headlines',
    'descriptions',
    'sitelinks',
    // 'callouts',
    // 'snippets',
  ],
  Display: [],
  'Performance Max': [],
  Local: ['Local'],
};

export const googleResourceLimits = {
  //items = number of items allowed
  //length = max length of characters each item
  callouts: {
    items: 10,
    length: 25,
  },
  headlines: {
    items: 15,
    length: 30,
  },
  descriptions: {
    items: 4,
    length: 90,
  },
  sitelinks: {
    items: 10,
    length: {
      headline: 25,
      description1: 35,
      description2: 35,
      finalUrl: 2048,
    },
  },
  videos: {
    items: 5,
    length: 2048,
  },
  images: {
    items: 5,
  },
};
