import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Brand, ChangeEventType } from '../../../types';
import { toCurrency } from '../../../utils/numberFormatter';
import { AdsTemplate } from '../../../types/IDiy';
import momentTz, { Moment } from 'moment-timezone';
import Spinner from '../../Spinner';
import { useSelector } from 'react-redux';

interface DiyUpdateAdBudgetFormProps {
  brand: Brand;
  currentBudget: number; // adset?.totalBudget
  newMonthlyBudget: number;
  setNewMonthlyBudget: Dispatch<SetStateAction<number>>;
  setTotalCharge: Dispatch<SetStateAction<number>>;
  isDecrease: boolean;
  setIsDecrease: Dispatch<SetStateAction<boolean>>;
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  thruYesterDaySpend: number;
  todaySpend: number;
  register: any;
  errors: any;
  clearErrors: any;
  setError: any;
  setValue: any;
  loading: boolean;
}

const DiyUpdateAdBudgetForm: React.FC<DiyUpdateAdBudgetFormProps> = ({
  brand,
  currentBudget,
  newMonthlyBudget,
  setNewMonthlyBudget,
  setTotalCharge,
  isDecrease,
  setIsDecrease,
  template,
  setTemplate,
  thruYesterDaySpend,
  todaySpend,
  register,
  errors,
  clearErrors,
  setError,
  setValue,
  loading,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const [changeType] = useState<string>('immediate');

  const calculateNewBudget = (newBudget: number) => {
    return newBudget > 0 ? newBudget - currentBudget : 0;
  };

  const budgetChangeType = () => {
    if (isDecrease) {
      return 'Decrease';
    }

    return 'Increase';
  };

  const handleOnChange = (e: ChangeEventType) => {
    const name = e.target.name;
    let temp: AdsTemplate = { ...template };
    const startDate = momentTz
      .unix(temp?.adSetTemplate?.start_time)
      .tz(timezone);
    const startMonth = startDate.clone().month();
    const startYear = startDate.clone().year();
    const currentDate = momentTz().tz(timezone);
    const currentDateMonth = currentDate.clone().month();
    const currentDateYear = currentDate.clone().year();
    const isNewCampaign =
      startMonth === currentDateMonth && startYear === currentDateYear;
    let newPeriodStart: Moment | null;
    if (!isNewCampaign) {
      newPeriodStart = currentDate.clone().startOf('month');
    } else {
      newPeriodStart = startDate;
    }

    const endOfMonth = newPeriodStart.clone().endOf('month').endOf('day');
    const remainingDays = endOfMonth.diff(currentDate, 'days') + 1;

    clearErrors('newMonthlyBudget');
    clearErrors('dailyBudget');
    let newBudget: number = 0;
    let totalCharge: number = 0;
    let dailyBudget: number = 0;
    if (name === 'dailyBudget') {
      dailyBudget = parseFloat(e.target.value);
      const totalBudget = dailyBudget * remainingDays;
      newBudget = parseInt((totalBudget + thruYesterDaySpend).toFixed(0));
      if (dailyBudget < todaySpend) {
        newBudget = parseInt(
          (newBudget + (todaySpend - dailyBudget)).toFixed(0),
        );
      }

      if (dailyBudget < 1) {
        setError('dailyBudget', {
          type: 'custom',
          message: 'Daily budget must be at least $1.00',
        });
      }
      setValue('newMonthlyBudget', newBudget);
    } else if (name === 'newMonthlyBudget') {
      newBudget = parseFloat(e.target.value);
      const remainingBudget = newBudget - thruYesterDaySpend;
      dailyBudget = parseFloat((remainingBudget / remainingDays).toFixed(2));

      if (dailyBudget < todaySpend) {
        const thisMonthSpend = thruYesterDaySpend + todaySpend;
        const remainingBudget = newBudget - thisMonthSpend;
        dailyBudget = parseFloat(
          (remainingBudget / (remainingDays - 1)).toFixed(2),
        );
      }

      if (dailyBudget < 0) {
        setError('dailyBudget', {
          type: 'custom',
          message: 'Invalid daily budget',
        });
      } else if (dailyBudget < 1) {
        setError('dailyBudget', {
          type: 'custom',
          message: 'Daily budget must be at least $1.00',
        });
      } else {
        clearErrors('dailyBudget');
      }

      setValue('dailyBudget', dailyBudget);
    } else if (name === 'futureBudget') {
      const nextMonth = currentDate.clone().add(1, 'month');
      const nextMonthDays = nextMonth.daysInMonth();
      newBudget = temp?.adSetTemplate?.totalBudget || 0;
      temp = {
        ...temp,
        adSetTemplate: {
          ...temp?.adSetTemplate,
          futureBudget: parseFloat(e.target.value),
          futureDailyBudget: parseFloat(
            (parseFloat(e.target.value) / nextMonthDays).toFixed(2),
          ),
        },
      };
    } else if (name === 'lifetime_budget') {
      newBudget = parseFloat(e.target.value);

      temp = {
        ...temp,
        adSetTemplate: {
          ...temp?.adSetTemplate,
          lifetime_budget: newBudget,
          daily_budget: null,
        },
      };
    }

    if (name !== 'futureBudget') {
      totalCharge = newBudget > 0 ? newBudget - currentBudget : 0;
      setNewMonthlyBudget(newBudget);
      setTotalCharge(totalCharge);
      setIsDecrease(totalCharge < 0);

      if (['dailyBudget', 'newMonthlyBudget'].includes(name)) {
        temp = {
          ...temp,
          adSetTemplate: {
            ...temp?.adSetTemplate,
            daily_budget: dailyBudget,
            totalCharge,
            lifetime_budget: null,
          },
        };
      }
    }

    temp = {
      ...temp,
      adSetTemplate: {
        ...temp?.adSetTemplate,
        totalBudget: newBudget,
      },
    };

    setTemplate(temp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Please enter your new desired monthly budget
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              This Month Spend:{' '}
              <Typography variant="body2" component="span">
                {toCurrency('USD', thruYesterDaySpend + todaySpend)}
              </Typography>
            </Typography>

            <Typography variant="body2" fontWeight="bold">
              This Month Spend (thru yesterday):{' '}
              <Typography variant="body2" component="span">
                {toCurrency('USD', thruYesterDaySpend)}
              </Typography>
            </Typography>

            <Typography variant="body2" fontWeight="bold">
              Today Spend:{' '}
              <Typography variant="body2" component="span">
                {toCurrency('USD', todaySpend)}
              </Typography>
            </Typography>
          </Grid>

          {changeType && !loading ? (
            <>
              <Grid item xs={6}>
                <TextField
                  {...register(
                    template?.limited ? 'lifetime_budget' : 'newMonthlyBudget',
                    {
                      required: 'Please set your new budget',
                      validate: {
                        mustBeZero: (value: string) =>
                          parseFloat(value) !== 0 ||
                          'You cannot set the new monthly budget to zero (0)',
                        shouldNotEqualToCurrent: (value: string) =>
                          parseFloat(value) !== currentBudget ||
                          'You cannot set new budget equal to current budget',
                      },
                    },
                  )}
                  fullWidth
                  required
                  variant="standard"
                  type="number"
                  id="newMonthlyBudget"
                  name={
                    template?.limited ? 'lifetime_budget' : 'newMonthlyBudget'
                  }
                  label={`This ${
                    template?.limited ? `Month's Lifetime` : `Month's`
                  } Budget`}
                  onChange={handleOnChange}
                  value={newMonthlyBudget}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  autoFocus
                  error={
                    template?.limited
                      ? !!errors.lifetime_budget
                      : !!errors.newMonthlyBudget
                  }
                  helperText={
                    template?.limited
                      ? errors.lifetime_budget
                        ? errors.lifetime_budget.message
                        : ''
                      : errors.newMonthlyBudget
                      ? errors.newMonthlyBudget.message
                      : ''
                  }
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                />
              </Grid>

              {!template?.limited ? (
                <Grid item xs={6}>
                  <TextField
                    {...register('futureBudget', {
                      required: 'Please set your new future budget',
                      validate: {
                        mustBeZero: (value: string) =>
                          parseFloat(value) !== 0 ||
                          'You cannot set the new monthly budget to zero (0)',
                      },
                    })}
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    id="futureBudget"
                    name="futureBudget"
                    label="Future Monthly Budget"
                    onChange={handleOnChange}
                    value={template?.adSetTemplate?.futureBudget}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    error={!!errors.futureBudget}
                    helperText={
                      errors.futureBudget ? errors.futureBudget.message : ''
                    }
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              ) : null}

              {!template?.limited && !brand?.allowSetupPayment ? (
                <Grid item xs={6}>
                  <TextField
                    {...register('dailyBudget', {
                      required: 'Please set your new daily budget',
                      validate: {
                        mustBeZero: (value: string) =>
                          parseFloat(value) !== 0 ||
                          'You cannot set the new daily budget to zero (0)',
                        mustBeGreaterThanZero: (value: string) =>
                          parseFloat(value) >= 1 ||
                          'The budget must be atleast $1.00',
                      },
                    })}
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    id="dailyBudget"
                    name="dailyBudget"
                    label="Daily Budget"
                    onChange={handleOnChange}
                    value={template?.adSetTemplate?.daily_budget}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    error={!!errors.dailyBudget}
                    helperText={
                      errors.dailyBudget ? errors.dailyBudget.message : ''
                    }
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              ) : null}
            </>
          ) : (
            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <Spinner />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Current Monthly Budget
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {toCurrency('USD', currentBudget || 0)}
              </Grid>

              <Grid item xs={6}>
                {' '}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  New Monthly Budget
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {toCurrency('USD', newMonthlyBudget || 0)}
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`Budget ${budgetChangeType()}`}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {toCurrency('USD', calculateNewBudget(newMonthlyBudget))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DiyUpdateAdBudgetForm;
