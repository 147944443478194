import axios from 'axios';
import { apiBaseUrl } from './api';

export const createFbLoginEmails = async (obj: any) => {
  const data = await axios.post(`${apiBaseUrl}/admin/create`, obj);

  return data?.data;
};

export const fetchAdminData = async () => {
  const data = await axios.get(`${apiBaseUrl}/admin/`);

  return data?.data;
};
