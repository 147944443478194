import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DEFAULT_COLOR_THEME } from '../../utils';
import { MdChevronLeft, MdChevronRight, MdLens } from 'react-icons/md';
import { GoogleAssestsSidebarVisibilityOnTabs } from '../../utils/constants/google';

interface GoogleProductAndServicesSidemenuProps {
  productsAndServices: string[];
  setSelectedProductAndService: any;
  selectedProductAndService: string;
  selectedMainmenu: string;
  selectedSubmenu: string;
  checkDirty: any;
}

const GoogleProductAndServicesSidemenu: React.FC<
  GoogleProductAndServicesSidemenuProps
> = ({
  productsAndServices,
  setSelectedProductAndService,
  selectedProductAndService,
  selectedMainmenu,
  selectedSubmenu,
  checkDirty,
}) => {
  const [open, setOpen] = React.useState(true);
  if (!selectedProductAndService && productsAndServices.length > 0) {
    setSelectedProductAndService(productsAndServices[0]);
  } else if (productsAndServices.length === 0) {
    setSelectedProductAndService('');
  }

  const handleOpen = () => {
    setOpen(!open);
  };

  if (
    !Object.keys(GoogleAssestsSidebarVisibilityOnTabs).includes(
      selectedMainmenu,
    )
  ) {
    return <></>;
  }
  console.log();
  if (
    !GoogleAssestsSidebarVisibilityOnTabs[selectedMainmenu].includes(
      selectedSubmenu,
    )
  ) {
    return <></>;
  }

  return (
    <Box
      sx={{ maxWidth: 300, flexShrink: 0, padding: `0 0 0 10px` }}
      bgcolor={`#ffffff`}
    >
      <Box
        sx={{
          display: `flex`,
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography
          color={DEFAULT_COLOR_THEME}
          fontWeight={600}
          display={open ? 'block' : 'none'}
        >
          Products and Services:
        </Typography>
        <Button
          sx={{
            flexShrink: 0,
            justifyContent: 'right',
            width: `fit-content`,
            minWidth: 0,
          }}
          onClick={handleOpen}
        >
          {open ? <MdChevronLeft size={24} /> : <MdChevronRight size={24} />}
        </Button>
      </Box>
      {open ? (
        productsAndServices.length > 0 ? (
          productsAndServices.map((ps, index) => {
            return (
              <Box key={index}>
                <Button
                  sx={{
                    display: `flex`,
                    justifyContent: 'left',
                    gap: 1,
                    width: `100%`,
                    //color: `#000000`,
                    //textDecoration: `underline`,
                    borderBottom: `1px solid #ccc`,
                    fontWeight: ps === selectedProductAndService ? 800 : 400,
                    textDecoration:
                      ps === selectedProductAndService ? `underline` : `none`,
                    bgcolor:
                      ps === selectedProductAndService ? `#f0f0f0` : `#ffffff`,
                    '&:hover': {
                      bgcolor: `#e0e0e0`,
                    },
                  }}
                  onClick={() => {
                    checkDirty(() => {
                      setSelectedProductAndService(ps);
                    });
                  }}
                >
                  <MdLens size={8} />
                  {ps}
                </Button>
              </Box>
            );
          })
        ) : (
          <>No Products and Services found</>
        )
      ) : null}
    </Box>
  );
};
export default GoogleProductAndServicesSidemenu;
