import { Brand } from '../../types';
import {
  SET_BRAND,
  SET_BRANDS,
  SET_BRAND_FILTER,
  SET_DEMO_MODE,
  SET_LOCATION,
  SET_LOCATIONS,
  SET_SHOW_ALL_BRANDS,
} from '../ActionNames';

const brands = (state: Brand[] = [], action: any) => {
  switch (action.type) {
    case SET_BRANDS:
      return { ...state, brands: action.payload };
    default:
      return state;
  }
};

const brand = (state: Brand = null, action: any) => {
  switch (action.type) {
    case SET_BRAND:
      return { ...state, brand: action.payload };
    default:
      return state;
  }
};

const brandFilter = (state: any = { filter: null }, action: any) => {
  switch (action.type) {
    case SET_BRAND_FILTER:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
};

const locations = (state: Brand[] = [], action: any) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return { ...state, locations: action.payload };
    default:
      return state;
  }
};

const location = (state: Brand = null, action: any) => {
  switch (action.type) {
    case SET_LOCATION:
      return { ...state, location: action.payload };
    default:
      return state;
  }
};

const showAllBrands = (state: boolean = false, action: any) => {
  switch (action.type) {
    case SET_SHOW_ALL_BRANDS:
      return action.payload;
    default:
      return state;
  }
};

const demoMode = (state: boolean = false, action: any) => {
  switch (action.type) {
    case SET_DEMO_MODE:
      return action.payload;
    default:
      return state;
  }
};

export {
  brands,
  brand,
  brandFilter,
  locations,
  location,
  showAllBrands,
  demoMode,
};
