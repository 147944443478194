import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { DEFAULT_COLOR_THEME } from '../../../utils';

export const leadFormColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 200),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { name, leadForm, onOpenPreview } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpenPreview(leadForm)}>
            <Tooltip
              title={<Typography variant="body2">{name}</Typography>}
              disableInteractive
            >
              {name}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 200),
      field: 'description',
      headerClassName: styles.header,
      headerName: 'Description',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { description, leadForm, onOpenPreview } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpenPreview(leadForm)}>
            <Tooltip
              title={<Typography variant="body2">{description}</Typography>}
              disableInteractive
            >
              {description}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'enableLeadSend',
      headerClassName: styles.header,
      headerName: 'Send Lead',
      width: 120,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { enableLeadSend, enableZapier, leadForm, onOpenPreview } =
          params.row;
        const value: any = enableLeadSend || enableZapier ? 'Yes' : 'No';

        return (
          <div
            className={`${styles.name} ${
              enableLeadSend || enableZapier ? '' : styles['-error']
            }`}
            onClick={() => onOpenPreview(leadForm)}
          >
            <Tooltip
              title={
                <Typography variant="body2">
                  {`${value} ${params.row.default ? '(Default)' : ''}`}
                </Typography>
              }
              disableInteractive
            >
              {value}
            </Tooltip>

            <Box component="span" ml={1}>
              {
                (params.row.default ? (
                  <Chip
                    label="Default"
                    size="small"
                    sx={{
                      backgroundColor: DEFAULT_COLOR_THEME,
                      color: 'white',
                    }}
                  />
                ) : null) as any
              }
            </Box>
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerClassName: styles.header,
      headerName: 'Created',
      sortingOrder: ['desc', 'asc'],
      width: 180,
      renderCell: (params: any) => {
        const { createdAt, leadForm, onOpenPreview } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpenPreview(leadForm)}>
            <Tooltip
              title={<Typography variant="body2">{createdAt}</Typography>}
              disableInteractive
            >
              {createdAt}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return columns;
};
