import React from 'react';
import { Box, Button, styled, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import { Logo } from '../types';
import Spinner from './Spinner';

interface AgencyLogoFieldProps {
  logo: Logo;
  handleUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveLogo: (public_id: string) => void;
  uploadLoading?: boolean;
}

const Input = styled('input')({
  display: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
  },
});

const AgencyLogoField: React.FC<AgencyLogoFieldProps> = ({
  logo,
  handleUploadImage,
  handleRemoveLogo,
  uploadLoading,
}) => {
  return (
    <>
      {logo.url !== '' ? (
        <Box component="div" position="relative" pt={2} pl={2}>
          <Box
            component="img"
            src={logo.url}
            alt=""
            sx={{
              width: '150px',
              height: '150px',
              objectFit: 'contain',
              border: 'solid',
            }}
          />

          <CancelIcon
            onClick={() => handleRemoveLogo(logo.public_id)}
            sx={{
              position: 'absolute',
              top: 16,
              right: 0,
              color: red[500],
              cursor: 'pointer',
            }}
          />
        </Box>
      ) : null}

      <Grid item xs={12}>
        <label htmlFor="agency-logo">
          <Input
            onChange={handleUploadImage}
            accept="image/*"
            id="agency-logo"
            multiple
            type="file"
          />

          <Button
            fullWidth
            sx={{
              color: 'rgba(2, 136, 209, 0.5)',
              borderStyle: 'dashed',
              borderColor: 'rgba(2, 136, 209, 0.5)',
              '&:hover': {
                borderStyle: 'dashed',
              },
              '&.Mui-disabled': {
                borderStyle: 'dashed',
                cursor: 'not-allowed',
              },
            }}
            variant="outlined"
            component="span"
            color="info"
          >
            {uploadLoading ? <Spinner /> : 'Upload Logo'}
          </Button>
        </label>
      </Grid>
    </>
  );
};

export default AgencyLogoField;
