import React, { useEffect, useState } from 'react';
import {
  Box,
  Select,
  InputLabel,
  FormControl,
  Button,
  Typography,
  TextField,
  Checkbox,
} from '@mui/material';

import { DEFAULT_COLOR_THEME } from '../../../utils';
import GoogleProductAndServicesSidemenu from '../../Select/GoogleProductAndServicesSidemenu';
import { useDispatch, useSelector } from 'react-redux';
import AssetGoogleAccount from './Google/GGAssetsAccount';
import AssetGoogleBusiness from './Google/GGAssetsBusiness';
import AssetGoogleImages from './Google/GGLAssetsImages';
import AssetGoogleVideos from './Google/GGLAssetsSearchVideos';
import AssetGoogleHeadlines from './Google/GGLAssetsSearchHeadlines';
import AssetGoogleDescriptions from './Google/GGLAssetsSearchDescriptions';
import AssetGoogleKeywords from './Google/GGLAssetsSearchKeywords';
import AssetGoogleSitelinks from './Google/GGLAssetsSearchSitelinks';

import { GoogleAssetsContainer } from '../../../utils/constants/google';
import { AdsAccountFormFields, Brand } from '../../../types';
import {
  syncCustomerIds,
  fetchGoogleAdsAccount,
  setGoogleAssetsAccount,
  fetchGoogleAssetsAccounts,
  setGoogleAssets,
  fetchGoogleAssets,
  deleteGoogleAssetsImage,
} from '../../../services/googleAds';
import { createAdAccount, removeAdAccount } from '../../../services/ads';
import { setBrand, toggleAlert } from '../../../redux/actions';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import CircularLoading from '../../CircularLoading';
import AssetGoogleLocations from './Google/GGLAssetsLocations';

interface GoogleAssetzFormProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
  //googleAdAccounts: AdsAccountFormFields[];
  selectedMainmenu: any;
  selectedSubmenu: any;
  selectedProductAndService: any;
  setSelectedProductAndService: (value: any) => void;
  productsAndServices: string[];
  setProductAndServices: any;
  setIsDirty: any;
  checkDirty: any;
}

const GoogleAssetzForm: React.FC<GoogleAssetzFormProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
  selectedMainmenu,
  selectedSubmenu,
  selectedProductAndService,
  setSelectedProductAndService,
  productsAndServices,
  setProductAndServices,
  setIsDirty,
  checkDirty,
}) => {
  const [googleAdAccounts, setGoogleAdAccounts] = useState<
    AdsAccountFormFields[]
  >([]);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [adAccounts, setAdAccounts] = useState<AdsAccountFormFields[]>([]);
  const [accountsForm, setAccountsForm] = useState<any>({});
  const [businessForm, setBusinessForm] = useState<any>({});
  const [subMenuData, setSubMenuData] = useState<any>({});
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [databaseImages, setDatabaseImages] = useState<
    {
      url: string;
      height: number;
      width: number;
      asset_id: string;
      public_id: string;
      resource_type: string;
    }[]
  >([]);

  const dispatch = useDispatch();

  // useEffect(() => {

  // }, [accountsForm, businessForm]);

  const saveBusinessForm = async (businessdata: any) => {
    console.log(`$businessdata`);
    console.log(businessdata);
    setLoadingScreen(true);

    setBusinessForm(businessdata);
    try {
      const gacss = await setGoogleAssetsAccount({
        brandId: brand._id,
        ...accountsForm,
        ...businessdata,
        products_and_services: productsAndServices,
      });

      setLoadingScreen(false);
      setShowSaveButton(false);
    } catch (error: any) {
      setLoadingScreen(false);
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    }
  };

  const fetchAssets = async () => {
    setLoadingScreen(true);
    if (selectedProductAndService !== '') {
      const response = await fetchGoogleAssets({
        brandId: brand._id,
        ps: selectedProductAndService,
      });

      if (response.data) {
        const update = {
          videos: response.data.videos || [''],
          headlines: response.data.headlines || [''],
          descriptions: response.data.descriptions || [''],
          keywords: response.data.keywords || [],
          sitelinks: response.data.sitelinks || [
            {
              headline: '',
              description1: '',
              description2: '',
              finalUrl: '',
            },
          ],
        };
        setSubMenuData(update);
      }

      console.log(`$response`);
      console.log(response);
    }
    setLoadingScreen(false);
  };

  useEffect(() => {
    fetchAssets();
  }, [selectedProductAndService]);

  const parseSelectedSubmenu = async (params: {
    submenu:
      | 'keywords'
      | 'images'
      | 'videos'
      | 'headlines'
      | 'descriptions'
      | 'sitelinks';
    submenuData: any;
  }) => {
    setLoadingScreen(true);
    const response = await setGoogleAssets({
      brandId: brand._id,
      product_and_service: selectedProductAndService,
      data: params.submenuData,
    });

    setLoadingScreen(false);
    dispatch(
      toggleAlert({
        toggle: true,
        message:
          `${selectedProductAndService} ${params.submenu} was saved successfully`.toUpperCase(),
      }),
    );

    console.log(`$response`);
    console.log(response);
  };

  const handleSelectAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      //setLoading(true);

      let temp: AdsAccountFormFields = {
        ...adAccount,
        brand: brand._id,
        manual: true,
        diy: true,
      };

      const response = await createAdAccount(temp);

      let tempAccounts: AdsAccountFormFields[] = [];
      if (adAccounts.length === 0) {
        tempAccounts = [response.data];
      } else {
        adAccounts.forEach((account: AdsAccountFormFields) => {
          if (account.accountId !== response.data.accountId) {
            tempAccounts = [...(adAccounts || []), response.data];
          } else {
            if (account.accountId === response.data.accountId) {
              account = response.data;
            }

            tempAccounts = [...tempAccounts, account];
          }
        });
      }

      setAdAccounts(tempAccounts);
      const forGoogleAccounts = {
        brandId: brand._id,
        customer_id: response.data.customerId,
        account_id: response.data.accountId,
      };

      const configureGoogleAccounts = await setGoogleAssetsAccount(
        forGoogleAccounts,
      );
      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was added successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      //setLoading(false);
    }
  };

  const handleRemoveAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      //setLoading(true);
      const response = await removeAdAccount(
        adAccount.accountId,
        brand?._id,
        campaignProvider,
      );

      const tempAccounts = adAccounts.filter(
        (account: AdsAccountFormFields) =>
          account?.accountId !== response.data.accountId,
      );

      setAdAccounts(tempAccounts);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was removed`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      //setLoading(false);
    }
  };

  const handleInputChange = async () => {
    setShowSaveButton(true);
  };

  const deleteImage = async (imgRecord: string) => {
    const public_id = imgRecord;
    setLoadingScreen(true);

    const deleteImg = await deleteGoogleAssetsImage({
      brandId: brand._id,
      public_id: public_id,
    });

    console.log(`$deleteImg`);
    console.log(deleteImg);

    setLoadingScreen(false);
    fetchGAssetsAccounts();
    dispatch(
      toggleAlert({
        toggle: true,
        message: 'Image was deleted successfully',
      }),
    );
  };

  const fetchGAssetsAccounts = async () => {
    setIsDirty(false);
    setLoadingScreen(true);
    const { data } = await fetchGoogleAssetsAccounts({ brandId: brand._id });

    if (data && data.doc) {
      if (data.doc.products_and_services) {
        setProductAndServices(data.doc.products_and_services);
      }
      setBusinessForm({
        business_name: data.doc.business_name || '',
        tracking_template: data.doc.tracking_template || '',
        phone_numbers: data.doc.phone_numbers || [],
        landing_page_url: data.doc.landing_page_url || [],
        competitors: data.doc.competitors || [],
        target_audience: data.doc.target_audience || '',
      });
      if (data.doc.images) {
        setDatabaseImages(data.doc.images);
      }
    }
    setLoadingScreen(false);
  };

  const getGoogleAdAccounts = async () => {
    try {
      //setLoading(true);
      await fetchGAssetsAccounts();
      const response = await fetchGoogleAdsAccount(
        brand._id,
        defaultRefreshToken,
      );

      setGoogleAdAccounts(response?.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      //setLoading(false);
    }
  };

  const handleSyncGoogleCustomerIds = async (value: string[], brand: Brand) => {
    try {
      getGoogleAdAccounts();
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const onRemoveAdAccount = () => {};

  //getGoogleAdAccounts();

  useEffect(() => {
    if (brand) {
      getGoogleAdAccounts();
    }
  }, [brand]);

  return (
    <Box
      position={`relative`}
      display={`flex`}
      justifyContent={`left`}
      alignItems={`start`}
      width={`100%`}
      borderLeft={`1px solid #ccc`}
      borderRight={`1px solid #ccc`}
      borderBottom={`1px solid #ccc`}
      sx={{
        paddingBottom: 2,
      }}
    >
      <CircularLoading loading={loadingScreen} />
      <GoogleProductAndServicesSidemenu
        setSelectedProductAndService={setSelectedProductAndService}
        selectedProductAndService={selectedProductAndService}
        productsAndServices={productsAndServices}
        selectedMainmenu={selectedMainmenu}
        selectedSubmenu={selectedSubmenu}
        checkDirty={checkDirty}
      />
      <Box
        sx={{ width: `100%` }}
        bgcolor={`#ffffff`}
        padding={`10px 10px 0 10px`}
        margin={0}
        overflow={`hidden`}
        display={!selectedMainmenu ? 'none' : 'block'}
      >
        {selectedMainmenu === 'Account' ? (
          <AssetGoogleAccount
            //submitForm={setAccountsForm}
            brand={brand}
            googleAdAccounts={googleAdAccounts}
            adAccounts={adAccounts}
            handleSyncGoogleCustomerIds={handleSyncGoogleCustomerIds}
            onSelectAdsAccount={handleSelectAdsAccount}
            onRemoveAdAccount={handleRemoveAdsAccount}
            connectedAccountsIds={connectedAccountsIds}
            //databaseImages={databaseImages}
            //doneUploading={fetchGAssetsAccounts}
            //deleteImage={deleteImage}
            //setIsDirty={setIsDirty}
          />
        ) : null}
        {selectedMainmenu === 'Business' ? (
          <AssetGoogleBusiness
            handleInputChange={handleInputChange}
            submitForm={saveBusinessForm}
            businessForm={businessForm}
            productsAndServices={productsAndServices}
            setProductAndServices={setProductAndServices}
            setSelectedProductAndService={setSelectedProductAndService}
            setIsDirty={setIsDirty}
          />
        ) : null}

        {selectedMainmenu === 'Search' && selectedProductAndService ? (
          <>
            {selectedSubmenu === 'videos' ? (
              <AssetGoogleVideos
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'headlines' ? (
              <AssetGoogleHeadlines
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'descriptions' ? (
              <AssetGoogleDescriptions
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'sitelinks' ? (
              <AssetGoogleSitelinks
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'keywords' ? (
              <AssetGoogleKeywords
                selectedProductAndService={selectedProductAndService}
                submitForm={parseSelectedSubmenu}
                defaultRefreshToken={defaultRefreshToken}
                submenuForm={subMenuData}
                brandId={brand?._id || ''}
                setIsDirty={setIsDirty}
              />
            ) : null}
          </>
        ) : null}

        {selectedMainmenu === 'Home' ? (
          <>
            {selectedSubmenu === 'account' ? (
              <AssetGoogleAccount
                brand={brand}
                googleAdAccounts={googleAdAccounts}
                adAccounts={adAccounts}
                handleSyncGoogleCustomerIds={handleSyncGoogleCustomerIds}
                onSelectAdsAccount={handleSelectAdsAccount}
                onRemoveAdAccount={handleRemoveAdsAccount}
                connectedAccountsIds={connectedAccountsIds}
              />
            ) : null}
            {selectedSubmenu === 'business' ? (
              <AssetGoogleBusiness
                handleInputChange={handleInputChange}
                submitForm={saveBusinessForm}
                businessForm={businessForm}
                productsAndServices={productsAndServices}
                setProductAndServices={setProductAndServices}
                setSelectedProductAndService={setSelectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'images' ? (
              <AssetGoogleImages
                deleteImage={deleteImage}
                brandId={brand._id}
                databaseImages={databaseImages}
                doneUploading={fetchGAssetsAccounts}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'videos' ? (
              <AssetGoogleVideos
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'headlines' ? (
              <AssetGoogleHeadlines
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'descriptions' ? (
              <AssetGoogleDescriptions
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'sitelinks' ? (
              <AssetGoogleSitelinks
                submitForm={parseSelectedSubmenu}
                handleInputChange={handleInputChange}
                submenuForm={subMenuData}
                selectedProductAndService={selectedProductAndService}
                setIsDirty={setIsDirty}
              />
            ) : null}
            {selectedSubmenu === 'keyword set' ? (
              <AssetGoogleKeywords
                selectedProductAndService={selectedProductAndService}
                submitForm={parseSelectedSubmenu}
                defaultRefreshToken={defaultRefreshToken}
                submenuForm={subMenuData}
                brandId={brand?._id || ''}
                setIsDirty={setIsDirty}
              />
            ) : null}

            {selectedSubmenu === 'location set' ? (
              <AssetGoogleLocations brand={brand} floatingSave={true} />
            ) : null}
          </>
        ) : null}

        {['Search', 'Display'].includes(selectedMainmenu) &&
        !selectedProductAndService ? (
          <>
            <Box sx={GoogleAssetsContainer}>
              <Typography
                variant="h6"
                fontWeight={800}
                mb={2}
                color={DEFAULT_COLOR_THEME}
              >
                Configure your Google Ads' Products and Services to get started.
              </Typography>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};
export default GoogleAssetzForm;
