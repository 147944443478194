import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';

import { Brand } from '../../../../types';
import {
  createGoogleCampaign,
  fetchAssetLocationSet,
  fetchGoogleTemplates,
  assembleGoogleAds,
} from '../../../../services/googleAds';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import LinearProgressBarWithLabel from '../../../ProgressBar/LinearProgressBarWithLabel';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import CircularLoading from '../../../CircularLoading';
import { MdSearch } from 'react-icons/md';
import {
  formatNumber,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import CampaignCreationInstructions from './GGAdzCreationInstruction';
import GoogleTemplatesPreview from '../../Templatez/Google/GGLTemplatesPreview';
import GGLAssetsLocationTable from '../../Assetz/Google/GGLAssetsLocationTable';

interface GGAdzCreationProps {
  brand: Brand;
  doneCreating: any;
  adsCollection: any[];
  location?: string;
}

const GGAdzCreation: React.FC<GGAdzCreationProps> = ({
  brand,
  location,
  doneCreating,
  adsCollection,
}) => {
  const count = adsCollection.length;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [templateCollection, setTemplateCollection] = useState([]);
  const [assetsLocationSet, setAssetsLocationSet] = useState([]);
  const [campaignCollection, setCampaignCollection] = useState([]);
  const [adName, setAdName] = useState(``);
  const [adDescription, setAdDescription] = useState(``);
  const [campaignNameTag, setCampaignNameTag] = useState(``);
  const [adGroupNameTag, setAdGroupNameTag] = useState(``);
  const [GGLCampaignName, setGGLCampaignName] = useState(``);
  const [GGLAdGroupName, setGGLAdGroupName] = useState(``);
  const [GGLAdName, setGGLAdName] = useState(``);
  const [campaignDuration, setCampaignDuration] = useState('everyMonth');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [currentPage, setCurrentPage] = useState(2);
  const [budget, setBudget] = useState('');
  const [budgetNextMonth, setBudgetNextMonth] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [dateStart, setDateStart] = useState(
    new Date().toISOString().slice(0, 16),
  );
  const [dateEnd, setDateEnd] = useState('');
  const [selectedExistingCampaign, setSelectedExistingCampaign] = useState('');

  const maxPages = 5;

  useEffect(() => {
    setGGLCampaignName(
      `aiADZ-${location ? `${location}-` : ``}Search-${count}`,
    );
    setGGLAdGroupName(
      `aiADZ-${location ? `${location}-` : ``}Search-${count}-${
        selectedTemplate?.name
      }-${count}`,
    );
    setGGLAdName(
      `aiADZ-${location ? `${location}-` : ``}Search-${count}-${
        selectedTemplate?.name
      }-${count}`,
    );
    setAdName(
      `aiADZ-${location ? `${location}-` : ``}Search-${count}-${
        selectedTemplate?.name
      }-${count}`,
    );
  }, [selectedTemplate]);

  const handleExistingCampaignChange = (event: SelectChangeEvent) => {
    setSelectedExistingCampaign(event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignDuration((event.target as HTMLInputElement).value);
  };

  const saveAd = () => {
    setIsLoading(true);
    assembleGoogleAds({
      brandId: brand._id,
      name: adName,
      description: adDescription,
      budget: budget,
      dailyBudget: dailyBudget,
      monthlyBudget: budgetNextMonth,
      dateStart: dateStart,
      dateEnd: dateEnd,
      budgetName: `${Date.now()}`,

      campaign: {
        name: GGLCampaignName,
        status: 'PAUSED',
        advertisingChannelType: 'SEARCH',
      },
      adgroup: {
        name: `${GGLAdGroupName}`,
        status: `PAUSED`,
      },
      ad: {
        name: `${GGLAdName}`,
        status: `PAUSED`,
      },
      template: selectedTemplate,
      locationSet: selectedLocationType,
    })
      .then((res: any) => {
        console.log(`assembleGoogleAds:`);
        console.log(res);
      })
      .finally(() => {
        setIsLoading(false);
        doneCreating();
      });
  };

  const handleNextBtnClick = () => {
    let errors = false;
    let errorMsg = '';

    if (currentPage === 2) {
      errors = !selectedTemplate;
      errorMsg = 'Please select a template to continue';
    }

    if (currentPage === 3) {
      errors = !selectedLocationType;
      errorMsg = 'Please select a location set to continue';
    }

    if (currentPage === 4) {
      errors =
        !dateStart ||
        !budget ||
        !budgetNextMonth ||
        !dailyBudget ||
        (campaignDuration === 'fixedDuration' ? !dateEnd : false);
      errorMsg = 'Please fill in all fields to continue';
    }

    if (!errors) {
      setCurrentPage(currentPage === maxPages ? currentPage : currentPage + 1);
    } else {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: errorMsg,
        }),
      );
    }
  };

  const fetchTemplates = async () => {
    const { data } = await fetchGoogleTemplates(brand?._id);
    console.log(`$ts`);
    console.log(data);

    setTemplateCollection(data);
    setIsLoading(false);
  };

  const fetchLocationSets = () => {
    fetchAssetLocationSet({
      brandId: brand._id,
    }).then((res: any) => {
      console.log(`Location Set:`);
      console.log(res);
      setAssetsLocationSet(res);
    });
  };

  useEffect(() => {
    fetchTemplates();
    fetchLocationSets();
  }, []);

  return (
    <Box
      sx={{
        height: 750,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`,
      }}
    >
      <CircularLoading loading={isLoading} />
      <Box
        sx={{
          width: `100%`,
          maxHeight: 610,
          overflowY: `auto`,
          ...scrollBarGreen,
        }}
      >
        {currentPage === 1 ? (
          <>
            <CampaignCreationInstructions />
          </>
        ) : null}
        {currentPage === 2 ? (
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: `2rem`,
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              <Typography
                variant="h6"
                color={`primary`}
                sx={{ fontWeight: 800 }}
              >
                Select a template to create a new Google Ad
              </Typography>
              <Box sx={{ maxWidth: 400, paddingLeft: 2, marginTop: 2 }}>
                {templateCollection.map((t) => (
                  <Button
                    key={t._id}
                    sx={{
                      width: `100%`,
                      display: `block`,
                      textAlign: `left`,
                      border: '1px solid #aaa',
                      marginBottom: 1,
                      fontWeight: 800,
                      backgroundColor: selectedTemplate
                        ? selectedTemplate._id === t._id
                          ? `#e6ffd4`
                          : `#ffffff`
                        : `#ffffff`,
                      borderColor: selectedTemplate
                        ? selectedTemplate._id === t._id
                          ? DEFAULT_COLOR_THEME
                          : `#aaa`
                        : `#aaa`,
                    }}
                    onClick={() => {
                      console.log(t);
                      setSelectedTemplate(t);
                    }}
                  >
                    {t.name}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                //maxWidth: 700,
                maxHeight: 610,
                maxWidth: 600,
                width: `100%`,
                overflowY: 'auto',
                backgroundColor: selectedTemplate ? `#ebebeb` : `#ffffff`,
                padding: `0.5rem`,
                ...scrollBarGreen,
              }}
            >
              {selectedTemplate ? (
                <Box
                  sx={{
                    backgroundColor: `#ffffff`,
                  }}
                >
                  {Object.keys(selectedTemplate).length > 0 ? (
                    <Box sx={{ padding: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `1.2rem`,
                        }}
                      >
                        Preview:
                      </Typography>
                      <GoogleTemplatesPreview
                        headlines={selectedTemplate.headlines}
                        descriptions={selectedTemplate.descriptions}
                        sitelinks={selectedTemplate.sitelinks}
                        url={selectedTemplate.final_url}
                        showToolbar={false}
                        center={true}
                      />

                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `1.2rem`,
                          marginTop: 2,
                        }}
                      >
                        Summary:
                      </Typography>

                      <Box sx={{ marginBottom: '10px' }}>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: DEFAULT_COLOR_THEME,
                          }}
                        >
                          Keywords:
                        </Typography>
                        {selectedTemplate.keywords ? (
                          <Box>
                            {selectedTemplate.keywords.map((key: any) => (
                              <Box
                                sx={{
                                  padding: `5px`,
                                }}
                              >
                                {`${key.keyword}`}
                              </Box>
                            ))}
                          </Box>
                        ) : null}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: DEFAULT_COLOR_THEME,
                            marginBottom: '10px',
                          }}
                        >
                          Phone Number:{` `}
                          <Typography
                            sx={{
                              fontWeight: '200',
                              display: 'inline',
                            }}
                          >
                            {selectedTemplate.phone_number}
                          </Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: DEFAULT_COLOR_THEME,
                            marginBottom: '10px',
                          }}
                        >
                          Final URL:{` `}
                          <Typography
                            sx={{
                              fontWeight: '200',
                              display: 'inline',
                            }}
                          >
                            {selectedTemplate.final_url}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginBottom: '10px',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Headlines:{` `}
                          </Typography>
                        </Box>

                        <Box sx={{ paddingLeft: `10px` }}>
                          {selectedTemplate.headlines.map((h: string) => (
                            <Box>{h}</Box>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginBottom: '10px',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Descriptions:{` `}
                          </Typography>
                        </Box>

                        <Box sx={{ paddingLeft: `10px` }}>
                          {selectedTemplate.descriptions.map((d: string) => (
                            <Box>{d}</Box>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginBottom: '10px',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Callouts:
                          </Typography>
                        </Box>

                        <Box sx={{ paddingLeft: `10px` }}>
                          {selectedTemplate.callouts?.map((c: string) => (
                            <Box>{c}</Box>
                          ))}
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: DEFAULT_COLOR_THEME,
                            marginBottom: '10px',
                          }}
                        >
                          Site Links
                          <Typography
                            sx={{
                              fontWeight: '200',
                              display: 'inline',
                            }}
                          >
                            {selectedTemplate.sitelinks.map((s: any) => (
                              <Box
                                sx={{
                                  fontSize: '1rem',
                                  color: `#000000`,
                                  padding: `5px`,
                                }}
                              >
                                {s.headline} - {s.description1} -{' '}
                                {s.description2} - {s.finalUrl}
                              </Box>
                            ))}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: DEFAULT_COLOR_THEME,
                            marginBottom: '10px',
                          }}
                        >
                          Images
                        </Typography>

                        <Box
                          sx={{
                            display: `flex`,
                            flexWrap: `wrap`,
                            gap: `0.5rem`,
                          }}
                        >
                          {selectedTemplate.images.map((img: any) => (
                            <Box
                              sx={{
                                width: '100px',
                              }}
                            >
                              <img
                                src={img.url}
                                alt={img.url}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}

        {currentPage === 3 ? (
          <Box sx={{}}>
            <Typography variant="h6" color={`primary`}>
              Select a location set to target your Google Ad
            </Typography>
            <Box sx={{ maxWidth: 800, paddingLeft: 2, marginTop: 2 }}>
              {assetsLocationSet?.map((l) => (
                <Box
                  key={l.name}
                  sx={{
                    width: `100%`,
                    textAlign: `left`,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    paddingX: '0.5rem',
                    paddingY: '0.2rem',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      border: '1px solid #aaa',
                      marginBottom: 1,
                      backgroundColor:
                        selectedLocationType?.name === l.name
                          ? `#e6ffd4`
                          : `#ffffff`,
                      borderColor:
                        selectedLocationType?.name === l.name
                          ? DEFAULT_COLOR_THEME
                          : `#aaa`,

                      maxHeight: 150,
                      minWidth: 700,
                      overflowY: 'auto',
                      ...scrollBarGreen,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: '0.5rem',
                        paddingY: '0.2rem',
                        position: 'sticky',
                        top: 0,
                        backgroundColor:
                          selectedLocationType?.name === l.name
                            ? `#e6ffd4`
                            : `#ffffff`,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l.locations.length} Locations
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        padding: '0.5rem',
                      }}
                    >
                      <GGLAssetsLocationTable
                        locations={l.locations.map((loc: any) => {
                          return {
                            ...loc,
                            reach: formatNumber(loc.reach),
                          };
                        })}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Button
                    onClick={() => setSelectedLocationType(l)}
                    variant="contained"
                    sx={{
                      backgroundColor: DEFAULT_COLOR_THEME,
                      paddingX: '20px',
                    }}
                  >
                    Select
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}

        {currentPage === 4 ? (
          <>
            <Typography
              variant="h6"
              color={`primary`}
              sx={{
                fontWeight: 800,
              }}
            >
              PLEASE SET YOUR SCHEDULE AND BUDGET
            </Typography>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Start Date/Time"
                variant="standard"
                type="datetime-local"
                value={dateStart}
                onChange={(e) => {
                  setDateStart(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  minWidth: 300,
                }}
              />
            </Box>

            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={campaignDuration}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="everyMonth"
                    control={<Radio />}
                    label="Run campaign every month"
                  />
                  <FormControlLabel
                    value="fixedDuration"
                    control={<Radio />}
                    label="Stop campaign on"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box
              sx={{
                marginBottom: 2,
                opacity: campaignDuration === 'fixedDuration' ? 1 : 0,
                height: campaignDuration === 'fixedDuration' ? 'auto' : 0,
                transition: 'opacity 0.5s ease',
              }}
            >
              <TextField
                label="End Date/Time"
                variant="standard"
                type="datetime-local"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  minWidth: 300,
                }}
              />
            </Box>

            <Box
              sx={{
                marginY: 4,
              }}
            >
              <TextField
                label="Current Month's Budget"
                variant="standard"
                value={budget}
                sx={{ marginRight: '2rem' }}
                name="currentMonthBudget"
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');
                  setBudget(val);
                  //get remaining days in month
                  const today = new Date();
                  const lastDay = new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    0,
                  );
                  const daysLeft = lastDay.getDate() - today.getDate() + 1;
                  const dailyBudget = parseFloat(e.target.value) / daysLeft;
                  setDailyBudget(
                    val ? (Math.floor(dailyBudget * 100) / 100).toFixed(2) : '',
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Future Monthly Budget"
                variant="standard"
                value={budgetNextMonth}
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');

                  setBudgetNextMonth(val);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Daily Budget"
                variant="standard"
                value={dailyBudget}
                onChange={(e) => {
                  //remove letters and non .
                  const val = e.target.value.replace(/[^0-9.]/g, '');
                  setDailyBudget(val ? val : '');

                  //get remaining days in month
                  const today = new Date();
                  const lastDay = new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    0,
                  );
                  const daysLeft = lastDay.getDate() - today.getDate();
                  const targetBudget = parseFloat(val) * daysLeft;
                  setBudget(val ? targetBudget.toFixed(2) : '');
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">USD</InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        ) : null}

        {currentPage === 5 ? (
          <>
            <Typography
              variant="h6"
              color={`primary`}
              sx={{
                fontWeight: 800,
              }}
            >
              CAMPAIGN DETAILS
            </Typography>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Ad Name"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adName}
                onChange={(e) => {
                  setAdName(e.target.value);
                }}
              />
            </Box>

            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                multiline
                rows={4}
                label="Description"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adDescription}
                onChange={(e) => {
                  setAdDescription(e.target.value);
                }}
              />
            </Box>
            <Typography
              color={`primary`}
              sx={{
                fontWeight: 600,
              }}
            >
              Campaign Name Tags
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
              }}
            >
              This tag will be used as the naming convention for the Google Ad
              Campaign
            </Typography>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Campaign Name Tag"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={campaignNameTag}
                onChange={(e) => {
                  setCampaignNameTag(
                    e.target.value.length < GGLCampaignName.length
                      ? GGLCampaignName
                      : e.target.value.includes(GGLCampaignName)
                      ? e.target.value
                      : GGLCampaignName + e.target.value,
                  );
                }}
                helperText={GGLCampaignName}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <TextField
                label="Ad Group Name Tag"
                variant="outlined"
                sx={{
                  minWidth: 500,
                }}
                value={adGroupNameTag}
                onChange={(e) => {
                  setAdGroupNameTag(
                    e.target.value.length < GGLAdGroupName.length
                      ? GGLAdGroupName
                      : e.target.value.includes(GGLAdGroupName)
                      ? e.target.value
                      : GGLAdGroupName + e.target.value,
                  );
                }}
                helperText={GGLAdGroupName}
              />
            </Box>
            <Box
              sx={{
                display: `none`,
                marginY: 2,
                maxWidth: 500,
              }}
            >
              <Typography>Add to existing Google Campaign?</Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Campaigns</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedExistingCampaign}
                  label="Age"
                  onChange={handleExistingCampaignChange}
                >
                  <MenuItem value={10}>aiADZ-Search-13</MenuItem>
                  <MenuItem value={20}>aiADZ-Search-12</MenuItem>
                  <MenuItem value={30}>aiADZ-Search-11</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: `none`,
              }}
            >
              <Typography
                color={`primary`}
                sx={{
                  fontWeight: 600,
                }}
              >
                Google Campaign Configuration
              </Typography>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Campaign Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLCampaignName}
                  onChange={(e) => {
                    setGGLCampaignName(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Ad Group Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLAdGroupName}
                  onChange={(e) => {
                    setGGLAdGroupName(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginY: 2,
                }}
              >
                <TextField
                  label="Ad Name"
                  variant="outlined"
                  sx={{
                    minWidth: 500,
                  }}
                  value={GGLAdName}
                  onChange={(e) => {
                    setGGLAdName(e.target.value);
                  }}
                />
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
          padding: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '50%',
          }}
        >
          <LinearProgressBarWithLabel value={(currentPage / maxPages) * 100} />
        </Box>
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'end',
            gap: '0.5rem',
            alignItems: 'end',
          }}
        >
          <Button
            variant="outlined"
            sx={{ display: currentPage === 1 ? 'none' : 'block' }}
            onClick={() => {
              setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{ display: currentPage === maxPages ? 'none' : 'block' }}
            onClick={handleNextBtnClick}
          >
            Next
          </Button>

          <Button
            variant="contained"
            sx={{ display: currentPage === maxPages ? 'block' : 'none' }}
            onClick={saveAd}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GGAdzCreation;
