import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { ChangeEventType, StripeCustomerForm } from '../../../../types';
import AccountActions from '../AccountActions';
import { MuiTelInput } from 'mui-tel-input';

interface StripeCustomerDetailsFormProps {
  formValues: StripeCustomerForm;
  onChange: (e: ChangeEventType) => void;
  onNext: () => void;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onChangeNumber: (newValue: string) => void;
  onClose: () => void;
}

const StripeCustomerDetailsForm: React.FC<StripeCustomerDetailsFormProps> = ({
  formValues,
  onChange,
  onNext,
  onBack,
  activeStep,
  steps,
  onChangeNumber,
  onClose,
}) => {
  return (
    <Box component="form" onSubmit={onNext} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="name"
            name="name"
            label="Full Name"
            onChange={onChange}
            value={formValues?.name}
            InputLabelProps={{ shrink: true }}
            size="small"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="email"
            id="name"
            name="email"
            label="Email"
            onChange={onChange}
            value={formValues?.email}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiTelInput
            fullWidth
            required
            variant="standard"
            id="phone"
            name="phone"
            label="Phone Number"
            onChange={(newValue: any) => onChangeNumber(newValue)}
            value={formValues?.phone}
            InputLabelProps={{ shrink: true }}
            size="small"
            onlyCountries={['US']}
            defaultCountry="US"
          />
        </Grid>
      </Grid>

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripeCustomerDetailsForm;
