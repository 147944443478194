import Resizer from 'react-image-file-resizer';
export type ResizerReturnType =
  | string
  | Blob
  | File
  | ProgressEvent<FileReader>;
type FileResizer = (file: globalThis.Blob) => Promise<ResizerReturnType>;

/**
 * Image Resizer
 * @reference https://www.npmjs.com/package/react-image-file-resizer
 * @param file
 * @returns <String> Base 64 format
 */
export const resizeFile: FileResizer = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      113,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
      120,
      45,
    );
  });
};

export const imageResizer = (
  file: any,
  maxWidth: number,
  maxHeight: number,
  minWidth: number,
  minHeight: number,
  format?: string,
) => {
  const resizeFile: FileResizer = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        format ? format : 'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        minWidth,
        minHeight,
      );
    });
  };

  return resizeFile(file);
};
