import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { ChangeEventType } from '../../types';
import { PrimaryButton } from '../Buttons';
import CircularLoading from '../CircularLoading';
import PageHeader from '../PageHeader';
import styles from '../../assets/styles/components/Forms/AuthForm.module.scss';
import pageStyles from '../../assets/styles/components/Forms/Form.module.scss';
import FormHeader from './FormHeader';
import { XsOnly } from '../../utils/breakpoints';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface AuthError {
  email?: string;
  password?: string;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}
interface AuthFormProps {
  handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  handleOnChange: (e: ChangeEventType) => void;
  handleFacebookLogin?: () => void;
  handleGoogleLogin?: () => void;
  title: string;
  isLogin?: boolean;
  email?: string | null;
  oldPassword?: string | null;
  newPassword?: string | null;
  confirmPassword?: string | null;
  password?: string | null;
  isJoin?: boolean;
  isChangePassword?: boolean;
  errors?: AuthError;
  loading?: boolean;
  role: string | null;
}

const AuthForm: React.FC<AuthFormProps> = ({
  handleOnSubmit,
  handleOnChange,
  handleFacebookLogin = null,
  handleGoogleLogin = null,
  title,
  isLogin = false,
  email,
  password,
  oldPassword,
  newPassword,
  confirmPassword,
  isJoin = false,
  isChangePassword = false,
  errors,
  loading,
  role,
}) => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleOnShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const handleOnShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleOnShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%' }}
      className={
        isChangePassword
          ? `${pageStyles.form} ${XsOnly() ? `${pageStyles['-mobile']}` : ''}`
          : styles.form
      }
    >
      <CircularLoading loading={loading} />

      {isChangePassword ? (
        <FormHeader title={title} my={2} />
      ) : (
        <PageHeader title={title} />
      )}

      <Grid container spacing={2}>
        {isChangePassword ? (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                variant="standard"
                size="small"
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                name="oldPassword"
                label="Old password"
                onChange={handleOnChange}
                value={oldPassword}
                InputLabelProps={{ shrink: true }}
                error={errors?.oldPassword ? true : false}
                helperText={errors?.oldPassword ? errors.oldPassword : ''}
                autoFocus
                sx={{
                  '& .MuiInputBase-root': {
                    '&:has(> input:-webkit-autofill)': {
                      backgroundColor: 'rgb(232, 240, 254)',
                    },
                  },
                }}
                InputProps={{
                  sx: { borderRadius: '49px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOnShowOldPassword} edge="end">
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                variant="standard"
                size="small"
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                name="newPassword"
                label="New password"
                onChange={handleOnChange}
                value={newPassword}
                InputLabelProps={{ shrink: true }}
                error={errors?.newPassword ? true : false}
                helperText={errors?.newPassword ? errors.newPassword : ''}
                sx={{
                  '& .MuiInputBase-root': {
                    '&:has(> input:-webkit-autofill)': {
                      backgroundColor: 'rgb(232, 240, 254)',
                    },
                  },
                }}
                InputProps={{
                  sx: { borderRadius: '49px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOnShowNewPassword} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                variant="standard"
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm password"
                onChange={handleOnChange}
                value={confirmPassword}
                InputLabelProps={{ shrink: true }}
                error={errors?.confirmPassword ? true : false}
                helperText={
                  errors?.confirmPassword ? errors.confirmPassword : ''
                }
                sx={{
                  '& .MuiInputBase-root': {
                    '&:has(> input:-webkit-autofill)': {
                      backgroundColor: 'rgb(232, 240, 254)',
                    },
                  },
                }}
                InputProps={{
                  sx: { borderRadius: '49px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleOnShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        ) : null}

        {isJoin ? (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                size="small"
                type={showNewPassword ? 'text' : 'password'}
                id="password"
                name="password"
                label="Password"
                onChange={handleOnChange}
                value={password}
                InputLabelProps={{ shrink: true }}
                error={errors?.password ? true : false}
                helperText={errors?.password ? errors.password : ''}
                sx={{
                  '& .MuiInputBase-root': {
                    '&:has(> input:-webkit-autofill)': {
                      backgroundColor: 'rgb(232, 240, 254)',
                    },
                  },
                }}
                InputProps={{
                  sx: { borderRadius: '49px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOnShowNewPassword} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                onChange={handleOnChange}
                value={confirmPassword}
                InputLabelProps={{ shrink: true }}
                error={errors?.confirmPassword ? true : false}
                helperText={
                  errors?.confirmPassword ? errors.confirmPassword : ''
                }
                sx={{
                  '& .MuiInputBase-root': {
                    '&:has(> input:-webkit-autofill)': {
                      backgroundColor: 'rgb(232, 240, 254)',
                    },
                  },
                }}
                InputProps={{
                  sx: { borderRadius: '49px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleOnShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      <div
        className={
          isChangePassword
            ? `${pageStyles.action} ${pageStyles['-unsticky']}`
            : styles.action
        }
      >
        <PrimaryButton title={title} loading={loading} rounded />
      </div>
    </Box>
  );
};

export default AuthForm;
