import * as React from 'react';
import {
  Box,
  Select,
  InputLabel,
  FormControl,
  Button,
  Typography,
} from '@mui/material';

import { DEFAULT_COLOR_THEME } from '../../utils';
import {
  GoogleAssetsSubMenu as gasSub,
  GoogleAssetsMainMenu as gasMain,
  GoogleAssestsSidebarVisibilityOnTabs,
} from '../../utils/constants/google';

interface GoogleAssetsSubMenuProps {
  setGoogleAssetzSubMenu: any;
  selectedProductAndService: any;
  selectedSubmenu: any;
  selectedMainmenu: any;
  toggleSidebar: any;
  checkDirty: any;
}

const GoogleAssetsSubMenu: React.FC<GoogleAssetsSubMenuProps> = ({
  setGoogleAssetzSubMenu,
  selectedProductAndService,
  selectedSubmenu,
  selectedMainmenu,
  toggleSidebar,
  checkDirty,
}) => {
  const assetTypes = gasSub[selectedMainmenu];
  if (!assetTypes.includes(selectedSubmenu) && assetTypes.length > 0) {
    setGoogleAssetzSubMenu(assetTypes[0]);
  }
  const isSelected = (at: any) => {
    if (at === selectedSubmenu) {
      return {
        color: DEFAULT_COLOR_THEME,
        bgcolor: `#ffffff`,
        pointerEvents: `none`,
        paddingBottom: 2,
        marginBottom: -1,
      };
    } else {
      return { color: `#ffffff`, marginBottom: -1, paddingBottom: 2 };
    }
  };

  if (assetTypes.length === 0) {
    return (
      <Box
        sx={{ width: `100%` }}
        bgcolor={`#ffffff`}
        padding={0}
        margin={0}
        overflow={`hidden`}
      >
        <Box
          sx={{
            display: `flex`,

            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            bgcolor: DEFAULT_COLOR_THEME,
            color: `#ffffff`,
            paddingTop: 1,
            paddingBottom: 0,
            paddingX: 3,
            width: `100%`,
            // borderTopLeftRadius: 20,
            // borderTopRightRadius: 20,
            minHeight: 46.5,
          }}
        ></Box>
      </Box>
    );
  }
  if (!selectedProductAndService) {
    return <></>;
  }
  return (
    <Box
      sx={{ width: `100%` }}
      bgcolor={`#ffffff`}
      padding={0}
      margin={0}
      overflow={`hidden`}
    >
      <Box
        sx={{
          display: `flex`,
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          gap: 1,
          bgcolor: DEFAULT_COLOR_THEME,
          color: `#ffffff`,
          paddingTop: 1,
          paddingBottom: 0,
          paddingX: 3,
          width: `100%`,
          // borderTopLeftRadius: 20,
          // borderTopRightRadius: 20,
        }}
      >
        <Button
          //color={DEFAULT_COLOR_THEME}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 800,
            fontSize: `1.1rem`,
            color: `#ffffff`,
            display: `block`,
            flexShrink: 0,
            padding: 0,
          }}
          onClick={toggleSidebar}
        >
          {Object.keys(GoogleAssestsSidebarVisibilityOnTabs).includes(
            selectedMainmenu,
          )
            ? GoogleAssestsSidebarVisibilityOnTabs[selectedMainmenu].includes(
                selectedSubmenu,
              )
              ? selectedProductAndService
              : ''
            : ''}
        </Button>

        <Box display={`flex`}>
          {assetTypes.map((at) => {
            return (
              <Button
                sx={isSelected(at)}
                onClick={() => {
                  checkDirty(() => {
                    setGoogleAssetzSubMenu(at);
                  });
                }}
                variant="contained"
              >
                {at}
              </Button>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default GoogleAssetsSubMenu;
