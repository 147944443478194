import React from 'react';
import logo from '../../../../assets/images/deposit-account.svg';
import styles from '../../../../assets/styles/pages/NoContent.module.scss';
import { PrimaryButton } from '../../../Buttons';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

interface CreateDepositAccountProps {
  onClickCreate: () => void;
}

const CreateDepositAccount: React.FC<CreateDepositAccountProps> = ({
  onClickCreate,
}) => {
  const theme = useTheme();
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <div className={styles['no-content']}>
      <div className={styles.base}>
        <Typography variant="body1">
          The deposit account allows your affiliates and any third party to
          deposit payments directly to your designated bank account. Click below
          to set up your deposit account.
        </Typography>

        <img
          src={logo}
          alt=""
          className={`${styles.image} ${isXsOnly ? `${styles['-small']}` : ''}`}
        />

        <div className={styles.button}>
          <PrimaryButton
            title="Create Deposit Account"
            type="button"
            handleOnClick={onClickCreate}
            rounded
          />
        </div>
      </div>
    </div>
  );
};

export { CreateDepositAccount };
