import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { stringToSnakeCase } from '../../../../../utils/stringModifier';
import { PrimaryButton } from '../../../../Buttons';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  FBLeadQuestionOption,
} from '../../../../../types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PopupModal from '../../../../Modal';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { DEFAULT_COLOR_THEME } from '../../../../../utils/Styling';

interface LeadFormCustomQuestionFormProps {
  prefilledQuestions: any[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  register: any;
  unregister: any;
  errors: any;
  clearErrors: any;
  onValidated: any;
  multipleChoiceQuestions: FBLeadGenQuestionForm[];
  shortAnswerQuestions: FBLeadGenQuestionForm[];
}

const LeadFormCustomQuestionForm: React.FC<LeadFormCustomQuestionFormProps> = ({
  prefilledQuestions,
  formValues,
  setFormValues,
  register,
  unregister,
  errors,
  clearErrors,
  onValidated,
  multipleChoiceQuestions,
  shortAnswerQuestions,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [openEditLabel, setOpenEditLabel] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] =
    useState<FBLeadGenQuestionForm>(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const [selectedOption, setSelectedOption] =
    useState<FBLeadQuestionOption>(null);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string>('');

  const handleRemoveQuestion = (type: string, index?: number) => {
    let tempMultipleQuestions: FBLeadGenQuestionForm[] = [
      ...(multipleChoiceQuestions || []),
    ];
    let tempShortQuestions: FBLeadGenQuestionForm[] = [
      ...(shortAnswerQuestions || []),
    ];

    const questions = (
      type === 'multiple_choice'
        ? tempMultipleQuestions || []
        : tempShortQuestions || []
    )?.filter(
      (question: FBLeadGenQuestionForm, questionIndex: number) =>
        index !== questionIndex,
    );

    setFormValues({
      ...formValues,
      questions: [
        ...(type === 'multiple_choice' ? questions : tempMultipleQuestions),
        ...(type === 'short_answer' ? questions : tempShortQuestions),
        ...prefilledQuestions,
      ],
    });
  };

  const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
    let tempQuestions: FBLeadGenQuestionForm[] = [];

    multipleChoiceQuestions.forEach(
      (question: FBLeadGenQuestionForm, qindex: number) => {
        if (qindex === questionIndex && question?.options?.length > 0) {
          let optionsTemp: any[] = [];
          question?.options?.forEach((option: any, oIndex: Number) => {
            if (oIndex !== optionIndex) {
              optionsTemp = [...optionsTemp, option];
            }
          });

          question.options = optionsTemp;
        }

        tempQuestions = [...tempQuestions, question];
      },
    );

    setFormValues({
      ...formValues,
      questions: [
        ...(tempQuestions || []),
        ...(shortAnswerQuestions || []),
        ...prefilledQuestions,
      ],
    });
  };

  const handleOnAddCustomQuestion = (questionType: string) => {
    setFormValues({
      ...formValues,
      questions: [
        ...formValues?.questions,
        {
          type: 'CUSTOM',
          options:
            questionType === 'multiple_choice'
              ? [
                  { value: '', key: '' },
                  { value: '', key: '' },
                ]
              : [],
          qualified: false,
        },
      ],
    });
  };

  const handleOnChangeCustomQuestion = (
    e: ChangeEventType,
    index: number,
    questionType: string,
  ) => {
    let tempMultipleChoice: FBLeadGenQuestionForm[] = [
      ...multipleChoiceQuestions,
    ];
    let tempShortAnswer: FBLeadGenQuestionForm[] = [...shortAnswerQuestions];

    const isPrefilledKey = ['Investment', 'Background', 'Experience'].includes(
      (questionType === 'multiple_choice'
        ? tempMultipleChoice
        : tempShortAnswer)[index].key,
    );

    (questionType === 'multiple_choice' ? tempMultipleChoice : tempShortAnswer)[
      index
    ].label = e.target.value;

    if (!isPrefilledKey) {
      (questionType === 'multiple_choice'
        ? tempMultipleChoice
        : tempShortAnswer)[index].key = stringToSnakeCase(e.target.value);
    }

    setFormValues({
      ...formValues,
      questions: [
        ...tempMultipleChoice,
        ...tempShortAnswer,
        ...prefilledQuestions,
      ],
    });
  };

  const handleOnChangeOption = (
    e: ChangeEventType,
    questionIndex: number,
    optionIndex: number,
  ) => {
    let temp: FBLeadGenQuestionForm[] = [...multipleChoiceQuestions];

    temp[questionIndex].options[optionIndex].key = stringToSnakeCase(
      e.target.value,
    );
    temp[questionIndex].options[optionIndex].value = e.target.value;

    setFormValues({
      ...formValues,
      questions: [...temp, ...shortAnswerQuestions, ...prefilledQuestions],
    });
  };

  const handleOnAddOption = (questionIndex: number) => {
    let temp: FBLeadGenQuestionForm[] = [...multipleChoiceQuestions];

    const field = temp[questionIndex].key;

    const isQualifyingQuestion = [
      'Background',
      'Investment',
      'Experience',
    ]?.includes(field);

    temp[questionIndex].options = [
      ...temp[questionIndex].options,
      { value: '', key: '', qualified: false },
    ];

    if (isQualifyingQuestion) {
      let options: FBLeadQuestionOption[] = [];
      temp[questionIndex].options.forEach(
        (option: FBLeadQuestionOption, index: number) => {
          if (index === temp[questionIndex].options.length - 1) {
            option.qualified = null;
          } else {
            option.qualified = true;
          }

          options = [...options, option];
        },
      );

      temp[questionIndex].options = options;
    }

    setFormValues({
      ...formValues,
      questions: [...temp, ...shortAnswerQuestions, ...prefilledQuestions],
    });
  };

  const handleOnChangeQuestionLabel = (
    e: ChangeEventType,
    index: number,
    questionType: string,
  ) => {
    let tempMultipleChoice: FBLeadGenQuestionForm[] = [
      ...multipleChoiceQuestions,
    ];
    let tempShortAnswer: FBLeadGenQuestionForm[] = [...shortAnswerQuestions];

    (questionType === 'multiple_choice' ? tempMultipleChoice : tempShortAnswer)[
      index
    ].key = e.target.value;

    setFormValues({
      ...formValues,
      questions: [
        ...tempMultipleChoice,
        ...tempShortAnswer,
        ...prefilledQuestions,
      ],
    });
  };

  const handleOnDrag = (result: any, questionType: string) => {
    let tempMultipleChoice: any = [...multipleChoiceQuestions];
    let tempShortAnswer: any = [...shortAnswerQuestions];

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [reorderBody] = (
      questionType === 'multiple_choice' ? tempMultipleChoice : tempShortAnswer
    ).splice(startIndex, 1);
    (questionType === 'multiple_choice'
      ? tempMultipleChoice
      : tempShortAnswer
    ).splice(endIndex, 0, reorderBody);

    setFormValues({
      ...formValues,
      questions: [
        ...tempMultipleChoice,
        ...tempShortAnswer,
        ...prefilledQuestions,
      ],
    });
  };

  const handleOnChangeOptionLabel = (
    e: ChangeEventType,
    index: number,
    optionIndex: number,
  ) => {
    let tempMultipleChoice: FBLeadGenQuestionForm[] = [
      ...multipleChoiceQuestions,
    ];

    tempMultipleChoice[index].options[optionIndex].key = e.target.value;

    setFormValues({
      ...formValues,
      questions: [
        ...tempMultipleChoice,
        ...shortAnswerQuestions,
        ...prefilledQuestions,
      ],
    });
  };

  const handleOpenEditLabel = (
    questionIndex: number,
    optionIndex?: number,
    question?: FBLeadGenQuestionForm,
    option?: FBLeadQuestionOption,
  ) => {
    if (option) {
      setSelectedOption(option);
      setSelectedOptionIndex(optionIndex);
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(question);
      setSelectedOption(null);
      setSelectedQuestionType(
        question?.options?.length > 0 ? 'multiple_choice' : 'short_answer',
      );
    }
    setSelectedQuestionIndex(questionIndex);
    setOpenEditLabel(true);
  };

  const handleCloseEditLabel = () => {
    setOpenEditLabel(false);
  };

  const renderQuestionForm = (
    question: FBLeadGenQuestionForm,
    index: number,
    key: string,
    questionType: string,
  ) => {
    const label =
      question?.options?.length > 0 ? 'Multiple Choice' : 'Short Answer';

    return (
      <Draggable index={index} key={key} draggableId={`${index}`}>
        {(draggableProvider) => (
          <Box
            component="div"
            ref={draggableProvider.innerRef}
            {...draggableProvider.draggableProps}
            {...draggableProvider.dragHandleProps}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Card>
                  <CardContent>
                    <Grid item xs={1}>
                      <DragIndicatorOutlinedIcon />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel shrink sx={{ width: '100%' }}>
                        <Box
                          component="div"
                          sx={{
                            whiteSpace: 'initial',
                          }}
                        >
                          {`${`${label} Question #${index + 1}${
                            question.key ? `: ${question.key}` : ''
                          }`} *`}{' '}
                          <Box component="span">
                            <PrimaryButton
                              type="button"
                              variant="text"
                              title="Edit Label"
                              startIcon={<EditOutlinedIcon />}
                              bold
                              underlined
                              handleOnClick={() =>
                                handleOpenEditLabel(index, null, question)
                              }
                            />
                          </Box>
                        </Box>
                      </InputLabel>

                      <TextField
                        {...(openEditLabel
                          ? {}
                          : register(key, {
                              required: true,
                            }))}
                        placeholder={`Enter your ${label} Question`}
                        fullWidth
                        multiline
                        variant="standard"
                        type="text"
                        id={key}
                        name={key}
                        float
                        onChange={(e: ChangeEventType) => {
                          clearErrors(key);
                          handleOnChangeCustomQuestion(e, index, questionType);
                        }}
                        value={question?.label}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        helperText={
                          errors[key] && `${label} #${index + 1} is required`
                        }
                        error={!!errors[key]}
                        autoFocus
                        InputProps={{
                          sx: { borderRadius: '49px' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  unregister(key);
                                  handleRemoveQuestion(questionType, index);
                                }}
                                edge="end"
                              >
                                <DeleteIcon sx={{ color: 'red' }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {question?.options?.length > 0 ? (
                      <Grid item xs={12} sm={6} ml={4} mt={1}>
                        <Grid container spacing={2}>
                          {question?.options.map(
                            (option: any, optionIndex: number) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  key={`option-${index + 1}-${optionIndex + 1}`}
                                >
                                  <TextField
                                    {...(openEditLabel
                                      ? {}
                                      : register(
                                          `option-${index + 1}-${
                                            optionIndex + 1
                                          }`,
                                          {
                                            required: `Option #${
                                              optionIndex + 1
                                            } is required`,
                                            validate: {
                                              mustBeUnique: (value: string) => {
                                                const filteredOptions =
                                                  question?.options?.filter(
                                                    (option) =>
                                                      option.value === value,
                                                  );

                                                return (
                                                  !(
                                                    filteredOptions.length > 1
                                                  ) ||
                                                  `Option #${
                                                    optionIndex + 1
                                                  } should be unique`
                                                );
                                              },
                                            },
                                          },
                                        ))}
                                    fullWidth
                                    variant="standard"
                                    type="text"
                                    id={`option-${index + 1}-${
                                      optionIndex + 1
                                    }`}
                                    name={`option-${index + 1}-${
                                      optionIndex + 1
                                    }`}
                                    label={
                                      <Fragment>
                                        <Box>
                                          {`${`${`Option #${optionIndex + 1}${
                                            option.key ? `: ${option.key}` : ''
                                          }`}`} *`}{' '}
                                          <Box component="span">
                                            <PrimaryButton
                                              type="button"
                                              variant="text"
                                              title="Edit Label"
                                              startIcon={<EditOutlinedIcon />}
                                              underlined
                                              bold
                                              handleOnClick={() =>
                                                handleOpenEditLabel(
                                                  index,
                                                  optionIndex,
                                                  null,
                                                  option,
                                                )
                                              }
                                            />
                                          </Box>
                                        </Box>
                                      </Fragment>
                                    }
                                    onChange={(e: ChangeEventType) => {
                                      clearErrors(
                                        `option-${index + 1}-${
                                          optionIndex + 1
                                        }`,
                                      );
                                      handleOnChangeOption(
                                        e,
                                        index,
                                        optionIndex,
                                      );
                                    }}
                                    value={option.value}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    size="small"
                                    error={
                                      !!errors[
                                        `option-${index + 1}-${optionIndex + 1}`
                                      ]
                                    }
                                    helperText={
                                      errors[
                                        `option-${index + 1}-${optionIndex + 1}`
                                      ]
                                        ? errors[
                                            `option-${index + 1}-${
                                              optionIndex + 1
                                            }`
                                          ]?.message
                                        : ''
                                    }
                                    autoFocus
                                    InputProps={{
                                      sx: {
                                        borderRadius: '49px',
                                      },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => {
                                              unregister(
                                                `option-${index + 1}-${
                                                  optionIndex + 1
                                                }`,
                                              );
                                              handleRemoveOption(
                                                index,
                                                optionIndex,
                                              );
                                            }}
                                            edge="end"
                                            disabled={
                                              question?.options?.length === 1
                                            }
                                          >
                                            <DeleteIcon
                                              sx={{
                                                color:
                                                  question?.options?.length ===
                                                  1
                                                    ? '#EBEBE4'
                                                    : 'red',
                                              }}
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              );
                            },
                          )}

                          <Grid item xs={12}>
                            <PrimaryButton
                              type="button"
                              title="Add Option"
                              handleOnClick={() => handleOnAddOption(index)}
                              startIcon={<AddIcon />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Draggable>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Custom Questions
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">
          Get the info your business needs.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Multiple Choice Questions
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        <DragDropContext
          onDragEnd={(result: any) => handleOnDrag(result, 'multiple_choice')}
        >
          <Droppable droppableId="multipleChoices">
            {(provider) => (
              <ul ref={provider.innerRef} {...provider.droppableProps}>
                {multipleChoiceQuestions?.map(
                  (question: FBLeadGenQuestionForm, index: number) => {
                    return renderQuestionForm(
                      question,
                      index,
                      `multiple-choice-question-${index}`,
                      'multiple_choice',
                    );
                  },
                )}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <Grid item xs={12} mt={1}>
          <PrimaryButton
            title="Add Multiple Choice"
            type="button"
            handleOnClick={() => handleOnAddCustomQuestion('multiple_choice')}
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Short Answer Questions
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        <DragDropContext
          onDragEnd={(result: any) => handleOnDrag(result, 'short_answer')}
        >
          <Droppable droppableId="shortAnswerQuestions">
            {(provider) => (
              <ul ref={provider.innerRef} {...provider.droppableProps}>
                {shortAnswerQuestions?.map(
                  (question: FBLeadGenQuestionForm, index: number) => {
                    return renderQuestionForm(
                      question,
                      index,
                      `short-answer-question-${index}`,
                      'short_answer',
                    );
                  },
                )}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <Grid item xs={12} mt={1}>
          <PrimaryButton
            title="Add Short Answer Question"
            type="button"
            handleOnClick={() => handleOnAddCustomQuestion('short_answer')}
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>

      <PopupModal open={openEditLabel} handleClose={null}>
        <Grid
          container
          spacing={2}
          sx={{ width: xsOnly ? '100%' : '400px', textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: DEFAULT_COLOR_THEME }}
            >
              Edit Question Label
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Change how your field names appear when you export files or build
              your lead notification.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...(openEditLabel ? register('key', { required: true }) : {})}
              fullWidth
              required
              variant="standard"
              type="text"
              name="key"
              label={`${selectedOption ? 'Option' : 'Question'} Label`}
              onChange={(e: ChangeEventType) => {
                clearErrors('key');
                if (selectedOption) {
                  handleOnChangeOptionLabel(
                    e,
                    selectedQuestionIndex,
                    selectedOptionIndex,
                  );
                } else {
                  handleOnChangeQuestionLabel(
                    e,
                    selectedQuestionIndex,
                    selectedQuestionType,
                  );
                }
              }}
              value={selectedOption?.key || '' || selectedQuestion?.key || ''}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.key}
              helperText={
                errors.key &&
                `${selectedOption ? 'Option' : 'Question'} label is required`
              }
            />
          </Grid>

          <Grid item xs={12}>
            <PrimaryButton
              title="Confirm"
              type="button"
              handleOnClick={onValidated(handleCloseEditLabel)}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Grid>
  );
};

export default LeadFormCustomQuestionForm;
