import {
  Box,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { StripeInvoiceBreakdown } from '../../types';
import { renderCCIcon } from '../Cards/Billing/PaymentMethodHelpers';
import { toCurrency } from '../../utils/numberFormatter';
import { XsOnly } from '../../utils/breakpoints';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_COLOR_THEME } from '../../utils';

interface BillingBreakdownDetailsProps {
  breakdown: StripeInvoiceBreakdown;
  onClose: () => void;
}

const BillingBreakdownDetails: React.FC<BillingBreakdownDetailsProps> = ({
  breakdown,
  onClose,
}) => {
  const buildCustomerAddress = (address: any) => {
    const { line1, line2, city, state, postal_code, country } = address;

    return `${line1 ? line1 : ''} ${line2 ? `${line2},` : ''} ${
      city ? `${city},` : ''
    } ${state ? `${state},` : ''} ${
      postal_code ? `${postal_code},` : ''
    } ${country}`;
  };

  return (
    <Box
      sx={{
        width: XsOnly() ? '100%' : '700px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
        padding: '20px',
      }}
    >
      <Grid container spacing={1}>
        {XsOnly() ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Receipt
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {breakdown.accountName}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4} flexDirection="column">
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Invoice #
          </Typography>

          <Typography variant="body1">
            {breakdown.invoiceNumber || 'N/A'}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={4}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Date of Issue
          </Typography>

          <Typography variant="body1">{breakdown.issuedDate}</Typography>
        </Grid>

        <Grid item xs={6} sm={4}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Due Date
          </Typography>

          <Typography variant="body1">{breakdown.dueDate || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ backgroundColor: 'lightgray', padding: '5px' }}>
            <Grid item xs={12} sm={4} flexDirection="column">
              <Typography variant="body1" fontWeight="bold">
                Bill to:
              </Typography>

              <Typography variant="body1">{breakdown.customerName}</Typography>

              <Typography variant="body1">{breakdown.customerEmail}</Typography>

              <Typography variant="body1">{breakdown.customerPhone}</Typography>

              <Typography variant="body1">
                {buildCustomerAddress(breakdown.customerAddress)}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              flexDirection="column"
              sx={{ mt: XsOnly() ? 1 : 0 }}
            >
              <Typography variant="body1" fontWeight="bold">
                Payment:
              </Typography>

              <Box
                component="div"
                sx={{
                  justifyContent: 'left',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {breakdown.paymentMethod ? (
                  <>
                    {renderCCIcon(breakdown.paymentMethod?.card?.brand, 20)}{' '}
                    <Box component="span" sx={{ marginLeft: '5px' }}>
                      {`●●●● ${breakdown.paymentMethod?.card?.last4}`}
                    </Box>
                  </>
                ) : (
                  'N/A'
                )}
              </Box>

              <Divider sx={{ my: 1 }} />

              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Previous Balance:
                </Typography>

                <Typography variant="body1">
                  {toCurrency('USD', breakdown.amountDue)}
                </Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Received Payment:
                </Typography>

                <Typography variant="body1">
                  {toCurrency('USD', breakdown.amountPaid)}
                </Typography>
              </Box>

              <Divider sx={{ my: 1 }} />

              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Balance Due:
                </Typography>

                <Typography variant="body1">
                  {toCurrency('USD', breakdown.amountRemaining)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {breakdown.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{toCurrency('USD', item.amount / 100)}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>
                  Subtotal
                </TableCell>
                <TableCell>{toCurrency('USD', breakdown.subTotal)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>
                  Subtotal ex. Tax
                </TableCell>
                <TableCell>
                  {toCurrency('USD', breakdown.subTotalTax)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>
                  Tax
                </TableCell>
                <TableCell>{toCurrency('USD', breakdown.tax)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>
                  Total
                </TableCell>
                <TableCell>{toCurrency('USD', breakdown.total)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingBreakdownDetails;
