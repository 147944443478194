import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface CircularLoadingProps {
  loading: boolean;
}

export const CircularLoading: React.FC<CircularLoadingProps> = ({
  loading,
}) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CircularLoading;
