import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { ChangeEventType } from '../../types';
import { humanizeString } from '../../utils/stringModifier';

interface BasicSelectMenuProps {
  label: string;
  id: string;
  name: string;
  value: string;
  data: any[];
  onChange: (e: ChangeEventType) => void;
  fullWidth?: boolean;
  hasMarginRight5?: boolean;
  disabled?: boolean;
  variant?: string;
}

const BasicSelectMenu: React.FC<BasicSelectMenuProps> = ({
  label,
  id,
  name,
  value,
  data,
  onChange,
  fullWidth = false,
  hasMarginRight5 = false,
  disabled,
  variant = 'standard',
}) => {
  return (
    <>
      <TextField
        id={id}
        select
        variant={variant as any}
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        InputLabelProps={{ shrink: true }}
        size="small"
        sx={{
          marginRight: hasMarginRight5 ? '5px' : '',
        }}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {data.map((text: any) => (
          <MenuItem key={text} value={text}>
            {humanizeString(text)}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default BasicSelectMenu;
