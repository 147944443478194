import React, { Fragment } from 'react';
import { TargetSearchKey } from '../../../types';
import { Box, Card, CardActions, CardHeader } from '@mui/material';
import { HtmlTooltip } from '../../Tooltip';
import { PrimaryButton } from '../../Buttons';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { numberShortener } from '../../../utils/numberFormatter';

interface SelectedTargetCardProps {
  target: TargetSearchKey;
  flexibleIndex?: number;
  onRemove: (target: TargetSearchKey, flexibleIndex?: number) => void;
  type: string;
}

const SelectedTargetCard: React.FC<SelectedTargetCardProps> = ({
  target,
  flexibleIndex,
  onRemove,
  type,
}) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        titleTypographyProps={{
          variant: 'body2',
          fontWeight: 'bold',
        }}
        sx={{ paddingBottom: 0 }}
        subheaderTypographyProps={{ variant: 'caption' }}
        title={target.name}
        subheader={
          type === 'geoLocations' ? target.type : target?.path?.join(' > ')
        }
        action={
          type === 'geoLocations' ? null : (
            <HtmlTooltip
              title={
                <Fragment>
                  <Box component="div" sx={{ marginBottom: '10px' }}>
                    <Box
                      component="span"
                      sx={{
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Size:
                    </Box>

                    {`${numberShortener(
                      target.audience_size_lower_bound || 0,
                    )} - ${numberShortener(
                      target.audience_size_upper_bound || 0,
                    )}`}
                  </Box>

                  <Box component="div" sx={{ marginBottom: '10px' }}>
                    {`${target?.path?.join(' > ')} > ${target.name}`}
                  </Box>

                  <div>
                    <Box
                      component="span"
                      sx={{
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Description:
                    </Box>

                    {target?.description
                      ? target?.description
                      : `People who have expressed an interest in or like pages related to ${target?.name}`}
                  </div>
                </Fragment>
              }
            >
              <InfoTwoToneIcon sx={{ fontSize: '18px', color: '#096F4D' }} />
            </HtmlTooltip>
          )
        }
      />

      <CardActions sx={{ marginTop: 0 }}>
        <PrimaryButton
          variant="text"
          title="Remove"
          type="button"
          theme="red"
          fontSize="12px"
          handleOnClick={() => {
            if (['exclusion', 'targets'].includes(type)) {
              onRemove(target);
            } else {
              onRemove(target, flexibleIndex);
            }
          }}
        />
      </CardActions>
    </Card>
  );
};

export default SelectedTargetCard;
