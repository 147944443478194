import axios from 'axios';
import { apiBaseUrl } from './api';
import { CrmSetting } from '../types/ICrm';

export const getCrmSettingsByBrand = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/settings`, {
    params: { brandId },
  });

  return data?.data;
};

export const createOrUpdateCrmSettings = async (
  brandId: string,
  params: CrmSetting,
) => {
  const data = await axios.post(`${apiBaseUrl}/crm/settings`, params, {
    params: { brandId },
  });

  return data?.data;
};
