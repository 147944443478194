import { GridColDef } from '@mui/x-data-grid-premium';
import { toCurrency } from '../../utils/numberFormatter';

const customSortComparator: any = (
  v1: any,
  v2: any,
  cellParams1: any,
  cellParams2: any,
) => {
  if (
    (cellParams1.rowNode.type === 'group' &&
      cellParams1.id.toString().includes('location')) ||
    (cellParams2.rowNode.type === 'group' &&
      cellParams2.id.toString().includes('location'))
  ) {
    return 0;
  }

  if (cellParams1.field === 'matchtype' && cellParams2.field === 'matchtype') {
    if (v1 < v2) {
      return -1;
    } else if (v1 > v2) {
      return 1;
    } else {
      return 0;
    }
  }

  return v1 - v2;
};

export const googleReportsColumnDefs: GridColDef[] = [
  {
    field: 'matchtype',
    headerName: 'Match',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    valueGetter: (_, row) => row.matchtype,
    sortComparator: customSortComparator,
  },
  {
    field: 'Clicks',
    headerName: 'Clicks',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    sortComparator: customSortComparator,
  },
  {
    field: `CPC`,
    headerName: `CPC`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    valueGetter: (_, row) => row.CPC,
    sortComparator: customSortComparator,
    renderCell: (params) =>
      params.rowNode.depth !== 2 && params.row.id !== 'locations-all'
        ? toCurrency(`USD`, params.row.CPC, 2)
        : '',
  },
  {
    field: 'Impressions',
    headerName: 'Impressions',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    width: 100,
    flex: 0,
    sortComparator: customSortComparator,
  },
  {
    field: `CPM`,
    headerName: `CPM`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    valueGetter: (_, row) => row.CPL,
    sortComparator: customSortComparator,
    renderCell: (params) =>
      params.rowNode.depth !== 2 && params.row.id !== 'locations-all'
        ? toCurrency(`USD`, params.row.CPM, 2)
        : '',
  },

  {
    field: 'Leads',
    headerName: 'Leads',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    sortComparator: customSortComparator,
  },
  {
    field: `CPL`,
    headerName: `CPL`,
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 80,
    width: 80,
    flex: 0,
    valueGetter: (_, row) => row.CPL,
    sortComparator: customSortComparator,
    renderCell: (params) =>
      params.rowNode.depth !== 2 && params.row.id !== 'locations-all'
        ? toCurrency(`USD`, params.row.CPL, 2)
        : '',
  },
  {
    field: 'Costs',
    headerName: 'Cost',
    type: 'number',
    headerClassName: 'bg-green',
    minWidth: 100,
    width: 100,
    flex: 0,
    valueGetter: (_, row) => row.Costs,
    sortComparator: customSortComparator,
    renderCell: (params) =>
      params.rowNode.depth !== 2 && params.row.id !== 'locations-all'
        ? toCurrency(`USD`, params.row.Costs, 2)
        : '',
  },
];
