import axios from "axios";
import { Attachment } from "../types";
import { apiBaseUrl } from "./api";

export const getChats = async (
  pageNum?: number,
  limit?: number,
  filter?: string,
  search?: string
) => {
  const data = await axios.get(
    `${apiBaseUrl}/chat/user${
      pageNum !== undefined && limit !== undefined
        ? `?pageNum=${pageNum}&limit=${limit}`
        : ""
    }${
      pageNum !== undefined && limit !== undefined && filter !== undefined
        ? `&filter=${filter}`
        : ""
    }${
      pageNum === undefined && limit === undefined && filter !== undefined
        ? `?filter=${filter}`
        : ""
    }${search !== undefined && search !== "" ? `&search=${search}` : ""}`
  );

  return data?.data.results;
};

export const getChatsAssociatedWithBrands = async (
  brandId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  search?: string
) => {
  const data = await axios.get(
    `${apiBaseUrl}/chat/user?brandId=${brandId}${
      pageNum !== undefined && limit !== undefined
        ? `&pageNum=${pageNum}&limit=${limit}`
        : ""
    }${filter !== undefined ? `&filter=${filter}` : ""}${
      search !== undefined && search !== "" ? `&search=${search}` : ""
    }`
  );

  return data?.data.results;
};

export const getChatsAssociatedWithSalesperson = async (
  salespersonId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  search?: string
) => {
  const data = await axios.get(
    `${apiBaseUrl}/chat/user?salespersonId=${salespersonId}${
      pageNum !== undefined && limit !== undefined
        ? `&pageNum=${pageNum}&limit=${limit}`
        : ""
    }${filter !== undefined ? `&filter=${filter}` : ""}${
      search !== undefined && search !== "" ? `&search=${search}` : ""
    }`
  );

  return data?.data.results;
};

export const getChatsAssociatedWithSalespersonAndBrand = async (
  brandId: string,
  salespersonId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  search?: string
) => {
  const data = await axios.get(
    `${apiBaseUrl}/chat/user?brandId=${brandId}&salespersonId=${salespersonId}${
      pageNum !== undefined && limit !== undefined
        ? `&pageNum=${pageNum}&limit=${limit}`
        : ""
    }${filter !== undefined ? `&filter=${filter}` : ""}${
      search !== undefined && search !== "" ? `&search=${search}` : ""
    }`
  );

  return data?.data.results;
};

export const getChatsAssociatedWithPage = async (
  pageId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  search?: string
) => {
  const data = await axios.get(
    `${apiBaseUrl}/chat/user?pageId=${pageId}${
      pageNum !== undefined && limit !== undefined
        ? `&pageNum=${pageNum}&limit=${limit}`
        : ""
    }${filter !== undefined ? `&filter=${filter}` : ""}${
      search !== undefined && search !== "" ? `&search=${search}` : ""
    }`
  );

  return data?.data.results;
};

export const getSingleChat = async (chatId: string) => {
  const data = await axios.get(`${apiBaseUrl}/chat/user/${chatId}`);
  return data?.data;
};

export const sendMessage = async (
  psId: string,
  obj: { text: string; chatId: string; attachment?: Attachment }
) => {
  const data = await axios.post(
    `${apiBaseUrl}/chat/user/send/message/${psId}`,
    obj
  );

  return data?.data;
};

export const takeThreadControl = async (psId: string, pageId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/chat/user/take-thread-control/${psId}`,
    { pageId }
  );

  return data?.data;
};

export const releaseThreadControl = async (psId: string, pageId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/chat/user/release-thread-control/${psId}`,
    { pageId }
  );

  return data?.data;
};

export const getMessage = async (chatId: string) => {
  const data = await axios.get(`${apiBaseUrl}/chat/user/get/message/${chatId}`);
  return data?.data;
};

export const setLastSeen = async (chatId: string, seenAt: string) => {
  const data = await axios.put(
    `${apiBaseUrl}/chat/user/message/seen/${chatId}?seenAt=${seenAt}`
  );

  return data?.data;
};
