import { Box } from '@mui/system';
import React from 'react';
import { GoogleAdsMetricsFields } from '../../../types';
import { BreakdownType } from '../../../utils/constants/breakdownType';
//import { CustomLoadingOverlay } from '../../Loading';
import {
  keywordColumns,
  locationColumns,
  searchTermColumns,
} from './GoogleCampaignBreakdownColumn';
import { useMediaQuery, useTheme } from '@mui/material';
import { StyledDataGrid } from '../../../utils';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';

interface GoogleCampaignBreakdownTableProps {
  data: GoogleAdsMetricsFields[];
  breakdownType: string;
  loading: boolean;
}

const GoogleCampaignBreakdownTable: React.FC<
  GoogleCampaignBreakdownTableProps
> = ({ data, breakdownType, loading }) => {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rowData = () => {
    const rows = data.map((row: GoogleAdsMetricsFields, index: number) => {
      let data: GoogleAdsMetricsFields = {
        id: index.toString(),
        impressions: row.impressions,
        cpm: row.impressions > 0 ? row.spend / row.impressions : 0,
        conversions: row.conversions,
        cpl: row.conversions > 0 ? row.spend / row.conversions : 0,
        spend: row.spend,
        clicks: row.clicks,
        cpc: row.clicks > 0 ? row.spend / row.clicks : 0,
      };

      if (breakdownType === BreakdownType.LOCATION)
        data = { ...data, location: row.location };

      if (breakdownType === BreakdownType.KEYWORD)
        data = { ...data, keyword: row.keyword };

      if (breakdownType === BreakdownType.SEARCH_TERM)
        data = { ...data, searchTerm: row.searchTerm };

      return data;
    });

    return rows;
  };

  const columns = () => {
    if (breakdownType === BreakdownType.LOCATION)
      return locationColumns(isMdAndDown);

    if (breakdownType === BreakdownType.KEYWORD)
      return keywordColumns(isMdAndDown);

    return searchTermColumns(isMdAndDown);
  };

  return (
    <div>
      <Box className={styles.table}>
        <DataGridPremium
          initialState={{
            sorting: {
              sortModel: [{ field: 'leads', sort: 'desc' }],
            },
          }}
          density="compact"
          autoHeight
          hideFooter
          rows={rowData()}
          columns={columns()}
          loading={loading}
          sx={StyledDataGrid()}
        />
      </Box>
    </div>
  );
};

export default GoogleCampaignBreakdownTable;
