import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { ChatUser } from '../../../types';
import styles from '../../../assets/styles/components/Forms/DesktopChatDialog.module.scss';
import {
  chatResult,
  isCompletedEngagement,
  isNewMessage,
  isPartialEngagement,
  isUnengagedMessage,
  isWaitingForResponse,
} from '../../../utils/chatDialogHelper';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import moment from 'moment';
import { DEFAULT_COLOR_THEME } from '../../../utils';

interface DesktopChatListProps {
  onScroll: (e: any) => void;
  chats: ChatUser[];
  onSelectChat: (chat: ChatUser) => void;
  setSelectedChat: Dispatch<SetStateAction<string>>;
  chat: ChatUser;
  dateFormatter: (lastInteraction: Date) => string;
}

const DesktopChatList: React.FC<DesktopChatListProps> = ({
  onScroll,
  chats,
  onSelectChat,
  setSelectedChat,
  chat,
  dateFormatter,
}) => {
  return (
    <List
      component="nav"
      sx={{
        height: '100vh',
        minHeight: 'calc(100vh - 310px)',
        maxHeight: 'calc(100vh - 310px)',
        // position: 'relative',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: DEFAULT_COLOR_THEME,
          borderRadius: '18px',

          '&:hover': {
            background: DEFAULT_COLOR_THEME,
          },
        },
      }}
      onScroll={onScroll}
    >
      {chats.length !== 0 ? (
        chats.map((chatUser: ChatUser) => {
          return (
            <ListItem
              key={chatUser.chatId}
              onClick={() => {
                onSelectChat(chatUser);
                setSelectedChat(chatUser._id);
              }}
              className={styles.list}
              classes={{ selected: `${styles['-active']}` }}
              selected={chatUser._id === chat?._id}
            >
              <ListItemIcon>
                <Avatar alt="Remy Sharp" src={chatUser.profilePic} />
              </ListItemIcon>

              <ListItemText
                primary={chatUser?.name}
                primaryTypographyProps={{
                  style: {
                    fontWeight: `${
                      isNewMessage(
                        `${chatUser.lastInteraction}`,
                        chatUser.chatSeenAt,
                      )
                        ? 'bold'
                        : ''
                    }`,
                    color: '#484848',
                  },
                }}
                secondary={
                  <Fragment>
                    <div className={styles.brand}>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontWeight: `${
                            isNewMessage(
                              `${chatUser.lastInteraction}`,
                              chatUser.chatSeenAt,
                            )
                              ? 'bold'
                              : ''
                          }`,
                          color: !['Lead', 'Scheduled'].includes(
                            chatResult(
                              chatUser.result,
                              chatUser.investment,
                              chatUser.brand,
                            ),
                          )
                            ? red[500]
                            : '#484848',
                        }}
                        component="span"
                        variant="body2"
                      >
                        {chatUser.brand?.brand}
                      </Typography>
                    </div>
                  </Fragment>
                }
              />

              <ListItemText
                primary={
                  <React.Fragment>
                    <span className={`${styles['chat-icon']}`}>
                      {(!isWaitingForResponse(
                        chatUser.partialEngagement,
                        chatUser.completedEngagement,
                        `${chatUser.lastInteraction}`,
                        chatUser.lastEngagedAt,
                      ) &&
                        isCompletedEngagement(
                          chatUser.partialEngagement,
                          chatUser.completedEngagement,
                        )) ||
                      isUnengagedMessage(
                        chatUser.partialEngagement,
                        chatUser.completedEngagement,
                      ) ? (
                        <MarkChatUnreadIcon
                          htmlColor={`${
                            isUnengagedMessage(
                              chatUser.partialEngagement,
                              chatUser.completedEngagement,
                            ) ||
                            isPartialEngagement(
                              chatUser.partialEngagement,
                              chatUser.completedEngagement,
                            )
                              ? '#1976d2'
                              : '#198754'
                          }`}
                          fontSize="small"
                        />
                      ) : (
                        <MarkChatUnreadOutlinedIcon
                          htmlColor={`${
                            isPartialEngagement(
                              chatUser.partialEngagement,
                              chatUser.completedEngagement,
                            )
                              ? '#1976d2'
                              : '#198754'
                          }`}
                          fontSize="small"
                        />
                      )}
                    </span>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <div>
                      <span
                        className={`${styles['time']} ${
                          isNewMessage(
                            `${chatUser.lastInteraction}`,
                            chatUser.chatSeenAt,
                          )
                            ? styles['-new-message']
                            : ''
                        }`}
                      >
                        {moment(
                          dateFormatter(chatUser.lastInteraction),
                        ).fromNow(true)}
                      </span>
                    </div>
                  </React.Fragment>
                }
                sx={{ textAlign: 'right' }}
              />
            </ListItem>
          );
        })
      ) : (
        <div className={`${styles['no-content']}`}>
          <p className={styles.text}>No User Opt-In</p>
        </div>
      )}
    </List>
  );
};

export default DesktopChatList;
