import { Box, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import momentTz, { Moment } from 'moment-timezone';
import { PrimaryButton } from '../../Buttons';

interface StripeTestClockFormProps {
  testClock: any;
  advanceDate: Moment | null;
  minDate: Moment | null;
  loading: boolean;
  setAdvanceDate: Dispatch<SetStateAction<Moment | null>>;
  onAdvanceTestClock: () => void;
}

const StripeTestClockForm: React.FC<StripeTestClockFormProps> = ({
  testClock,
  advanceDate,
  minDate,
  loading,
  setAdvanceDate,
  onAdvanceTestClock,
}) => {
  return (
    <div>
      <Box
        component="div"
        sx={{ textAlign: 'center', flexDirection: 'column' }}
      >
        {testClock ? (
          <>
            <Box component="div" sx={{ marginBottom: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Advance Date To"
                  inputFormat="MM/DD/YYYY"
                  value={advanceDate}
                  minDate={minDate}
                  onChange={(e: any) => setAdvanceDate(e)}
                  renderInput={(params) => {
                    const value = momentTz(params.inputProps.value).format(
                      'LL',
                    );
                    params.inputProps.value = value;

                    return (
                      <TextField {...params} size="small" variant="standard" />
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Box component="div" sx={{ marginBottom: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  label="Advance Time To"
                  value={advanceDate}
                  onChange={(e: any) => setAdvanceDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="standard" />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <div>
              <PrimaryButton
                title="Advance Test Clock"
                handleOnClick={onAdvanceTestClock}
                loading={loading}
              />
            </div>
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Test Clock is Disabled or Expired
            </Typography>
          </>
        )}
      </Box>
    </div>
  );
};

export default StripeTestClockForm;
