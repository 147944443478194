import LinearProgressBar from './LinearProgressBar';
import { GridOverlay } from '@mui/x-data-grid-premium';

export function CustomLoadingOverlay() {
  return (
    <GridOverlay sx={{ top: 0, width: '100%' }}>
      <LinearProgressBar />
    </GridOverlay>
  );
}
