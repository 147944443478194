import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { ChangeEventType, StripeCustomerForm } from '../../../types';
import CountrySelectMenu from '../../Select/CountrySelectMenu';
import AccountActions from './AccountActions';

interface StripeCustomerAddressFormProps {
  formValues: StripeCustomerForm;
  onChange: (e: ChangeEventType) => void;
  onOpenTerms: React.FormEventHandler<HTMLFormElement> | undefined;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onClose: () => void;
}

const StripeCustomerAddressForm: React.FC<StripeCustomerAddressFormProps> = ({
  formValues,
  onChange,
  onOpenTerms,
  onBack,
  activeStep,
  steps,
  onClose,
}) => {
  return (
    <Box component="form" onSubmit={onOpenTerms} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="line1"
            name="line1"
            label="Company Name, Street, PO Box"
            onChange={onChange}
            value={formValues?.address?.line1}
            InputLabelProps={{ shrink: true }}
            size="small"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            fullWidth
            type="text"
            id="line2"
            name="line2"
            label="Apartment, Suite, Unit, Building"
            onChange={onChange}
            value={formValues?.address?.line2}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            required
            type="text"
            id="city"
            name="city"
            label="City"
            onChange={onChange}
            value={formValues?.address?.city}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="state"
            name="state"
            label="State"
            onChange={onChange}
            value={formValues?.address?.state}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CountrySelectMenu
            value={{
              code: 'US',
              label: 'United States',
              phone: '1',
              suggested: true,
            }}
            disabled
            shrink
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="postalCode"
            name="postal_code"
            label="Postal Code"
            onChange={onChange}
            value={formValues?.address?.postal_code}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripeCustomerAddressForm;
