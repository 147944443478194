import moment from 'moment';
import { CallToActionType } from '../constants/facebookAds';
import { AdImage, AdVideo, ImageFilters, VideoFilters } from '../../types';
import { arrayObjectUniqueFilter } from '../arrayFormatter';

export const FBCampaignObjective = {
  AppPromotion: 'OUTCOME_APP_PROMOTION',
  Awareness: 'OUTCOME_AWARENESS',
  Engagement: 'OUTCOME_ENGAGEMENT',
  Leads: 'OUTCOME_LEADS',
  Sales: 'OUTCOME_SALES',
  Traffic: 'OUTCOME_TRAFFIC',
};

export const fbCampaignObjectives = [
  'OUTCOME_APP_PROMOTION',
  'OUTCOME_AWARENESS',
  'OUTCOME_ENGAGEMENT',
  'OUTCOME_LEADS',
  'OUTCOME_SALES',
  'OUTCOME_TRAFFIC',
];

export const FBDestinationType = {
  Messenger: 'MESSENGER',
  WhatsApp: 'WHATSAPP',
  PhoneCall: 'PHONE_CALL',
  OnPost: 'ON_POST',
  OnPage: 'ON_PAGE',
  OnEvent: 'ON_EVENT',
  OnVideo: 'ON_VIDEO',
  OnAd: 'ON_AD',
  LeadFromMessenger: 'LEAD_FROM_MESSENGER',
  LeadFromIGDirect: 'LEAD_FROM_IG_DIRECT',
  Website: 'WEBSITE',
  Undefined: 'UNDEFINED',
  App: 'APP',
};

export const fbCampaignCategories = [
  'HOUSING',
  'CREDIT',
  'EMPLOYMENT',
  'ISSUES_ELECTIONS_POLITICS',
  'NONE',
];

export const FBBillingEvent = {
  AppInstalls: 'APP_INSTALLS',
  Clicks: 'CLICKS',
  Impressions: 'IMPRESSIONS',
  LinkClicks: 'LINK_CLICKS',
  None: 'NONE',
  OfferClaims: 'OFFER_CLAIMS',
  PageLikes: 'PAGE_LIKES',
  PostEngagement: 'POST_ENGAGEMENT',
  ThruPlay: 'THRUPLAY',
  Purchase: 'PURCHASE',
  ListingInteraction: 'LISTING_INTERACTION',
  TwoSecondContinuousVideoViews: 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
};

export const fbAdSetBillingEvents = [
  'APP_INSTALLS',
  'CLICKS',
  'IMPRESSIONS',
  'LINK_CLICKS',
  'NONE',
  'OFFER_CLAIMS',
  'PAGE_LIKES',
  'POST_ENGAGEMENT',
  'THRUPLAY',
  'PURCHASE',
  'LISTING_INTERACTION',
  'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
];

export const FBOptimizationGoal = {
  None: 'NONE',
  AppInstalls: 'APP_INSTALLS',
  AdRecallLift: 'AD_RECALL_LIFT',
  EngagedUsers: 'ENGAGED_USERS',
  EventResponses: 'EVENT_RESPONSES',
  Impressions: 'IMPRESSIONS',
  LeadGeneration: 'LEAD_GENERATION',
  QualityLead: 'QUALITY_LEAD',
  LinkClicks: 'LINK_CLICKS',
  OffsiteConversions: 'OFFSITE_CONVERSIONS',
  PageLikes: 'PAGE_LIKES',
  PostEngagement: 'POST_ENGAGEMENT',
  QualityCall: 'QUALITY_CALL',
  Reach: 'REACH',
  LandingPageViews: 'LANDING_PAGE_VIEWS',
  VisitInstagramProfile: 'VISIT_INSTAGRAM_PROFILE',
  Value: 'VALUE',
  ThruPlay: 'THRUPLAY',
  DerivedEvents: 'DERIVED_EVENTS',
  AppInstallsAndOffsiteConversions: 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS',
  Conversations: 'CONVERSATIONS',
  InAppValue: 'IN_APP_VALUE',
  MessagingPurchaseConversion: 'MESSAGING_PURCHASE_CONVERSION',
  MessagingAppointmentConversion: 'MESSAGING_APPOINTMENT_CONVERSION',
};

export const fbAdSetOptimizationGoal = [
  'NONE',
  'APP_INSTALLS',
  'AD_RECALL_LIFT',
  'ENGAGED_USERS',
  'EVENT_RESPONSES',
  'IMPRESSIONS',
  'LEAD_GENERATION',
  'QUALITY_LEAD',
  'LINK_CLICKS',
  'OFFSITE_CONVERSIONS',
  'PAGE_LIKES',
  'POST_ENGAGEMENT',
  'QUALITY_CALL',
  'REACH',
  'LANDING_PAGE_VIEWS',
  'VISIT_INSTAGRAM_PROFILE',
  'VALUE',
  'THRUPLAY',
  'DERIVED_EVENTS',
  'APP_INSTALLS_AND_OFFSITE_CONVERSIONS',
  'CONVERSATIONS',
  'IN_APP_VALUE',
  'MESSAGING_PURCHASE_CONVERSION',
  'MESSAGING_APPOINTMENT_CONVERSION',
];

export const fbTargetSearchTypes = [
  'geo_locations',
  'gender',
  'age_min',
  'age_max',
  'interests',
  'behaviors',
  'life_events',
  'industries',
  'income',
  'family_statuses',
  'user_device',
  'user_os',
  'wireless_carrier',
  'relationship_statuses',
  'education_schools',
  'education_statuses',
  'college_years',
  'education_majors',
  'work_employers',
  'work_positions',
];

export const fbGeoLocationTypes = [
  'countries',
  'regions',
  'cities',
  'zips',
  'custom_locations',
  'geo_markets',
  'electoral_district',
  'location_types',
  'country_groups',
];

export const targetSearchTypes = [
  'country',
  'region',
  'city',
  'zip',
  'geo_market',
  'electoral_district',
  'country_group',
  'behaviors',
  'life_events',
  'industries',
  'income',
  'family_statuses',
  'user_device',
  'user_os',
  'education_school',
  'education_major',
  'employer',
  'work_position',
  'interest',
];

export const targetLocationTypes = ['recent', 'home', 'travel_in'];

export const targetRelationshipStatuses = [
  { id: 1, name: 'single' },
  { id: 2, name: 'in_relationship' },
  { id: 3, name: 'married' },
  { id: 4, name: 'engaged' },
  { id: 6, name: 'not specified' },
  { id: 7, name: 'in a civil union' },
  { id: 8, name: 'in a domestic partnership' },
  { id: 9, name: 'In an open relationship' },
  { id: 10, name: "It's complicated" },
  { id: 11, name: 'Separated' },
  { id: 12, name: 'Divorced' },
  { id: 13, name: 'Widowed' },
];

export const targetEducationStatuses = [
  { id: 1, name: 'HIGH_SCHOOL' },
  { id: 2, name: 'UNDERGRAD' },
  { id: 3, name: 'ALUM' },
  { id: 4, name: 'HIGH_SCHOOL_GRAD' },
  { id: 5, name: 'SOME_COLLEGE' },
  { id: 6, name: 'ASSOCIATE_DEGREE' },
  { id: 7, name: 'IN_GRAD_SCHOOL' },
  { id: 8, name: 'SOME_GRAD_SCHOOL' },
  { id: 9, name: 'MASTER_DEGREE' },
  { id: 10, name: 'PROFESSIONAL_DEGREE' },
  { id: 11, name: 'DOCTORATE_DEGREE' },
  { id: 12, name: 'UNSPECIFIED' },
  { id: 13, name: 'SOME_HIGH_SCHOOL' },
];

export const listOfYear = () => {
  let years = [];
  const currentYear = moment().format('YYYY');
  const dateStart = moment().set('year', 1980);
  while (parseInt(dateStart.format('YYYY')) <= parseInt(currentYear)) {
    years.push(parseInt(dateStart.format('YYYY')));
    dateStart.add(1, 'year');
  }

  return years;
};

export const FbAdStatus = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  DELETED: 'DELETED',
  ARCHIVED: 'ARCHIVED',
  WITH_ISSUES: 'WITH_ISSUES',
  PENDING_REVIEW: 'PENDING_REVIEW',
  DISAPPROVED: 'DISAPPROVED',
  PREAPPROVED: 'PREAPPROVED',
  PENDING_BILLING_INFO: 'PENDING_BILLING_INFO',
  CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
  ADSET_PAUSED: 'ADSET_PAUSED',
  IN_PROCESS: 'IN_PROCESS',
  PROCESSING_PAYMENT: 'PROCESSING_PAYMENT',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
};

export const fbAdsStatuses = [
  FbAdStatus.ACTIVE,
  FbAdStatus.PAUSED,
  FbAdStatus.DELETED,
  FbAdStatus.ARCHIVED,
];

export const buildDestinationTypes = (objective: string) => {
  if (objective === FBCampaignObjective.Traffic) {
    return ['MESSENGER', 'WHATSAPP', 'PHONE_CALL', 'UNDEFINED'];
  }

  if (objective === FBCampaignObjective.Engagement) {
    return ['ON_POST', 'ON_PAGE', 'ON_EVENT', 'ON_VIDEO', 'MESSENGER'];
  }

  if (objective === FBCampaignObjective.Leads) {
    return [
      'ON_AD',
      'LEAD_FROM_MESSENGER',
      'LEAD_FROM_IG_DIRECT',
      'PHONE_CALL',
      'UNDEFINED',
    ];
  }

  if (objective === FBCampaignObjective.Sales) {
    return ['WEBSITE', 'MESSENGER', 'PHONE_CALL', 'UNDEFINED'];
  }

  return ['UNDEFINED'];
};

export const buildOptimizationGoal = (
  objective: string,
  destinationType: string,
) => {
  /** TRAFFIC */
  if (
    objective === FBCampaignObjective.Traffic &&
    [FBDestinationType.Messenger, FBDestinationType.WhatsApp].includes(
      destinationType,
    )
  ) {
    return ['LINK_CLICKS', 'REACH', 'IMPRESSIONS'];
  }

  if (
    objective === FBCampaignObjective.Traffic &&
    destinationType === FBDestinationType.PhoneCall
  ) {
    return ['QUALITY_CALL', 'LINK_CLICKS'];
  }

  if (
    objective === FBCampaignObjective.Traffic &&
    destinationType === FBDestinationType.Undefined
  ) {
    return ['LINK_CLICKS', 'LANDING_PAGE_VIEWS', 'REACH', 'IMPRESSIONS'];
  }

  /** ENGAGEMENT */
  if (
    objective === FBCampaignObjective.Engagement &&
    destinationType === FBDestinationType.OnPost
  ) {
    return ['POST_ENGAGEMENT', 'REACH', 'IMPRESSIONS'];
  }

  if (
    objective === FBCampaignObjective.Engagement &&
    destinationType === FBDestinationType.OnPage
  ) {
    return ['PAGE_LIKES'];
  }

  if (
    objective === FBCampaignObjective.Engagement &&
    destinationType === FBDestinationType.OnEvent
  ) {
    return ['EVENT_RESPONSES', 'POST_RESPONSES', 'REACH', 'IMPRESSIONS'];
  }

  if (
    objective === FBCampaignObjective.Engagement &&
    destinationType === FBDestinationType.OnVideo
  ) {
    return ['THRUPLAY', 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS'];
  }

  if (
    objective === FBCampaignObjective.Engagement &&
    destinationType === FBDestinationType.Messenger
  ) {
    return ['CONVERSATIONS', 'LINK_CLICKS', 'LEAD_GENERATION'];
  }

  /** LEADS */

  if (
    objective === FBCampaignObjective.Leads &&
    destinationType === FBDestinationType.OnAd
  ) {
    return ['LEAD_GENERATION', 'QUALITY_LEAD'];
  }

  if (
    objective === FBCampaignObjective.Leads &&
    [
      FBDestinationType.LeadFromMessenger,
      FBDestinationType.LeadFromIGDirect,
    ].includes(destinationType)
  ) {
    return ['LEAD_GENERATION'];
  }

  if (
    (objective === FBCampaignObjective.Leads &&
      destinationType === FBDestinationType.PhoneCall) ||
    (objective === FBCampaignObjective.Sales &&
      destinationType === FBDestinationType.PhoneCall)
  ) {
    return ['QUALITY_CALL'];
  }

  if (
    [FBCampaignObjective.Sales, FBCampaignObjective.Leads].includes(
      objective,
    ) &&
    destinationType === FBDestinationType.Undefined
  ) {
    return [
      'OFFSITE_CONVERSIONS',
      'LINK_CLICKS',
      'REACH',
      'LANDING_PAGE_VIEWS',
      'IMPRESSIONS',
    ];
  }

  /** SALES */
  if (
    objective === FBCampaignObjective.Sales &&
    destinationType === FBDestinationType.Undefined
  ) {
    return ['OFFSITE_CONVERSIONS'];
  }

  if (
    objective === FBCampaignObjective.Sales &&
    destinationType === FBDestinationType.Messenger
  ) {
    return ['CONVERSATIONS'];
  }

  if (
    objective === FBCampaignObjective.Sales &&
    destinationType === FBDestinationType.Website
  ) {
    return ['LINK_CLICKS'];
  }

  /** AWARENESS */
  if (
    objective === FBCampaignObjective.Awareness &&
    destinationType === FBDestinationType.Undefined
  ) {
    return ['REACH'];
  }

  return [];
};

export const buildBillingEvents = (optimizationGoal: string) => {
  if (optimizationGoal === FBOptimizationGoal.LinkClicks) {
    return [FBBillingEvent.LinkClicks, FBBillingEvent.Impressions];
  }

  if (optimizationGoal === FBOptimizationGoal.ThruPlay) {
    return [FBBillingEvent.ThruPlay, FBBillingEvent.Impressions];
  }

  return [FBBillingEvent.Impressions];
};

/**
 * @reference https://www.facebook.com/business/help/410873986524407
 * @param objective
 * @param placement
 * @returns
 */
export const buildCallToActions = (objective: string, placement: string) => {
  if (objective === FBCampaignObjective.Traffic) {
    return [
      CallToActionType.APPLY_NOW,
      CallToActionType.CONTACT_US,
      CallToActionType.DONATE_NOW,
      CallToActionType.DOWNLOAD,
      CallToActionType.GET_OFFER,
      CallToActionType.GET_QUOTE,
      CallToActionType.GET_SHOWTIMES,
      CallToActionType.LEARN_MORE,
      CallToActionType.LISTEN_NOW,
      CallToActionType.ORDER_NOW,
      CallToActionType.REQUEST_TIME,
      CallToActionType.SHOP_NOW,
      CallToActionType.SIGN_UP,
      CallToActionType.SUBSCRIBE,
      CallToActionType.WATCH_MORE,
    ];
  }

  if (objective === FBCampaignObjective.Engagement) {
    return [
      CallToActionType.APPLY_NOW,
      CallToActionType.CONTACT_US,
      CallToActionType.DONATE_NOW,
      CallToActionType.GET_PROMOTIONS,
      CallToActionType.GET_QUOTE,
      CallToActionType.LEARN_MORE,
      CallToActionType.ORDER_NOW,
      CallToActionType.SEND_MESSAGE,
      CallToActionType.SHOP_NOW,
      CallToActionType.SIGN_UP,
      CallToActionType.SUBSCRIBE,
    ];
  }

  return [
    CallToActionType.SUBSCRIBE,
    CallToActionType.APPLY_NOW,
    CallToActionType.DOWNLOAD,
    CallToActionType.GET_OFFER,
    CallToActionType.GET_QUOTE,
    CallToActionType.LEARN_MORE,
    CallToActionType.SIGN_UP,
  ];
};

export const LeadFormQuestionType = {
  CUSTOM: 'CUSTOM',
  CITY: 'CITY',
  COMPANY_NAME: 'COMPANY_NAME',
  COUNTRY: 'COUNTRY',
  DOB: 'DOB',
  EMAIL: 'EMAIL',
  GENDER: 'GENDER',
  FIRST_NAME: 'FIRST_NAME',
  FULL_NAME: 'FULL_NAME',
  JOB_TITLE: 'JOB_TITLE',
  LAST_NAME: 'LAST_NAME',
  MARITIAL_STATUS: 'MARITIAL_STATUS',
  PHONE: 'PHONE',
  PHONE_OTP: 'PHONE_OTP',
  POST_CODE: 'POST_CODE',
  PROVINCE: 'PROVINCE',
  RELATIONSHIP_STATUS: 'RELATIONSHIP_STATUS',
  STATE: 'STATE',
  STREET_ADDRESS: 'STREET_ADDRESS',
  ZIP: 'ZIP',
  WORK_EMAIL: 'WORK_EMAIL',
  MILITARY_STATUS: 'MILITARY_STATUS',
  WORK_PHONE_NUMBER: 'WORK_PHONE_NUMBER',
  STORE_LOOKUP: 'STORE_LOOKUP',
  STORE_LOOKUP_WITH_TYPEAHEAD: 'STORE_LOOKUP_WITH_TYPEAHEAD',
  DATE_TIME: 'DATE_TIME',
  ID_CPF: 'ID_CPF',
  ID_AR_DNI: 'ID_AR_DNI',
  ID_CL_RUT: 'ID_CL_RUT',
  ID_CO_CC: 'ID_CO_CC',
  ID_EC_CI: 'ID_EC_CI',
  ID_PE_DNI: 'ID_PE_DNI',
  ID_MX_RFC: 'ID_MX_RFC',
  JOIN_CODE: 'JOIN_CODE',
  FACEBOOK_LEAD_ID: 'FACEBOOK_LEAD_ID',
};

export const buildLeadQuestionTypes = () => {
  const values = Object.values(LeadFormQuestionType);

  return values.filter((value: string) => {
    return value !== LeadFormQuestionType.CUSTOM;
  });
};

export const IMAGE_FILTER_OPTIONS = [
  {
    type: 'Aspect Ratio',
    options: [
      // Width x Height
      // 1920 x 1080
      { option: 'full_horizontal', label: 'Fullscreen horizontal (16:9)' },
      // 1080 x 1920
      { option: 'full_vertical', label: 'Fullscreen vertical (9:16)' },
      // 1080 x 1350
      { option: 'vertical', label: 'Vertical (4:5)' },
      // Width === Height
      { option: 'square', label: 'Square (1:1)' },
    ],
  },
  {
    type: 'Height',
    options: [
      { option: 'height_600', label: '600 pixels or more' },
      { option: 'height_1080', label: '1080 pixels or more' },
    ],
  },
  {
    type: 'Width',
    options: [
      { option: 'width_600', label: '600 pixels or more' },
      { option: 'width_1080', label: '1080 pixels or more' },
    ],
  },
];

export const VIDEO_FILTER_OPTIONS = [
  {
    type: 'Length',
    options: [
      { option: 'seconds_15', label: 'Less than 15 seconds' },
      { option: 'seconds_60', label: 'Less than 60 seconds' },
      { option: 'seconds_60_more', label: '60 seconds or more' },
    ],
  },
  {
    type: 'Aspect Ratio',
    options: [
      // Width x Height
      // 1920 x 1080
      { option: 'full_horizontal', label: 'Fullscreen horizontal (16:9)' },
      // 1080 x 1920
      { option: 'full_vertical', label: 'Fullscreen vertical (9:16)' },
      // 1080 x 1350
      { option: 'vertical', label: 'Vertical (4:5)' },
      // Width === Height
      { option: 'square', label: 'Square (1:1)' },
    ],
  },
  {
    type: 'Height',
    options: [
      { option: 'height_600_less', label: 'Less than 600 pixels' },
      { option: 'height_600_more', label: '600 pixels or more' },
      { option: 'height_1080_more', label: '1080 pixels or more' },
    ],
  },
  {
    type: 'Width',
    options: [
      { option: 'width_600_less', label: 'Less than 600 pixels' },
      { option: 'width_600_more', label: '600 pixels or more' },
      { option: 'width_1080_more', label: '1080 pixels or more' },
    ],
  },
];

export const adVideoFiltering = (filters: VideoFilters, videos: AdVideo[]) => {
  const noFilter = Object.keys(filters).filter(
    (video: string) => filters[video] === true,
  );

  let data: AdVideo[] = [];

  if (noFilter.length > 0) {
    if (filters.seconds_15) {
      const filtered = videos.filter((video: AdVideo) => video.length < 15);

      data = [...data, ...filtered];
    }

    if (filters.seconds_60) {
      const filtered = videos.filter((video: AdVideo) => video.length < 60);

      data = [...data, ...filtered];
    }

    if (filters.seconds_60_more) {
      const filtered = videos.filter((video: AdVideo) => video.length >= 60);

      data = [...data, ...filtered];
    }

    if (filters.full_horizontal) {
      const filtered = videos.filter(
        (video: AdVideo) =>
          video.thumbnails.data[0].height === 1080 &&
          video.thumbnails.data[0].width === 1920,
      );

      data = [...data, ...filtered];
    }

    if (filters.full_vertical) {
      const filtered = videos.filter(
        (video: AdVideo) =>
          video.thumbnails.data[0].height === 1920 &&
          video.thumbnails.data[0].width === 1080,
      );

      data = [...data, ...filtered];
    }

    if (filters.vertical) {
      const filtered = videos.filter(
        (video: AdVideo) =>
          video.thumbnails.data[0].height === 1350 &&
          video.thumbnails.data[0].width === 1080,
      );

      data = [...data, ...filtered];
    }

    if (filters.square) {
      const filtered = videos.filter(
        (video: AdVideo) =>
          video.thumbnails.data[0].height === video.thumbnails.data[0].width,
      );

      data = [...data, ...filtered];
    }

    if (filters.height_600_less) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].height < 600,
      );

      data = [...data, ...filtered];
    }

    if (filters.height_600_more) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].height >= 600,
      );

      data = [...data, ...filtered];
    }

    if (filters.height_1080_more) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].height >= 1080,
      );

      data = [...data, ...filtered];
    }

    if (filters.width_600_less) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].width < 600,
      );

      data = [...data, ...filtered];
    }

    if (filters.width_600_more) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].width >= 600,
      );

      data = [...data, ...filtered];
    }

    if (filters.width_1080_more) {
      const filtered = videos.filter(
        (video: AdVideo) => video.thumbnails.data[0].width >= 1080,
      );

      data = [...data, ...filtered];
    }

    return arrayObjectUniqueFilter(data, 'id');
  } else {
    return videos;
  }
};

export const adImageFiltering = (filters: ImageFilters, images: AdImage[]) => {
  const noFilter = Object.keys(filters).filter(
    (image: any) => filters[image] === true,
  );

  let data: AdImage[] = [];

  if (noFilter.length > 0) {
    if (filters.width_600) {
      const filtered = images.filter((image: AdImage) => image.width >= 600);

      data = [...data, ...filtered];
    }

    if (filters.width_1080) {
      const filtered = images.filter((image: AdImage) => image.width >= 1080);

      data = [...data, ...filtered];
    }

    if (filters.height_600) {
      const filtered = images.filter((image: AdImage) => image.height >= 600);

      data = [...data, ...filtered];
    }

    if (filters.height_1080) {
      const filtered = images.filter((image: AdImage) => image.height >= 1080);

      data = [...data, ...filtered];
    }

    if (filters.square) {
      const filtered = images.filter(
        (image: AdImage) => image.height === image.width,
      );

      data = [...data, ...filtered];
    }

    if (filters.full_horizontal) {
      const filtered = images.filter(
        (image: AdImage) => image.height === 1080 && image.width === 1920,
      );

      data = [...data, ...filtered];
    }

    if (filters.full_vertical) {
      const filtered = images.filter((image: AdImage) => {
        const verticalImageRatio = image.width / image.height;
        const verticalAspectRatio = 9 / 16;
        const negTolerance = verticalAspectRatio - verticalAspectRatio * 0.05;
        const posTolerance = verticalAspectRatio + verticalAspectRatio * 0.05;
        const isFullVertical =
          verticalImageRatio >= negTolerance &&
          verticalImageRatio <= posTolerance;

        return isFullVertical;
      });

      data = [...data, ...filtered];
    }

    if (filters.vertical) {
      const filtered = images.filter(
        (image: AdImage) => image.height === 1350 && image.width === 1080,
      );

      data = [...data, ...filtered];
    }

    return arrayObjectUniqueFilter(data, 'id');
  } else {
    return images;
  }
};
