import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Salesperson } from '../../../types';
import { humanizeString } from '../../../utils/stringModifier';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const mobileColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerClassName: styles.header,
      headerName: 'ID',
      width: 50,
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { name } = params.row;

        return <div className={styles.name}>{name}</div>;
      },
    },
    {
      field: 'invite',
      headerClassName: styles.header,
      headerName: 'Invite',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const { handleOnClickInviteBtn, name, email, _id, role, withUser } =
          params.row;

        return (
          <span
            className={`${styles.invite} ${
              withUser ? `${styles['-disabled']}` : ''
            }`}
            onClick={(e) => {
              if (withUser) return;

              e.stopPropagation();
              handleOnClickInviteBtn({ name, email, _id, role });
            }}
          >
            Invite
          </span>
        );
      },
    },
    {
      field: 'action',
      headerClassName: styles.header,
      headerName: 'Action',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const { handleOnClickEdit, handleOnClickDelete } = params.row;

        const salesperson: Salesperson = {
          name: params.row.name,
          firstname: params.row.firstname,
          lastname: params.row.lastname,
          email: params.row.email,
          cell: params.row.cell,
          broker: params.row.broker,
          investment: params.row.investment,
          role: params.row.role,
          user: params.row.user,
          _id: params.row._id,
          active: params.row.active,
        };

        return (
          <div>
            <IconButton
              onClick={() => handleOnClickEdit(salesperson)}
              size="small"
            >
              <ModeEditOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'green' }}
              />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => {
                handleOnClickDelete(params.row._id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'red' }}
              />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return columns;
};

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerClassName: styles.header,
    headerName: 'ID',
    width: 50,
    disableColumnMenu: true,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'name',
    headerClassName: styles.header,
    headerName: 'Name',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: any) => {
      const { name } = params.row;

      return <div className={styles.name}>{name}</div>;
    },
  },
  {
    field: 'email',
    headerClassName: styles.header,
    headerName: 'Email',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: any) => {
      const { email } = params.row;

      return <div className={styles.name}>{email}</div>;
    },
  },
  {
    field: 'role',
    headerClassName: styles.header,
    headerName: 'Role',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: any) => {
      const { role } = params.row;

      return <div className={styles.name}>{humanizeString(role)}</div>;
    },
  },
  {
    field: 'invite',
    headerClassName: styles.header,
    headerName: 'Invite',
    hideSortIcons: true,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      const { handleOnClickInviteBtn, name, email, _id, role, withUser } =
        params.row;

      return (
        <span
          className={`${styles.invite} ${
            withUser ? `${styles['-disabled']}` : ''
          }`}
          onClick={(e) => {
            if (withUser) return;

            e.stopPropagation();
            handleOnClickInviteBtn({ name, email, _id, role });
          }}
        >
          Invite
        </span>
      );
    },
  },
  {
    field: 'action',
    headerClassName: styles.header,
    headerName: 'Action',
    hideSortIcons: true,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      const { handleOnClickEdit, handleOnClickDelete } = params.row;

      const salesperson: Salesperson = {
        name: params.row.name,
        firstname: params.row.firstname,
        lastname: params.row.lastname,
        email: params.row.email,
        cell: params.row.cell,
        broker: params.row.broker,
        investment: params.row.investment,
        role: params.row.role,
        user: params.row.user,
        _id: params.row._id,
        active: params.row.active,
      };

      return (
        <div>
          <IconButton
            onClick={() => handleOnClickEdit(salesperson)}
            size="small"
          >
            <ModeEditOutlineOutlinedIcon
              sx={{ fontSize: '18px', color: 'green' }}
            />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => {
              handleOnClickDelete(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon
              sx={{ fontSize: '18px', color: 'red' }}
            />
          </IconButton>
        </div>
      );
    },
  },
];
