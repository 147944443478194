import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import styles from '../../../../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../../../../PageHeader';
import LinearProgressBarWithLabel from '../../../../ProgressBar/LinearProgressBarWithLabel';
import { PrimaryButton } from '../../../../Buttons';
import {
  Brand,
  ChangeEventType,
  Country,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  LeadFormData,
  LeadNotification,
  LeadNotificationBodyField,
} from '../../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessType } from '../../../../../utils/helpers/BrandHelpers';
import { DiySettings } from '../../../../../types/IDiy';
import { getSingleDiySetting } from '../../../../../services/diy';
import LeadFormQuestionFieldsForm from './LeadFormQuestionFieldsForm';
import {
  ThankYouPageButtonType,
  buildDefaultFormQuestions,
  buildDefaultNotificationFields,
  buildNotificationBodyFields,
  getQuestionKeys,
} from '../../../../../utils/helpers/LeadHelpers';
import LeadFormPrivacyPolicyForm from './LeadFormPrivacyPolicyForm';
import LeadFormThankYouPageForm from './LeadFormThankYouPageForm';
import LeadFormDetailsForm from './LeadFormDetailsForm';
import {
  createLeadForms,
  getDefaultLeadNotification,
  getFormLeadNotification,
  getSingleLeadForms,
  sendTestLeadZapier,
} from '../../../../../services/lead';
import { phoneNoSpaceFormat } from '../../../../../utils/phoneNumberFormatter';
import { errorMessageParser } from '../../../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../../../redux/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LeadFormCustomQuestionForm from './LeadFormCustomQuestionForm';
import LeadFormPrefillQuestionForm from './LeadFormPrefillQuestionForm';
import { useForm } from 'react-hook-form';
import { topfireMediaId } from '../../../../../services/api';
import CircularLoading from '../../../../CircularLoading';
import LeadFormNotification from './LeadFormNotification';
import { adsProvider } from '../../../../../utils/constants/facebookAds';
import { DEFAULT_TAG, TFM_TAG } from '../../../../../utils';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { HtmlTooltip } from '../../../../Tooltip';
import {
  securedUrlPattern,
  validZapierWebhookUrl,
} from '../../../../../utils/validation';
import LeadFormQualifyingQuestions from './LeadFormQualifyingQuestions';

const FacebookLeadForm: React.FC = () => {
  const params = useParams();
  const leadFormId = params.leadFormId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isDuplicate = pathNames.includes('duplicate');
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider =
    useSelector((state: any) => state?.campaignProvider?.campaignProvider) ||
    adsProvider.FACEBOOK;
  const isTFM =
    ((location || brand)?.__type === 'location'
      ? (location || brand)?.franchisor?.agency?._id
      : (location || brand)?.agency?._id) === topfireMediaId;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
    unregister,
    setValue,
    setError,
  } = useForm({
    mode: 'onSubmit',
  });

  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<string>('');
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [formValues, setFormValues] = useState<FBLeadGenForm>({
    cta: ThankYouPageButtonType.VIEW_WEBSITE,
    brandId: (location || brand)?._id,
    allow_organic_lead: true,
    block_display_for_non_targeted_viewer: false,
    name: `${isTFM ? TFM_TAG : DEFAULT_TAG}${
      (location || brand)?.__type === 'location'
        ? `-${(location || brand)?.brand}`
        : ''
    }-Leads-v1`,
    privacy_policy: {
      url: '',
      link_text: 'Privacy Policy',
    },
    question_page_custom_headline:
      'A representative will reach out to you soon',
    questions: [
      ...((location || brand)?.businessType === BusinessType.FRANDEV
        ? buildDefaultFormQuestions(location || brand)
        : []),
      { type: 'EMAIL' },
      { type: 'PHONE' },
      { type: 'FIRST_NAME' },
      { type: 'LAST_NAME' },
      { type: 'CITY' },
      { type: 'STATE' },
    ],
    locale: 'EN_US',
    is_optimized_for_quality: false,
    thank_you_page: {
      title: `Thanks, you're all set.`,
      body: 'You can visit our website or exit the form now.',
      button_type: ThankYouPageButtonType.VIEW_WEBSITE,
      button_text: 'Visit Website',
    },
    enableSendLead: true,
    enableZapierWebhook: false,
  });
  const [selectedCountry, setSelectedCountry] = useState<Country>({
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  });
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchBrandLoading, setFetchBrandLoading] = useState<boolean>(false);
  const [leadFormLoading, setLeadFormLoading] = useState<boolean>(false);
  const [leadNotificationFormValues, setLeadNotificationFormValues] =
    useState<LeadNotification>({
      recipients: [],
      cc: [],
      bcc: [],
      subject: `${isTFM ? 'TopFire Media' : 'Sales Chatz'} Facebook Lead: ${
        (location || brand)?.__type === 'location'
          ? `${(location || brand)?.franchisor?.brand}-${
              (location || brand)?.brand
            }`
          : (location || brand)?.brand
      }`,
      bodyFields: [],
      brand: {
        _id: '',
      },
      zapierWebhookUrl: '',
      enableZapierWebhook: false,
      default: false,
    });
  const [testZapierLoading, setTestZapierLoading] = useState<boolean>(false);
  const [defaultNotification, setDefaultNotification] =
    useState<LeadNotification>(null);
  const [defaultBodyFields, setDefaultBodyFields] = useState<
    LeadNotificationBodyField[]
  >([]);
  const [defaultBodyFieldsErrors, setDefaultBodyFieldsErrors] = useState<any[]>(
    [],
  );
  const [showClearDefaultFields, setShowClearDefaultFields] =
    useState<boolean>(false);
  const [defaultZapierErrors, setDefaultZapierErrors] = useState<string[]>([]);
  const [defaultNotificationLoading, setDefaultNotificationLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (isDuplicate && leadFormId && !fetchLoading && !fetchBrandLoading) {
      getLeadForm();
    }
  }, [isDuplicate, leadFormId, fetchLoading, fetchBrandLoading]);

  useEffect(() => {
    if (location || brand) {
      getDiySettings(location || brand);
    }
  }, [location, brand]);

  useEffect(() => {
    if ((location || brand) && activeStep === 'Lead Notification') {
      getDefaultNotification();
    }
  }, [location, brand, activeStep]);

  useEffect(() => {
    if ((location || brand)?.__type === 'location' && !fetchLoading) {
      getBrandDiySettings((location || brand)?.franchisor);
    }
  }, [location, brand, fetchLoading]);

  useEffect(() => {
    const step = steps.find((value: string, index: number) => {
      return index === activeStepIndex;
    });

    setActiveStep(step);
  }, [activeStepIndex]);

  useEffect(() => {
    if (steps.length > 0) {
      const step = steps.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [steps, activeStepIndex]);

  useEffect(() => {
    let temp: FBLeadGenForm = { ...formValues };

    if (defaultNotification?.bodyFields?.length > 0) {
      temp = { ...temp, enableSendLead: true };
    }

    if (defaultNotification?.zapierWebhookUrl) {
      temp = { ...temp, enableZapierWebhook: true };
    }

    setFormValues(temp);
  }, [defaultNotification]);

  useEffect(() => {
    if (defaultZapierErrors.length > 0) {
      const fields = defaultZapierErrors.join(', ');
      const errorMsg = `Your Zapier Webhook URL might not work due to missing fields (${fields}). Update your Zapier Webhook or create a new one.`;
      setError('zapierWebhookUrl', { type: 'custom', message: errorMsg });
    }
  }, [defaultZapierErrors]);

  useEffect(() => {
    const customQuestions = formValues?.questions?.filter(
      (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
    );

    setSteps(buildSteps(customQuestions));
  }, [formValues?.questions]);

  const getLeadForm = async () => {
    setLeadFormLoading(true);
    try {
      const response = await getSingleLeadForms(leadFormId);

      const data: LeadFormData = response.data;

      const {
        privacy_policy,
        questions,
        thank_you_page,
        question_page_custom_headline,
        block_display_for_non_targeted_viewer,
        locale,
        allow_organic_lead,
        is_optimized_for_quality,
      } = data.fields;

      setFormValues({
        ...formValues,
        follow_up_action_url:
          data?.cta === ThankYouPageButtonType.SCHEDULE_CALL
            ? diySettings?.defaultCalendarUrl || ''
            : diySettings?.defaultUrl || '',
        name: `${isTFM ? TFM_TAG : DEFAULT_TAG}${
          (location || brand)?.__type === 'location'
            ? `-${(location || brand)?.brand}`
            : ''
        }-Leads-v${response.data.version + 1}`,
        cta: data.cta,
        description: data.description,
        privacy_policy,
        questions,
        thank_you_page: {
          ...thank_you_page,
          business_phone_number:
            phoneNoSpaceFormat((location || brand)?.phoneNumber) || '',
          button_type:
            thank_you_page?.button_type ||
            data?.cta === ThankYouPageButtonType.CALL_BUSINESS
              ? data?.cta
              : ThankYouPageButtonType.VIEW_WEBSITE,
          website_url:
            data?.cta === ThankYouPageButtonType.SCHEDULE_CALL
              ? diySettings?.defaultCalendarUrl || ''
              : diySettings?.defaultUrl || '',
        },
        question_page_custom_headline,
        block_display_for_non_targeted_viewer,
        locale,
        allow_organic_lead,
        is_optimized_for_quality,
        baseTemplate: data?._id,
      });

      if (data) {
        getLeadNotification(data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLeadFormLoading(false);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    setFetchLoading(true);

    try {
      const response = await getSingleDiySetting(brand?._id);

      setFormValues({
        ...formValues,
        follow_up_action_url: response?.data?.defaultUrl,
        thank_you_page: {
          ...formValues?.thank_you_page,
          website_url: response?.data?.defaultUrl,
          business_phone_number: phoneNoSpaceFormat(
            (location || brand)?.phoneNumber || '',
          ),
          country_code: '+1',
        },
        privacy_policy: {
          ...formValues?.privacy_policy,
          url:
            brand?.__type === 'brand'
              ? response.data?.defaultPrivacyPolicyUrl || ''
              : '',
        },
      });

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    try {
      setFetchBrandLoading(true);
      const response = await getSingleDiySetting(brand?._id);

      setFormValues({
        ...formValues,
        privacy_policy: {
          ...formValues?.privacy_policy,
          url: response.data?.defaultPrivacyPolicyUrl || '',
        },
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchBrandLoading(false);
    }
  };

  const getLeadNotification = async (form: LeadFormData) => {
    try {
      const response = await getFormLeadNotification(
        form?._id,
        (location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor?._id
          : (location || brand)?._id,
      );

      const data: LeadNotification = response.data;

      if (data) {
        const {
          recipients,
          cc,
          bcc,
          subject,
          bodyFields,
          zapierWebhookUrl,
          enableZapierWebhook,
        } = data as LeadNotification;

        setLeadNotificationFormValues({
          recipients,
          cc,
          bcc,
          subject,
          bodyFields,
          brand: {
            _id: (location || brand)?._id,
          },
          zapierWebhookUrl,
          enableZapierWebhook,
          default: false,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getDefaultNotification = async () => {
    try {
      setDefaultNotificationLoading(true);

      const fallbackBodyFields = buildNotificationBodyFields(
        formValues?.questions || [],
        campaignProvider,
      );

      const response = await getDefaultLeadNotification(
        (location || brand)?._id,
      );

      if (response.data) {
        setDefaultBodyFields(fallbackBodyFields);
        setShowClearDefaultFields(true);

        let temp: LeadNotification = {
          ...leadNotificationFormValues,
          recipients: [],
          subject: `${isTFM ? 'TopFire Media' : 'Sales Chatz'} Facebook Lead: ${
            (location || brand)?.__type === 'location'
              ? `${(location || brand)?.franchisor?.brand}-${
                  (location || brand)?.brand
                }`
              : (location || brand)?.brand
          }`,
        };

        const questionkeys = getQuestionKeys(formValues?.questions || []);

        const { bodyFields, errors } = buildDefaultNotificationFields(
          response.data.bodyFields,
          questionkeys,
          setValue,
        );

        setDefaultBodyFieldsErrors(errors);

        temp = {
          ...temp,
          bodyFields,
          subject: response.data.subject,
          bcc: response.data.bcc || [],
        };

        if (response.data.zapierWebhookUrl) {
          const defaultNotificationLeadForm = await getSingleLeadForms(
            response.data.leadForm,
          );

          setValue(`zapierWebhookUrl`, response.data.zapierWebhookUrl);

          temp = {
            ...temp,
            zapierWebhookUrl: response.data.zapierWebhookUrl,
          };

          if (defaultNotificationLeadForm.data) {
            const defaultLeadFormQuestionKeys = getQuestionKeys(
              defaultNotificationLeadForm.data.fields.questions,
            );

            const errors = defaultLeadFormQuestionKeys.filter(
              (key: any) => !questionkeys.includes(key),
            );

            if (errors.length > 0) {
              const zapierErrors = errors.map((error: any) => {
                const placeholders = error.match(/<<[^>]+>>/g) || [];

                const cleanedPlaceholders = placeholders.map(
                  (placeholder: any) => placeholder.replace(/<<|>>/g, ''),
                );

                return cleanedPlaceholders;
              });

              setDefaultZapierErrors(zapierErrors);
            }
          }
        }

        setLeadNotificationFormValues(temp);
      } else {
        setLeadNotificationFormValues({
          ...leadNotificationFormValues,
          bodyFields: fallbackBodyFields,
        });
      }

      setDefaultNotification(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setDefaultNotificationLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      let temp: FBLeadGenForm = { ...formValues };

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.VIEW_WEBSITE
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.SCHEDULE_CALL
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
            button_type: ThankYouPageButtonType.VIEW_WEBSITE,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS
      ) {
        temp = {
          ...temp,
          follow_up_action_url: null,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: `+${selectedCountry?.phone}${temp?.thank_you_page?.business_phone_number}`,
            country_code: selectedCountry?.code,
          },
        };
      }

      await createLeadForms(temp, leadNotificationFormValues);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Lead form created successfully',
        }),
      );

      navigate('/assetz');
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleTestZapier = async () => {
    setTestZapierLoading(true);
    try {
      const response = await sendTestLeadZapier(
        (location || brand)?._id,
        leadNotificationFormValues,
        formValues?.questions,
      );

      dispatch(toggleAlert({ toggle: true, message: response.data.message }));
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setTestZapierLoading(false);
    }
  };

  const handleNext = () => {
    if (
      activeStep === 'Thank You Page' &&
      formValues?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS
    ) {
      setFormValues({
        ...formValues,
        thank_you_page: {
          ...formValues?.thank_you_page,
          business_phone_number: phoneNoSpaceFormat(
            formValues?.thank_you_page?.business_phone_number,
          ),
        },
      });
    }

    setActiveStepIndex((prev) => prev + 1);
    setProgress((prevProgress) => prevProgress + (1 / steps.length) * 100);
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress((prevProgress) => prevProgress - (1 / steps.length) * 100);
  };

  const buildSteps = (customQuestions: FBLeadGenQuestionForm[]) => {
    let steps: string[] = ['Custom Questions', 'Prefill Questions'];

    if (customQuestions?.length > 0) {
      steps = [...steps, 'Qualifying Questions'];
    }

    steps = [
      ...steps,
      'Privacy Policy',
      'Thank You Page',
      'Lead Notification',
      'Form Details',
    ];

    return steps;
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'Custom Questions':
        title = 'PLEASE SETUP YOUR CUSTOM QUESTIONS';
        break;
      case 'Prefill Questions':
        title = 'PLEASE SETUP YOUR PREFILL QUESTIONS';
        break;
      case 'Custom Fields':
        title = `ADD QUESTION'S CUSTOM FIELD NAMES`;
        break;
      case 'Qualified Lead Fields':
        title = 'SET YOUR QUALIFIED LEAD FIELDS';
        break;
      case 'Privacy Policy':
        title = 'ENTER YOUR PRIVACY POLICY LINK';
        break;
      case 'Thank You Page':
        title = 'SET YOUR ENDING ACTIONS';
        break;
      case 'Lead Notification':
        title = 'SET YOUR LEAD NOTIFICATION';
        break;
      case 'Qualifying Questions':
        title = 'PLEASE CHOOSE YOUR LEAD QUALIFYING QUESTIONS';
        break;
      default:
        title = 'FORM DETAILS';
        break;
    }

    return title;
  };

  const renderContent = () => {
    if (
      activeStep === 'Custom Questions' &&
      !fetchLoading &&
      !fetchBrandLoading
    ) {
      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      const prefilledQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      const multipleChoiceQuestions = customQuestions?.filter(
        (question: FBLeadGenQuestionForm) => question?.options?.length > 0,
      );

      const shortAnswerQuestions = customQuestions?.filter(
        (question: FBLeadGenQuestionForm) => !(question?.options?.length > 0),
      );

      return (
        <LeadFormCustomQuestionForm
          prefilledQuestions={prefilledQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          unregister={unregister}
          clearErrors={clearErrors}
          onValidated={handleSubmit}
          multipleChoiceQuestions={multipleChoiceQuestions}
          shortAnswerQuestions={shortAnswerQuestions}
        />
      );
    }

    if (activeStep === 'Prefill Questions') {
      const prefilledQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      return (
        <LeadFormPrefillQuestionForm
          prefillQuestions={prefilledQuestions}
          customQuestions={customQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }

    if (activeStep === 'Qualifying Questions') {
      const prefilledQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      return (
        <LeadFormQualifyingQuestions
          formValues={formValues}
          setFormValues={setFormValues}
          customQuestions={customQuestions}
          prefilledQuestions={prefilledQuestions}
        />
      );
    }

    if (activeStep === 'Custom Fields') {
      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      const prefillQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      return (
        <LeadFormQuestionFieldsForm
          customQuestions={customQuestions}
          prefillQuestions={prefillQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      );
    }

    if (activeStep === 'Privacy Policy') {
      return (
        <LeadFormPrivacyPolicyForm
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }

    if (activeStep === 'Thank You Page') {
      return (
        <LeadFormThankYouPageForm
          formValues={formValues}
          setFormValues={setFormValues}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          diySettings={diySettings}
          errors={errors}
          register={register}
          unregister={unregister}
          clearErrors={clearErrors}
          brand={location || brand}
        />
      );
    }

    if (activeStep === 'Lead Notification') {
      return (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ color: '#096F4D' }}>
                    Setup your email notification
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="style"
                      name="style"
                      value={formValues?.enableSendLead ? 'yes' : 'no'}
                      onChange={(e: ChangeEventType) => {
                        setFormValues({
                          ...formValues,
                          enableSendLead: e.target.value === 'yes',
                        });
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {formValues?.enableSendLead && !defaultNotificationLoading ? (
            <LeadFormNotification
              formValues={leadNotificationFormValues}
              setFormValues={setLeadNotificationFormValues}
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              questions={formValues?.questions || []}
              brand={location || brand}
              setValue={setValue}
              setErrors={setError}
              handleSubmit={handleSubmit}
              diySettings={diySettings}
              setDiySettings={setDiySettings}
              defaultNotification={defaultNotification}
              errorFields={defaultBodyFieldsErrors}
              setErrorFields={setDefaultBodyFieldsErrors}
              defaultBodyFields={defaultBodyFields}
              showClearDefaultFields={showClearDefaultFields}
              setShowClearDefaultFields={setShowClearDefaultFields}
            />
          ) : null}

          <Grid container spacing={2} my={2}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      justifyContent: 'flex-start',
                      color: '#096F4D',
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                    }}
                  >
                    {'Setup your Zapier Webhook URL'}

                    <HtmlTooltip
                      title={
                        <Fragment>
                          <Box component="div" sx={{ fontWeight: 'bold' }}>
                            Zapier Catch Hook URL
                          </Box>

                          <Box component="div" sx={{ marginBottom: '10px' }}>
                            The Zapier Webhook URL is a special web address that
                            helps you manage information from Facebook. When
                            someone fills out a form on Facebook, Facebook sends
                            the form details to this special address. Zapier can
                            then take this information and do things
                            automatically for you, like adding the details to a
                            spreadsheet, sending you an email, or updating your
                            customer records. This makes it easier for you to
                            keep track of new leads and ensures you don't miss
                            any important information.
                          </Box>

                          <Box component="div" sx={{ marginBottom: '10px' }}>
                            <a
                              href="https://help.zapier.com/hc/en-us/articles/8496288690317-Trigger-Zaps-from-webhooks"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Learn More
                            </a>
                          </Box>
                        </Fragment>
                      }
                    >
                      <InfoTwoToneIcon
                        sx={{ fontSize: '18px', color: '#096F4D' }}
                      />
                    </HtmlTooltip>
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="style"
                      name="style"
                      value={formValues?.enableZapierWebhook ? 'yes' : 'no'}
                      onChange={(e: ChangeEventType) => {
                        setFormValues({
                          ...formValues,
                          enableZapierWebhook: e.target.value === 'yes',
                        });
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {formValues?.enableZapierWebhook ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      {...(formValues?.enableZapierWebhook
                        ? register('zapierWebhookUrl', {
                            required: true,
                            pattern: securedUrlPattern && validZapierWebhookUrl,
                          })
                        : {})}
                      fullWidth
                      required
                      variant="standard"
                      type="text"
                      name="zapierWebhookUrl"
                      label="Zapier Webhook URL"
                      onChange={(e: ChangeEventType) => {
                        clearErrors('zapierWebhookUrl');
                        setDefaultZapierErrors([]);
                        setLeadNotificationFormValues({
                          ...leadNotificationFormValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={leadNotificationFormValues?.zapierWebhookUrl}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      error={!!errors.zapierWebhookUrl}
                      helperText={
                        errors?.zapierWebhookUrl?.message ||
                        (errors?.zapierWebhookUrl &&
                          'Website URL is required') ||
                        'Enter your Zapier Catch Webhook URL'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PrimaryButton
                      title="Test Zapier Webhook"
                      type="button"
                      handleOnClick={handleSubmit(handleTestZapier)}
                      loading={testZapierLoading}
                      disabled={defaultZapierErrors.length > 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {formValues?.enableSendLead || formValues?.enableZapierWebhook ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          defaultChecked={leadNotificationFormValues?.default}
                        />
                      }
                      label="Set as default"
                      value={leadNotificationFormValues?.default}
                      onChange={(e: any) => {
                        setLeadNotificationFormValues({
                          ...leadNotificationFormValues,
                          default: e.target.checked,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </>
      );
    }

    if (activeStep === 'Form Details') {
      return (
        <LeadFormDetailsForm
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading
        loading={fetchLoading || leadFormLoading || defaultNotificationLoading}
      />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={12}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          {renderContent()}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            paddingY: '20px',
          }}
        >
          {activeStepIndex > 0 ? (
            <PrimaryButton
              title="Back"
              type="button"
              handleOnClick={handleBack}
              marginRight5
              variant="text"
              color="red"
            />
          ) : null}

          <PrimaryButton
            loading={activeStepIndex + 1 === steps.length && loading}
            title={activeStepIndex + 1 === steps.length ? 'Submit' : 'Next'}
            type="button"
            handleOnClick={
              activeStepIndex + 1 === steps.length
                ? handleSubmit(handleOnSubmit)
                : handleSubmit(handleNext)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FacebookLeadForm;
