import { Card, CardHeader, IconButton } from '@mui/material';
import React, { ReactNode } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
interface BillingProfileCardProps {
  field: string;
  title: string;
  value: any;
  icon: ReactNode;
  onClick?: (field: string) => void;
}

const BillingProfileCard: React.FC<BillingProfileCardProps> = ({
  field,
  value,
  icon,
  onClick,
  title,
}) => {
  return (
    <Card>
      <CardHeader
        avatar={icon}
        title={title}
        titleTypographyProps={{ variant: 'subtitle2' }}
        subheader={value}
        action={
          onClick ? (
            <IconButton onClick={() => onClick(field)}>
              <ChevronRightIcon />
            </IconButton>
          ) : null
        }
      />
    </Card>
  );
};

export default BillingProfileCard;
