import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import { IconContext } from 'react-icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { buildCardBrand, renderCCIcon } from './PaymentMethodHelpers';

interface PaymentMethodCardProps {
  payment: any;
  defaultPaymentMethodId: string;
  onSetDefault: (paymentMethodId: string) => void;
  customer: any;
  index: number;
  onEdit: (paymentMethod: string) => void;
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  payment,
  defaultPaymentMethodId,
  onSetDefault,
  customer,
  index,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isDefault = payment.id === defaultPaymentMethodId;

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        sx={{ pb: 0 }}
        avatar={
          <IconContext.Provider value={{ size: '40px' }}>
            {renderCCIcon(payment.card.brand)}
          </IconContext.Provider>
        }
        action={
          <IconButton
            aria-label="more"
            id={`card-button-${index}`}
            aria-controls={open ? `card-menu-${index}` : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
        }
      />

      <Menu
        id={`card-menu-${index}`}
        MenuListProps={{
          'aria-labelledby': `card-button-${index}`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {isDefault ? null : (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onSetDefault(payment.id);
            }}
          >
            Set As Default
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleCloseMenu();
            onEdit(payment);
          }}
        >
          Edit
        </MenuItem>
      </Menu>

      <CardContent sx={{ py: 0 }}>
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          sx={{ mb: 1.5 }}
        >
          {`${buildCardBrand(payment.card)} ●●●● ${payment.card.last4}`}
        </Typography>

        <Typography color="text.secondary">
          {`Expires ${payment.card.exp_month}/${payment.card.exp_year}`}
        </Typography>

        <Typography color="text.secondary">
          {`${customer?.address.line1} ${customer?.address.line2}, ${customer?.address.city}, ${customer?.address.state}, ${customer?.address.postal_code} ${customer?.address.country}`}
        </Typography>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        {isDefault ? (
          <Chip label="Default" size="small" color="primary" />
        ) : (
          <Chip
            label="Backup Payment"
            size="small"
            sx={{ color: 'white', backgroundColor: 'darkgray' }}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default PaymentMethodCard;
