import React from 'react';
import { Box, Typography } from '@mui/material';
import { ChatUser } from '../../types';
import { DEFAULT_COLOR_THEME } from '../../utils';

interface DialogHeaderProps {
  chat: ChatUser;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ chat }) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        background: '#fff',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottom: `1px solid ${DEFAULT_COLOR_THEME}`,
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={1}
      px={2}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 'bold', color: '#484848' }}
      >
        Chat
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 'bold', color: '#484848' }}
      >
        {chat?.name} - {chat?.brand.brand}
      </Typography>
    </Box>
  );
};

export default DialogHeader;
