import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { StripeCustomerBalanceCredit } from '../../../types';
import momentTz from 'moment-timezone';
import { creditBalanceColumns } from './CreditBalanceListTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';

interface CreditBalanceListTableProps {
  creditBalances: StripeCustomerBalanceCredit[];
  loading: boolean;
}

const CreditBalanceListTable: React.FC<CreditBalanceListTableProps> = ({
  creditBalances,
  loading,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rows = creditBalances.map((balance: StripeCustomerBalanceCredit) => {
    return {
      id: balance.id,
      type: balance.type,
      description: balance.description,
      date: momentTz
        .unix(balance.created)
        .tz(timezone)
        .format('MM/DD/YYYY HH:mm A'),
      amount: balance.amount,
    };
  }, []);

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={creditBalanceColumns(isMdAndDown)}
        autoHeight
        getRowHeight={() => 50}
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default CreditBalanceListTable;
