import { Box, Modal } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { clientBaseUrl, stripePublicKey } from '../../services/api';
import StripePaymentUIForm from '../Forms/Payment/StripePaymentUIForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { DEFAULT_COLOR_THEME, IS_DIY_ADZ } from '../../utils';

interface StripePaymentUIModalProps {
  open: boolean;
  onClose: () => void;
  clientSecret: string;
  transactionType: string;
  allowRedirect?: boolean;
}

const stripePromise = loadStripe(stripePublicKey);

const StripePaymentUIModal: React.FC<StripePaymentUIModalProps> = ({
  open,
  onClose,
  clientSecret,
  transactionType,
  allowRedirect = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  const buildRedirectUrl = () => {
    const path = window.location.pathname;

    if (transactionType === 'setup') {
      return `/${
        IS_DIY_ADZ ? 'billingz' : 'billings'
      }/payment-method/added?path=${path}&transaction=${transactionType}`;
    }

    return `/${
      IS_DIY_ADZ ? 'billingz' : 'billings'
    }/payment-success?path=${path}&transaction=${transactionType}`;
  };

  const handleSubmitPayment = async (stripe: any, elements: any) => {
    try {
      if (!stripe || !elements) {
        return;
      }

      setLoading(true);

      if (transactionType === 'payment') {
        const response = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: clientBaseUrl,
          },
          redirect: 'if_required',
        });

        if (response.error) {
          if (
            response.error.type === 'card_error' ||
            response.error.type === 'validation_error'
          ) {
            setLoading(false);
            dispatch(
              toggleAlert({
                toggle: true,
                message: response.error.message,
                type: 'error',
              }),
            );
          } else {
            setLoading(false);
            dispatch(
              toggleAlert({
                toggle: true,
                message: response.error.message,
                type: 'error',
              }),
            );
          }
        } else {
          setLoading(false);
          if (allowRedirect) {
            navigate(buildRedirectUrl());
          } else {
            onClose();
          }
        }
      } else if (transactionType === 'setup') {
        const { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: clientBaseUrl,
          },
          redirect: 'if_required',
        });

        console.log(error);

        if (error) {
          dispatch(
            toggleAlert({
              toggle: true,
              message: error.message,
              type: 'error',
            }),
          );
        } else {
          setLoading(false);
          if (allowRedirect) {
            navigate(buildRedirectUrl());
          } else {
            onClose();
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '500px',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            // background: "#F1F0F0",
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: DEFAULT_COLOR_THEME,
            borderRadius: '18px',

            '&:hover': {
              background: DEFAULT_COLOR_THEME,
            },
          },
        }}
      >
        {clientSecret ? (
          <Elements options={options as any} stripe={stripePromise}>
            <StripePaymentUIForm
              clientSecret={clientSecret}
              onPay={handleSubmitPayment}
              loading={loading}
              transactionType={transactionType}
            />
          </Elements>
        ) : null}
      </Box>
    </Modal>
  );
};

export default StripePaymentUIModal;
