import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  AdsAccountFormFields,
  Brand,
  ChangeEventType,
  FbAdsAccountData,
  IFacebookUser,
} from '../../../types';
import { fetchFbAdsAccount } from '../../../services/ads';
import { adsProvider } from '../../../utils/constants/facebookAds';
import { DiySettings } from '../../../types/IDiy';
import Spinner from '../../Spinner';
import { PrimaryButton } from '../../Buttons';
import { getOffsetLabel } from '../../../utils/timezones';

interface DiyAdsAccountFormProps {
  brand: Brand;
  isEdit?: boolean;
  formValues: Brand;
  setFormValues: Dispatch<SetStateAction<Brand>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  facebookAccount: IFacebookUser;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  userId: string;
  loading: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  setBusinessId: Dispatch<SetStateAction<string>>;
}

const DiyAdsAccountForm: React.FC<DiyAdsAccountFormProps> = ({
  brand,
  isEdit = false,
  formValues,
  setFormValues,
  setLoading,
  disabled,
  facebookAccount,
  diySettings,
  setDiySettings,
  userId,
  loading,
  setIsDirty,
  businessId,
  setBusinessId,
}) => {
  const [fbAdsAccounts, setFbAdsAccounts] = useState<AdsAccountFormFields[]>(
    [],
  );

  useEffect(() => {
    if (formValues?.facebookBusinessIds?.length > 0) {
      getFbAdsAccounts(brand, userId);
      setBusinessId(formValues?.facebookBusinessIds[0] || '');
    }
  }, [formValues?.facebookBusinessIds]);

  const getFbAdsAccounts = async (brand: Brand, userId: string) => {
    try {
      setLoading(true);

      const response = await fetchFbAdsAccount(
        formValues?.facebookBusinessIds,
        formValues?._id,
        userId,
      );

      if (response?.data) {
        const newFormvalues = response.data.map(
          (adsAccount: FbAdsAccountData) => {
            return {
              accountId: adsAccount.account_id,
              name: adsAccount.name,
              currency: adsAccount.currency,
              provider: adsProvider.FACEBOOK,
              timezone: adsAccount.timezone_name,
              timezoneUtcOffset: adsAccount.timezone_offset_hours_utc,
            };
          },
        );

        setFbAdsAccounts(newFormvalues);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnAddBusinessId = () => {
    setIsDirty(true);
    let temp: Brand = {
      ...formValues,
      facebookBusinessIds: [businessId],
    };
    setFormValues(temp);

    if (temp?.facebookBusinessIds?.length === 0) {
      setDiySettings({
        ...diySettings,
        facebookPage: null,
        instagramAccount: null,
      });
    }
  };

  const handleOnSelectAdAccount = (value: any) => {
    setIsDirty(true);
    setDiySettings({
      ...diySettings,
      fbAdAccount: value,
    });
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            size="small"
            type="text"
            id="facebookBusinessIds"
            name="facebookBusinessIds"
            label="Facebook Business ID"
            onChange={(e: ChangeEventType) => setBusinessId(e.target.value)}
            defaultValue={null}
            value={businessId || ''}
            InputLabelProps={{ shrink: true }}
            helperText={
              <Fragment>
                <Box
                  component="a"
                  target="_blank"
                  href="https://m.facebook.com/help/1181250022022158"
                >
                  Where can I find my Facebook business ID?
                </Box>
              </Fragment>
            }
            autoFocus
            sx={{
              '& .MuiInputBase-root': {
                '&:has(> input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                },
              },
            }}
            InputProps={{
              sx: { borderRadius: '49px' },
              endAdornment: (
                <InputAdornment position="end">
                  <PrimaryButton
                    type="button"
                    variant="text"
                    title="Connect"
                    handleOnClick={handleOnAddBusinessId}
                    disabled={disabled}
                  />
                </InputAdornment>
              ),
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {fbAdsAccounts?.length > 0 &&
          formValues?.facebookBusinessIds?.length > 0 ? (
            <Autocomplete
              id="adsAccount"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.accountId}>
                    {option.name}
                  </li>
                );
              }}
              options={fbAdsAccounts.sort(
                (a, b) => -b.name.localeCompare(a.name),
              )}
              value={fbAdsAccounts?.find((account: AdsAccountFormFields) => {
                return (
                  account.accountId === diySettings?.fbAdAccount?.accountId
                );
              })}
              getOptionLabel={(option) =>
                `${option?.name} (${option?.accountId})`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Ad Accounts"
                  placeholder="Select Your Ads Accounts"
                  helperText={
                    diySettings?.fbAdAccount?.timezone ? (
                      <Fragment>
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          component="div"
                        >
                          Timezone:
                          <Typography variant="caption" display="inline" ml={1}>
                            {`${getOffsetLabel(
                              diySettings?.fbAdAccount?.timezoneUtcOffset,
                            )} ${diySettings?.fbAdAccount?.timezone}`}
                          </Typography>
                        </Typography>

                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          component="div"
                        >
                          Currency:
                          <Typography variant="caption" display="inline" ml={1}>
                            {`${diySettings?.fbAdAccount?.currency}`}
                          </Typography>
                        </Typography>
                      </Fragment>
                    ) : null
                  }
                />
              )}
              onChange={async (e: any, value: any, reason: string) => {
                let selectedAccount: any = null;
                if (reason !== 'clear') {
                  selectedAccount = value;
                }

                handleOnSelectAdAccount(selectedAccount);
              }}
              disabled={disabled}
            />
          ) : isEdit && loading ? (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <Spinner />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default DiyAdsAccountForm;
