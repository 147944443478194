import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { toCurrency } from '../../../utils/numberFormatter';

export const templateOveviewColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'template',
      headerClassName: styles.header,
      headerName: 'Template Name',
      width: 300,
      renderCell: (params: any) => {
        const { template } = params.row;

        return (
          <div className={styles.name}>
            <Tooltip
              title={<Typography variant="body2">{template}</Typography>}
              disableInteractive
            >
              {template}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'spend',
      headerClassName: styles.header,
      headerName: 'Spend',
      flex: 1,
      valueGetter: (_, row) => row.spend,
      renderCell: (params: any) => {
        const { spend } = params.row;

        return <div className={styles.name}>{toCurrency('USD', spend)}</div>;
      },
    },
    {
      field: 'leads',
      headerClassName: styles.header,
      headerName: 'Leads',
      flex: 1,
      renderCell: (params: any) => {
        const { leads } = params.row;

        return <div className={styles.name}>{leads}</div>;
      },
    },
    {
      field: 'cpl',
      headerClassName: styles.header,
      headerName: 'CPL',
      flex: 1,
      valueGetter: (_, row) => row.cpl,
      renderCell: (params: any) => {
        const { cpl } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      field: 'visitors',
      headerClassName: styles.header,
      headerName: 'Visitors',
      flex: 1,
      renderCell: (params: any) => {
        const { visitors } = params.row;

        return <div className={styles.name}>{visitors}</div>;
      },
    },
    {
      field: 'cpv',
      headerClassName: styles.header,
      headerName: 'CPV',
      flex: 1,
      valueGetter: (_, row) => row.cpv,
      renderCell: (params: any) => {
        const { cpv } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpv)}</div>;
      },
    },
    {
      field: 'usageCount',
      headerClassName: styles.header,
      headerName: 'Usage Count',
      flex: 1,
      renderCell: (params: any) => {
        const { usageCount } = params.row;

        return <div className={styles.name}>{usageCount}</div>;
      },
    },
    {
      field: 'averageSpend',
      headerClassName: styles.header,
      headerName: 'AVG Spend',
      flex: 1,
      valueGetter: (_, row) => row.averageSpend,
      renderCell: (params: any) => {
        const { averageSpend } = params.row;

        return (
          <div className={styles.name}>{toCurrency('USD', averageSpend)}</div>
        );
      },
    },
  ];

  return columns;
};
