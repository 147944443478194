import { Box } from '@mui/material';
import React from 'react';
import { ScoreboardScatterBreakdown } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { breakdownColumns } from './ScatterChartBreakdownTableColumns';
import ModalHeader from '../../Modal/ModalHeader';
import { DataGridPremium } from '@mui/x-data-grid-premium';

interface ScatterChartBreakdownTableProps {
  breakdown: ScoreboardScatterBreakdown[];
  isLead: boolean;
}

const ScatterChartBreakdownTable: React.FC<ScatterChartBreakdownTableProps> = ({
  breakdown,
  isLead,
}) => {
  const rows = breakdown.map((breakdown: ScoreboardScatterBreakdown) => {
    return {
      id: breakdown.templateId,
      template: breakdown.template,
      spend: breakdown.spend,
      leads: breakdown.leads || 0,
      visitors: breakdown.visitors || 0,
      cpl: breakdown.cpl,
      cpv: breakdown.cpv,
    };
  });

  return (
    <Box sx={{ width: '500px' }}>
      <ModalHeader title="Template Report" />

      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={breakdownColumns(isLead)}
        autoHeight
        hideFooterSelectedRowCount
      />
    </Box>
  );
};

export default ScatterChartBreakdownTable;
