declare let FB: any;

const endpoint =
  'me?fields=accounts.limit(1000){picture{url},access_token,id,name}';

/**
 * Fetch All Allowed Facebook Page
 * @description Fetch all the Facebook Pages that you allowed during Facebook Login
 * @author Angelo David <angelod@codev.com>
 * @since 1.2.4
 * @reference https://developers.facebook.com/docs/javascript/reference/FB.api/
 */
export const fetchFBPages = (token: string, requestType: string) => {
  return new Promise((resolve, reject) => {
    FB.api(endpoint, requestType, { access_token: token }, (response: any) => {
      if (!response || response.error) {
        reject(response.error);
      } else if (!response.accounts && requestType === 'default') {
        reject('No Pages');
      } else if (!response.data && requestType === 'page') {
        reject('No Pages');
      } else {
        if (requestType === 'get') {
          resolve({
            data: response.accounts.data,
            pagination: response.accounts.paging,
          });
        }
        if (requestType === 'page') {
          resolve({ data: response.data, pagination: response.paging });
        }
      }
    });
  });
};
