import React, { useState, useContext, FormEvent, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { ChangeEventType } from '../../types';
import { facebookLogin, googleLogin, login } from '../../services/user';
import {
  LOGGED_IN_USER,
  AUTH_TOKEN,
  GOOGLE_LOGIN_SCOPES,
  IS_DIY_ADZ,
} from '../../utils';
import setAuthToken from '../../services/axios-client';
import { signIn } from '../../components/Auth/FacebookLoginProvider';
import { isExpired, resetSession } from '../../utils/tokenValidator';
import styles from '../../assets/styles/pages/Login.module.scss';
import { useGoogleLogin } from '@react-oauth/google';
import LoginForm from '../../components/Forms/Payment/Auth/LoginForm';
import { useDispatch } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBrandTimezone,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
  toggleAlert,
} from '../../redux/actions';
import ReactPlayer from 'react-player/lazy';
import splashScreen from '../../assets/videos/splash-screen.mp4';
import jwtDecode from 'jwt-decode';
import { Box } from '@mui/material';

const Login: React.FC = () => {
  const data = useParams();
  const role = data.role;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chatId: string = searchParams.get('chat');
  const brandId: string = searchParams.get('brandId');
  const loginRedirectUrl: string = searchParams.get('redirectUrl');

  const { state: ctxState, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const redirectUrl = ctxState?.authUser?.redirectUrl;
  const reduxDispatch = useDispatch();
  const authToken = localStorage.getItem(AUTH_TOKEN);

  useEffect(() => {
    if (authToken) {
      if (isExpired(authToken)) {
        resetSession();
        resetReduxSession();
        navigate('/login', { replace: true });
      } else {
        if (IS_DIY_ADZ) {
          setLoggingIn(true);
          setTimeout(() => {
            setLoggingIn(false);
            redirectAction();
          }, 3900);
        } else {
          redirectAction();
        }
      }
    }
  }, [authToken]);

  const redirectAction = () => {
    if (chatId && brandId) {
      navigate(`/chats/dialog?chat=${chatId}&brandId=${brandId}`, {
        replace: true,
      });
    } else {
      navigate(IS_DIY_ADZ && !loginRedirectUrl ? '/scorecardz' : redirectUrl, {
        replace: true,
      });
    }
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    login({
      email,
      password,
      role,
      diy: IS_DIY_ADZ,
      loginRedirectUrl,
    })
      .then(async (data) => {
        const decode: any = jwtDecode(data.token);
        dispatch({
          type: LOGGED_IN_USER,
          //TODO: need provide only the info that we get letter on from lg token
          payload: {
            token: data.token,
            role: data.user.role,
            roleBasedId: data.user.roleBasedId,
            capabilities: decode.capabilities,
            withAgencyAccess: decode.withAgencyAccess,
            withBrandAccess: decode.withBrandAccess,
            user: {
              id: data.user._id,
              email: data.user.email,
              page: data.page,
              brand: data.brand,
              redirectUrl: data.redirectUrl,
              name: data.name,
              accessToken: decode?.accessToken,
              imageUrl: decode?.imageUrl,
            },
          },
        });

        localStorage.setItem(AUTH_TOKEN, data.token);
        setAuthToken(data.token);
        reduxDispatch(toggleAlert({ toggle: true, message: data.message }));

        /* TODO: Temporary disable
        try {
          const serviceWorker = await registerWorker();
          await subscribe(serviceWorker, data.user._id);
        } catch (error: any) {
          console.log(error.message);
        }
        */

        setLoading(false);

        return data.redirectUrl;
      })
      .then((redirectUrl) => {
        setLoggingIn(true);
        setTimeout(() => {
          setLoggingIn(false);
          navigate(
            IS_DIY_ADZ && !loginRedirectUrl ? '/scorecardz' : redirectUrl,
            {
              replace: true,
            },
          );
        }, 7000);

        setLoading(false);
      })
      .catch((error: any) => {
        const err = error.response?.data;
        setErrors({ ...errors, email: err?.email, password: err?.password });
        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: err?.message || 'Invalid Credentials',
            type: 'error',
            horizontal: 'center',
            vertical: 'top',
          }),
        );

        setLoading(false);
      });
  };

  const handleOnChange = (e: ChangeEventType) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
  };

  const handleFbLogin = () => {
    signIn()
      .then(async (response: any) => {
        setLoading(true);
        if (response.status === 'connected') {
          try {
            const token = response.authResponse.accessToken;

            const data = await facebookLogin(token, role, IS_DIY_ADZ);
            if (data) {
              dispatch({
                type: LOGGED_IN_USER,
                //TODO: need provide only the info that we get letter on from lg token
                payload: {
                  token: data.token,
                  role: data.user.role,
                  roleBasedId: data.user.roleBasedId,
                  user: {
                    id: data.user._id,
                    email: data.user.email,
                    accessToken: data.user.accessToken,
                    imageUrl: data.user.imageUrl,
                    name: data.user.name,
                  },
                },
              });
              localStorage.setItem(AUTH_TOKEN, data.token);
              setAuthToken(data.token);
              reduxDispatch(
                toggleAlert({
                  toggle: true,
                  message: data.message,
                }),
              );
              navigate(data.redirectUrl, { replace: true });
            }
          } catch (error: any) {
            setLoading(false);
            const err = error.response.data;
            reduxDispatch(
              toggleAlert({
                toggle: true,
                message: err?.message,
                type: 'error',
                horizontal: 'center',
                vertical: 'top',
              }),
            );
          }
        }

        setLoading(false);
      })
      .catch((error: any) => {
        // TODO: Add Facebook Login Error Parser
        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: error.message,
            type: 'error',
            horizontal: 'center',
            vertical: 'top',
          }),
        );
      });
  };

  const handleGoogleLogin = async (response: any) => {
    setLoading(true);
    try {
      const data = await googleLogin(response.code, role, IS_DIY_ADZ);

      if (data) {
        dispatch({
          type: LOGGED_IN_USER,
          //TODO: need provide only the info that we get letter on from lg token
          payload: {
            token: data.token,
            role: data.user.role,
            roleBasedId: data.user.roleBasedId,
            user: {
              id: data.user._id,
              email: data.user.email,
              refreshToken: data.user.refreshToken,
              imageUrl: data.user.imageUrl,
              name: data.user.name,
            },
          },
        });
        localStorage.setItem(AUTH_TOKEN, data.token);
        setAuthToken(data.token);
        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
        navigate(data.redirectUrl, { replace: true });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      reduxDispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
          horizontal: 'center',
          vertical: 'top',
        }),
      );
    }
  };

  const handleOnGoogleLogin = useGoogleLogin({
    onSuccess: (response) => handleGoogleLogin(response),
    onError: (response) => console.log(response),
    flow: 'auth-code',
    scope: GOOGLE_LOGIN_SCOPES,
  });

  const resetReduxSession = () => {
    reduxDispatch(setBrands([]));
    reduxDispatch(setBrand(null));
    reduxDispatch(setBrandTimezone(null));
    reduxDispatch(setAgencies([]));
    reduxDispatch(setAgency(null));
    reduxDispatch(setBrandFilter(null));
    reduxDispatch(setCampaignProvider(null));
    reduxDispatch(setBudgetReportProvider(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setLocation(null));
  };

  return (
    <>
      {IS_DIY_ADZ && loggingIn ? (
        <Box
          component="div"
          sx={{
            position: 'relative',
            paddingTop: '56.25%',
          }}
        >
          {splashScreen ? (
            <ReactPlayer
              url={splashScreen}
              playing
              height="calc(100vh - 60px)"
              width="100vw"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                marginLeft: 'calc(50% - 50vw)',
              }}
              volume={0.2}
            />
          ) : null}
        </Box>
      ) : (
        <div className={styles.login}>
          <LoginForm
            onSubmit={handleOnSubmit}
            loading={loading}
            onGoogleLogin={handleOnGoogleLogin}
            onFacebookLogin={handleFbLogin}
            onChange={handleOnChange}
            email={email}
            password={password}
            role={role}
            errors={errors}
          />
        </div>
      )}
    </>
  );
};

export default Login;
