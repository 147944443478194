import axios from 'axios';
import momentTz, { Moment } from 'moment-timezone';
import {
  AdBudget,
  BudgetFormFields,
  BudgetReport,
  BudgetSyncFields,
} from '../types';
import { apiBaseUrl } from './api';

export const createBudget = async (obj: AdBudget) => {
  const data = await axios.post(`${apiBaseUrl}/budget`, obj);

  return data?.data;
};

export const createChatzBudget = async (obj: BudgetFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/budget/create`, obj);

  return data?.data;
};

export const fetchAllBudgetsByPage = async (pageId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget/chatz?pageId=${pageId}&filter=active`,
  );

  return data?.data;
};

export const fetchAiAllBudgets = async (year: string, provider: string) => {
  const data = await axios.get(`${apiBaseUrl}/budget`, {
    params: { year, provider },
  });

  return data?.data;
};

export const fetchAiAllBudgetsByAgency = async (
  agencyId: string,
  year: string,
  provider: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/budget`, {
    params: {
      agencyId,
      filter: 'active',
      provider,
      year,
    },
  });

  return data?.data;
};

export const fetchAllBudgets = async () => {
  const data = await axios.get(`${apiBaseUrl}/budget/chatz?filter=active`);

  return data?.data;
};

export const fetchAllBudgetsByAgency = async (agencyId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget/chatz?agencyId=${agencyId}&filter=active`,
  );

  return data?.data;
};

export const fetchAllBudgetsBySalesperson = async (
  salespersonId: string,
  isBrand?: boolean,
) => {
  let params: any = { salespersonId, filter: 'active' };

  if (isBrand) {
    params = { ...params, type: 'location' };
  }
  const data = await axios.get(`${apiBaseUrl}/budget/chatz`, { params });

  return data?.data;
};

export const fetchAllBudgetsByBrandManager = async (
  managerId: string,
  isBrand?: boolean,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget/chatz?managerId=${managerId}&filter=active${
      isBrand ? '&type=location' : ''
    }`,
  );

  return data?.data;
};

export const fetchAllBudgetsByLocations = async (franchisorId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget/chatz?franchisorId=${franchisorId}&filter=active`,
  );

  return data?.data;
};

export const getBrandBudget = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/budget/${brandId}`);

  return data?.data;
};

export const getBudgetReportByPage = async (
  pageId: string,
  month: string,
  year: string,
  accessToken: string,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(`${apiBaseUrl}/budget/report`, {
    params: {
      pageId,
      month,
      year,
      token: accessToken,
      timezone,
      filter: 'active',
    },
  });

  return data?.data;
};

export const getAiBudgetReport = async (
  month: string,
  year: string,
  provider: string,
  diy: boolean,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(`${apiBaseUrl}/budget/report`, {
    params: {
      month,
      year,
      timezone,
      provider,
      diy,
    },
  });

  return data?.data;
};

export const getAiBudgetReportByAgency = async (
  agencyId: string,
  month: string,
  year: string,
  provider: string,
  diy: boolean,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(`${apiBaseUrl}/budget/report`, {
    params: {
      month,
      year,
      timezone,
      provider,
      diy,
      agencyId,
    },
  });

  return data?.data;
};

export const getBudgetReport = async (
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report/chatz?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportBySalesperson = async (
  salespersonId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  diy: boolean,
  isBrand?: boolean,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report/chatz?startDate=${startDate}&endDate=${endDate}${
      isBrand ? '&type=location' : ''
    }`,
    {
      params: {
        salespersonId,
        month,
        year,
        timezone,
        refreshToken,
        filter: 'active',
        provider,
        isReport,
        diy,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByAgency = async (
  agencyId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report/chatz?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        agencyId,
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByManager = async (
  managerId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  diy: boolean,
  isBrand?: boolean,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report/chatz?startDate=${startDate}&endDate=${endDate}${
      isBrand ? '&type=location' : ''
    }`,
    {
      params: {
        managerId,
        month,
        year,
        refreshToken,
        timezone,
        filter: 'active',
        provider,
        isReport,
        diy,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByLocation = async (
  franchisorId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = momentTz.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report/chatz?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        franchisorId,
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const syncBudget = async (obj: BudgetSyncFields) => {
  const data = await axios.post(`${apiBaseUrl}/budget/sync`, obj);

  return data?.data;
};

export const syncChatzBudget = async (obj: BudgetSyncFields) => {
  const data = await axios.post(`${apiBaseUrl}/budget/sync/chatz`, obj);

  return data?.data;
};

export const getSingleAdBudget = async (
  brandId: string,
  month: number,
  year: number,
  provider: string,
  adsetId?: string,
  campaignId?: string,
) => {
  let params: any = { brandId, month, year, provider };

  if (adsetId) {
    params = { ...params, adsetId };
  }

  if (campaignId) {
    params = { ...params, campaignId };
  }

  const data = await axios.get(`${apiBaseUrl}/budget/single`, {
    params,
  });

  return data?.data;
};

export const syncDailyBudget = async (params: BudgetReport[]) => {
  const data = await axios.post(
    `${apiBaseUrl}/budget/sync/daily-budget`,
    params,
  );

  return data?.data;
};
