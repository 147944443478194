import React from 'react';
import { Typography } from '@mui/material';

interface ModalHeaderProps {
  title: string;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title }) => {
  return (
    <Typography
      variant="h6"
      sx={{ fontWeight: 'bold', textAlign: 'center', color: '#096F4D' }}
    >
      {title}
    </Typography>
  );
};

export default ModalHeader;
