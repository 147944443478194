import jwtDecode from 'jwt-decode';
import {
  AGENCY_SESSION,
  AUTH_TOKEN,
  BRAND_FILTER_SESSION,
  BRAND_SESSION,
  BUDGET_PROVIDER_SESSION,
  CAMPAIGN_PROVIDER_SESSION,
  LOCATION_SESSION,
} from './constants';

interface DecodedToken {
  exp: number;
}

export const isExpired = (token: string) => {
  try {
    const decodedToken: DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp < currentTime;
  } catch (error: any) {
    console.log(error.message);
    return true;
  }
};

export const resetSession = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(BRAND_SESSION);
  localStorage.removeItem(AGENCY_SESSION);
  localStorage.removeItem(CAMPAIGN_PROVIDER_SESSION);
  localStorage.removeItem(BRAND_FILTER_SESSION);
  localStorage.removeItem(LOCATION_SESSION);
  localStorage.removeItem(BUDGET_PROVIDER_SESSION);
};
