import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Radio,
  Checkbox,
  Modal,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  formatNumber,
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';

import {
  fetchAssetLocationSet,
  fetchGoogle,
  fetchGoogleAssetsByBrand,
  saveModifiedAdBudget,
  saveModifiedAdCallouts,
  saveModifiedAdDescriptions,
  saveModifiedAdHeadlines,
  saveModifiedAdKeywords,
  saveModifiedAdSitelinks,
  saveModifiedAdFinalUrls,
  saveModifiedAdLocations,
  //saveModifiedAdLocations,
} from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import LinearProgressBarWithLabel from '../../../ProgressBar/LinearProgressBarWithLabel';
import GoogleTemplateHeadlines from './GGLTemplatesHeadlines';
import GoogleTemplatesDescriptions from './GGLTemplatesDescriptions';
import GoogleTemplatesSiteLinks from './GGLTemplateSiteLinks';
import GoogleTemplatesKeywords from './GGLTemplatesKeywords';
import GoogleTemplatesImages from './GGLTemplatesImages';
import GoogleTemplatesPreview from './GGLTemplatesPreview';
import GoogleTemplatesCallouts from './GGLTemplatesCallouts';
import GoogleSelect from '../../../Select/GoogleSelect';
import GoogleTemplateSummary from './GGLTemplateSummary';
import GGLAssetsLocationTable from '../../Assetz/Google/GGLAssetsLocationTable';
import { TiWarningOutline } from 'react-icons/ti';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `100%`,
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: `4px solid ${DEFAULT_COLOR_THEME}`,
  boxShadow: 24,
  p: 4,
};

interface GGTemplateCreationProps {
  creationComplete: any;
  checkPS?: any;
  brandId: string;
  defaultRefreshToken: string;
  editPages?: string[];
  isExisting: boolean;
  template?: any;
  ad?: any;
}

const GGTemplateCreation: React.FC<GGTemplateCreationProps> = ({
  creationComplete,
  checkPS,
  brandId,
  defaultRefreshToken,
  editPages,
  isExisting = false,
  template,
  ad,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [productsAndServicesCollection, setProductsAndServicesCollection] =
    useState([]);
  const [phoneNumberCollection, setPhoneNumberCollection] = useState([]);
  const [landingPageUrlCollection, setLandingPageUrlCollection] = useState([]);
  const [imagesCollection, setImagesCollection] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [enteredPS, setEnteredPS] = useState('');
  //ad
  const [assetsLocationSet, setAssetsLocationSet] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [campaignDuration, setCampaignDuration] = useState('everyMonth');
  const [budget, setBudget] = useState('');
  const [budgetNextMonth, setBudgetNextMonth] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [dateStart, setDateStart] = useState(
    new Date().toISOString().slice(0, 16),
  );

  const [dateEnd, setDateEnd] = useState('');
  const [isSettingUp, setIsSettingUp] = useState(true);
  const [updatingModal, setUpdatingModal] = useState(false);
  const [confirmOverwriteModal, setConfirmOverwriteModal] = useState(false);
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [affectedAssets, setAffectedAssets] = useState([]);
  const [googleAds, setGoogleAds] = useState([]);

  const [pages, setPages] = useState(
    editPages.length
      ? editPages
      : [
          'Product & Service',
          'URL',
          'Keywords',
          'Keywords Matching Type',
          'Phone Number',
          'Headlines',
          'Descriptions',
          'Callouts',
          'Sitelinks',
          'Images',
          'Preview',
        ],
  );
  //new templates
  // const [templateForm, setTemplateForm] = useState({
  //   productAndService: '',
  //   headlines: [],
  //   descriptions: [],
  //   keywords: [],
  //   sitelinks: [],
  //   callouts: [],
  //   phoneNumber: {
  //     label: '',
  //     number: '',
  //   },
  //   landingPageUrl: {
  //     label: '',
  //     url: '',
  //   },
  //   images: [],
  //   businessImage: '',
  //   category: 'search',
  // });
  const [currentTemplateForm, setCurrentTemplateForm] = useState({
    productAndService: '',
    phoneNumber: {
      label: '',
      number: '',
    },
    landingPageUrl: {
      label: '',
      url: '',
    },
    headlines: [],
    descriptions: [],
    callouts: [],
    keywords: [],
    sitelinks: [],
    images: [],
    category: 'search',
    businessImage: {
      asset_id: '',
      url: '',
      id: '',
    },
  });

  const [isDirty, setIsDirty] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignDuration((event.target as HTMLInputElement).value);
  };

  const fetchLocationSets = () => {
    fetchAssetLocationSet({
      brandId: brandId,
    }).then((res: any) => {
      console.log(`Location Set:`);
      console.log(res);
      setAssetsLocationSet(res);
    });
  };

  const fetchGoogleAssets = async () => {
    setIsLoading(true);
    const { data } = await fetchGoogleAssetsByBrand(brandId);
    console.log(data);
    const newData: {
      headlines: {
        data: string;
        ps: string;
      }[];
      descriptions: {
        data: string;
        ps: string;
      }[];
      keywords: {
        data: any;
        ps: string;
      }[];
      sitelinks: {
        data: {
          headline: string;
          description1: string;
          description2: string;
          finalUrl: string;
        };
        ps: string;
      }[];
    } = {
      headlines: [],
      descriptions: [],
      keywords: [],
      sitelinks: [],
    };
    data.query.forEach(
      (q: {
        product_and_service: string;
        headlines?: string[];
        descriptions?: string[];
        keywords?: {
          keyword: string;
          exact: boolean;
          phrase: boolean;
          broad: boolean;
        }[];
        sitelinks?: {
          headline: string;
          description1: string;
          description2: string;
          finalUrl: string;
        }[];
      }) => {
        if (q.descriptions) {
          q.descriptions.forEach((d) => {
            if (d)
              newData.descriptions.push({ data: d, ps: q.product_and_service });
          });
        }
        if (q.headlines) {
          q.headlines.forEach((h) => {
            if (h)
              newData.headlines.push({ data: h, ps: q.product_and_service });
          });
        }
        if (q.keywords) {
          q.keywords.forEach((k) => {
            if (k)
              newData.keywords.push({
                data: k,
                ps: q.product_and_service,
              });
          });
        }
        if (q.sitelinks) {
          q.sitelinks.forEach((s) => {
            if (s.headline)
              newData.sitelinks.push({ data: s, ps: q.product_and_service });
          });
        }
      },
    );
    console.log(`$newData`);
    console.log(newData);
    setPhoneNumberCollection(
      data.gacc.phone_numbers
        .filter((p: any) => p.number)
        .map((p: any, index: number) => {
          return {
            id: `p${index}-${p}`,
            data: p,
            label: p.label,
          };
        }),
    );
    setLandingPageUrlCollection(
      data.gacc.landing_page_url
        .filter((p: any) => p.url)
        .map((p: any, index: number) => {
          return {
            id: `p${index}-${p}`,
            data: p,
            label: p.label,
          };
        }),
    );
    setProductsAndServicesCollection(
      data.gacc.products_and_services.map((p: any, index: number) => {
        return {
          id: `p${index}-${p}`,
          data: p,
          label: p.toUpperCase(),
        };
      }),
    );
    setImagesCollection(
      data.gacc.images.map((i: any, index: number) => {
        return {
          id: i.asset_id,
          ...i,
        };
      }),
    );

    //@ts-ignore
    const keywordSet = Object.groupBy(
      newData.keywords.map((k, i) => {
        return {
          ...k,
          id: `k${i}-${k.ps}`,
          label: k.data.keyword,
        };
      }),
      (k: any) => k.ps,
    );
    console.log(`$keywordSet`);
    console.log(keywordSet);
    console.log(
      Object.keys(keywordSet).map((key, i) => {
        return {
          id: `k${i}-${key}`,
          ps: key,
          label: `${key.toUpperCase()} Keywords`,
          data: keywordSet[key],
        };
      }),
    );
    setIsLoading(false);
    if (currentPage === '') {
      setCurrentPage(pages[0]);
    }
  };

  const saveTemplate = async () => {
    let hitTemplate = false;
    let hits: string[] = [];
    pages.forEach((page) => {
      if (['Budget', 'Locations', 'Preview'].includes(page)) return;
      hitTemplate = true;
      hits.push(page);
      console.log(`hit for ${page}`);
    });

    if (isExisting && hitTemplate) {
      if (hits.length > 0) {
        setAffectedAssets(hits);
        console.log(`is existing, opening modal`);
        setConfirmOverwriteModal(true);
        return;
      }
    }

    if (isExisting && !hitTemplate) {
      handleSave();
      console.log(`is existing, no hit`);
      return;
    }

    try {
      const req = await creationComplete({
        brandId: brandId,
        final_url: currentTemplateForm.landingPageUrl.url,
        product_and_service: currentTemplateForm.productAndService,
        keywords: currentTemplateForm.keywords,
        headlines: currentTemplateForm.headlines,
        descriptions: currentTemplateForm.descriptions,
        sitelinks: currentTemplateForm.sitelinks,
        callouts: currentTemplateForm.callouts,
        images: currentTemplateForm.images,
        business_logo: currentTemplateForm.businessImage,
        phone_number: currentTemplateForm.phoneNumber.number,
        name: templateName,
      });

      return req;
    } catch (req) {
      console.log(req);
    }
  };

  const handleNextBtnClick = () => {
    let errors = false;
    let errorMsg = '';

    if (currentPage === 'Budget') {
      if (!budget) {
        errors = true;
        errorMsg = 'Please enter a budget';
      }
      if (!dailyBudget) {
        errors = true;
        errorMsg = 'Please enter a daily budget';
      }
      if (!campaignDuration) {
        errors = true;
        errorMsg = 'Please select a campaign duration';
      }

      if (campaignDuration === 'fixedDuration' && !dateEnd) {
        errors = true;
        errorMsg = 'Please select an end date';
      }

      if (campaignDuration === 'fixedDuration' && dateEnd < dateStart) {
        errors = true;
        errorMsg = 'End date cannot be before start date';
      }
    }

    //Location
    if (currentPage === 'Locations') {
      if (!selectedLocationType) {
        errors = true;
        errorMsg = 'Please select a location set';
      }
    }

    //Product and service and landing page url
    if (currentPage === 'Product & Service') {
      if (!currentTemplateForm.productAndService) {
        errors = true;
        errorMsg = 'Please create a product and service';
      }

      // if (checkPS(currentTemplateForm.productAndService)) {
      //   errors = true;
      //   errorMsg = 'This product and service already exists';
      // }

      if (currentTemplateForm.productAndService) {
        setTemplateName(
          currentTemplateForm.productAndService
            .replace(/[^a-zA-Z0-9]/g, '_')
            .toLowerCase(),
        );
      }
    }

    //URL
    if (currentPage === 'URL') {
      if (!currentTemplateForm.landingPageUrl?.url) {
        errors = true;
        errorMsg = 'Please select a landing page URL';
      }

      if (currentTemplateForm.landingPageUrl?.url) {
        setCurrentTemplateForm({
          ...currentTemplateForm,
          landingPageUrl: currentTemplateForm.landingPageUrl,
        });
      }
    }

    //Keywords
    if (currentPage === 'Keywords') {
      errors = currentTemplateForm.keywords.length === 0;
      errorMsg = 'Please enter at least one keyword';
    }

    //Keywords matching type
    if (currentPage === 'Keywords Matching Type') {
      let error = 0;
      currentTemplateForm.keywords.forEach((keyword) => {
        if (!keyword.exact && !keyword.phrase && !keyword.broad) {
          error++;
        }
      });

      if (error > 0) {
        errors = true;
        errorMsg = 'Please select matching type for all keywords';
      }
    }

    //Phone number
    if (currentPage === 'Phone Number') {
      console.log(currentTemplateForm.phoneNumber, `from phone number`);
      errors = currentTemplateForm.phoneNumber.number.length === 0;
      errorMsg = 'Please select a phone number';
    }

    //Head lines
    if (currentPage === 'Headlines') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.headlines.forEach((headline, i) => {
        if (dupliCheck.has(headline)) {
          errorMsg += `Headline #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(headline);
        }

        if (
          headline.length === 0 ||
          headline.length > googleResourceLimits.headlines.length
        ) {
          error++;
          errorMsg += `Headline #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.headlines.length
          } characters, `;
        }
      });

      if (currentTemplateForm.headlines.length < 5) {
        errorMsg += 'Please create at least 5 headlines';
      }
      errors = error > 0 || currentTemplateForm.headlines.length < 5;
    }

    //Descriptions
    if (currentPage === 'Descriptions') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.descriptions.forEach((d, i) => {
        if (dupliCheck.has(d)) {
          errorMsg += `Description #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(d);
        }

        if (
          d.length === 0 ||
          d.length > googleResourceLimits.descriptions.length
        ) {
          error++;
          errorMsg += `Description #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.descriptions.length
          } characters, `;
        }
      });

      if (currentTemplateForm.descriptions.length < 2) {
        errorMsg += 'Please create at least 2 descriptions';
      }
      errors = error > 0 || currentTemplateForm.descriptions.length < 2;
    }

    //Callouts
    if (currentPage === 'Callouts') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.callouts.forEach((c, i) => {
        if (dupliCheck.has(c)) {
          errorMsg += `Callout #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(c);
        }

        if (c.length === 0 || c.length > googleResourceLimits.callouts.length) {
          error++;
          errorMsg += `Callout #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.callouts.length
          } characters, `;
        }
      });

      if (currentTemplateForm.callouts.length < 4) {
        errorMsg += 'Please create at least 4 callouts';
      }
      errors = error > 0 || currentTemplateForm.callouts.length < 4;
    }

    //Sitelinks
    if (currentPage === 'Sitelinks') {
      errors = currentTemplateForm.sitelinks.length < 2;
      errorMsg = 'Please create at least 2 sitelinks';
      let error = 0;

      currentTemplateForm.sitelinks.forEach((sitelink) => {
        Object.keys(sitelink).forEach((key) => {
          if (
            sitelink[key].length === 0 ||
            //@ts-ignore
            sitelink[key].length > googleResourceLimits.sitelinks.length[key]
          ) {
            error++;
            console.log(`key`, key);
            console.log(`sitelink[key]`, sitelink[key]);
          }
        });
      });

      if (error)
        errors
          ? (errorMsg += `, Please fill in all sitelinks correctly`)
          : (errorMsg = `Please fill in all sitelinks correctly`);

      errors = error > 0;
    }

    //Images
    if (currentPage === 'Images') {
      console.log(`page 9 saving`);
      console.log(currentTemplateForm.businessImage.url);
      console.log(currentTemplateForm.images);
      // errors = currentTemplateForm.images.length < 1;
      // errorMsg = 'Please select at least one image';
      if (currentTemplateForm.businessImage.url) {
        console.log(`business image url`);
        console.log(currentTemplateForm.businessImage.url);
        // setCurrentTemplateForm({
        //   ...currentTemplateForm,
        //   businessImage: currentTemplateForm.businessImage,
        // });
      }
      if (currentTemplateForm.images.length > 0) {
        console.log(`images`);
        console.log(currentTemplateForm.images);
        // setCurrentTemplateForm({
        //   ...currentTemplateForm,
        //   images: currentTemplateForm.images,
        // });
      }

      console.log(currentTemplateForm, `from next btnimages`);
    }
    console.log(`$errors`);
    console.log(errors);

    if (!errors) {
      const getIndex = pages.indexOf(currentPage);
      const nextPage = pages[getIndex + 1];
      setCurrentPage(nextPage);
    } else {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: errorMsg,
        }),
      );
    }
  };

  const fetchAds = () => {
    fetchGoogle({
      brandId: brandId,
      db: 'googleAds',
    }).then((data) => {
      console.log(data);
      setGoogleAds(data);
    });
  };

  useEffect(() => {
    console.log(`ad`, ad);
    if (ad) {
      setDailyBudget(ad.dailyBudget);
      setBudget(ad.budget);
      setBudgetNextMonth(ad.monthlyBudget);
      //setDateStart(ad.dateStart);
      //setDateEnd(ad.dateEnd);
      setSelectedLocationType(ad.locationSet);
      setIsSettingUp(false);
    }
    fetchGoogleAssets();
    fetchLocationSets();
    fetchAds();
    console.log(`editPages`, editPages);
    if (isExisting) {
      const templ = {
        productAndService: template?.product_and_service,
        phoneNumber: {
          label: '',
          number: template?.phone_number,
        },
        landingPageUrl: {
          label: '',
          url: template?.final_url,
        },
        headlines: template?.headlines,
        descriptions: template?.descriptions,
        callouts: template?.callouts,
        keywords: template?.keywords,
        sitelinks: template?.sitelinks,
        images: template?.images,
        category: 'search',
        businessImage: {
          asset_id: '',
          url: template?.business_logo,
          id: '',
        },
      };
      console.log(`templ`, templ);
      setCurrentTemplateForm(templ);
      setTemplateName(template?.name);
    }
  }, []);

  const saveHeadlines = (headlines: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      headlines: headlines,
    });
  };

  const saveCallouts = (callouts: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      callouts: callouts,
    });
  };

  const saveDescriptions = (descriptions: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      descriptions: descriptions,
    });
  };

  const saveKeywords = (keywords: string[]) => {
    console.log(`keywords`, keywords);
    setCurrentTemplateForm({
      ...currentTemplateForm,
      keywords: keywords,
    });
  };

  const saveSitelinks = (sitelinks: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      sitelinks: sitelinks,
    });
  };

  const saveImages = (images: any[], image: any) => {
    console.log(images, `from save images`);
    setCurrentTemplateForm({
      ...currentTemplateForm,
      images: [...images],
      businessImage: image,
    });
  };

  const handleCloseConfirmOverwrite = () => {
    setConfirmOverwriteModal(false);
    setConfirmOverwrite(false);
  };

  const handleSave = async () => {
    const fakeDelay = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    setConfirmOverwriteModal(false);
    setIsLoading(true);
    console.log(`saving`);
    //check which has to be modified
    const t = ad?.template || template;
    console.log(`t`, t);

    const affectedAds = googleAds
      .filter((a) => a.template?._id === t._id)
      .map((a) => {
        return a;
      });

    console.log(`affectedAds`, affectedAds);

    for (let a of affectedAds) {
      console.log(`affected ad`, a);
      for (let p of pages) {
        console.log(`page`, p);
        if (p === 'Budget') {
          if (ad._id === a._id) {
            console.log(`saving budget`);
            console.log(`brandId`, brandId);
            console.log(`ad`, ad);
            console.log(`dailyBudget`, dailyBudget);
            console.log(`budget`, budget);
            console.log(`budgetNextMonth`, budgetNextMonth);
            console.log(`dateStart`, dateStart);
            console.log(`dateEnd`, dateEnd);
            await saveModifiedAdBudget({
              brandId: brandId,
              ad: ad,
              budget: {
                dailyBudget: dailyBudget,
                budget: budget,
                budgetNextMonth: budgetNextMonth,
                dateStart: dateStart,
                dateEnd: dateEnd,
              },
            });
            //await fakeDelay(2000);
          }
        }

        if (p === 'Locations') {
          if (ad._id === a._id) {
            console.log(`saving locations`);
            console.log(`brandId`, brandId);
            console.log(`ad`, ad);
            console.log(`selectedLocationType`, selectedLocationType);
            saveModifiedAdLocations({
              brandId: brandId,
              ad: ad,
              locations: selectedLocationType.locations,
              locationSet: selectedLocationType,
            });
            //await fakeDelay(2000);
          }
        }

        if (p === 'URL') {
          console.log(`saving URL`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.landingPageUrl`,
            currentTemplateForm.landingPageUrl,
          );
          // saveModifiedAdURL({
          //   brandId: brandId,
          //   ad: ad,
          //   landingPageUrl: currentTemplateForm.landingPageUrl,
          // });
          await fakeDelay(2000);
        }

        if (p === 'Headlines') {
          console.log(`saving headlines`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.headlines`,
            currentTemplateForm.headlines,
          );
          await saveModifiedAdHeadlines({
            brandId: brandId,
            ad: ad,
            headlines: currentTemplateForm.headlines,
          });
          //await fakeDelay(2000);
        }
        if (p === 'Descriptions') {
          console.log(`saving descriptions`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.descriptions`,
            currentTemplateForm.descriptions,
          );
          await saveModifiedAdDescriptions({
            brandId: brandId,
            ad: ad,
            descriptions: currentTemplateForm.descriptions,
          });
          //await fakeDelay(2000);
        }
        if (p === 'Keywords') {
          console.log(`saving keywords`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.keywords`,
            currentTemplateForm.keywords,
          );
          await saveModifiedAdKeywords({
            brandId: brandId,
            ad: ad,
            keywords: currentTemplateForm.keywords,
          });
          //await fakeDelay(2000);
        }
        if (p === 'Sitelinks') {
          console.log(`saving sitelinks`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.sitelinks`,
            currentTemplateForm.sitelinks,
          );
          await saveModifiedAdSitelinks({
            brandId: brandId,
            ad: ad,
            sitelinks: currentTemplateForm.sitelinks,
          });
          //await fakeDelay(2000);
        }
        if (p === 'Callouts') {
          console.log(`saving callouts`);
          console.log(`brandId`, brandId);
          console.log(`ad`, ad);
          console.log(
            `currentTemplateForm.callouts`,
            currentTemplateForm.callouts,
          );
          await saveModifiedAdCallouts({
            brandId: brandId,
            ad: ad,
            callouts: currentTemplateForm.callouts,
          });
          //await fakeDelay(2000);
        }
        if (p === 'Images') {
        }

        if (p === 'Preview') {
        }
      }
    }

    setIsLoading(false);
    creationComplete(t);
  };

  const overwriteModal = (
    <Modal
      open={confirmOverwriteModal}
      // onClose={handleCloseConfirmOverwrite}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isExisting ? (
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={DEFAULT_COLOR_THEME}
            sx={{
              display: `flex`,
              justifyContent: `start`,
              gap: 1,
              alignItems: `center`,
              fontWeight: 600,
            }}
          >
            <TiWarningOutline color={DEFAULT_COLOR_THEME} size={28} /> Are you
            sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Changes to this template will affect all ads that are using it. Are
            you sure you want to make changes for this template?
          </Typography>

          {affectedAssets.length > 0 ? (
            <Box sx={{ marginY: 2 }}>
              <Typography>Changes to this template:</Typography>
              <Box sx={{ maxHeight: `400px`, overflowY: `auto` }}>
                {affectedAssets?.map((a) => (
                  <Box sx={{ paddingLeft: 2 }}>• {a}</Box>
                ))}
              </Box>
            </Box>
          ) : null}

          {googleAds?.filter((a) => a?.template?._id === ad?.template?._id)
            .length > 0 ? (
            <Box sx={{ marginY: 2 }}>
              <Typography>Ads using this template:</Typography>
              <Box sx={{ maxHeight: `400px`, overflowY: `auto` }}>
                {googleAds
                  ?.filter((a) => a?.template?._id === ad?.template?._id)
                  .map((a) => (
                    <Box sx={{ paddingLeft: 2 }}>• {a.name}</Box>
                  ))}
              </Box>
            </Box>
          ) : null}
          <Box sx={{ marginY: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmOverwrite}
                  onChange={() => {
                    setConfirmOverwrite(!confirmOverwrite);
                  }}
                />
              }
              label="I understand the implications of overwriting this template"
            />
          </Box>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: 2,
            }}
          >
            <Button onClick={handleCloseConfirmOverwrite}>Cancel</Button>
            <Button
              variant="contained"
              disabled={!confirmOverwrite}
              onClick={handleSave}
            >
              {confirmOverwrite
                ? 'Confirm and Save'
                : 'you must confirm to save'}
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );

  const page: {
    [key: string]: JSX.Element;
  } = {
    Budget: (
      <>
        <Typography
          variant="h6"
          color={`primary`}
          sx={{
            fontWeight: 800,
          }}
        >
          PLEASE SET YOUR SCHEDULE AND BUDGET
        </Typography>
        <Box
          sx={{
            marginY: 2,
          }}
        >
          <TextField
            label="Start Date/Time"
            variant="standard"
            type="datetime-local"
            value={dateStart}
            onChange={(e) => {
              setDateStart(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              minWidth: 300,
            }}
          />
        </Box>

        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={campaignDuration}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="everyMonth"
                control={<Radio />}
                label="Run campaign every month"
              />
              <FormControlLabel
                value="fixedDuration"
                control={<Radio />}
                label="Stop campaign on"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            marginBottom: 2,
            opacity: campaignDuration === 'fixedDuration' ? 1 : 0,
            height: campaignDuration === 'fixedDuration' ? 'auto' : 0,
            transition: 'opacity 0.5s ease',
          }}
        >
          <TextField
            label="End Date/Time"
            variant="standard"
            type="datetime-local"
            value={dateEnd}
            onChange={(e) => {
              setDateEnd(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              minWidth: 300,
            }}
          />
        </Box>

        <Box
          sx={{
            marginY: 4,
          }}
        >
          <TextField
            label="Current Month's Budget"
            variant="standard"
            value={budget}
            sx={{ marginRight: '2rem' }}
            name="currentMonthBudget"
            onChange={(e) => {
              //if (isSettingUp) return;
              //remove letters and non .
              const val = e.target.value.replace(/[^0-9.]/g, '');
              setBudget(val);
              //get remaining days in month
              const today = new Date();
              const lastDay = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
              );
              const daysLeft = lastDay.getDate() - today.getDate() + 1;
              const dailyBudget = parseFloat(e.target.value) / daysLeft;
              setDailyBudget(
                val ? (Math.floor(dailyBudget * 100) / 100).toFixed(2) : '',
              );
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />

          <TextField
            label="Future Monthly Budget"
            variant="standard"
            value={budgetNextMonth}
            onChange={(e) => {
              //remove letters and non .
              const val = e.target.value.replace(/[^0-9.]/g, '');

              setBudgetNextMonth(val);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
        </Box>
        <Box
          sx={{
            marginY: 2,
          }}
        >
          <TextField
            label="Daily Budget"
            variant="standard"
            value={dailyBudget}
            onChange={(e) => {
              //remove letters and non .
              //if (isSettingUp) return;
              const val = e.target.value.replace(/[^0-9.]/g, '');
              setDailyBudget(val ? val : '');

              //get remaining days in month
              const today = new Date();
              const lastDay = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
              );
              const daysLeft = lastDay.getDate() - today.getDate();
              const targetBudget = parseFloat(val) * daysLeft;
              setBudget(val ? targetBudget.toFixed(2) : '');
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
        </Box>
      </>
    ),
    Locations: (
      <Box sx={{}}>
        <Typography variant="h6" color={`primary`}>
          Select a location set to target your Google Ad
        </Typography>
        <Box sx={{ maxWidth: 800, paddingLeft: 2, marginTop: 2 }}>
          {assetsLocationSet?.map((l) => (
            <Box
              key={l.name}
              sx={{
                width: `100%`,
                textAlign: `left`,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                paddingX: '0.5rem',
                paddingY: '0.2rem',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  border: '1px solid #aaa',
                  marginBottom: 1,
                  backgroundColor:
                    selectedLocationType?.name === l.name
                      ? `#e6ffd4`
                      : `#ffffff`,
                  borderColor:
                    selectedLocationType?.name === l.name
                      ? DEFAULT_COLOR_THEME
                      : `#aaa`,

                  maxHeight: 150,
                  minWidth: 700,
                  overflowY: 'auto',
                  ...scrollBarGreen,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingX: '0.5rem',
                    paddingY: '0.2rem',
                    position: 'sticky',
                    top: 0,
                    backgroundColor:
                      selectedLocationType?.name === l.name
                        ? `#e6ffd4`
                        : `#ffffff`,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: DEFAULT_COLOR_THEME,
                      fontWeight: 'bold',
                    }}
                  >
                    {l.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: DEFAULT_COLOR_THEME,
                      fontWeight: 'bold',
                    }}
                  >
                    {l.locations.length} Locations
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  <GGLAssetsLocationTable
                    locations={l.locations.map((loc: any) => {
                      return {
                        ...loc,
                        reach: formatNumber(loc.reach),
                      };
                    })}
                    selected={selectedLocationType?.name === l.name}
                    fullWidth
                  />
                </Box>
              </Box>
              <Button
                onClick={() => setSelectedLocationType(l)}
                variant="contained"
                sx={{
                  backgroundColor: DEFAULT_COLOR_THEME,
                  paddingX: '20px',
                }}
              >
                Select
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    ),
    'Product & Service': (
      <>
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              marginBottom: '10px',
              textTransform: 'uppercase',
            }}
          >
            Enter Product or Services:
          </Typography>
          <TextField
            sx={{ width: '100%' }}
            label="Product or Services"
            value={currentTemplateForm.productAndService}
            onChange={(e) =>
              setCurrentTemplateForm({
                ...currentTemplateForm,
                productAndService: e.target.value,
              })
            }
            onKeyDown={(e) => {
              //if enter key is pressed
              if (e.key === 'Enter') {
                handleNextBtnClick();
              }
            }}
          />
        </Box>
      </>
    ),
    URL: (
      <>
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <Box
            sx={{
              maxWidth: `700px`,
              marginTop: 8,
              marginBottom: 4,
              paddingRight: 2,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: 800,
                color: DEFAULT_COLOR_THEME,
              },
              maxHeight: '450px',
              overflowY: 'auto',
              ...scrollBarGreen,
            }}
          >
            <GoogleSelect
              title="Select Final URL:"
              options={landingPageUrlCollection.map((ps: any) => ({
                row1: ps.data.label,
                row2: ps.data.url,
                value: ps.data,
              }))}
              selected={currentTemplateForm.landingPageUrl.url}
              onSelect={(url: any) => {
                setCurrentTemplateForm({
                  ...currentTemplateForm,
                  landingPageUrl: url,
                });
              }}
            />
          </Box>
        </Box>
      </>
    ),
    Keywords: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `500px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Box>
              <GoogleTemplatesKeywords
                templateUrl={currentTemplateForm.landingPageUrl.url}
                currentPage={currentPage}
                selectedProductAndService={
                  currentTemplateForm.productAndService
                }
                submitForm={saveKeywords}
                templateForm={currentTemplateForm.keywords}
                setIsDirty={setIsDirty}
                brandId={brandId}
                defaultRefreshToken={defaultRefreshToken}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Keywords Matching Type': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `500px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Box>
              <GoogleTemplatesKeywords
                templateUrl={currentTemplateForm.landingPageUrl.url}
                currentPage={currentPage}
                selectedProductAndService={
                  currentTemplateForm.productAndService
                }
                submitForm={saveKeywords}
                templateForm={currentTemplateForm.keywords}
                setIsDirty={setIsDirty}
                brandId={brandId}
                defaultRefreshToken={defaultRefreshToken}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Phone Number': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Box
              sx={{
                maxWidth: `500px`,
                marginBottom: 4,
                fontWeight: 800,
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '1.2rem',
                  fontWeight: 800,
                  color: DEFAULT_COLOR_THEME,
                },
              }}
            >
              <GoogleSelect
                title="Select a Phone Number:"
                options={phoneNumberCollection.map((ps: any) => ({
                  row1: ps.data.label,
                  row2: ps.data.number,
                  value: ps.data,
                }))}
                selected={currentTemplateForm.phoneNumber}
                onSelect={(s: any) => {
                  console.log(s);
                  setCurrentTemplateForm({
                    ...currentTemplateForm,
                    phoneNumber: s,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Headlines: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `550px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create headlines:
            </Typography>
            <Box>
              <GoogleTemplateHeadlines
                submitForm={saveHeadlines}
                templateForm={currentTemplateForm?.headlines}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Descriptions: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `550px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create descriptions:
            </Typography>
            <Box>
              <GoogleTemplatesDescriptions
                submitForm={saveDescriptions}
                templateForm={currentTemplateForm.descriptions}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Callouts: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `550px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create callouts:
            </Typography>
            <Box>
              <GoogleTemplatesCallouts
                submitForm={saveCallouts}
                templateForm={currentTemplateForm.callouts}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Sitelinks: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: `500px`,
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create site links:
            </Typography>
            <Box>
              <GoogleTemplatesSiteLinks
                submitForm={saveSitelinks}
                templateForm={currentTemplateForm.sitelinks}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Images: (
      <>
        <Box sx={{ width: '100%' }}>
          <GoogleTemplatesImages
            brandId={brandId}
            imagesCollection={imagesCollection}
            templateForm={{
              businessImage: { ...currentTemplateForm.businessImage },
              images: { ...currentTemplateForm.images },
            }}
            submitForm={(e: { images: any; businessImage: any }) => {
              console.log(e, 'from submit form');
              saveImages(e.images, e.businessImage);
              //saveBusinessImage(e.businessImage);
            }}
            setIsDirty={setIsDirty}
            refetchImages={fetchGoogleAssets}
          />
        </Box>
      </>
    ),
    Preview: (
      <>
        <Box
          sx={{
            width: '100%',
            maxHeight: `500px`,
            overflowY: `auto`,
            position: 'relative',
            ...scrollBarGreen,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'row-reverse',
          }}
        >
          <Box
            sx={{
              width: '50%',
              position: 'sticky',
              top: 0,
              right: 0,
              paddingRight: '1rem',
            }}
          >
            <GoogleTemplatesPreview
              headlines={currentTemplateForm.headlines}
              descriptions={currentTemplateForm.descriptions}
              url={currentTemplateForm.landingPageUrl.url}
              sitelinks={currentTemplateForm.sitelinks}
              images={currentTemplateForm.images.map((i) => i.url)}
              business_logo={currentTemplateForm.businessImage?.url}
            />
          </Box>
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Box
              sx={{
                maxWidth: `500px`,
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: DEFAULT_COLOR_THEME,
                  marginBottom: '10px',
                  textTransform: 'uppercase',
                }}
              >
                Enter name for this Template:
              </Typography>
              <TextField
                sx={{ width: '100%' }}
                label="Template Name"
                value={templateName}
                disabled={isExisting}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontStyle: 'italic',
                  color: DEFAULT_COLOR_THEME,
                  marginBottom: '10px',
                  display: isExisting ? 'block' : 'none',
                }}
              >
                Template name cannot be changed on existing templates
              </Typography>
            </Box>
            <Box>
              <GoogleTemplateSummary template={currentTemplateForm} ad={ad} />
            </Box>
          </Box>
        </Box>
      </>
    ),
  };
  const creationFooter = (
    <Box
      sx={{
        position: 'sticky',
        bottom: -20,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        padding: '1rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '50%',
        }}
      >
        <LinearProgressBarWithLabel
          value={((pages.indexOf(currentPage) + 1) / pages.length) * 100}
        />
      </Box>
      <Box
        sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'end',
          gap: '0.5rem',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          sx={{ display: currentPage === pages[0] ? 'none' : 'block' }}
          onClick={() => {
            const getIndex = pages.indexOf(currentPage);
            const prevPage = pages[getIndex - 1];
            setCurrentPage(prevPage);
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{ display: currentPage === 'Preview' ? 'none' : 'block' }}
          onClick={handleNextBtnClick}
        >
          Next
        </Button>

        <Button
          variant="contained"
          sx={{ display: currentPage === 'Preview' ? 'block' : 'none' }}
          onClick={saveTemplate}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box sx={GoogleAssetsContainer}>
      <CircularLoading loading={isLoading} />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        {page[currentPage]}
        {creationFooter}
        {overwriteModal}
      </Box>
    </Box>
  );
};

export default GGTemplateCreation;
