import { Moment } from 'moment-timezone';
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styles from '../../../../assets/styles/pages/Payment.module.scss';
import PaymentOnboardingSteps from './PaymentOnboardingSteps';
import {
  createBrandStripeAccount,
  generateStripeBrandOnboardingLink,
} from '../../../../services/stripe/account';
import {
  Brand,
  ChangeEventType,
  StripeBusinessProfile,
  StripeCompanyForm,
  StripeIndividualForm,
  StripeSubAccountForm,
} from '../../../../types';
import { stripeAccountType } from '../../../../utils/constants/stripe';
import FormHeader from '../../FormHeader';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';

interface StripeAccountFormProps {
  onCloseForm: (account?: any) => void;
  setAccount: Dispatch<SetStateAction<any>>;
}

const StripeAccountForm: React.FC<StripeAccountFormProps> = ({
  onCloseForm,
  setAccount,
}) => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const [formValues, setFormValues] = useState<StripeSubAccountForm>({
    type: stripeAccountType.EXPRESS,
    country: 'US',
    email: '',
    business_type: 'company',
    default_currency: 'USD',
    metadata: {
      user_id: location ? location?._id : brand?._id,
      role: 'brand',
    },
    external_account: {
      object: 'bank_account',
      country: 'US',
      currency: 'USD',
      account_holder_name: '',
      account_holder_type: 'company',
      routing_number: '',
      account_number: '',
    },
  });
  const [individualForm, setIndividualForm] = useState<StripeIndividualForm>({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    ssn_last_4: '',
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
    dob: {
      day: '',
      month: '',
      year: '',
    },
    birthdate: null,
  });
  const [companyForm, setCompanyForm] = useState<StripeCompanyForm>({
    name: '',
    phone: '',
    registration_number: '',
    tax_id: '',
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
  });
  const [businessSupport, setBusinessSupport] = useState<StripeBusinessProfile>(
    {
      support_email: '',
      name: '',
      support_address: {
        city: '',
        country: 'US',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
      },
      support_phone: '',
    },
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [createdAccount, setCreatedAccount] = useState<any>(null);
  const [brandToRegister, setBrandToRegister] = useState<Brand>(null);

  useEffect(() => {
    if (location) return setBrandToRegister(location);

    if (brand) return setBrandToRegister(brand);
  }, [brand, location]);

  const isBank = (field: string) => {
    return [
      'account_holder_name',
      'account_holder_type',
      'routing_number',
      'account_number',
    ].includes(field);
  };

  const isAddress = (field: string) => {
    return [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(field);
  };

  const handleOnChangeForm = (e: ChangeEventType) => {
    const isEmail = e.target.name === 'email';
    if (isBank(e.target.name)) {
      setFormValues({
        ...formValues,
        external_account: {
          ...formValues.external_account,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
      if (isEmail) {
        setBusinessSupport({
          ...businessSupport,
          [`support_${e.target.name}`]: e.target.value,
        });
      }
    }
  };

  const handleOnChangeBankType = (value: string) => {
    setFormValues({
      ...formValues,
      external_account: {
        ...formValues.external_account,
        account_holder_type: value,
      },
    });
  };

  const handleOnChangeIndividual = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setIndividualForm({
        ...individualForm,
        address: { ...individualForm.address, [e.target.name]: e.target.value },
      });
    } else {
      setIndividualForm({ ...individualForm, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeCompany = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setCompanyForm({
        ...companyForm,
        address: { ...companyForm.address, [e.target.name]: e.target.value },
      });
      setBusinessSupport({
        ...businessSupport,
        support_address: {
          ...businessSupport.support_address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      const isName = e.target.name === 'name';
      setCompanyForm({ ...companyForm, [e.target.name]: e.target.value });
      if (isName) {
        setBusinessSupport({
          ...businessSupport,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleChangeCompanyNumber = (newValue: string) => {
    setCompanyForm({ ...companyForm, phone: newValue.split(' ').join('') });
    setBusinessSupport({
      ...businessSupport,
      support_phone: newValue.split(' ').join(''),
    });
  };

  const handleBuildBirthdate = (date: Moment | null) => {
    const day = date.format('D');
    const month = date.format('M');
    const year = date.format('YYYY');

    setIndividualForm({
      ...individualForm,
      birthdate: date,
      dob: { day, month, year },
    });
  };

  const handleCreateAccount = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      let form: StripeSubAccountForm = {
        ...formValues,
        company: companyForm,
        business_profile: businessSupport,
      };

      const response = await createBrandStripeAccount(
        brandToRegister?._id,
        form,
      );

      setAccount(response.data);
      setCreatedAccount(response.data);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleGenerateOnboardingLink();

      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  const handleGenerateOnboardingLink = async () => {
    try {
      setLoading(true);
      let response: any;
      response = await generateStripeBrandOnboardingLink(brandToRegister?._id);

      setLoading(false);
      window.open(response.data.url);
      //navigate('/preferences');
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  return (
    <div className={styles.payment}>
      <div className={styles.base}>
        <div className={styles.header}>
          <FormHeader title="Deposit Account Onboarding Form" />
        </div>

        <div>
          <PaymentOnboardingSteps
            formValues={formValues}
            companyForm={companyForm}
            individualForm={individualForm}
            onChangeForm={handleOnChangeForm}
            onChangeIndividual={handleOnChangeIndividual}
            onChangeBirthdate={handleBuildBirthdate}
            onChangeCompany={handleOnChangeCompany}
            onCreate={handleCreateAccount}
            loading={loading}
            generateLink={handleGenerateOnboardingLink}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            onChangeNumber={handleChangeCompanyNumber}
            onCloseForm={onCloseForm}
            account={createdAccount}
            onChangeBankType={handleOnChangeBankType}
          />
        </div>
      </div>
    </div>
  );
};

export default StripeAccountForm;
