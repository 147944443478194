import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useMediaQuery, useTheme } from '@mui/material';
import { Brand, GoogleAdsMetrics, BrandFacebookReport } from '../../types';
import {
  getBrandCampaignAnalytics,
  getGoogleCampaignMetrics,
  getGoogleCampaignMetricsNew,
} from '../../services/report';
import styles from '../../assets/styles/pages/Reports.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import { adsProvider } from '../../utils/constants/facebookAds';
import PageHeader from '../../components/PageHeader';
import { AuthContext } from '../../context';
import { IS_DIY_ADZ } from '../../utils';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import CircularLoading from '../../components/CircularLoading';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import GoogleCampaignMetrics from '../../components/Reporting/GoogleCampaignMetrics';
import { BusinessType } from '../../utils/helpers/BrandHelpers';
import { DrawerMenu } from '../../components/Menu';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import ChatzBudgets from '../Budgets/ChatzBudgetReport';
//new
import GoogleDirectCampaignMetrics from '../../components/Reporting/GoogleDirectCampaignMetrics';
import FacebookCampaignReportTable from '../../components/Tables/ReportList/FacebookCampaignReportTable';
import { NoData } from '../NoContent';

const Reports: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const startRange = useSelector((state: any) => state.startRange.date)?.tz(
    timezone,
  );
  const endRange = useSelector((state: any) => state.endRange.date)?.tz(
    timezone,
  );
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const refreshToken = state.authUser?.refreshToken;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);
  const [googleCampaigns, setGoogleCampaigns] =
    useState<GoogleAdsMetrics>(null);
  const [selectedAd, setSelectedAd] = useState<string>(null);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>('');
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;
  const [facebookReports, setFacebookReports] =
    useState<BrandFacebookReport>(null);

  const isFrandev: boolean =
    (location || brand)?.businessType === BusinessType.FRANDEV;
  //new
  const [directData, setDirectData] = useState<any>(null);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if (
      (location || brand) &&
      campaignProvider &&
      startRange &&
      endRange &&
      defaultRefreshToken
    ) {
      resetData();
      const delayDebounceFn = setTimeout(() => {
        fetchBrandAnalytics();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    startRange,
    endRange,
    location,
    brand,
    campaignProvider,
    defaultRefreshToken,
  ]);

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const resetData = () => {
    setGoogleCampaigns(null);
    setFacebookReports(null);
    setDirectData(null);
  };

  const resetGoogleReports = async () => {
    setAnalyticsLoading(true);
    setDirectData(null);
    await fetchGoogleReports();
    setAnalyticsLoading(false);
  };

  const fetchGoogleReports = async () => {
    const { data } = await getGoogleCampaignMetricsNew({
      brandId: (location || brand)._id,
      refreshToken: defaultRefreshToken,
      fromDate: startRange.toISOString(),
      toDate: endRange.toISOString(),
      campaignId: '',
    });

    setDirectData(data);

    return data;
  };

  const fetchBrandAnalytics = async () => {
    try {
      setAnalyticsLoading(true);

      if (isFacebook) {
        const response = await getBrandCampaignAnalytics(
          (location || brand)?._id,
          startRange,
          endRange,
        );

        setFacebookReports(response.data);
      }

      if (isGoogle && !IS_DIY_ADZ) {
        const data = await getGoogleCampaignMetrics(
          (location || brand)?._id,
          defaultRefreshToken,
          startRange,
          endRange,
        );
        if (data?.errors?.length > 0) {
          let msg: string = '';
          data.errors.forEach((error: any) => {
            msg += error;
          });
          dispatch(
            toggleAlert({
              toggle: true,
              message: msg,
              type: 'error',
            }),
          );
        } else {
          setGoogleCampaigns(data);
        }
      }

      if (isGoogle && IS_DIY_ADZ) {
        await fetchGoogleReports();
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setAnalyticsLoading(false);
    }
  };

  const handleOpenPreview = async (adId: string) => {
    setSelectedAd(adId);
    handleOpenAdPreviewModal();
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]?.tz(timezone)));
    dispatch(setEndRange(dates[1]?.tz(timezone)));
  };

  const handleOpenAdPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleCloseAdPreviewModal = () => {
    setOpenPreviewModal(false);
  };

  return (
    <>
      {IS_DIY_ADZ &&
      (location || brand)?.__type === 'brand' &&
      (location || brand)?.allowLocations ? (
        <ChatzBudgets />
      ) : (
        <div className={styles.report}>
          <CircularLoading loading={analyticsLoading} />

          <div className={styles.base}>
            <div className={styles.header}>
              <PageHeader title={IS_DIY_ADZ ? 'Reportz' : 'Reports'} />

              <div className={styles.controls}>
                <DateRangePicker
                  toDate={endRange}
                  fromDate={startRange}
                  onChange={handleChangeDateRange}
                  maxDate={moment()}
                  separator="-"
                />
              </div>
            </div>

            {facebookReports?.leads?.length > 0 ||
            facebookReports?.traffic?.length > 0 ||
            googleCampaigns?.data.length > 0 ||
            directData ? (
              <>
                {facebookReports?.leads?.length > 0 && isFacebook ? (
                  <div>
                    <FacebookCampaignReportTable
                      reportData={facebookReports?.leads || []}
                      onOpenPreview={handleOpenPreview}
                      isLeads
                      isFranDev={isFrandev}
                    />
                  </div>
                ) : null}

                {facebookReports?.traffic?.length > 0 && isFacebook ? (
                  <div>
                    <FacebookCampaignReportTable
                      reportData={facebookReports?.traffic || []}
                      onOpenPreview={handleOpenPreview}
                    />
                  </div>
                ) : null}

                {isGoogle && !IS_DIY_ADZ && googleCampaigns?.data.length > 0 ? (
                  <GoogleCampaignMetrics
                    brand={location || brand}
                    fromDate={startRange}
                    toDate={endRange}
                    data={googleCampaigns?.data || []}
                    refreshToken={defaultRefreshToken}
                  />
                ) : isGoogle && IS_DIY_ADZ && !analyticsLoading ? (
                  <GoogleDirectCampaignMetrics
                    refreshToken={defaultRefreshToken}
                    allowCheckboxes={
                      state.role === 'admin' || state.role === 'super-admin'
                        ? true
                        : state.capabilities.createTemplate
                        ? true
                        : false
                    }
                    directData={directData}
                    brandId={(location || brand)?._id}
                    brand={(location || brand).brand}
                    fromDate={moment(startRange).format('MMM DD, YYYY')}
                    toDate={moment(endRange).format('MMM DD, YYYY')}
                    reload={resetGoogleReports}
                  />
                ) : null}
              </>
            ) : (
              <NoData />
            )}

            <DrawerMenu
              anchor="right"
              open={openPreviewModal}
              onClose={handleCloseAdPreviewModal}
            >
              <FacebookAdDetails
                adId={selectedAd}
                brand={location || brand}
                onClose={handleCloseAdPreviewModal}
                isMobile={xsOnly}
              />
            </DrawerMenu>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
