import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { aiReportTableColumns } from './AIReportTableColumns';
import { CustomLoadingOverlay } from '../../Loading';
import { AIReport } from '../../../types';

interface AIReportTableProps {
  insights: AIReport[];
  loading: boolean;
  onOpenInsightz: (insight: any) => void;
  onOpenPreview: (insight: any) => void;
}

const AIReportTable: React.FC<AIReportTableProps> = ({
  insights,
  loading,
  onOpenInsightz,
  onOpenPreview,
}) => {
  const theme = useTheme();
  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rows = insights.map((insight: AIReport) => {
    return {
      id: insight.id,
      name: insight.adName,
      acquisitionScore: insight.acquisitionScore,
      audienceScore: insight.audienceScore,
      adScore: insight.adScore,
      performanceScore: insight.performanceScore,
      status: insight.status,
      insight,
      onOpenInsightz,
      onOpenPreview,
    };
  });

  const cellBgFormatter = (params: any) => {
    const { value } = params;

    if (params.field === 'performanceScore') {
      if (value >= 6) return 'green';

      if (value >= 5 && value < 6) return 'yellow';

      return 'red';
    }

    return '';
  };

  return (
    <Box
      sx={{
        '& .green': {
          backgroundColor: 'green',
          fontWeight: 'bold',
        },
        '& .yellow': {
          backgroundColor: 'yellow',
          fontWeight: 'bold',
        },
        '& .red': {
          backgroundColor: 'red',
          fontWeight: 'bold',
        },
        paddingX: '5px',
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: 'performanceScore', sort: 'desc' }],
          },
        }}
        className={styles.table}
        density="compact"
        rows={rows}
        columns={aiReportTableColumns(mdAndDown)}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
      />
    </Box>
  );
};

export default AIReportTable;
