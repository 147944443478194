import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import momentTz from 'moment-timezone';
import { Tooltip, Typography } from '@mui/material';
import { isQualifiedLead } from '../../../utils/helpers/LeadHelpers';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const leadColumns = (
  isFrandev: boolean,
  timezone: string,
  isMobile: boolean,
) => {
  let columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 200),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Full Name',
      sortingOrder: ['desc', 'asc'],
      valueGetter: (_, row) => `${row.firstName} ${row.lastName}`,
      renderCell: (params: any) => {
        const { firstName, lastName, lead, onOpen } = params.row;
        const fullName: any = `${firstName} ${lastName}`;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{fullName}</Typography>}
              disableInteractive
            >
              {fullName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 200),
      field: 'phoneNumber',
      headerClassName: styles.header,
      headerName: 'Phone Number',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { phoneNumber, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{phoneNumber}</Typography>}
              disableInteractive
            >
              {phoneNumber}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 200),
      field: 'email',
      headerClassName: styles.header,
      headerName: 'Email',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { email, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{email}</Typography>}
              disableInteractive
            >
              {email}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (isFrandev) {
    columns = [
      ...columns,
      {
        field: 'investmentQualified',
        headerClassName: styles.header,
        headerName: 'IQL',
        description: 'Investment Qualified Lead',
        width: 80,
        disableColumnMenu: true,
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => (row.investmentQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { investmentQualified, lead, onOpen, investmentPresent } =
            params.row;
          const qualified: any = investmentQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                investmentQualified
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {investmentPresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {investmentPresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'backgroundQualified',
        headerClassName: styles.header,
        headerName: 'BQL',
        width: 80,
        sortingOrder: ['desc', 'asc'],
        disableColumnMenu: true,
        description: 'Background Qualified Lead',
        valueGetter: (_, row) => (row.backgroundQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { backgroundQualified, lead, onOpen, backgroundPresent } =
            params.row;
          const qualified: any = backgroundQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                backgroundQualified
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {backgroundPresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {backgroundPresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'experienceQualified',
        headerClassName: styles.header,
        headerName: 'EQL',
        description: 'Experience Qualified Lead',
        width: 80,
        disableColumnMenu: true,
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => (row.experienceQualified ? 'Yes' : 'No'),
        renderCell: (params: any) => {
          const { experienceQualified, lead, onOpen, experiencePresent } =
            params.row;
          const qualified: any = experienceQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                experienceQualified
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={
                  <Typography variant="body2">
                    {experiencePresent ? qualified : ''}
                  </Typography>
                }
                disableInteractive
              >
                {experiencePresent ? qualified : ''}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'qualifiedLead',
        headerClassName: styles.header,
        headerName: 'QL',
        width: 80,
        disableColumnMenu: true,
        description: 'Qualified Lead',
        sortingOrder: ['desc', 'asc'],
        valueGetter: (_, row) => {
          const {
            investmentQualified,
            backgroundQualified,
            experienceQualified,
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          } = row;

          const conditions = [
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          ];
          const isQualified = isQualifiedLead(
            conditions,
            investmentQualified,
            backgroundQualified,
            experienceQualified,
          );

          return isQualified ? 'Yes' : 'No';
        },
        renderCell: (params: any) => {
          const {
            investmentQualified,
            backgroundQualified,
            experienceQualified,
            lead,
            onOpen,
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          } = params.row;

          const conditions = [
            investmentPresent,
            backgroundPresent,
            experiencePresent,
          ];
          const isQualified = isQualifiedLead(
            conditions,
            investmentQualified,
            backgroundQualified,
            experienceQualified,
          );
          const qualified: any = isQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                isQualified ? `${styles['-green']}` : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={<Typography variant="body2">{qualified}</Typography>}
                disableInteractive
              >
                {qualified}
              </Tooltip>
            </div>
          );
        },
      },
      {
        ...defaultProps(isMobile, 200),
        field: 'details',
        headerClassName: styles.header,
        headerName: 'Details',
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: any) => {
          const { details, lead, onOpen } = params.row;

          return (
            <div className={styles.name} onClick={() => onOpen(lead)}>
              <Tooltip
                title={<Typography variant="body2">{details}</Typography>}
                disableInteractive
              >
                {details}
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      ...defaultProps(isMobile, 200),
      field: 'adName',
      headerClassName: styles.header,
      headerName: 'Ad Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { adName, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{adName}</Typography>}
              disableInteractive
            >
              {adName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 170),
      field: 'createdAt',
      headerClassName: styles.header,
      headerName: 'Created',
      sortingOrder: ['desc', 'asc'],
      valueGetter: (_, row) => row.createdAt,
      renderCell: (params: any) => {
        const { createdAt, lead, onOpen } = params.row;
        const formattedDate: any = momentTz
          .unix(createdAt)
          .tz(timezone)
          .format('MM/DD/YYYY hh:mma');

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{formattedDate}</Typography>}
              disableInteractive
            >
              {formattedDate}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return columns;
};
