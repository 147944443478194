import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, Popover, Typography } from '@mui/material';
import Spinner from '../Spinner';
import { styled } from '@mui/material/styles';
import styles from '../../assets/styles/components/Modal/Modal.module.scss';
import { PrimaryButton } from '../Buttons';
import ModalHeader from './ModalHeader';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

const StyledBox = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: 10,
  [theme.breakpoints.down('md')]: {
    width: '95%',
    maxHeight: '500px',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    maxHeight: '600px',
  },
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#F1F0F0',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: DEFAULT_COLOR_THEME,
    borderRadius: '18px',

    '&:hover': {
      background: DEFAULT_COLOR_THEME,
    },
  },
}));

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}

const PopupModal: React.FC<ModalProps> = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <StyledBox>{children}</StyledBox>
    </Modal>
  );
};

export default PopupModal;

interface PopoverModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  id: string;
  anchorEl?: HTMLButtonElement | null;
  anchorOrigin?: any;
  transformOrigin?: any;
  anchorVertical?: string;
  anchorHorizontal?: string;
  transformVertical?: string;
  transformHorizontal?: string;
}

export const PopoverModal: React.FC<PopoverModalProps> = ({
  open,
  handleClose,
  children,
  id,
  anchorEl,
  anchorVertical,
  anchorHorizontal,
  transformVertical,
  transformHorizontal,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: anchorVertical as any,
        horizontal: anchorHorizontal as any,
      }}
      transformOrigin={{
        vertical: transformVertical as any,
        horizontal: transformHorizontal as any,
      }}
    >
      {children}
    </Popover>
  );
};

interface DeleteModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  handleDelete: () => void;
  title: string;
  subtitle: string;
  loading?: boolean;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  handleClose,
  handleDelete,
  title,
  subtitle,
  loading,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition={true}
    >
      <StyledBox>
        <div className={styles.modal}>
          <h3 className={styles.header}>{title}</h3>

          <p className={styles.subtitle}>{subtitle}</p>

          <div>
            <Button
              onClick={handleDelete}
              sx={{ marginRight: '10px' }}
              size="small"
              variant="contained"
              color="error"
            >
              {loading ? <Spinner /> : 'Yes'}
            </Button>

            <Button
              onClick={handleClose}
              size="small"
              variant="contained"
              color="primary"
            >
              No
            </Button>
          </div>
        </div>
      </StyledBox>
    </Modal>
  );
};

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onReject: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
  title: string;
  subtitle: string;
  loading?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  onReject,
  title,
  subtitle,
  loading,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition={true}
    >
      <StyledBox>
        <div className={styles.modal}>
          <ModalHeader title={title} />

          <Typography
            variant="body1"
            mb={4}
            mt={3}
            sx={{ color: DEFAULT_COLOR_THEME }}
          >
            {subtitle}
          </Typography>

          <div>
            <PrimaryButton
              title="Yes"
              type="button"
              handleOnClick={onConfirm}
              marginRight10
            />

            <PrimaryButton
              title="No"
              type="button"
              handleOnClick={onReject}
              theme="red"
            />
          </div>
        </div>
      </StyledBox>
    </Modal>
  );
};
