import { IconButton, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { getAdScoreValue } from '../../../utils/helpers/AIHelpers';

export const aiReportTableColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 300),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Ad Name',
      renderCell: (params) => {
        const { name, insight, onOpenPreview, status } = params.row;
        const isPaused = status !== 'ACTIVE';

        return (
          <Tooltip
            title={
              <Typography variant="body2">{`${name}${
                isPaused ? ` (${constantStringToHumanized(status)})` : ''
              }`}</Typography>
            }
            disableInteractive
          >
            <div
              className={`${styles.name} ${
                isPaused ? `${styles['-error']}` : ''
              }`}
              onClick={() => onOpenPreview(insight)}
            >
              {name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'adScore',
      headerClassName: styles.header,
      headerName: 'Ad',
      width: 80,
      valueGetter: (_, row) => getAdScoreValue(row.adScore),
      renderCell: (params) => {
        const { adScore, insight, onOpenPreview } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {getAdScoreValue(adScore)}
              </Typography>
            }
            disableInteractive
          >
            <div className={styles.name} onClick={() => onOpenPreview(insight)}>
              {getAdScoreValue(adScore)}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'audienceScore',
      headerClassName: styles.header,
      headerName: 'Audience',
      width: 100,
      valueGetter: (_, row) => row.audienceScore,
      renderCell: (params) => {
        const { audienceScore, insight, onOpenPreview } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{audienceScore}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => onOpenPreview(insight)}>
              {audienceScore}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'acquisitionScore',
      headerClassName: styles.header,
      headerName: 'Acquisition',
      width: 100,
      valueGetter: (_, row) => row.acquisitionScore,
      renderCell: (params) => {
        const { acquisitionScore, insight, onOpenPreview } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{acquisitionScore}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => onOpenPreview(insight)}>
              {acquisitionScore}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'performanceScore',
      headerClassName: styles.header,
      headerName: 'Performance',
      width: 140,
      valueGetter: (_, row) => row.performanceScore,
      renderCell: (_) => {
        return <></>;
      },
    },
    {
      field: 'action',
      headerClassName: styles.header,
      headerName: 'Action',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        const { onOpenInsightz, insight } = params.row;
        return (
          <div>
            <IconButton
              onClick={() => onOpenInsightz(insight)}
              sx={{ fontSize: '13px', color: '#1976d2' }}
            >
              Analyze
            </IconButton>
          </div>
        );
      },
    },
  ];

  return columns;
};
