import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/authContext';
import { ChatUser } from '../../../types';
import { SALESPERSON } from '../../../utils';
import {
  columns,
  mobileColumns,
  salespersonColumn,
} from './ChatsListTableColumns';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useMediaQuery, useTheme } from '@mui/material';

interface ChatListTableProps {
  chats: ChatUser[];
  loading: boolean;
  rowData: number;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
}

const ChatsListTable: React.FC<ChatListTableProps> = ({
  chats,
  loading,
  rowData,
  paginationModel,
  setPaginationModel,
}) => {
  const theme = useTheme();
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const { state } = useContext(AuthContext);
  const isSalesperson = state.role === SALESPERSON;
  const navigate = useNavigate();
  const rows = chats.slice().map((chat: ChatUser, index: number) => {
    return {
      brand: chat.brand,
      brandId: chat.brandId,
      broker: chat.broker,
      calendlyeventdate: chat.calendlyeventdate,
      candidateemail: chat.candidateemail,
      candidatephone: chat.candidatephone,
      chatId: chat.chatId,
      chatuserId: chat.chatuserId,
      createdAt: chat.createdAt,
      email: chat.email,
      firstname: chat.firstname,
      gender: chat.gender,
      industryexperience: chat.industryexperience,
      investment: chat.investment,
      isFollowupEnabled: chat.isFollowupEnabled,
      language: chat.language,
      lastInteraction: chat.lastInteraction,
      lastSeen: chat.lastSeen,
      lastname: chat.lastname,
      name: chat.name,
      pageId: chat.pageId,
      profilePic: chat.profilePic,
      result: chat.result,
      salesid: chat.salesid,
      status: chat.status,
      subscribed: chat.subscribed,
      tellusaboutyourself: chat.tellusaboutyourself,
      territory: chat.territory,
      timestamp: chat.timestamp,
      timezone: chat.timezone,
      updatedAt: chat.updatedAt,
      whentocall: chat.whentocall,
      chatSeenAt: chat.chatSeenAt,
      partialEngagement: chat.partialEngagement,
      completedEngagement: chat.completedEngagement,
      isSalesperson: isSalesperson,
      lastEngagedAt: chat.lastEngagedAt,
      __type: chat.__type,
      __v: chat.__v,
      _id: chat._id,
      id: chat._id,
      navigate: navigate,
    };
  });

  const renderColumns = () => {
    if (isSalesperson) {
      if (isXsOnly) {
        return mobileColumns;
      } else {
        return salespersonColumn;
      }
    } else {
      if (isXsOnly) {
        return mobileColumns;
      }

      return columns;
    }
  };

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={renderColumns()}
        autoHeight
        hideFooterSelectedRowCount
        pageSizeOptions={[25, 50, 100]}
        slots={{
          loadingOverlay: CustomLoadingOverlay,
        }}
        rowCount={rowData}
        loading={loading}
        rowHeight={isXsOnly ? 70 : 50}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
      />
    </Box>
  );
};

export default ChatsListTable;
