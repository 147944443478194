import {
  DataGridPremiumProps,
  getDataGridUtilityClass,
  GridColDef,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import {
  Box,
  Tooltip,
  Typography,
  unstable_composeClasses as composeClasses,
} from '@mui/material';
import { AdLead, ClientTetherSalesCycleReportData } from '../../../types';
import {
  numberWithCommaConverter,
  toCurrency,
} from '../../../utils/numberFormatter';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { Dispatch, SetStateAction } from 'react';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';

export const ctSalesCycleGroupingColumn = (
  onOpenDetails: (lead: AdLead) => void,
  isByAds: boolean,
  isMobile: boolean,
  expandedKeys: string[],
  setExpandedKeys: Dispatch<SetStateAction<string[]>>,
  data: ClientTetherSalesCycleReportData[],
) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    ...defaultProps(isMobile, 320),
    headerName: isByAds ? 'Ad Name' : 'Stage Label',
    headerClassName: `${styles.header}`,
    sortable: true,
    renderCell: (params) => (
      <CustomGridGroupColDef
        {...params}
        onClick={onOpenDetails}
        isByAds={isByAds}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        data={data}
      />
    ),
  };

  return gridGroupColumn;
};

export const ctSalesCycleColumns = (
  isMobile: boolean,
  commissionMode: boolean,
) => {
  let columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 320),
      field: 'adName',
      headerClassName: `${styles.header}`,
      headerName: 'Ad Name',
      groupingValueGetter: (_, row) => row.adName,
      renderCell: (params) => {
        const { adName, level } = params.row;

        return (
          <div className={styles.name}>
            {level !== 'sales_cycle' ? adName : ''}
          </div>
        );
      },
    },
    {
      field: 'closeRatio',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Close %',
      groupingValueGetter: (_, row) => row.closeRatio,
      renderCell: (params) => {
        const { closeRatio, level } = params.row;

        return (
          <div className={styles.name}>
            {level === 'sales_cycle' ? `${closeRatio}%` : ''}
          </div>
        );
      },
    },
  ];

  if (commissionMode) {
    columns = [
      ...columns,
      {
        field: 'commission',
        width: 100,
        headerClassName: `${styles.header}`,
        headerName: 'Commission',
        groupingValueGetter: (_, row) => row.value,
        renderCell: (params) => {
          const { commission, level } = params.row;

          return (
            <div className={styles.name}>
              {level === 'sales_cycle' ? toCurrency('USD', commission, 0) : ''}
            </div>
          );
        },
      },
    ];
  }

  if (!commissionMode) {
    columns = [
      ...columns,
      {
        field: 'value',
        width: 100,
        headerClassName: `${styles.header}`,
        headerName: 'Value',
        groupingValueGetter: (_, row) => row.value,
        renderCell: (params) => {
          const { value, level } = params.row;

          return (
            <div className={styles.name}>
              {level === 'sales_cycle' ? toCurrency('USD', value, 0) : ''}
            </div>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      field: 'leads',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Leads',
      groupingValueGetter: (_, row) => row.leads,
      renderCell: (params) => {
        const { leads, level } = params.row;

        return (
          <div className={styles.name}>
            {level === 'sales_cycle' || (level === 'lead' && leads > 1)
              ? numberWithCommaConverter(leads, 0)
              : ''}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const ctSalesCycleColumnsByAds = (
  onOpenDetails: (lead: AdLead) => void,
) => {
  let columns: GridColDef[] = [
    {
      field: 'value',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Value',
      groupingValueGetter: (_, row) => row.value,
      renderCell: (params) => {
        const { value, level, lead } = params.row;

        return (
          <div
            className={styles.name}
            onClick={() => (level === 'lead' ? onOpenDetails(lead) : null)}
          >
            {level === 'lead' ? '' : toCurrency('USD', value, 0)}
          </div>
        );
      },
    },
    {
      field: 'spend',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'Spend',
      groupingValueGetter: (_, row) => row.spend,
      renderCell: (params) => {
        const { spend, level, lead } = params.row;

        return (
          <div
            className={styles.name}
            onClick={() => (level === 'lead' ? onOpenDetails(lead) : null)}
          >
            {level === 'lead' ? '' : toCurrency('USD', spend, 0)}
          </div>
        );
      },
    },
    {
      field: 'leads',
      width: 70,
      headerClassName: `${styles.header}`,
      headerName: 'Leads',
      groupingValueGetter: (_, row) => row.leads,
      renderCell: (params) => {
        const { leads, level, lead } = params.row;

        return (
          <div
            className={styles.name}
            onClick={() => (level === 'lead' ? onOpenDetails(lead) : null)}
          >
            {level !== 'lead' || (level === 'lead' && leads > 1)
              ? numberWithCommaConverter(leads, 0)
              : ''}
          </div>
        );
      },
    },
    {
      field: 'roi',
      width: 100,
      headerClassName: `${styles.header}`,
      headerName: 'ROI',
      groupingValueGetter: (_, row) => row.roi,
      renderCell: (params) => {
        const { roi, level, lead } = params.row;

        return (
          <div
            className={styles.name}
            onClick={() => (level === 'lead' ? onOpenDetails(lead) : null)}
          >
            {level !== 'lead'
              ? `${numberWithCommaConverter(roi <= 0 ? 0 : roi || 0, 0)}%`
              : ''}
          </div>
        );
      },
    },
  ];

  return columns;
};

type OwnerState = { classes: DataGridPremiumProps['classes'] };

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export const CustomGridGroupColDef = (props: any) => {
  const {
    id,
    field,
    formattedValue,
    rowNode,
    offsetMultiplier = 2,
    onClick,
    row,
    isByAds,
    expandedKeys,
    setExpandedKeys,
    data,
  } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const Icon = (rowNode as any).childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const setCollapseKeys = () => {
    let groups: string[] = [...expandedKeys];

    const existingKey = groups.find(
      (key: any) => key === row.group[row.group.length - 1],
    );

    if (existingKey) {
      groups = groups.filter((key: string) => key !== existingKey);
    } else {
      (data || [])?.forEach((report: any) => {
        if (report.group.includes(row.group[0])) {
          groups = [...groups, ...report.group];
        }
      });
    }

    const filteredKeys = arrayUniqueFilter(groups);
    setExpandedKeys(filteredKeys);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    apiRef.current.setRowChildrenExpansion(
      id,
      !(rowNode as any).childrenExpanded,
    );
    apiRef.current.setCellFocus(id, field);
    setCollapseKeys();
    event.stopPropagation();
  };

  const value =
    formattedValue === undefined
      ? (rowNode as any).groupingKey
      : formattedValue;

  const removeLastParenthesisContent = (str: string): string => {
    return str.replace(/\s*\([^)]*\)\s*$/, '');
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * offsetMultiplier }}>
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleOnClick}
            tabIndex={-1}
            aria-label={
              (rowNode as any).childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...rootProps?.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>

      <div
        className={styles.name}
        onClick={
          row.level === 'lead' && isByAds ? () => onClick(row.lead) : null
        }
      >
        <Tooltip
          title={
            <Typography variant="body2">
              {`${removeLastParenthesisContent(value)}`}
            </Typography>
          }
          disableInteractive
        >
          {removeLastParenthesisContent(value) as any}
        </Tooltip>
      </div>
    </Box>
  );
};
