import {
  Box,
  Button,
  Badge,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { FaCheck } from 'react-icons/fa';
import AssetGoogleImages from '../../Assetz/Google/GGLAssetsImages';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface GoogleTemplatesImagesProps {
  brandId: string;
  imagesCollection: {
    url: string;
    height: number;
    width: number;
    asset_id: string;
    public_id: string;
  }[];
  submitForm: any;
  templateForm: any;
  refetchImages: any;
  setIsDirty: any;
}

const GoogleTemplatesImages: React.FC<GoogleTemplatesImagesProps> = ({
  brandId,
  imagesCollection,
  refetchImages,
  submitForm,
  templateForm,
  setIsDirty,
}) => {
  const [images, setImages] = useState([]);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const maxItems = googleResourceLimits.images.items;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (templateForm) {
      console.log('templateForm', templateForm);
      console.log('templateForm.images', templateForm.images);
      if (templateForm.images && templateForm.images.length > 0) {
        setImages({ ...templateForm.images });
      }
      if (templateForm.businessImage) {
        setBusinessLogo({ ...templateForm.businessImage });
      }
    }
  }, []);

  useEffect(() => {
    submitForm({
      images: [...images],
      businessImage: { ...businessLogo },
    });
  }, [images, businessLogo]);

  const selectThisImage = (image: any) => {
    if (images.map((img) => img.asset_id).includes(image.asset_id)) {
      setImages(images.filter((img) => img.asset_id !== image.asset_id));
      return;
    }

    if (images.length < maxItems) {
      setImages([...images, image]);
    } else {
      alert(`You can only select ${maxItems} images`);
    }

    console.log('images from selection', images);
  };

  const selectAsBusinessLogo = (image: any) => {
    if (businessLogo?.asset_id === image.asset_id) {
      setBusinessLogo(null);
      return;
    } else {
      setBusinessLogo(image);
    }
  };

  return (
    <Box
      sx={{
        justifyContent: `space-around`,
        alignItems: `start`,
        maxHeight: 550,
        overflowY: `auto`,
        ...scrollBarGreen,
      }}
    >
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{
          border: '3px solid #efefef',
          marginBottom: `10px`,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropDownIcon
              sx={{
                width: `32px`,
                height: `32px`,
                border: `2px solid rgba(0,0,0,0.54)`,
                borderRadius: `100%`,
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            background: `#efefef`,
            ':hover': {
              background: `#e6ffd4`,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              textTransform: 'uppercase',
            }}
          >
            Select a Business Logo:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: `100%`,
            }}
          >
            {imagesCollection.map((image, index) => (
              <Badge
                sx={{ margin: '1rem' }}
                invisible={
                  businessLogo !== null
                    ? businessLogo?.asset_id === image.asset_id
                      ? false
                      : true
                    : true
                }
                badgeContent={
                  <FaCheck
                    display={
                      businessLogo?.asset_id === image.asset_id
                        ? `block`
                        : `none`
                    }
                  />
                }
                color="primary"
              >
                <Card
                  sx={{
                    maxWidth: 345,
                    padding: '0.5rem',
                    border: `0px solid ${DEFAULT_COLOR_THEME}`,
                    borderWidth:
                      businessLogo?.asset_id === image.asset_id ? `4px` : '0px',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      selectAsBusinessLogo(image);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={image.url}
                      alt=""
                    />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        {image.width} x {image.height}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Badge>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        sx={{
          border: '3px solid #efefef',
          marginBottom: `10px`,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropDownIcon
              sx={{
                width: `32px`,
                height: `32px`,
                border: `2px solid rgba(0,0,0,0.54)`,
                borderRadius: `100%`,
              }}
            />
          }
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            background: `#efefef`,
            ':hover': {
              background: `#e6ffd4`,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              textTransform: 'uppercase',
            }}
          >
            Select Images to be used in this Template:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: `100%`,
            }}
          >
            {imagesCollection.map((image, index) => (
              <Badge
                sx={{ margin: '1rem' }}
                invisible={
                  images.map((img) => img.asset_id).includes(image.asset_id)
                    ? false
                    : true
                }
                badgeContent={
                  <FaCheck
                    display={
                      images.map((img) => img.asset_id).includes(image.asset_id)
                        ? `block`
                        : `none`
                    }
                  />
                }
                color="primary"
              >
                <Card
                  sx={{
                    maxWidth: 345,
                    padding: '0.5rem',
                    border: `0px solid ${DEFAULT_COLOR_THEME}`,
                    borderWidth: images
                      .map((img) => img.asset_id)
                      .includes(image.asset_id)
                      ? `4px`
                      : '0px',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      selectThisImage(image);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={image.url}
                      alt=""
                    />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        {image.width} x {image.height}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Badge>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        sx={{
          border: '3px solid #efefef',
          marginBottom: `10px`,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropDownIcon
              sx={{
                width: `32px`,
                height: `32px`,
                border: `2px solid rgba(0,0,0,0.54)`,
                borderRadius: `100%`,
              }}
            />
          }
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            background: `#efefef`,
            ':hover': {
              background: `#e6ffd4`,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              textTransform: 'uppercase',
            }}
          >
            Upload images to your Google Ads account:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: `100%`,
            }}
          >
            <AssetGoogleImages
              brandId={brandId}
              databaseImages={imagesCollection}
              doneUploading={refetchImages}
              deleteImage={() => {}}
              setIsDirty={setIsDirty}
              showCollection={false}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: `100%`,
        }}
      ></Box>
    </Box>
  );
};

export default GoogleTemplatesImages;
