import { GridColDef } from '@mui/x-data-grid-premium';
import { toCurrency } from '../../../utils/numberFormatter';
import { Tooltip, Typography } from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';

const defaultProps = (
  isMobile: boolean,
  width: number,
  defaultWidth?: number,
) => {
  if (isMobile) return { width };

  if (defaultWidth) return { flex: 1, minWidth: defaultWidth };

  return { flex: 1 };
};

export const locationColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 120, 120),
      field: 'location',
      headerClassName: styles.header,
      headerName: 'Location',
      renderCell: (params: any) => {
        const { location } = params.row;

        return <div className={styles.name}>{location}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerClassName: styles.header,
      headerName: 'Clicks',
      valueGetter: (_, row) => parseInt(row.clicks),
      renderCell: (params: any) => {
        const { clicks } = params.row;

        return <div className={styles.name}>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerClassName: styles.header,
      headerName: 'CPC',
      valueGetter: (_, row) => row.cpc,
      renderCell: (params: any) => {
        const { cpc } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerClassName: styles.header,
      headerName: 'Impressions',
      valueGetter: (_, row) => parseInt(row.impressions),
      renderCell: (params: any) => {
        const { impressions } = params.row;

        return <div className={styles.name}>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerClassName: styles.header,
      headerName: 'CPM',
      valueGetter: (_, row) => row.cpm,
      renderCell: (params: any) => {
        const { cpm } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'leads',
      headerClassName: styles.header,
      headerName: 'Leads',
      valueGetter: (_, row) => row.conversions,
      renderCell: (params: any) => {
        const { conversions } = params.row;

        return <div className={styles.name}>{conversions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerClassName: styles.header,
      headerName: 'CPL',
      valueGetter: (_, row) => row.cpl,
      renderCell: (params: any) => {
        const { cpl } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerClassName: styles.header,
      headerName: 'Cost',
      valueGetter: (_, row) => row.spend,
      renderCell: (params: any) => {
        const { spend } = params.row;

        return <div className={styles.name}>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};

export const keywordColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 200, 200),
      field: 'keyword',
      headerClassName: styles.header,
      headerName: 'Search Keyword',
      renderCell: (params: any) => {
        const { keyword } = params.row;

        return (
          <div className={styles.name}>
            <Tooltip
              title={<Typography variant="body2">{keyword}</Typography>}
              disableInteractive
            >
              {keyword}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerClassName: styles.header,
      headerName: 'Clicks',
      valueGetter: (_, row) => parseInt(row.clicks),
      renderCell: (params: any) => {
        const { clicks } = params.row;

        return <div className={styles.name}>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerClassName: styles.header,
      headerName: 'CPC',
      valueGetter: (_, row) => row.cpc,
      renderCell: (params: any) => {
        const { cpc } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerClassName: styles.header,
      headerName: 'Impressions',
      valueGetter: (_, row) => parseInt(row.impressions),
      renderCell: (params: any) => {
        const { impressions } = params.row;

        return <div className={styles.name}>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerClassName: styles.header,
      headerName: 'CPM',
      valueGetter: (_, row) => row.cpm,
      renderCell: (params: any) => {
        const { cpm } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'leads',
      headerClassName: styles.header,
      headerName: 'Leads',
      valueGetter: (_, row) => row.conversions,
      renderCell: (params: any) => {
        const { conversions } = params.row;

        return <div className={styles.name}>{conversions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerClassName: styles.header,
      headerName: 'CPL',
      valueGetter: (_, row) => row.cpl,
      renderCell: (params: any) => {
        const { cpl } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerClassName: styles.header,
      headerName: 'Cost',
      valueGetter: (_, row) => row.spend,
      renderCell: (params: any) => {
        const { spend } = params.row;

        return <div className={styles.name}>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};

export const searchTermColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 200, 200),
      field: 'searchTerm',
      headerClassName: styles.header,
      headerName: 'Search Term',
      renderCell: (params: any) => {
        const { searchTerm } = params.row;

        return (
          <div className={styles.name}>
            <Tooltip
              title={<Typography variant="body2">{searchTerm}</Typography>}
              disableInteractive
            >
              {searchTerm}
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerClassName: styles.header,
      headerName: 'Clicks',
      valueGetter: (_, row) => parseInt(row.clicks),
      renderCell: (params: any) => {
        const { clicks } = params.row;

        return <div className={styles.name}>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerClassName: styles.header,
      headerName: 'CPC',
      valueGetter: (_, row) => row.cpc,
      renderCell: (params: any) => {
        const { cpc } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerClassName: styles.header,
      headerName: 'Impressions',
      valueGetter: (_, row) => parseInt(row.impressions),
      renderCell: (params: any) => {
        const { impressions } = params.row;

        return <div className={styles.name}>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerClassName: styles.header,
      headerName: 'CPM',
      valueGetter: (_, row) => row.cpm,
      renderCell: (params: any) => {
        const { cpm } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'leads',
      headerClassName: styles.header,
      headerName: 'Leads',
      valueGetter: (_, row) => row.conversions,
      renderCell: (params: any) => {
        const { conversions } = params.row;

        return <div className={styles.name}>{conversions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerClassName: styles.header,
      headerName: 'CPL',
      valueGetter: (_, row) => row.cpl,
      renderCell: (params: any) => {
        const { cpl } = params.row;

        return <div className={styles.name}>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerClassName: styles.header,
      headerName: 'Cost',
      valueGetter: (_, row) => row.spend,
      renderCell: (params: any) => {
        const { spend } = params.row;

        return <div className={styles.name}>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};
