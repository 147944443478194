import React from 'react';
import { PrimaryButton } from '../../Buttons';
import { Box } from '@mui/material';

interface AccountActionsProps {
  activeStep: number;
  onBack: () => void;
  steps: string[];
  marginTop?: string;
  onCancel?: () => void;
}

const AccountActions: React.FC<AccountActionsProps> = ({
  activeStep,
  onBack,
  steps,
  marginTop = '',
  onCancel,
}) => {
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        display: 'flex',
        marginTop,
      }}
    >
      <PrimaryButton
        title="Back"
        variant="text"
        type="button"
        disabled={activeStep === 0}
        theme="red"
        handleOnClick={onBack}
      />

      <PrimaryButton
        title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        variant="text"
        theme="primary"
      />

      <PrimaryButton
        title="Cancel"
        variant="text"
        type="button"
        theme="red"
        handleOnClick={onCancel}
      />
    </Box>
  );
};

export default AccountActions;
