import { Box } from '@mui/system';
import React from 'react';
import { BudgetReport } from '../../../types';
import { CustomLoadingOverlay } from '../../Loading';
import { useMediaQuery, useTheme } from '@mui/material';
import { budgetReportChatzColumns } from './BudgetReportChatzTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';

interface BudgetReportChatzTableProps {
  budgetReports: BudgetReport[];
  loading: boolean;
  isDiy?: boolean;
}

const BudgetReportChatzTable: React.FC<BudgetReportChatzTableProps> = ({
  budgetReports,
  loading,
  isDiy = false,
}) => {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const cellBgFormatter = (params: any) => {
    const { value } = params;
    const { status } = params.row;

    if (params.field === 'percentage') {
      if (value === 0) return '';

      if (value < 80) return 'primary';

      if (value >= 80 && value < 95) return 'yellow';

      if (value >= 95 && value < 101) return 'green';

      if (value >= 101) return 'red';
    }

    if (params.field === 'brand' && status === 'Paused') {
      return 'red';
    }

    return '';
  };

  return (
    <Box
      sx={{
        '& .green': {
          backgroundColor: 'green',
          fontWeight: 'bold',
        },
        '& .yellow': {
          backgroundColor: 'yellow',
          fontWeight: 'bold',
        },
        '& .red': {
          backgroundColor: 'red',
          fontWeight: 'bold',
        },
        '& .primary': {
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
        },
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: 'brand', sort: 'asc' }],
          },
        }}
        autoHeight
        density="compact"
        rows={budgetReports}
        columns={budgetReportChatzColumns(isMdAndDown, isDiy)}
        slots={{
          loadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
      />
    </Box>
  );
};

export default BudgetReportChatzTable;
