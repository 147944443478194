import React from 'react';
import styles from '../../assets/styles/pages/Home.module.scss';
import { Box } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import ChatGptForm from '../../components/Forms/ChatGptForm';

const Tools: React.FC = () => {
  return (
    <div className={styles.home}>
      <div className={styles.base}></div>

      <PageHeader title="Tools" />

      <Box>
        <ChatGptForm />
      </Box>
    </div>
  );
};

export default Tools;
