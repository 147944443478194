import React, { ReactNode, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

/**
 * Carousel List Component
 * @author Angelo David <angelod@codev.com>
 * @since
 * @reference https://github.com/YIZHUANG/react-multi-carousel
 */
interface CarouselListProps {
  showDots?: boolean;
  children: ReactNode;
  arrows?: boolean;
  customRightArrows?: ReactNode | null;
  customLeftArrows?: ReactNode | null;
}

const CarouselList: React.FC<CarouselListProps> = ({
  showDots = true,
  children,
  arrows = true,
  customRightArrows = null,
  customLeftArrows = null,
}) => {
  const [props, setProps] = useState<any>(null);

  useEffect(() => {
    let defaultProps: any = {};
    if (customRightArrows) {
      defaultProps = { ...defaultProps, customRightArrows };
    }

    if (customLeftArrows) {
      defaultProps = { ...defaultProps, customLeftArrows };
    }

    setProps(defaultProps);
  }, []);

  const responsiveOption = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      {...props}
      showDots={showDots}
      responsive={responsiveOption}
      arrows={arrows}
    >
      {children}
    </Carousel>
  );
};

export default CarouselList;
