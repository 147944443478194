import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { numberShortener } from '../../../utils/numberFormatter';
import Spinner from '../../Spinner';
import { HtmlTooltip } from '../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Brand } from '../../../types';
import { PrimaryButton } from '../../Buttons';
import { DiySettings } from '../../../types/IDiy';
import { createOrUpdateDiySettings } from '../../../services/diy';
import { DEFAULT_COLOR_THEME } from '../../../utils/Styling';

interface DiyAudienceSizeProps {
  reachEstimate: any;
  loading: boolean;
  locations?: Brand[];
  selectedLocation?: Brand;
  setSelectedLocation?: Dispatch<SetStateAction<Brand>>;
  brand?: Brand;
  error?: string | null;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
}

const DiyAudienceSize: React.FC<DiyAudienceSizeProps> = ({
  reachEstimate,
  loading,
  locations = [],
  selectedLocation,
  setSelectedLocation,
  brand,
  error = null,
  diySettings,
  setDiySettings,
}) => {
  const [defaultLoading, setDefaultLoading] = useState<boolean>(false);

  const handleSetDefaultLocation = async () => {
    try {
      let temp: DiySettings = {
        ...diySettings,
        defaultLocation: selectedLocation,
      };

      setupDiySetting(brand?._id, temp);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const setupDiySetting = async (brandId: string, form: DiySettings) => {
    setDefaultLoading(true);
    try {
      const response = await createOrUpdateDiySettings(brandId, form);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setDefaultLoading(false);
    }
  };

  return (
    <Card
      sx={{
        borderLeft: 'solid',
        borderWidth: '5px',
        borderColor: DEFAULT_COLOR_THEME,
      }}
    >
      <CardHeader
        title={
          <Fragment>
            <Box component="div">
              {error ? (
                <Box component="div" sx={{ marginBottom: '10px' }}>
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    {error}
                  </Typography>
                </Box>
              ) : null}

              {locations.length > 0 ? (
                <>
                  <Box component="div" sx={{ marginBottom: '10px' }}>
                    <Typography variant="caption">
                      Select location to view estimated reach.
                    </Typography>
                  </Box>

                  <Box component="div" sx={{ marginBottom: '10px' }}>
                    <Autocomplete
                      id="locations"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option?._id}>
                            {option.brand}
                          </li>
                        );
                      }}
                      options={locations?.sort(
                        (a, b) => -b.brand?.localeCompare(a.brand),
                      )}
                      value={selectedLocation}
                      getOptionLabel={(option) => option?.brand}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="United States"
                          label="Locations"
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '50px',
                              backgroundColor: 'white',
                              legend: {
                                marginLeft: '10px',
                              },
                            },
                            '& .MuiAutocomplete-inputRoot': {
                              paddingLeft: '20px !important',
                              borderRadius: '50px',
                            },
                            '& .MuiInputLabel-outlined': {
                              backgroundColor: 'none',
                            },
                            '& .MuiInputLabel-shrink': {
                              paddingRight: 0,
                              marginLeft: '10px',
                            },
                            input: {
                              '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'black',
                                fontWeight: 'bold',
                              },
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        marginRight: '5px',
                      }}
                      onChange={async (e: any, value: any, reason, detail) => {
                        setSelectedLocation(value);
                      }}
                    />
                  </Box>

                  {selectedLocation ? (
                    <Box
                      component="div"
                      sx={{
                        marginBottom: '10px',
                        justifyContent: 'flex-end',
                        display: 'flex',
                      }}
                    >
                      <PrimaryButton
                        title="Set Default"
                        type="button"
                        variant="text"
                        disabled={
                          selectedLocation?._id ===
                          diySettings?.defaultLocation?._id
                        }
                        loading={defaultLoading}
                        handleOnClick={handleSetDefaultLocation}
                      />
                    </Box>
                  ) : null}
                </>
              ) : null}

              <div>Estimated Audience Size</div>
            </Box>
          </Fragment>
        }
        titleTypographyProps={{
          fontSize: '15px',
          color: DEFAULT_COLOR_THEME,
        }}
        subheader={
          loading ? (
            <Spinner />
          ) : (reachEstimate?.users_lower_bound || 0) < 0 ? (
            'less than 10'
          ) : (
            `${numberShortener(
              reachEstimate?.users_lower_bound || 0,
            )} - ${numberShortener(reachEstimate?.users_upper_bound || 0)}`
          )
        }
        subheaderTypographyProps={{
          fontSize: '18px',
          marginTop: '10px',
          textAlign: loading ? 'center' : 'left',
          fontWeight: 'bold',
        }}
        action={
          <HtmlTooltip
            disableInteractive
            title={
              <Fragment>
                <Box component="div" sx={{ marginBottom: '10px' }}>
                  Estimated audience size is an estimate of how many Accounts
                  Center accounts may meet your targeting criteria. It is based
                  on factors such as targeting selections, ad placements and how
                  many Accounts Center accounts were shown ads on Meta apps and
                  services in the past 30 days. Learn more
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  This is not an estimate of how many people will see your ad
                  and is not designed to match population or census data. This
                  metric is in development.
                </Box>

                <div>
                  <a
                    href="https://www.facebook.com/business/help/1665333080167380"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </div>
              </Fragment>
            }
          >
            <InfoTwoToneIcon sx={{ fontSize: '18px', color: '#096F4D' }} />
          </HtmlTooltip>
        }
      />
    </Card>
  );
};

export default DiyAudienceSize;
