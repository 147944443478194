import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ModalHeader from './ModalHeader';
import { RiRefund2Line } from 'react-icons/ri';
import { toCurrency } from '../../utils/numberFormatter';
import PoweredByStripe from '../../assets/images/powered-by-stripe.svg';

interface CreditPreviewProps {
  amount: number;
}

const CreditPreview: React.FC<CreditPreviewProps> = ({ amount }) => {
  return (
    <Grid container spacing={2} sx={{ width: '280px', borderRadius: '10px' }}>
      <Grid item xs={12}>
        <ModalHeader title="Credited Balance" />
      </Grid>

      <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
        <RiRefund2Line size={100} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          You've been awarded for a total credit of
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {toCurrency('USD', amount)}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          This credit will apply on your next invoice.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box component="img" src={PoweredByStripe} alt="" width={130} />
      </Grid>
    </Grid>
  );
};

export default CreditPreview;
