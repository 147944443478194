import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const reduxStore = createStore(
  reducers,
  composeWithDevTools(applyMiddleware()),
);

export default reduxStore;
