import { Card, Container, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import TopNavBar from '../components/Navigation/TopNavBar';
import PublicNavbar from '../components/Navigation/PublicNavbar';
import { AuthContext } from '../context';
import {
  ADMIN,
  AGENCY,
  AUTH_TOKEN,
  BRAND_MANAGER,
  LOGGED_IN_USER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../utils';
import styles from '../assets/styles/components/Layout.module.scss';
import SideNavBar from '../components/Navigation/SideNavBar';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Brand } from '../types';
import CircularLoading from '../components/CircularLoading';
import Toast from '../components/Notification/Toast';
import { isExpired, resetSession } from '../utils/tokenValidator';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBrandTimezone,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
  toggleAlert,
} from '../redux/actions';
import MobileFilters from '../components/Navigation/MobileFilters';
import { drawers } from '../components/Navigation/Drawers';
import { DEFAULT_COLOR_THEME } from '../utils/Styling';

const DRAWER_WIDTH = 240;

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false);
  const pathLocation = useLocation();
  const { state, dispatch: authDispatch } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isAuthenticated = state.isAuthenticated;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const location: Brand = useSelector((state: any) => state.location?.location);
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const redirectUrl = state.authUser?.redirectUrl;

  const pathNames = pathLocation.pathname.split('/');
  const isDashboard = pathNames.includes('dashboard');

  useEffect(() => {
    if (location || brand) {
      const pathNames = pathLocation.pathname.split('/');
      const currentPath = pathNames[1];

      const paths = drawers(
        location ? location : brand,
        isAdmin || isSuperAdmin,
        isAgency,
        isFranchisee,
      ).map((path: any) => {
        return path.pathName;
      });

      if (!paths.includes(currentPath)) {
        navigate('/dashboard');
      }
    }
  }, [brand, location]);

  useEffect(() => {
    if (isExpired(authToken) || !isAuthenticated) {
      resetSession();
      resetReduxSession();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Your session has been expired',
          type: 'error',
          horizontal: 'center',
          vertical: 'top',
        }),
      );
      navigate('/login');
    }
  }, [pathLocation]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resetReduxSession = () => {
    authDispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
        withBrandAccess: null,
        withAgencyAccess: null,
        isAuthenticated: false,
      },
    });
    dispatch(setBrands([]));
    dispatch(setBrand(null));
    dispatch(setBrandTimezone(null));
    dispatch(setAgencies([]));
    dispatch(setAgency(null));
    dispatch(setBrandFilter(null));
    dispatch(setCampaignProvider(null));
    dispatch(setBudgetReportProvider(null));
    dispatch(setLocations([]));
    dispatch(setLocation(null));
  };

  const handleOpenMobileOptions = () => {
    setShowMobileOptions((show) => !show);
  };

  if (!isAuthenticated || (isAuthenticated && !isAdmin && !isSuperAdmin)) {
    if (isSalesperson || isAgency || isBrandManager) {
      return (
        <Navigate to={redirectUrl} state={{ from: pathLocation }} replace />
      );
    }

    return <Navigate to="/login" state={{ from: pathLocation }} replace />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CircularLoading loading={loading} />

      <TopNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        setLoading={setLoading}
        onOpenMobileOptions={handleOpenMobileOptions}
      />

      <SideNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        open={mobileOpen}
        isAdmin={isSuperAdmin || isAdmin}
        isAgency={isAgency}
        brand={brand}
        location={location}
        isFranchisee={isFranchisee}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${isDashboard ? 220 : DRAWER_WIDTH}px)` },
          marginLeft: { md: `${DRAWER_WIDTH}px` },
          backgroundColor: '#f5f5f5',
          marginTop: '50px',
        }}
      >
        {xsOnly ? (
          <MobileFilters open={showMobileOptions} setLoading={setLoading} />
        ) : null}

        {isDashboard ? (
          <Box
            sx={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#F1F0F0',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            <Outlet />
          </Box>
        ) : (
          <Card
            sx={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                // background: "#F1F0F0",
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            {/*
              * Temporary disable
              * Uncomment if needed
            <CardHeader avatar={<SimpleBreadcrumbs />}></CardHeader>
            */}

            <Outlet />
          </Card>
        )}
      </Box>

      <Toast />

      <footer></footer>
    </Box>
  );
};

export default AdminLayout;

export const SalespersonLayout: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false);
  const pathLocation = useLocation();
  const { state, dispatch: authDispatch } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isAuthenticated = state.isAuthenticated;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const location: Brand = useSelector((state: any) => state.location?.location);
  const pathNames = pathLocation.pathname.split('/');
  const isDashboard = pathNames.includes('dashboard');
  const dispatch = useDispatch();
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const redirectUrl = state.authUser?.redirectUrl;

  useEffect(() => {
    if (isExpired(authToken) || !isAuthenticated) {
      resetSession();
      resetReduxSession();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Your session has been expired',
          type: 'error',
          horizontal: 'center',
          vertical: 'top',
        }),
      );
      navigate('/login');
    }
  }, [pathLocation]);

  useEffect(() => {
    if (location || brand) {
      const pathNames = pathLocation.pathname.split('/');
      const currentPath = pathNames[1];

      const paths = drawers(
        location ? location : brand,
        isAdmin || isSuperAdmin,
        isAgency,
        isFranchisee,
      ).map((path: any) => {
        return path.pathName;
      });

      if (!paths.includes(currentPath)) {
        navigate('/dashboard');
      }
    }
  }, [brand, location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resetReduxSession = () => {
    dispatch(setBrands([]));
    authDispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
        withBrandAccess: null,
        withAgencyAccess: null,
        isAuthenticated: false,
      },
    });
    dispatch(setBrand(null));
    dispatch(setBrandTimezone(null));
    dispatch(setAgencies([]));
    dispatch(setAgency(null));
    dispatch(setBrandFilter(null));
    dispatch(setCampaignProvider(null));
    dispatch(setBudgetReportProvider(null));
    dispatch(setLocations([]));
    dispatch(setLocation(null));
  };

  const handleOpenMobileOptions = () => {
    setShowMobileOptions((show) => !show);
  };

  if (
    (!isAuthenticated && !isSuperAdmin) ||
    (!isAuthenticated && !isAdmin) ||
    (!isAuthenticated && !isSalesperson) ||
    (!isAuthenticated && !isAgency)
  ) {
    const chatId: string = searchParams.get('chat');
    const brandId: string = searchParams.get('brandId');
    const url: string = `/login?chat=${chatId}&brandId=${brandId}`;
    if (chatId && brandId) {
      return <Navigate to={url} state={{ from: location }} replace />;
    }

    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CircularLoading loading={loading} />

      <TopNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        setLoading={setLoading}
        onOpenMobileOptions={handleOpenMobileOptions}
      />

      <SideNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        open={mobileOpen}
        isAdmin={isSuperAdmin || isAdmin}
        isAgency={isAgency}
        brand={brand}
        location={location}
        isFranchisee={isFranchisee}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${isDashboard ? 240 : DRAWER_WIDTH}px)` },
          marginLeft: { md: `${DRAWER_WIDTH}px` },
          backgroundColor: '#f5f5f5',
          marginTop: '50px',
        }}
      >
        {xsOnly ? (
          <MobileFilters open={showMobileOptions} setLoading={setLoading} />
        ) : null}

        {isDashboard ? (
          <Outlet />
        ) : (
          <Card
            sx={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                // background: "#F1F0F0",
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            {/*
              * Temporary disable
              * Uncomment if needed
            <CardHeader avatar={<SimpleBreadcrumbs />}></CardHeader>
          */}

            <Outlet />
          </Card>
        )}
      </Box>
      <Toast />
      <footer></footer>
    </Box>
  );
};

export const PublicLayout: React.FC = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const isForgotPassword = pathNames.includes('forgot-password');
  const isPrivacy = pathNames.includes('privacy');
  const isTerms = pathNames.includes('terms');
  const isJoin = pathNames.includes('join');
  const isResetPassword = pathNames.includes('reset-password');
  const showPublicNavbar =
    isForgotPassword || isPrivacy || isTerms || isJoin || isResetPassword;

  return (
    <div>
      {showPublicNavbar ? <PublicNavbar /> : null}

      <main>
        {isPrivacy || isTerms ? (
          <Container>
            <Outlet />
          </Container>
        ) : (
          <div className={styles.layout}>
            <div className={styles.outlet}>
              <Outlet />
            </div>
          </div>
        )}
      </main>

      <Toast />

      <footer />
    </div>
  );
};
