import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface AssetGoogleVideosProps {
  submitForm: any;
  handleInputChange: any;
  submenuForm: any;
  selectedProductAndService: string;
  setIsDirty: any;
}

const AssetGoogleVideos: React.FC<AssetGoogleVideosProps> = ({
  submitForm,
  handleInputChange,
  submenuForm,
  selectedProductAndService,
  setIsDirty,
}) => {
  const vs = () => {
    const vs = [];
    for (let i = 0; i < 5; i++) {
      vs.push('');
    }
    return vs;
  };
  const [videos, setVideos] = useState(
    submenuForm
      ? submenuForm[selectedProductAndService]?.videos || [...vs()]
      : [...vs()],
  );
  const maxItems = googleResourceLimits.videos.items;
  const maxLength = googleResourceLimits.videos.length;

  useEffect(() => {
    if (submenuForm.videos) {
      setVideos(submenuForm.videos);
    }
  }, [submenuForm]);

  // useEffect(() => {
  //   submitForm({
  //     submenu: 'videos',
  //     submenuData: videos,
  //   });
  // }, [videos]);

  return (
    <Box sx={GoogleAssetsContainer}>
      <FloatingButton
        title="Save"
        onClick={() => {
          submitForm({
            submenu: 'videos',
            submenuData: { videos },
          });
        }}
      />
      <Typography
        variant="h6"
        fontWeight={800}
        mb={2}
        color={DEFAULT_COLOR_THEME}
      >
        Video URLs [{videos.length} of {maxItems}]
      </Typography>
      {videos.length > 0 ? (
        <>
          {videos.map((vid: string, index: number) => (
            <Box
              sx={{
                textAlign: 'right',
                marginBottom: '1rem',
                maxWidth: 780,
              }}
            >
              <FormControl variant="outlined" key={index} fullWidth>
                <InputLabel
                  htmlFor={`Video-URLs-${index}`}
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  Video URL {index + 1}
                </InputLabel>
                <OutlinedInput
                  id={`Video-URLs-${index}`}
                  type={'text'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          const newVid = [...videos];
                          newVid.splice(index, 1);
                          setVideos(newVid);
                          setIsDirty(true);
                        }}
                        edge="end"
                      >
                        <MdDeleteOutline color="#aaaaaa" />
                      </IconButton>
                    </InputAdornment>
                  }
                  name={`Video-URLs-${index}`}
                  fullWidth
                  value={vid}
                  inputProps={{ maxLength }}
                  onChange={(e) => {
                    setIsDirty(true);
                    const newVids = [...videos];
                    newVids[index] = e.target.value;
                    setVideos(newVids);
                  }}
                  onInput={handleInputChange}
                />
              </FormControl>

              <Typography variant="body2" color="textSecondary">
                {`${vid.length}/${maxLength}`}
              </Typography>
            </Box>
          ))}
        </>
      ) : null}
      {videos.length < maxItems ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setVideos([...videos, '']);
          }}
        >
          Add Video URL
        </Button>
      ) : null}
    </Box>
  );
};

export default AssetGoogleVideos;
