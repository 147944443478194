import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface GoogleTemplatesDescriptionsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
}

const GoogleTemplatesDescriptions: React.FC<
  GoogleTemplatesDescriptionsProps
> = ({ submitForm, templateForm, setIsDirty }) => {
  const [descriptions, setDescriptions] = useState([]);
  const maxItems = googleResourceLimits.descriptions.items;
  const maxLength = googleResourceLimits.descriptions.length;

  useEffect(() => {
    if (templateForm && templateForm.length > 0) {
      setDescriptions(templateForm);
    }
  }, [templateForm]);

  useEffect(() => {
    submitForm(descriptions);
  }, [descriptions]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `space-between`,
        alignItems: `start`,
      }}
    >
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
        }}
      >
        <Box sx={{}}>
          <TextField
            multiline
            rows={17}
            sx={{
              backgroundColor: 'white',
              border: `2px solid ${DEFAULT_COLOR_THEME}`,
              borderRadius: 2,
            }}
            value={descriptions.join('\n')}
            onChange={(e) => {
              setIsDirty(true);

              const temp = e.target.value.split('\n');
              if (temp.length > maxItems) {
                temp.splice(maxItems, temp.length - maxItems);
              }
              setDescriptions(temp);
            }}
            placeholder="Enter or paste descriptions separated by new lines"
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 800,
          maxHeight: 460,
          overflowY: 'auto',
          width: `100%`,
          borderRadius: 2,
          display: descriptions.length > 0 ? 'block' : 'none',
          ...scrollBarGreen,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Descriptions [{descriptions.length} of {maxItems}]
        </Typography>
        {descriptions.map((description, index) => (
          <Box
            sx={{
              textAlign: 'right',
              marginBottom: '1rem',
              maxWidth: 800,
              position: 'relative',
            }}
          >
            <Button
              sx={{
                position: 'absolute',
                top: 6,
                right: 2,
                padding: '5px',
                backgroundColor: 'transparent',
                zIndex: 1,
                width: 42,
                height: 42,
                minWidth: 32,
                '&:hover': {
                  backgroundColor: '#ffcccc',
                },
                borderRadius: `100%`,
              }}
              onClick={() => {
                const temp = [...descriptions];
                temp.splice(index, 1);
                setDescriptions(temp);
                setIsDirty(true);
              }}
            >
              <MdDeleteOutline size={28} color="#aaaaaa" />
            </Button>
            <TextField
              error={description.length > maxLength || description === ''}
              sx={{
                backgroundColor: 'white',
                '& input': {
                  paddingRight: `50px`,
                },
              }}
              id={`Description-${index}`}
              label={`Description ${index + 1}`}
              variant="outlined"
              name={`Description-${index}`}
              fullWidth
              value={description}
              onChange={(e) => {
                setIsDirty(true);
                const updatedItem = [...descriptions];
                updatedItem[index] = e.target.value;
                setDescriptions(updatedItem);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    description.length > maxLength || description === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {`${description.length}/${maxLength}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    description.length > maxLength || description === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {description.length > maxLength ? 'Max length reached' : ''}
                {description === '' ? 'Description cannot be empty' : ''}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesDescriptions;
