/**
 * Breakpoint Utils
 * @description Reusable function for MUI breakpoint view
 * @author Angelo David <angelod@codev.com>
 * @reference https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints
 * @return Boolean
 */
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const XsOnly = () => {
  return useMediaQuery(useTheme().breakpoints.only('xs'));
};

const XsAndUp = () => {
  return useMediaQuery(useTheme().breakpoints.up('xs'));
};

const SmOnly = () => {
  return useMediaQuery(useTheme().breakpoints.only('sm'));
};

const SmAndUp = () => {
  return useMediaQuery(useTheme().breakpoints.up('sm'));
};

const SmAndDown = () => {
  return useMediaQuery(useTheme().breakpoints.down('sm'));
};

const MdOnly = () => {
  return useMediaQuery(useTheme().breakpoints.only('md'));
};

const MdAndUp = () => {
  return useMediaQuery(useTheme().breakpoints.up('md'));
};

const MdAndDown = () => {
  return useMediaQuery(useTheme().breakpoints.down('md'));
};

const LgOnly = () => {
  return useMediaQuery(useTheme().breakpoints.only('lg'));
};

const LgAndUp = () => {
  return useMediaQuery(useTheme().breakpoints.up('lg'));
};

const LgAndDown = () => {
  return useMediaQuery(useTheme().breakpoints.down('lg'));
};

const XlOnly = () => {
  return useMediaQuery(useTheme().breakpoints.only('xl'));
};

const XlAndDown = () => {
  return useMediaQuery(useTheme().breakpoints.down('xl'));
};

export {
  XsOnly,
  XsAndUp,
  SmOnly,
  SmAndUp,
  SmAndDown,
  MdOnly,
  MdAndDown,
  MdAndUp,
  LgOnly,
  LgAndDown,
  LgAndUp,
  XlOnly,
  XlAndDown,
};
