/**
 * Titleize Text
 * @example test name => Test Name
 */
const capitalized = (value: string) => {
  return value.toLowerCase().replace(/\b[a-z](?=[a-z])/g, (letter: string) => {
    return letter.toUpperCase();
  });
};

/**
 * Humanized String
 * @example test-name => Test Name
 */
export const humanizeString = (value: string) => {
  if (!value) {
    return '';
  }

  const normalizedString = value.replace(/-/g, ' ');
  return capitalized(normalizedString);
};

/**
 * Copy Text to Clipboard
 */
export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const camelCaseToHumanized = (value: string) => {
  return value
    .match(/^[a-z]+|[A-Z][a-z]*/g)
    .map(function (x) {
      return x[0].toUpperCase() + x.substr(1).toLowerCase();
    })
    .join(' ');
};

/**
 * Contant Value to Titleize
 * @example TEST_NAME => Test Name
 */
export const constantStringToHumanized = (value: string) => {
  const normalizedString = value.replace(/_/g, ' ');
  return capitalized(normalizedString);
};

/**
 * Contant Value to Titleize
 * @example test_name => Test Name
 */
export const snakeCaseToHumanized = (value: string) => {
  if (!value) return '';

  const normalizedString = value.replace(/_/g, ' ');

  return capitalized(normalizedString);
};

/**
 * String to Snake Case
 * @example if not lowered cased - Test Name => Test_Name
 *          if lowered cased - Test Name => test_name
 */
export const stringToSnakeCase = (value: string, lowerCased?: boolean) => {
  if (!value) return '';

  const normalizedString = value.replace(/\s+/g, '_');

  if (lowerCased) return normalizedString.toLowerCase();

  return normalizedString;
};
