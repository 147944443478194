import axios from "axios";
import { ExpressTheme } from "../types";
import { apiBaseUrl } from "./api";

export const createExpressTheme = async (obj: ExpressTheme) => {
  const data = await axios.post(`${apiBaseUrl}/theme`, obj);

  return data?.data;
};

export const editExpressTheme = async (
  themeName: string,
  obj: ExpressTheme
) => {
  const data = await axios.put(`${apiBaseUrl}/theme/${themeName}`, obj);

  return data?.data;
};

export const getAppTheme = async (themeName: string) => {
  const data = await axios.get(`${apiBaseUrl}/theme/${themeName}`);

  return data?.data;
};
