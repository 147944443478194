import axios from 'axios';
import { apiBaseUrl } from './api';

export const fetchGoogleCustomers = async (refreshToken: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/customers?refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const fetchGoogleKeywordIdeas = async (params: {
  keywords: string[];
  url: string;
  brandId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/keywords/ideas`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssetsAccounts = async (params: {
  brandId: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteGoogleAssetsImage = async (params: {
  brandId: string;
  public_id: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${apiBaseUrl}/ads/google/assets/image`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssets = async (params: {
  brandId: string;
  ps: string;
}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAssetsByBrand = async (brandId: string) => {
  // /assets/brand

  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/assets/brand`, {
      params: {
        brandId,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleAssetsAccount = async (params: {
  brandId: string;
  customer_id: string;
  account_id: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/assets`,
      params,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const uploadGoogleAssetImage = async (params: {
  brandId: string;
  resourceName: string;
  image: File | Blob | string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/assets/image`,
      params,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAds = async (brandId: string) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/ads`, {
      params: {
        brandId,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleTemplates = async (brandId: string) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/templates?brandId=${brandId}`,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleTemplates = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.put(
      `${apiBaseUrl}/ads/google/templates`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const createGoogleCampaign = async (params: any) => {
  const { brandId, campaign } = params;
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/campaigns`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const assembleGoogleAds = async (params: any) => {
  const { brandId, budget, budgetName } = params;
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/adcreation`,
      params,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogle = async (params: { brandId: string; db: string }) => {
  const { data } = await axios.get(`${apiBaseUrl}/ads/google/db`, {
    params,
  });
  return data;
};

export const fetchAssetLocationSet = async (params: { brandId: string }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/ads/google/assets/locations`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdBudget = async (params: {
  brandId: string;
  ad: any;
  budget: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        budget: params.budget,
        modify: 'budget',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdLocations = async (params: {
  brandId: string;
  ad: any;
  locations: any;
  locationSet: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        locations: params.locations,
        locationSet: params.locationSet,
        modify: 'locations',
      },
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdKeywords = async (params: {
  brandId: string;
  ad: any;
  keywords: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        keywords: params.keywords,
        modify: 'keywords',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdSitelinks = async (params: {
  brandId: string;
  ad: any;
  sitelinks: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        sitelinks: params.sitelinks,
        modify: 'sitelinks',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdHeadlines = async (params: {
  brandId: string;
  ad: any;
  headlines: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        headlines: params.headlines,
        modify: 'headlines',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdDescriptions = async (params: {
  brandId: string;
  ad: any;
  descriptions: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        descriptions: params.descriptions,
        modify: 'descriptions',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdFinalUrls = async (params: {
  brandId: string;
  ad: any;
  finalurl: string;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        finalurl: params.finalurl,
        modify: 'finalurl',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveModifiedAdCallouts = async (params: {
  brandId: string;
  ad: any;
  callouts: any;
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseUrl}/ads/google/admodification`,
      {
        brandId: params.brandId,
        ad: params.ad,
        callouts: params.callouts,
        modify: 'callouts',
      },
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const saveAssetLocationSet = async (params: {
  brandId: string;
  _id: string;
  name: string;
  locations: any[];
  isExisting: boolean;
  containingAds?: any[];
}) => {
  try {
    const { brandId, _id, name, locations, isExisting, containingAds } = params;
    const { data } = await axios.put(
      `${apiBaseUrl}/ads/google/assets/locations`,
      {
        brandId,
        _id,
        name,
        locations,
      },
    );

    if (params.isExisting) {
      if (!containingAds.length) return data;

      // for (const ad of containingAds) {
      //   const overwrite = await axios.post(
      //     `${apiBaseUrl}/ads/google/admodification`,
      //     { ad: ad, locations, modify: 'locations' },
      //   );
      // }

      const promises = Promise.all(
        containingAds.map((ad: any) => {
          return axios.post(`${apiBaseUrl}/ads/google/admodification`, {
            brandId,
            ad: ad,
            locations,
            modify: 'locations',
          });
        }),
      );

      const results = await promises;
      return results;
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleLocationSuggestions = async (params: {}) => {
  try {
    const { data } = await axios.get(`${apiBaseUrl}/ads/google/locations`, {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const setGoogleAssets = async (params: {
  brandId: string;
  product_and_service: string;
  data: any;
}) => {
  try {
    const { data } = await axios.put(`${apiBaseUrl}/ads/google/assets`, params);

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGoogleAdsAccount = async (
  brandId: string,
  refreshToken: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ads/google/accounts`, {
    params: {
      brandId,
      refreshToken,
    },
  });

  return data?.data;
};

export const syncCustomerIds = async (
  brandId: string,
  customerIds: string[],
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/google/customers/sync`, {
    brandId,
    customerIds,
  });

  return data?.data;
};

export const fetchGoogleCampaigns = async (
  brandId: string,
  refreshToken: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/campaigns?brandId=${brandId}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const setGoogleKeywords = async (params: {
  brandId: string;
  matchType: string[];
  keyword: {
    match_type: string;
    text: string;
    resource_name: string;
    ad_group_resource_name: string;
    negative?: boolean | undefined;
  }[];
}) => {
  const newkeywords = await axios.post(
    `${apiBaseUrl}/report/brand/campaign/google/keywords`,
    {
      ...params,
    },
  );

  return newkeywords?.data;
};
