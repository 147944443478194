import axios from 'axios';
import { apiBaseUrl } from './api';
import { Moment } from 'moment';

export const getBrandAnalytics = async (
  brandId: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/analytics/${brandId}?fromDate=${fromDate}&toDate=${toDate}`,
  );

  return data?.data;
};

export const getBrandCampaignAnalytics = async (
  brandId: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign?brandId=${brandId}&fromDate=${fromDate}&toDate=${toDate}`,
  );

  return data?.data;
};

export const getSingleCampaignAnalytics = async (
  brandId: string,
  fromDate: Moment,
  toDate: Moment,
  type: string,
  id: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/single/${id}`,
    {
      params: {
        brandId,
        type,
        fromDate: fromDate.toString(),
        toDate: toDate.toString(),
      },
    },
  );

  return data?.data;
};

export const getGoogleCampaignMetrics = async (
  brandId: string,
  refreshToken: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/google?brandId=${brandId}&fromDate=${fromDate}&toDate=${toDate}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const getGoogleCampaignMetricsNew = async (params: {
  brandId: string;
  refreshToken: string;
  fromDate: Moment | string;
  toDate: Moment | string;
  campaignId: string;
}) => {
  const url = `${apiBaseUrl}/report/brand/campaign/google-reports`;

  const response = await axios.get(url, { params });
  const data = response.data;
  return data;
};

export const fetchGoogleCampaignLocationMetrics = async (
  brandId: string,
  refreshToken: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/google/location?brandId=${brandId}&refreshToken=${refreshToken}&fromDate=${fromDate}&toDate=${toDate}`,
  );

  return data?.data;
};

export const fetchGoogleCampaignKeywordMetrics = async (
  brandId: string,
  refreshToken: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/google/keyword?brandId=${brandId}&refreshToken=${refreshToken}&fromDate=${fromDate}&toDate=${toDate}`,
  );

  return data?.data;
};

export const fetchGoogleCampaignSearchTermMetrics = async (
  brandId: string,
  refreshToken: string,
  fromDate: Moment,
  toDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/google/search-term?brandId=${brandId}&refreshToken=${refreshToken}&fromDate=${fromDate}&toDate=${toDate}`,
  );

  return data?.data;
};

export const fetchFacebookCampaignDashboardData = async (
  brandId: string,
  provider: string,
  refreshToken: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/dashboard?brandId=${brandId}&provider=${provider}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const fetchFacebookCampaignLineChartData = async (
  brandId: string,
  provider: string,
  refreshToken: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/brand/campaign/dashboard/line?brandId=${brandId}&provider=${provider}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const getDiyScoreboardScatterData = async (
  brandId: string,
  fromDate: Moment,
  endDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/scoreboard/locations?fromDate=${fromDate}&toDate=${endDate}`,
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const getDiyScoreboardTemplateData = async (
  brandId: string,
  fromDate: Moment,
  endDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/report/scoreboard/templates?fromDate=${fromDate}&toDate=${endDate}`,
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};

export const getAiReportData = async (
  brandId: string,
  fromDate: Moment,
  endDate: Moment,
  platform: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ai/report?fromDate=${fromDate}&toDate=${endDate}`,
    {
      params: {
        brandId,
        platform,
      },
    },
  );

  return data?.data;
};

export const getAllBrandsAiReportData = async (
  fromDate: Moment,
  endDate: Moment,
  platform: string,
  salespersonId?: string,
  agencyId?: string,
) => {
  let params: any = { platform };

  if (salespersonId) {
    params = { ...params, salespersonId };
  }

  if (agencyId) {
    params = { ...params, agencyId };
  }

  const data = await axios.get(
    `${apiBaseUrl}/ai/report/all?fromDate=${fromDate}&toDate=${endDate}`,
    { params },
  );

  return data?.data;
};

export const getSingleAiReportSettings = async (platform: string) => {
  const data = await axios.get(`${apiBaseUrl}/ai/report/setting`, {
    params: {
      platform,
    },
  });

  return data?.data;
};

export const getAiInsightsData = async (
  adId: string,
  fromDate: Moment,
  endDate: Moment,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ai/insightz/data?fromDate=${fromDate}&toDate=${endDate}`,
    {
      params: { adId },
    },
  );

  return data?.data;
};
