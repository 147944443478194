import axios from 'axios';
import { ProfileFormValues, Salesperson } from '../types';
import { apiBaseUrl } from './api';

export const createSalesperson = async (obj: Salesperson) => {
  const data = await axios.post(`${apiBaseUrl}/salesperson/create`, obj);

  return data?.data;
};

export const editSalesperson = async (
  obj: Salesperson,
  salespersonId: string,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/salesperson/edit/${salespersonId}`,
    obj,
  );

  return data?.data;
};

export const editSalespersonProfile = async (
  obj: ProfileFormValues,
  salespersonId: string,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/salesperson/edit/${salespersonId}`,
    obj,
  );

  return data?.data;
};

export const deleteSalesperson = async (salespersonId: string) => {
  try {
    const data = await axios.delete(
      `${apiBaseUrl}/salesperson/remove/${salespersonId}`,
    );

    return data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSalesperson = async (
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
  fromSort?: boolean,
  getAll?: boolean,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?${getAll ? '' : 'role=salesperson'}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        fromSort ? `&fromSort=${true}` : ''
      }${diy !== undefined ? `&diy=${diy}` : ''}`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSalespersonByBrand = async (
  brandId?: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
  fromSort?: boolean,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?role=salesperson${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}&brandId=${brandId}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        fromSort ? `&fromSort=${true}` : ''
      }${diy !== undefined ? `&diy=${diy}` : ''}`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getSalespersonAssociatedToPage = async (
  pageId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?pageId=${pageId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}&role=salesperson`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getAllBrandManagers = async (
  pageNum?: number,
  limit?: number,
  filter?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?role=brand-manager${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getAllMarketingManagers = async (
  pageNum?: number,
  limit?: number,
  filter?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?role=marketing-manager${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getAllBrandManagersByBrand = async (
  brandId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?role=brand-manager${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}&brandId=${brandId}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getManagersAssociatedToPage = async (
  pageId: string,
  pageNum?: number,
  limit?: number,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/salesperson/get?role=brand-manager&pageId=${pageId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getSingleSalesperson = async (id: string) => {
  try {
    const data = await axios.get(`${apiBaseUrl}/salesperson/get/${id}`);

    return data?.data;
  } catch (error) {
    console.log(error);
  }
};
