import axios from 'axios';
import { apiBaseUrl } from './api';
import { AiInsight } from '../types';

export const getOpenAiResponse = async (question: string) => {
  const data = await axios.post(`${apiBaseUrl}/ai/openai`, { question });

  return data?.data;
};

export const getAdCopyAnalysis = async (
  adCopy: string,
  brandId: string,
  templateId: string,
) => {
  let params: any = { adCopy, brandId, prompt: `Grade ad "${adCopy}"` };

  if (templateId) {
    params = { ...params, templateId };
  }

  const data = await axios.post(`${apiBaseUrl}/ai/ad-copy/analyze`, params);

  return data?.data;
};

export const generateAdCopy = async (templateId: string, prompt: string) => {
  let params: any = { prompt };

  if (templateId) {
    params = { ...params, templateId };
  }
  const data = await axios.post(`${apiBaseUrl}/ai/ad-copy/generate`, params);

  return data?.data;
};

export const getImproveAdByTemplate = async (
  brandId: string,
  templateId: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ai/ad-improve/single`, {
    params: {
      brandId,
      templateId,
    },
  });

  return data?.data;
};

export const getAiInsightzAnalysis = async (
  insight: AiInsight,
  type: string,
  brandId: string,
) => {
  const data = await axios.post(`${apiBaseUrl}/ai/report/analyze`, {
    type,
    insight,
    brandId,
  });

  return data?.data;
};

export const getAiInsightzAnalysisDebug = async (
  prompt: string,
  type: string,
) => {
  const data = await axios.post(`${apiBaseUrl}/ai/report/analyze/debug`, {
    prompt,
    type,
  });

  return data?.data;
};
