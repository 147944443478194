import {
  Box,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  IconButton,
  Autocomplete,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Modal,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  formatNumber,
  GoogleAssetsContainer,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { GoogleLogin } from '@react-oauth/google';
import GoogleAdsAccountForm from '../../GoogleAdsAccountForm';
import { adsProvider } from '../../../../utils/constants/facebookAds';
import AssetGoogleImages from './GGLAssetsImages';
import {
  fetchAssetLocationSet,
  fetchGoogle,
  fetchGoogleLocationSuggestions,
  saveAssetLocationSet,
} from '../../../../services/googleAds';
import GoogleMapComponent from '../../../Maps/GoogleMap';
import CircularLoading from '../../../CircularLoading';
import { MdDeleteOutline, MdSearch } from 'react-icons/md';
import CountrySelectMenu from '../../../Select/CountrySelectMenu';
import { MdOutlineStarOutline, MdDriveFileRenameOutline } from 'react-icons/md';
import { TiWarningOutline } from 'react-icons/ti';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { FloatingButton } from '../../../Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import GGLAssetsLocationTable from './GGLAssetsLocationTable';
import { add } from 'lodash';
import { DEFAULT_COLOR_THEME } from '../../../../utils';

interface AssetGoogleLocationsProps {
  brand: any;
  floatingSave?: boolean;
}

const AssetGoogleLocations: React.FC<AssetGoogleLocationsProps> = ({
  brand,
  floatingSave = false,
}) => {
  const [assetLocations, setAssetLocations] = useState<any[]>([]);
  const [isExisting, setIsExisting] = useState(false);
  const [confirmOverwriteModal, setConfirmOverwriteModal] = useState(false);
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [locationSetId, setLocationSetId] = useState('');
  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryFilter, setCountryFilter] = useState({
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  });
  const [includedLocations, setIncludedLocations] = useState([]);
  const [excludedLocations, setExcludedLocations] = useState([]);
  const [locationSetName, setLocationSetName] = useState('');
  const [locationFilters, setLocationFilters] = useState(`All`);
  const [customBulk, setCustomBulk] = useState('');
  const [googleAds, setGoogleAds] = useState([]);

  const [IsCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
  };

  const handleCloseConfirmOverwrite = () => {
    setConfirmOverwriteModal(false);
    setConfirmOverwrite(false);
  };

  const handleOnSelectCountry = (e: any, value: any) => {
    console.log(value);
    setCountryFilter(value);
  };

  const fetchAssets = () => {
    fetchAssetLocationSet({
      brandId: brand._id,
    }).then((data) => {
      console.log(data);
      setAssetLocations(
        data.map((x: any) => {
          return {
            ...x,
            locations: x.locations.map((y: any) => {
              return {
                ...y,
                reach: formatNumber(y.reach),
              };
            }),
          };
        }),
      );
    });
  };

  const fetchAds = () => {
    console.log(brand);
    fetchGoogle({
      brandId: brand._id,
      db: 'googleAds',
    }).then((data) => {
      console.log(data);
      setGoogleAds(data);
    });
  };

  const cancelCreateOrEdit = () => {
    setIsCreating(false);
    setLocationSetName('');
    setIncludedLocations([]);
    setLocationSetId('');
    setIsExisting(false);
    setConfirmOverwrite(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (!locationSetName && locationSetName.trim() === '') {
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Name cannot be blank`,
          type: 'error',
        }),
      );
      setIsLoading(false);
      return;
    }

    if (includedLocations.length === 0) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Please include at least one location`,
          type: 'error',
        }),
      );
      setIsLoading(false);
      return;
    }

    if (isExisting && !confirmOverwrite) {
      setConfirmOverwriteModal(true);
      setIsLoading(false);
      return;
    }

    setConfirmOverwriteModal(false);
    const saveLocations = await saveAssetLocationSet({
      brandId: brand._id,
      _id: locationSetId,
      name: locationSetName,
      locations: includedLocations,
      isExisting: isExisting,
      containingAds: googleAds?.filter(
        (ad) => ad.locationSet._id === locationSetId,
      ),
    });

    dispatch(
      toggleAlert({
        toggle: true,
        message: `Location Set Saved`,
      }),
    );
    setConfirmOverwrite(false);

    console.log(`$saveLocations`, saveLocations);
    setIsLoading(false);
    setIsCreating(false);
    setLocationSetName('');
    setIncludedLocations([]);
    setLocationSetId('');
    fetchAssets();
    return {
      name: locationSetName,
      locations: includedLocations,
    };
  };

  const getBulkSuggestions = async (q: string) => {
    setIsLoading(true);
    return Promise.all(
      q.split('\n')?.map(async (line) => {
        const res = await getSuggestion(line, null);
        res[0].star = true;
        return { primary: res[0], others: res.slice(1) };
      }),
    ).then((data) => {
      console.log(data);
      const primaries = data?.map((x) => x.primary);
      const others = data?.reduce((acc, x) => [...x.others, ...acc], []);
      setIsLoading(false);
      setLocationList([...primaries, ...others]);
    });
  };

  const handleEdit = (location: any) => {
    setIsExisting(true);
    setIsCreating(true);
    setLocationSetName(location.name);
    setIncludedLocations(location.locations);
    setLocationSetId(location._id);
    setConfirmOverwrite(false);
  };

  const getSuggestion = async (q: string, filter: string | null = null) => {
    const { data } = await fetchGoogleLocationSuggestions({
      brandId: brand._id,
      query: q,
      ccode: countryFilter.code,
    });

    console.log(data);

    if (data) {
      const filteredData = data
        // .filter(
        //   (x: any) =>
        //     (x.geoTargetConstant.targetType === filter || filter === null) &&
        //     customLocationCountry &&
        //     x.geoTargetConstant.canonicalName
        //       .toLowerCase()
        //       .includes(customCountry.toLowerCase()),
        // )
        .map(
          (location: {
            geoTargetConstant: {
              resourceName: string; //'geoTargetConstants/21167';
              status: string; //'ENABLED';
              id: string; // '21167';
              name: string; //'New York';
              countryCode: string; // 'US';
              targetType: string; //'State';
              canonicalName: string; //'New York,United States';
            };
            geoTargetConstantParents: [
              {
                resourceName: string; // 'geoTargetConstants/2840';
                status: string; // 'ENABLED';
                id: string; //'2840';
                name: string; //'United States';
                countryCode: string; //'US';
                targetType: string; // 'Country';
              },
            ];
            locale: string; //'en';
            reach: string; //'33200000';
            searchTerm: string; // 'new york';
          }) => {
            return {
              id: location.geoTargetConstant.id,
              resourceName: location.geoTargetConstant.resourceName,
              name: location.geoTargetConstant.name,
              address: location.geoTargetConstant.canonicalName,
              reach: location.reach,
              targetType: location.geoTargetConstant.targetType,
              countryCode: location.geoTargetConstant.countryCode,
            };
          },
        );
      setLocationList(filteredData);
      return filteredData;
    } else {
      return [];
    }
  };

  const customBulkChange = (e: any) => {
    setCustomBulk(e.target.value);
  };

  const searchBulk = () => {
    getBulkSuggestions(customBulk);
  };

  useEffect(() => {
    fetchAssets();
    fetchAds();
  }, []);

  return (
    <Box
      sx={{
        ...GoogleAssetsContainer,
        position: `relative`,
      }}
    >
      <CircularLoading loading={isLoading} />
      {IsCreating ? (
        <Box
          sx={{
            display: `flex`,
            gap: 2,
            fontSize: `0.9em`,
          }}
        >
          <Box
            sx={{
              width: `48%`,
            }}
          >
            <Box>
              <Box>Filter target geolocation based on a country:</Box>
              <CountrySelectMenu
                value={countryFilter}
                onChange={handleOnSelectCountry}
                label=""
              />
            </Box>

            <Box
              sx={{
                marginY: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  padding: '1rem',
                  border: '1px solid #aaa',
                  borderRadius: '5px',
                }}
              >
                <Box>
                  <TextField
                    label="Enter Locations"
                    fullWidth
                    multiline
                    value={customBulk}
                    onChange={customBulkChange}
                    placeholder='Enter locations separated by new line. Example: "New York"'
                    minRows={3}
                    sx={{
                      ':placeholder': {
                        color: '#555555',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <MdSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button onClick={searchBulk} variant="contained">
                    Search Locations
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  border: `1px solid #555555`,
                  borderRadius: `5px`,
                  paddingY: 1,
                  paddingX: 2,
                  marginY: 2,
                  position: `relative`,
                }}
              >
                <Typography
                  sx={{
                    position: `absolute`,
                    top: `-10px`,
                    left: `10px`,
                    backgroundColor: `#fff`,
                    padding: `0 10px`,
                    fontSize: `0.9em`,
                  }}
                >
                  Filter search results:
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `All`}
                        onChange={() => {
                          if (locationFilters === `All`) {
                            setLocationFilters(`Postal Code`);
                          } else {
                            setLocationFilters(`All`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>All</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `Postal Code`}
                        onChange={() => {
                          if (locationFilters === `Postal Code`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`Postal Code`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>Zip</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `City`}
                        onChange={() => {
                          if (locationFilters === `City`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`City`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>City</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `DMA Region`}
                        onChange={() => {
                          if (locationFilters === `DMA Region`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`DMA Region`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>DMA</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `County`}
                        onChange={() => {
                          if (locationFilters === `County`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`County`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>County</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `State`}
                        onChange={() => {
                          if (locationFilters === `State`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`State`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>State</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={locationFilters === `Country`}
                        onChange={() => {
                          if (locationFilters === `Country`) {
                            setLocationFilters(`All`);
                          } else {
                            setLocationFilters(`Country`);
                          }
                        }}
                      />
                    }
                    label={<Typography fontSize={`0.9em`}>Country</Typography>}
                  />
                </FormGroup>
              </Box>
              <Box
                sx={{
                  display: locationList.length > 0 ? 'flex' : 'none',
                  flexDirection: `column`,

                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <Box>Location Results:</Box>
                {locationList.length > 0
                  ? locationList
                      .filter((location) => {
                        if (locationFilters === `All`) {
                          return true;
                        } else {
                          return location.targetType === locationFilters;
                        }
                      })
                      .map((location) =>
                        location.divider ? (
                          <Box
                            sx={{
                              borderBottom: `1px solid #555555`,
                              minHeight: `10px`,
                            }}
                          ></Box>
                        ) : (
                          <Box
                            sx={{
                              border: '1px solid #000',
                              padding: '10px',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: `relative`,
                            }}
                          >
                            <Box
                              sx={{
                                display: location.star ? 'block' : 'none',
                                position: `absolute`,
                                top: `-5px`,
                                left: `10px`,
                                backgroundColor: `#fff`,
                                padding: `0 5px`,
                                rounded: `100%`,
                              }}
                            >
                              <Tooltip title="Top Result">
                                <MdOutlineStarOutline color="gold" size={16} />
                              </Tooltip>
                            </Box>

                            <Box
                              sx={{
                                width: `50%`,
                                paddingTop: `5px`,
                              }}
                            >
                              <Box
                                sx={{ fontWeight: 800, padding: 0, margin: 0 }}
                              >
                                {location.name}
                              </Box>
                              {location.address}
                            </Box>
                            <Box
                              sx={{
                                width: `20%`,
                              }}
                            >
                              Reach:
                              <br />
                              {formatNumber(location.reach)}
                            </Box>
                            <Box
                              sx={{
                                width: `10%`,
                              }}
                            >
                              Type: {location.targetType}
                            </Box>
                            <Box
                              sx={{
                                textAlign: `right`,
                                width: `20%`,
                              }}
                            >
                              <Button
                                disabled={includedLocations
                                  .map((x) => x.id)
                                  .includes(location.id)}
                                onClick={() => {
                                  console.log(location);
                                  //check if already included
                                  if (
                                    includedLocations
                                      .map((x) => x.id)
                                      .includes(location.id)
                                  ) {
                                    return;
                                  } else {
                                    setIncludedLocations([
                                      ...includedLocations,
                                      location,
                                    ]);
                                  }
                                }}
                              >
                                {includedLocations
                                  .map((x) => x.id)
                                  .includes(location.id)
                                  ? 'Included'
                                  : 'Include'}
                              </Button>
                              <Button
                                sx={{ display: 'none' }}
                                onClick={() => {
                                  setExcludedLocations([
                                    ...excludedLocations,
                                    location,
                                  ]);
                                }}
                              >
                                Exclude
                              </Button>
                            </Box>
                          </Box>
                        ),
                      )
                  : null}
              </Box>
            </Box>
          </Box>
          <Box width={`50%`}>
            <Modal
              open={confirmOverwriteModal}
              // onClose={handleCloseConfirmOverwrite}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color={DEFAULT_COLOR_THEME}
                  sx={{
                    display: `flex`,
                    justifyContent: `start`,
                    gap: 1,
                    alignItems: `center`,
                    fontWeight: 600,
                  }}
                >
                  <TiWarningOutline color={DEFAULT_COLOR_THEME} size={28} /> Are
                  you sure?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Changes to this location set will affect all ads that are
                  using it. Are you sure you want to make changes for this
                  location set?
                </Typography>
                {googleAds?.filter((ad) => ad.locationSet._id === locationSetId)
                  .length > 0 ? (
                  <Box sx={{ marginY: 2 }}>
                    <Typography>Ads using this location set:</Typography>
                    <Box sx={{ maxHeight: `400px`, overflowY: `auto` }}>
                      {googleAds
                        ?.filter((ad) => ad.locationSet._id === locationSetId)
                        .map((ad) => (
                          <Box sx={{ paddingLeft: 2 }}>• {ad.name}</Box>
                        ))}
                    </Box>
                  </Box>
                ) : null}
                <Box sx={{ marginY: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmOverwrite}
                        onChange={() => {
                          setConfirmOverwrite(!confirmOverwrite);
                        }}
                      />
                    }
                    label="I understand the implications of overwriting this location set"
                  />
                </Box>
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    gap: 2,
                  }}
                >
                  <Button onClick={handleCloseConfirmOverwrite}>Cancel</Button>
                  <Button
                    variant="contained"
                    disabled={!confirmOverwrite}
                    onClick={handleSave}
                  >
                    {confirmOverwrite
                      ? 'Confirm and Save'
                      : 'you must confirm to save'}
                  </Button>
                </Box>
              </Box>
            </Modal>
            <FloatingButton fixed title="Save" onClick={handleSave} />

            <Button
              sx={{
                position: `fixed`,
                bottom: `40px`,
                left: `300px`,
                zIndex: 1000,
                borderRadius: `25px`,
                bgcolor: `rgba(50, 50, 50, 0.1)`,
                color: `#000000`,
                height: `48px`,
                paddingX: `10px`,
                '&:hover': {
                  bgcolor: `rgba(50, 50, 50, 0.2)`,
                },
              }}
              variant="contained"
              onClick={cancelCreateOrEdit}
            >
              Cancel
            </Button>

            <Box
              sx={{
                marginBottom: 2,
              }}
            >
              <TextField
                label="Location Set Name"
                fullWidth
                multiline
                value={locationSetName}
                placeholder="Enter a name for this location set"
                onChange={(e) => setLocationSetName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <MdDriveFileRenameOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <GoogleMapComponent zoom={3} places={includedLocations} />
            <Box
              sx={{
                marginY: 2,
              }}
            >
              <Box
                sx={{
                  padding: 1,
                  border: `1px solid #555555`,
                  borderRadius: `5px`,
                  position: `relative`,
                  display: includedLocations.length > 0 ? `block` : `none`,
                }}
              >
                <Box
                  sx={{
                    position: `absolute`,
                    top: `-10px`,
                    left: `10px`,
                    backgroundColor: `#fff`,
                    padding: `0 10px`,
                    display: includedLocations.length > 0 ? `block` : `none`,
                  }}
                >
                  Included Locations:
                </Box>
                {includedLocations.map((il) => (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '10px',
                      borderRadius: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginY: '10px',
                    }}
                  >
                    <Box sx={{ display: `none` }}>{il.name}</Box>

                    <Box
                      sx={{
                        width: `50%`,
                      }}
                    >
                      {il.address}
                    </Box>
                    <Box
                      sx={{
                        width: `20%`,
                      }}
                    >
                      Reach:
                      <br />
                      {formatNumber(il.reach)}
                    </Box>
                    <Box
                      sx={{
                        width: `10%`,
                      }}
                    >
                      Type: {il.targetType}
                    </Box>
                    <Box
                      sx={{
                        textAlign: `right`,
                        width: `20%`,
                      }}
                    >
                      <Button
                        sx={{
                          float: `right`,
                          minWidth: 0,
                          height: 50,
                          width: 50,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          borderRadius: `50%`,
                          ':hover': {
                            backgroundColor: `#ff7777`,
                            color: `#fff`,
                          },
                        }}
                        onClick={() => {
                          setIncludedLocations(
                            includedLocations.filter((x) => x.id !== il.id),
                          );
                        }}
                      >
                        <MdDeleteOutline size={24} color={`#999999`} />
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}

      {!IsCreating ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Button
              onClick={() => {
                setIsCreating(true);
              }}
              variant="contained"
            >
              Create New Location Set
            </Button>
          </Box>

          <Box>
            {assetLocations.length > 0
              ? assetLocations.map((location, i) => (
                  <Accordion
                    sx={{
                      maxWidth: 1100,
                      background: `#efefef`,
                      ':hover': {
                        background: `#e6ffd4`,
                      },
                    }}
                    defaultExpanded={i === 0 ? true : false}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Typography fontSize={`1.2em`} fontWeight={800}>
                          {location.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '320px',
                          }}
                        >
                          <Typography fontFamily={`Courier`}>
                            {location.locations.length} locations
                          </Typography>
                          <Typography marginRight={2} fontFamily={`Courier`}>
                            Used in{' '}
                            {
                              googleAds?.filter(
                                (ad) => ad.locationSet._id === location._id,
                              ).length
                            }{' '}
                            Ads
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        backgroundColor: `#fff`,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            //maxWidth: 700,
                            maxHeight: `500px`,
                            overflowY: `scroll`,
                            ...scrollBarGreen,

                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GGLAssetsLocationTable
                            locations={location.locations}
                          />

                          {googleAds?.filter(
                            (ad) => ad.locationSet._id === location._id,
                          ).length > 0 ? (
                            <Box marginBottom={2}>
                              <Typography fontSize={`1em`} fontWeight={800}>
                                Google Campaigns using this location set
                              </Typography>
                              <List>
                                {googleAds
                                  ?.filter(
                                    (ad) => ad.locationSet._id === location._id,
                                  )
                                  .map((ad) => (
                                    <ListItem disablePadding>
                                      {ad.campaign}
                                    </ListItem>
                                  ))}
                              </List>
                            </Box>
                          ) : null}
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleEdit(location);
                            }}
                          >
                            Edit
                          </Button>
                          <Button variant="contained">Delete</Button>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))
              : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AssetGoogleLocations;
