import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import {
  ChangeEventType,
  StripeCompanyForm,
  StripeIndividualForm,
} from '../../../../types';
import { stripeBusinessType } from '../../../../utils/constants/stripe';
import CountrySelectMenu from '../../../Select/CountrySelectMenu';
import AccountActions from '../AccountActions';

interface StripeAddressFormProps {
  companyForm: StripeCompanyForm;
  individualForm: StripeIndividualForm;
  onChangeIndividual: (e: ChangeEventType) => void;
  onChangeCompany: (e: ChangeEventType) => void;
  businessType: string;
  onNext: () => void;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onClose: (account?: any) => void;
}

const StripeAddressForm: React.FC<StripeAddressFormProps> = ({
  companyForm,
  individualForm,
  onChangeIndividual,
  onChangeCompany,
  businessType,
  onNext,
  activeStep,
  onBack,
  steps,
  onClose,
}) => {
  return (
    <Box component="form" onSubmit={onNext} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="line1"
            name="line1"
            label="Address Line 1 (Company Name, Street, PO Box)"
            onChange={
              businessType === stripeBusinessType.COMPANY
                ? onChangeCompany
                : onChangeIndividual
            }
            value={
              businessType === stripeBusinessType.COMPANY
                ? companyForm?.address?.line1
                : individualForm?.address?.line1
            }
            InputLabelProps={{ shrink: true }}
            size="small"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="line2"
            name="line2"
            label="Address Line 2 (Apartment, Suite, Unit, Building)"
            onChange={
              businessType === stripeBusinessType.COMPANY
                ? onChangeCompany
                : onChangeIndividual
            }
            value={
              businessType === stripeBusinessType.COMPANY
                ? companyForm?.address?.line2
                : individualForm?.address?.line2
            }
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="city"
            name="city"
            label="City"
            onChange={
              businessType === stripeBusinessType.COMPANY
                ? onChangeCompany
                : onChangeIndividual
            }
            value={
              businessType === stripeBusinessType.COMPANY
                ? companyForm?.address?.city
                : individualForm?.address?.city
            }
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="state"
            name="state"
            label="State"
            onChange={
              businessType === stripeBusinessType.COMPANY
                ? onChangeCompany
                : onChangeIndividual
            }
            value={
              businessType === stripeBusinessType.COMPANY
                ? companyForm?.address?.state
                : individualForm?.address?.state
            }
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CountrySelectMenu
            value={{
              code: 'US',
              label: 'United States',
              phone: '1',
              suggested: true,
            }}
            disabled
            shrink
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="postalCode"
            name="postal_code"
            label="Postal Code"
            onChange={
              businessType === stripeBusinessType.COMPANY
                ? onChangeCompany
                : onChangeIndividual
            }
            value={
              businessType === stripeBusinessType.COMPANY
                ? companyForm?.address?.postal_code
                : individualForm?.address?.postal_code
            }
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripeAddressForm;
