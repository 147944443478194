import React, {
  useReducer,
  createContext,
  useEffect,
  Context,
  ReactNode,
  useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { AUTH_TOKEN, LOGGED_IN_USER } from '../utils';
import setAuthToken from '../services/axios-client';

// initial state type
interface AuthUser {
  id: string;
  email: string;
  accessToken?: string | null;
  imageUrl?: string | null;
  name?: string | null;
  page?: string | null;
  refreshToken?: string | null;
  provider?: string | null;
  brand?: string | null;
  redirectUrl?: string | null;
  withAgencyAccess?: boolean | null;
  withBrandAccess?: boolean | null;
}

interface State {
  authUser: AuthUser | null;
  token: string | null;
  role: string | null;
  roleBasedId: string | null;
  isAuthenticated: boolean;
  accessToken: string | null;
  imageUrl: string | null;
  page: string | null;
  refreshToken: string | null;
  provider?: string | null;
  brand?: string | null;
  redirectUrl?: string | null;
  name?: string | null;
  withAgencyAccess?: boolean | null;
  withBrandAccess?: boolean | null;
  capabilities?: UserCapability;
}

// action type
interface Action {
  type: string;
  payload?: any;
}

interface ProviderProps {
  children: ReactNode;
}

// state
const initialState: State = {
  authUser: null,
  token: null,
  isAuthenticated: false,
  role: null,
  roleBasedId: null,
  accessToken: null,
  imageUrl: null,
  page: null,
  refreshToken: null,
  provider: null,
  brand: null,
  redirectUrl: null,
  name: null,
  capabilities: null,
  withAgencyAccess: null,
  withBrandAccess: null,
};

interface ContextValueType {
  state: State;
  dispatch: React.Dispatch<Action>;
  loading: boolean;
}

export interface UserCapability {
  approveTemplate: boolean;
  createTemplate: boolean;
  createAds: boolean;
  viewReports: boolean;
  addLocations: boolean;
  addUser: boolean;
  createBrands: boolean;
  viewBudgets: boolean;
  editBudgets: boolean;
  agencyAdmin: boolean;
}

interface DecodedToken {
  _id: string;
  email: string;
  role: string;
  roleBasedId: string;
  accessToken: string;
  imageUrl: string;
  page: string;
  refreshToken: string;
  provider: string;
  brand: string;
  redirectUrl: string;
  name: string;
  capabilities: UserCapability;
  withAgencyAccess: boolean;
  withBrandAccess: boolean;
}

// reducer Firebase
const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        authUser: action.payload.user, //TODO: need think how we get user info from the lg token
        token: action.payload.token,
        role: action.payload.role,
        roleBasedId: action.payload.roleBasedId,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        imageUrl: action.payload.imageUrl,
        page: action.payload.page,
        refreshToken: action.payload.refreshToken,
        provider: action.payload.provider,
        brand: action.payload.brand,
        redirectUrl: action.payload.redirectUrl,
        name: action.payload.name,
        capabilities: action.payload.capabilities,
        withBrandAccess: action.payload.withBrandAccess,
        withAgencyAccess: action.payload.withAgencyAccess,
      };
    default:
      return state;
  }
};

// create context
export const AuthContext: Context<ContextValueType> = createContext(
  {} as ContextValueType,
);

// create provider
const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [loading, setLoading] = useState<boolean>(true);
  const value: ContextValueType = { state, dispatch, loading };

  useEffect(() => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN);
      if (token) {
        const decode: DecodedToken = jwtDecode(token);
        setAuthToken(token);
        dispatch({
          type: LOGGED_IN_USER,
          //TODO: need provide only the info that we get letter on from lg token
          payload: {
            token: token,
            role: decode?.role,
            roleBasedId: decode?.roleBasedId,
            capabilities: decode?.capabilities,
            withAgencyAccess: decode?.withAgencyAccess,
            withBrandAccess: decode?.withBrandAccess,
            user: {
              id: decode?._id,
              email: decode?.email,
              accessToken: decode?.accessToken,
              imageUrl: decode?.imageUrl,
              page: decode?.page,
              refreshToken: decode?.refreshToken,
              provider: decode?.provider,
              brand: decode?.brand,
              redirectUrl: decode?.redirectUrl,
              name: decode?.name,
            },
          },
        });
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
