import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';

interface AssetGoogleHeadlinesProps {
  submitForm: any;
  handleInputChange: any;
  submenuForm: any;
  selectedProductAndService: string;
  setIsDirty: any;
}

const AssetGoogleHeadlines: React.FC<AssetGoogleHeadlinesProps> = ({
  submitForm,
  handleInputChange,
  submenuForm,
  selectedProductAndService,
  setIsDirty,
}) => {
  const tempH = () => {
    const headlines = [];
    for (let i = 0; i < 5; i++) {
      headlines.push('');
    }
    return headlines;
  };
  const [headlines, setHeadlines] = useState([...tempH()]);
  const maxItems = googleResourceLimits.headlines.items;
  const maxLength = googleResourceLimits.headlines.length;

  useEffect(() => {
    if (submenuForm.headlines) {
      setHeadlines(submenuForm.headlines);
    }
  }, [submenuForm]);

  return (
    <Box sx={GoogleAssetsContainer}>
      <FloatingButton
        title="Save"
        onClick={() => {
          submitForm({
            submenu: 'headlines',
            submenuData: { headlines },
          });
        }}
      />
      <Typography
        variant="h6"
        fontWeight={800}
        mb={2}
        color={DEFAULT_COLOR_THEME}
      >
        Headlines [{headlines.length} of {maxItems}]
      </Typography>
      {headlines.map((headline, index) => (
        <Box
          sx={{
            textAlign: 'right',
            marginBottom: '1rem',
            maxWidth: 360,
          }}
        >
          <FormControl variant="outlined" key={index} fullWidth>
            <InputLabel
              htmlFor={`Headline-${index}`}
              sx={{
                backgroundColor: 'white',
              }}
            >
              Headline {index + 1}
            </InputLabel>
            <OutlinedInput
              id={`Headline-${index}`}
              type={'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      const newHeadlines = [...headlines];
                      newHeadlines.splice(index, 1);
                      setHeadlines(newHeadlines);
                      setIsDirty(true);
                    }}
                    edge="end"
                  >
                    <MdDeleteOutline color="#aaaaaa" />
                  </IconButton>
                </InputAdornment>
              }
              name={`Headline-${index}`}
              fullWidth
              value={headline}
              inputProps={{ maxLength }}
              onChange={(e) => {
                setIsDirty(true);
                const updatedHeadlines = [...headlines];
                updatedHeadlines[index] = e.target.value;
                setHeadlines(updatedHeadlines);
              }}
            />
          </FormControl>
          <Typography variant="body2" color="textSecondary">
            {`${headline.length}/${maxLength}`}
          </Typography>
        </Box>
      ))}
      {headlines.length < maxItems ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const newHeadlines = [...headlines];
            newHeadlines.push('');
            setHeadlines(newHeadlines);
          }}
        >
          Add Headline
        </Button>
      ) : null}
    </Box>
  );
};

export default AssetGoogleHeadlines;
