import { Box } from '@mui/material';
import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import momentTz from 'moment-timezone';
import { subscriptionTableColumns } from './SubscriptionsListTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';

interface SubscriptionsListTableProps {
  subscriptions: any[];
  loading: boolean;
  onOpenPayment?: (clientSecret: string, transactionType: string) => void;
  onOpenCancel: (subscriptionId: string) => void;
  onOpenRenew?: (subscriptionId: string) => void;
}

const SubscriptionsListTable: React.FC<SubscriptionsListTableProps> = ({
  subscriptions,
  loading,
  onOpenPayment,
  onOpenCancel,
  onOpenRenew,
}) => {
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );

  const rows = subscriptions.map((subscription) => {
    const {
      id,
      status,
      created,
      current_period_end,
      current_period_start,
      cancel_at,
    } = subscription;

    return {
      id,
      status,
      startDate: momentTz.unix(created).tz(timezone).format('LL'),
      periodStart: momentTz
        .unix(current_period_start)
        .tz(timezone)
        .format('LL'),
      periodEnd: momentTz.unix(current_period_end).tz(timezone).format('LL'),
      clientSecret: subscription.latest_invoice?.payment_intent?.client_secret,
      paymentMethod: subscription?.default_payment_method,
      onOpenPayment,
      onOpenCancel,
      cancelAt: cancel_at,
      formattedCancelAt: cancel_at
        ? momentTz.unix(cancel_at).tz(timezone).format('MMM DD')
        : null,
      onOpenRenew,
      paymentIntent: subscription.latest_invoice?.payment_intent,
      invoicePageUrl: subscription.latest_invoice?.hosted_invoice_url,
    };
  }, []);

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={subscriptionTableColumns}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default SubscriptionsListTable;
