import { Card } from '@mui/material';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { toCurrency } from '../../utils/numberFormatter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
);

const CHART_HEIGHT: number = 400;

interface BarChartProps {
  trend?: string;
  chartData: any;
  horizontal?: boolean;
  title?: string | null;
  subtitle?: string | null;
  titleFontSize?: number;
  showLegend?: boolean;
  isCurrency?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  trend,
  chartData,
  horizontal = false,
  title = null,
  subtitle = null,
  titleFontSize = 14,
  showLegend = true,
  isCurrency = false,
}) => {
  const options = () => {
    let option: any = {
      responsive: true,
      plugins: {
        legend: {
          display: showLegend,
          position: horizontal ? ('right' as const) : ('top' as const),
        },
        title: {
          display: true,
          text: title ? [title] : [`${trend} Day Trend`],
          font: {
            size: titleFontSize,
          },
        },
        subtitle: {
          display: true,
          text: subtitle
            ? subtitle
            : `Most recent ${trend} days compared to previous ${trend} days`,
        },
      },
      maintainAspectRatio: false,
    };

    if (horizontal) {
      option = { ...option, indexAxis: 'y' as const };
    }

    if (isCurrency) {
      option = {
        ...option,
        scales: {
          x: {
            ticks: {
              callback: (value: any, index: number, ticks: any) => {
                return toCurrency('USD', value, 0);
              },
            },
          },
        },
      };
    }

    return option;
  };

  return (
    <Card sx={{ padding: '20px' }}>
      {chartData ? (
        <Bar options={options()} data={chartData} height={CHART_HEIGHT} />
      ) : null}
    </Card>
  );
};

export default BarChart;
