import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoMdLink, IoMdAdd } from 'react-icons/io';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { GoogleAssetsContainer } from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import { fetchGoogleKeywordIdeas } from '../../../../services/googleAds';
import { toggleAlert } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

interface AssetGoogleKeywordsProps {
  selectedProductAndService: string;
  submitForm: any;
  defaultRefreshToken: string;
  submenuForm: any;
  brandId: string;
  setIsDirty: any;
}

const AssetGoogleKeywords: React.FC<AssetGoogleKeywordsProps> = ({
  selectedProductAndService,
  submitForm,
  defaultRefreshToken,
  submenuForm,
  brandId,
  setIsDirty,
}) => {
  const [keywordPage, setKeywordPage] = useState(2);
  const [suggestedKeywords, setSuggestedKeywords] = useState<
    {
      keyword: string;
      avg_monthly_searches: string;
      competition: string;
      high_top_of_page_bid_micros: string;
      low_top_of_page_bid_micros: string;
    }[]
  >([]);
  const [textboxKeywords, setTextboxKeywords] = useState<string>('');
  const [keywords, setKeywords] = useState<
    {
      exact: boolean;
      phrase: boolean;
      broad: boolean;
      keyword: string;
    }[]
  >([]);
  const [keywordUrl, setKeywordUrl] = useState<string>('');
  const [isGeneratingKeywords, setIsGeneratingKeywords] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (submenuForm.keywords) {
      setKeywords(submenuForm.keywords);
      if (submenuForm.keywords.length === 0) {
        setKeywordPage(1);
        setTextboxKeywords('');
        setSuggestedKeywords([]);
        generateKeywords();
      } else {
        setKeywordPage(2);
      }
    }
  }, [submenuForm]);

  useEffect(() => {
    setKeywordPage(2);
    setTextboxKeywords('');
  }, [selectedProductAndService]);

  const generateKeywords = async () => {
    setIsGeneratingKeywords(true);
    const ideas = await fetchGoogleKeywordIdeas({
      keywords: keywords.map((keyword) => keyword.keyword),
      brandId: brandId,
      url: '',
    });

    console.log(`$ideas`);
    console.log(ideas);
    if (ideas.keywordIdeas) {
      const kis = ideas.keywordIdeas.map((k: any) => {
        return {
          keyword: k.text,
          avg_monthly_searches: k.keyword_idea_metrics.avg_monthly_searches,
          competition: k.keyword_idea_metrics.competition,
          high_top_of_page_bid_micros:
            k.keyword_idea_metrics.high_top_of_page_bid_micros,
          low_top_of_page_bid_micros:
            k.keyword_idea_metrics.low_top_of_page_bid_micros,
        };
      });

      console.log(`$kis`);
      console.log(kis);

      setSuggestedKeywords([
        ...kis.filter(
          (k: any) =>
            !keywords.map((keyword) => keyword.keyword).includes(k.keyword),
        ),
        ...suggestedKeywords,
      ]);
    }

    setIsGeneratingKeywords(false);
  };

  const submitCheck = () => {
    const newkeywords = saveBulkKeywords();

    let errors = 0;
    newkeywords.forEach((keyword) => {
      if (!keyword.exact && !keyword.phrase && !keyword.broad) {
        errors++;
      }
    });

    if (errors > 0) {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please select at least one match type for each keyword',
          type: 'error',
        }),
      );
    }

    submitForm({
      submenu: 'keywords',
      submenuData: { keywords },
    });
  };

  const saveBulkKeywords = () => {
    //split by comma and newline
    console.log(`$textboxKeywords`);
    console.log(textboxKeywords);

    const kw = textboxKeywords.split(/,|\n/);

    console.log(`$kw`);
    console.log(kw);

    const newkeywords = [
      ...keywords.filter(
        (keyword) => keyword.keyword !== '' && kw.includes(keyword.keyword),
      ),
      ...kw
        .filter(
          (keyword) =>
            keyword !== '' &&
            !keywords
              .map((keyword) => keyword.keyword)
              .includes(keyword.toLowerCase()),
        )
        .map((keyword) => {
          return {
            exact: false,
            phrase: false,
            broad: false,
            keyword: keyword.toLowerCase(),
          };
        }),
    ];

    setKeywords(newkeywords);

    setKeywordPage(2);

    return newkeywords;
  };

  return (
    <Box sx={GoogleAssetsContainer}>
      <FloatingButton fixed title="Save" onClick={submitCheck} />
      {keywordPage === 1 ? (
        <>
          <Box
            display={'flex'}
            justifyContent={`space-between`}
            gap={4}
            sx={{
              width: `100%`,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              mb={2}
              color={DEFAULT_COLOR_THEME}
            >
              Edit Keywords
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={saveBulkKeywords}
            >
              Configure Match Types
            </Button>
          </Box>
          <Box display={'flex'} justifyContent={`space-between`} gap={4}>
            <Box
              sx={{
                width: `100%`,
                maxWidth: `45%`,
              }}
            >
              <Box
                sx={{
                  width: `100%`,
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={25}
                  variant="standard"
                  type="text"
                  value={textboxKeywords}
                  onChange={(e) => {
                    setIsDirty(true);
                    setTextboxKeywords(e.target.value);
                  }}
                  placeholder={`Enter or paste keywords. You can separate each keyword by commas or enter one per line.`}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    padding: `0px 10px`,
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    backgroundColor: `#ffffff`,
                  }}
                />
              </Box>
              <Box textAlign={`center`} display={`none`}>
                <Button
                  variant="contained"
                  sx={{
                    width: `100%`,
                    marginY: 2,
                    mx: `auto`,
                  }}
                  onClick={saveBulkKeywords}
                >
                  Configure Match Types
                </Button>
              </Box>
            </Box>

            <Box
              padding={1}
              borderRadius={2}
              //border={`2px solid ${DEFAULT_COLOR_THEME}`}
              sx={{
                width: `100%`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                <Box
                  bgcolor={DEFAULT_COLOR_THEME}
                  borderRadius={100}
                  height={32}
                  width={32}
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  flexShrink={0}
                >
                  <IoMdLink size={24} color="#ffffff" />
                </Box>
                <TextField
                  id="input-with-sx"
                  label="Enter a related website"
                  variant="standard"
                  fullWidth
                  value={keywordUrl}
                  onChange={(e) => {
                    setIsDirty(true);
                    setKeywordUrl(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: 2,
                  my: 1,
                }}
              >
                <Box
                  bgcolor={DEFAULT_COLOR_THEME}
                  borderRadius={100}
                  height={32}
                  width={32}
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  flexShrink={0}
                >
                  <HiBuildingOffice2 size={24} color="#ffffff" />
                </Box>
                <TextField
                  id="input-with-sx"
                  label="Enter your product or service"
                  variant="standard"
                  value={selectedProductAndService}
                  fullWidth
                />
              </Box>

              <Typography>
                Suggested Keywords for {selectedProductAndService}
              </Typography>

              <Box
                bgcolor={`#e5e5e5`}
                my={2}
                borderRadius={2}
                maxHeight={`385px`}
                overflow={`auto`}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    // background: "#F1F0F0",
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: DEFAULT_COLOR_THEME,
                    borderRadius: '18px',

                    '&:hover': {
                      background: DEFAULT_COLOR_THEME,
                    },
                  },
                  padding: '5px',
                }}
              >
                {suggestedKeywords.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <>
                    {suggestedKeywords.map((k) => {
                      return (
                        <Box>
                          <Button
                            variant="text"
                            type="button"
                            size="small"
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              gap: 1,
                              width: `100%`,
                              justifyContent: 'left',
                              backgroundColor: `#ffffff`,
                            }}
                            onClick={() => {
                              setTextboxKeywords(
                                textboxKeywords + '\n' + k.keyword,
                              );
                              setSuggestedKeywords(
                                suggestedKeywords.filter(
                                  (kw) => kw.keyword !== k.keyword,
                                ),
                              );
                            }}
                          >
                            <Box
                              borderRadius={100}
                              height={24}
                              width={24}
                              display={`flex`}
                              justifyContent={`center`}
                              alignItems={`center`}
                              flexShrink={0}
                            >
                              <IoMdAdd size={24} color={DEFAULT_COLOR_THEME} />
                            </Box>
                            <Tooltip
                              title={`Avg Monthly Searches: ${k.avg_monthly_searches}\nCompetition: ${k.competition}\nHigh Top of Page Bid: ${k.high_top_of_page_bid_micros}\nLow Top of Page Bid: ${k.low_top_of_page_bid_micros}`}
                            >
                              <Typography color={`#000`} fontSize={12}>
                                {k.keyword}
                              </Typography>
                            </Tooltip>
                          </Button>
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  width: `100%`,
                }}
              >
                <Button
                  variant="contained"
                  sx={{ width: `50%` }}
                  onClick={() => {
                    setTextboxKeywords(
                      textboxKeywords +
                        '\n' +
                        suggestedKeywords
                          .map((k) => k.keyword)
                          .join('\n')
                          .replace(/,/g, ''),
                    );
                    setSuggestedKeywords([]);
                    //setKeywordPage(1);
                  }}
                >
                  Add All Keyword Suggestions
                </Button>
                <Button
                  disabled={isGeneratingKeywords}
                  variant="contained"
                  sx={{ width: `50%` }}
                  onClick={generateKeywords}
                >
                  {isGeneratingKeywords
                    ? 'Generating Keywords'
                    : 'Generate More Suggestions'}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={`space-between`}
            gap={4}
            sx={{
              width: `100%`,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              mb={2}
              color={DEFAULT_COLOR_THEME}
            >
              Keywords
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => {
                setTextboxKeywords(
                  keywords.map((keyword) => keyword.keyword).join('\n'),
                );
                setKeywordPage(1);
                setSuggestedKeywords([]);
                generateKeywords();
                console.log(`$defaultRefreshToken`);
                console.log(defaultRefreshToken);
              }}
            >
              Edit Keywords
            </Button>
          </Box>
          {keywords.length === 0 ? (
            <>No Keywords Yet</>
          ) : (
            <>
              <Box
                padding={`0 0 0 0`}
                borderRadius={2}
                display={'flex'}
                justifyContent={`space-between`}
                gap={1}
                sx={{
                  width: `100%`,
                  maxWidth: `45%`,
                }}
              >
                <Box
                  sx={{
                    width: `100%`,
                    maxWidth: `45%`,
                  }}
                ></Box>
                <Box
                  display={'flex'}
                  justifyContent={`center`}
                  sx={{
                    width: `100%`,
                    maxWidth: `45%`,
                  }}
                >
                  Match Types
                </Box>
              </Box>
              <Box
                padding={`0 0 0 0`}
                borderRadius={2}
                display={'flex'}
                justifyContent={`space-between`}
                gap={1}
                sx={{
                  width: `100%`,
                  maxWidth: `45%`,
                }}
                position={`sticky`}
                top={0}
                zIndex={2}
              >
                <Box
                  sx={{
                    width: `100%`,
                    maxWidth: `45%`,
                  }}
                ></Box>
                <Box
                  display={'flex'}
                  justifyContent={`space-between`}
                  sx={{
                    width: `100%`,
                    maxWidth: `45%`,
                  }}
                  bgcolor={`#ffffff`}
                  zIndex={2}
                >
                  <Typography bgcolor={`#ffffff`} zIndex={2}>
                    Exact
                  </Typography>
                  <Typography bgcolor={`#ffffff`} zIndex={2}>
                    Phrase
                  </Typography>
                  <Typography bgcolor={`#ffffff`} zIndex={2}>
                    Broad
                  </Typography>
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={`column`}
                justifyContent={`space-between`}
                gap={1}
              >
                {keywords.map((keyword) => {
                  return (
                    <>
                      <Box
                        padding={1}
                        borderRadius={2}
                        border={`2px solid ${
                          !keyword.exact && !keyword.phrase && !keyword.broad
                            ? 'red'
                            : DEFAULT_COLOR_THEME
                        }`}
                        display={'flex'}
                        justifyContent={`space-between`}
                        gap={1}
                        sx={{
                          width: `100%`,
                          maxWidth: `45%`,
                        }}
                      >
                        <Box
                          sx={{
                            width: `100%`,
                            maxWidth: `45%`,
                          }}
                        >
                          {keyword.keyword}
                        </Box>
                        <Box
                          display={'flex'}
                          justifyContent={`space-between`}
                          sx={{
                            width: `100%`,
                            maxWidth: `45%`,
                          }}
                        >
                          <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            checked={keyword.exact}
                            onChange={(e) => {
                              setIsDirty(true);
                              const temp = [...keywords];
                              const index = temp.findIndex(
                                (kw) => kw.keyword === keyword.keyword,
                              );
                              temp[index].exact = !temp[index].exact;
                              setKeywords(temp);
                            }}
                          />
                          <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            checked={keyword.phrase}
                            onChange={(e) => {
                              setIsDirty(true);
                              const temp = [...keywords];
                              const index = temp.findIndex(
                                (kw) => kw.keyword === keyword.keyword,
                              );
                              temp[index].phrase = !temp[index].phrase;
                              setKeywords(temp);
                            }}
                          />
                          <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            checked={keyword.broad}
                            onChange={(e) => {
                              setIsDirty(true);
                              const temp = [...keywords];
                              const index = temp.findIndex(
                                (kw) => kw.keyword === keyword.keyword,
                              );
                              temp[index].broad = !temp[index].broad;
                              setKeywords(temp);
                            }}
                          />
                        </Box>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default AssetGoogleKeywords;
