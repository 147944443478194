import { TOGGLE_ALERT } from '../ActionNames';

const toggleAlert = (state: any = { toggle: null }, action: any) => {
  switch (action.type) {
    case TOGGLE_ALERT:
      return {
        ...state,
        toggle: action.payload,
      };
    default:
      return state;
  }
};

export { toggleAlert };
