import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoMdLink, IoMdAdd } from 'react-icons/io';
import { FaRegTrashAlt } from 'react-icons/fa';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import { fetchGoogleKeywordIdeas } from '../../../../services/googleAds';
import { toggleAlert } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

interface GoogleTemplatesKeywordsProps {
  selectedProductAndService: string;
  submitForm: any;
  templateUrl: string;
  defaultRefreshToken: string;
  templateForm: any;
  brandId: string;
  setIsDirty: any;
  currentPage: string;
}

const GoogleTemplatesKeywords: React.FC<GoogleTemplatesKeywordsProps> = ({
  selectedProductAndService,
  templateUrl,
  submitForm,
  defaultRefreshToken,
  templateForm,
  brandId,
  setIsDirty,
  currentPage,
}) => {
  const [keywordPage, setKeywordPage] = useState(2);
  const [psForKeywords, setPsForKeywords] = useState<string>('');
  const [suggestedKeywords, setSuggestedKeywords] = useState<
    {
      keyword: string;
      avg_monthly_searches: string;
      competition: string;
      high_top_of_page_bid_micros: string;
      low_top_of_page_bid_micros: string;
    }[]
  >([]);
  const [textboxKeywords, setTextboxKeywords] = useState<string>('');
  const [keywords, setKeywords] = useState<
    {
      exact: boolean;
      phrase: boolean;
      broad: boolean;
      keyword: string;
    }[]
  >([]);
  const [keywordUrl, setKeywordUrl] = useState<string>('');
  const [isGeneratingKeywords, setIsGeneratingKeywords] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPage === 'Keywords') {
      editKeywords();
      setKeywordUrl(templateUrl);
      setPsForKeywords(selectedProductAndService);
      console.log(`$templateForm`);
      console.log(templateForm);
      setKeywords(templateForm);
      setTextboxKeywords(templateForm.map((k: any) => k.keyword).join('\n'));
    }

    if (currentPage === 'Keywords Matching Type') {
      saveBulkKeywords();
      setKeywords(templateForm);
      setTextboxKeywords(templateForm.map((k: any) => k.keyword).join('\n'));
    }
  }, [currentPage]);

  useEffect(() => {
    submitForm(filterKeywords());
  }, [textboxKeywords]);

  //   useEffect(() => {
  //     setKeywordPage(2);
  //     setTextboxKeywords('');
  //   }, [selectedProductAndService]);

  const editKeywords = () => {
    setTextboxKeywords(keywords.map((keyword) => keyword.keyword).join('\n'));
    setKeywordPage(1);
    setSuggestedKeywords([]);
    generateKeywords();
  };

  const setPage2 = () => {};

  const generateKeywords = async () => {
    setIsGeneratingKeywords(true);
    const ideas = await fetchGoogleKeywordIdeas({
      keywords:
        keywords.map((keyword) => keyword.keyword).length > 0
          ? [psForKeywords, ...keywords.map((keyword) => keyword.keyword)]
          : [psForKeywords ? psForKeywords : selectedProductAndService],
      brandId: brandId,
      url: '',
    });

    if (ideas.keywordIdeas) {
      const kis = ideas.keywordIdeas.map((k: any) => {
        return {
          keyword: k.text,
          avg_monthly_searches: k?.keyword_idea_metrics?.avg_monthly_searches,
          competition: k?.keyword_idea_metrics?.competition,
          high_top_of_page_bid_micros:
            k?.keyword_idea_metrics?.high_top_of_page_bid_micros,
          low_top_of_page_bid_micros:
            k?.keyword_idea_metrics?.low_top_of_page_bid_micros,
        };
      });

      setSuggestedKeywords([
        ...kis.filter(
          (k: any) =>
            !keywords.map((keyword) => keyword.keyword).includes(k.keyword),
        ),
        ...suggestedKeywords,
      ]);
    }

    setIsGeneratingKeywords(false);
  };

  const submitCheck = () => {
    const newkeywords = saveBulkKeywords();

    let errors = 0;
    newkeywords.forEach((keyword) => {
      if (!keyword.exact && !keyword.phrase && !keyword.broad) {
        errors++;
      }
    });

    if (errors > 0) {
      return dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please select at least one match type for each keyword',
          type: 'error',
        }),
      );
    }

    submitForm({
      submenu: 'keywords',
      submenuData: { keywords },
    });
  };

  const filterKeywords = (extra: string[] = []) => {
    const kw = extra
      ? [...extra, ...textboxKeywords.split(/,|\n/)]
      : textboxKeywords.split(/,|\n/);
    const newkeywords = [
      ...keywords.filter(
        (keyword) => keyword.keyword !== '' && kw.includes(keyword.keyword),
      ),
      ...kw
        .filter(
          (keyword) =>
            keyword !== '' &&
            !keywords
              .map((keyword) => keyword.keyword)
              .includes(keyword.toLowerCase()),
        )
        .map((keyword) => {
          return {
            exact: false,
            phrase: false,
            broad: false,
            keyword: keyword.toLowerCase(),
          };
        }),
    ];
    return newkeywords;
  };

  const saveBulkKeywords = () => {
    const newkeywords = filterKeywords();
    setKeywords(newkeywords);

    setKeywordPage(2);

    return newkeywords;
  };

  return (
    <Box sx={{}}>
      {keywordPage === 1 ? (
        <>
          <Box
            display={'flex'}
            justifyContent={`space-between`}
            gap={4}
            sx={{
              width: `100%`,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              mb={2}
              color={DEFAULT_COLOR_THEME}
            >
              Create Keywords
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30, display: `none` }}
              onClick={saveBulkKeywords}
            >
              Configure Match Types
            </Button>
          </Box>
          <Box display={'flex'} justifyContent={`space-between`} gap={4}>
            <Box
              sx={{
                width: `100%`,
                maxWidth: `45%`,
              }}
            >
              <Box
                sx={{
                  width: `100%`,
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={18}
                  variant="standard"
                  type="text"
                  value={textboxKeywords}
                  onChange={(e) => {
                    setIsDirty(true);
                    setTextboxKeywords(e.target.value);
                  }}
                  placeholder={`Enter or paste keywords. You can separate each keyword by commas or enter one per line.`}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    padding: `0px 10px`,
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    backgroundColor: `#ffffff`,
                  }}
                />
              </Box>
            </Box>

            <Box
              padding={1}
              borderRadius={2}
              //border={`2px solid ${DEFAULT_COLOR_THEME}`}
              sx={{
                width: `100%`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                <Box
                  bgcolor={DEFAULT_COLOR_THEME}
                  borderRadius={100}
                  height={32}
                  width={32}
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  flexShrink={0}
                >
                  <IoMdLink size={24} color="#ffffff" />
                </Box>
                <TextField
                  id="input-with-sx"
                  label="Enter a related website"
                  variant="standard"
                  fullWidth
                  value={keywordUrl}
                  onChange={(e) => {
                    setIsDirty(true);
                    setKeywordUrl(e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: 2,
                  my: 1,
                }}
              >
                <Box
                  bgcolor={DEFAULT_COLOR_THEME}
                  borderRadius={100}
                  height={32}
                  width={32}
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  flexShrink={0}
                >
                  <HiBuildingOffice2 size={24} color="#ffffff" />
                </Box>
                <TextField
                  id="input-with-sx"
                  label="Enter your product or service"
                  variant="standard"
                  value={psForKeywords}
                  onChange={(e) => {
                    setPsForKeywords(e.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Typography>
                Suggested Keywords for {selectedProductAndService}
              </Typography>

              <Box
                bgcolor={`#e5e5e5`}
                my={2}
                borderRadius={2}
                maxHeight={`225px`}
                overflow={`auto`}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    // background: "#F1F0F0",
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: DEFAULT_COLOR_THEME,
                    borderRadius: '18px',

                    '&:hover': {
                      background: DEFAULT_COLOR_THEME,
                    },
                  },
                  padding: '5px',
                }}
              >
                {suggestedKeywords.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <>
                    {suggestedKeywords.map((k) => {
                      return (
                        <Box>
                          <Button
                            variant="text"
                            type="button"
                            size="small"
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              gap: 1,
                              width: `100%`,
                              justifyContent: 'left',
                              backgroundColor: `#ffffff`,
                            }}
                            onClick={() => {
                              setTextboxKeywords(
                                textboxKeywords + '\n' + k.keyword,
                              );
                              setSuggestedKeywords(
                                suggestedKeywords.filter(
                                  (kw) => kw.keyword !== k.keyword,
                                ),
                              );
                              submitForm([...filterKeywords([k.keyword])]);
                            }}
                          >
                            <Box
                              borderRadius={100}
                              height={24}
                              width={24}
                              display={`flex`}
                              justifyContent={`center`}
                              alignItems={`center`}
                              flexShrink={0}
                            >
                              <IoMdAdd size={24} color={DEFAULT_COLOR_THEME} />
                            </Box>
                            <Tooltip
                              title={`Avg Monthly Searches: ${k.avg_monthly_searches}\nCompetition: ${k.competition}\nHigh Top of Page Bid: ${k.high_top_of_page_bid_micros}\nLow Top of Page Bid: ${k.low_top_of_page_bid_micros}`}
                            >
                              <Typography color={`#000`} fontSize={12}>
                                {k.keyword}
                              </Typography>
                            </Tooltip>
                          </Button>
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  width: `100%`,
                }}
              >
                <Button
                  variant="contained"
                  sx={{ width: `50%` }}
                  onClick={() => {
                    setTextboxKeywords(
                      textboxKeywords +
                        '\n' +
                        suggestedKeywords
                          .map((k) => k.keyword)
                          .join('\n')
                          .replace(/,/g, ''),
                    );
                    setSuggestedKeywords([]);
                    generateKeywords();
                    submitForm([
                      ...filterKeywords(
                        suggestedKeywords.map((k) => k.keyword),
                      ),
                    ]);
                    //setKeywordPage(1);
                  }}
                >
                  Add All Keyword Suggestions
                </Button>
                <Button
                  disabled={isGeneratingKeywords}
                  variant="contained"
                  sx={{ width: `50%` }}
                  onClick={generateKeywords}
                >
                  {isGeneratingKeywords
                    ? 'Generating Keywords'
                    : 'Generate More Suggestions'}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={`space-between`}
            gap={4}
            sx={{
              width: `100%`,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={800}
              mb={2}
              color={DEFAULT_COLOR_THEME}
            >
              Configure Match Types
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30, display: `none` }}
              onClick={editKeywords}
            >
              Edit Keywords
            </Button>
          </Box>
          {keywords.length === 0 ? (
            <>No Keywords Yet</>
          ) : (
            <>
              <Box
                padding={`0 0 0 0`}
                borderRadius={2}
                display={'flex'}
                justifyContent={`space-between`}
                gap={1}
                sx={{
                  width: `100%`,
                  maxWidth: `45%`,
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <TableContainer
                    sx={{
                      maxHeight: 500,
                      position: 'relative',
                      ...scrollBarGreen,
                      overflowX: 'hidden',
                    }}
                  >
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 650,
                        border: `2px solid ${DEFAULT_COLOR_THEME}`,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            backgroundColor: DEFAULT_COLOR_THEME,
                          }}
                        >
                          <TableCell
                            sx={{
                              border: 'none',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          ></TableCell>
                          <TableCell
                            width={50}
                            padding="none"
                            align="center"
                            colSpan={3}
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingLeft: '10px',
                              paddingY: '5px',
                              border: 'none',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Match Types
                          </TableCell>
                          <TableCell
                            sx={{
                              border: 'none',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          ></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            width={300}
                            padding="none"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingLeft: '10px',
                              paddingY: '5px',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Keyword
                          </TableCell>
                          <TableCell
                            width={50}
                            padding="none"
                            align="center"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingY: '5px',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Exact
                          </TableCell>
                          <TableCell
                            width={50}
                            padding="none"
                            align="center"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingY: '5px',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Phrase
                          </TableCell>
                          <TableCell
                            width={50}
                            padding="none"
                            align="center"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingY: '5px',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Broad
                          </TableCell>
                          <TableCell
                            width={50}
                            padding="none"
                            align="center"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 800,
                              color: '#ffffff',
                              paddingY: '5px',
                              backgroundColor: DEFAULT_COLOR_THEME,
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {keywords.map((keyword) => (
                          <TableRow
                            key={keyword.keyword}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {keyword.keyword}
                            </TableCell>
                            <TableCell width={50}>
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                                checked={keyword.exact}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const temp = [...keywords];
                                  const index = temp.findIndex(
                                    (kw) => kw.keyword === keyword.keyword,
                                  );
                                  temp[index].exact = !temp[index].exact;
                                  setKeywords(temp);
                                }}
                              />
                            </TableCell>
                            <TableCell width={50}>
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                                checked={keyword.phrase}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const temp = [...keywords];
                                  const index = temp.findIndex(
                                    (kw) => kw.keyword === keyword.keyword,
                                  );
                                  temp[index].phrase = !temp[index].phrase;
                                  setKeywords(temp);
                                }}
                              />
                            </TableCell>
                            <TableCell width={50}>
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                                checked={keyword.broad}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const temp = [...keywords];
                                  const index = temp.findIndex(
                                    (kw) => kw.keyword === keyword.keyword,
                                  );
                                  temp[index].broad = !temp[index].broad;
                                  setKeywords(temp);
                                }}
                              />
                            </TableCell>
                            <TableCell width={50}>
                              <Button
                                onClick={() => {
                                  setIsDirty(true);
                                  setKeywords(
                                    keywords.filter(
                                      (kw) => kw.keyword !== keyword.keyword,
                                    ),
                                  );
                                }}
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default GoogleTemplatesKeywords;
